var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "share-place-widget" }, [
    _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "title" }, [
        _vm._v(_vm._s(_vm.$t("sharePlaceWidget.title"))),
      ]),
      _c(
        "button",
        { staticClass: "close ripple", on: { click: _vm.onClickClose } },
        [_c("i", { staticClass: "material-icons-round" }, [_vm._v("close")])]
      ),
    ]),
    _c("div", { staticClass: "app-list" }, [
      _c("div", { staticClass: "button-group" }, [
        _c("button", { on: { click: _vm.copyLink } }, [
          _c("i", { staticClass: "material-icons-round" }, [_vm._v("link")]),
        ]),
        _c("label", [_vm._v(_vm._s(_vm.$t("sharePlaceWidget.copy")))]),
      ]),
      _c("div", { staticClass: "button-group" }, [
        _c(
          "button",
          {
            staticStyle: { "background-color": "#03c755" },
            on: { click: _vm.clickLineButton },
          },
          [
            _c("img", {
              attrs: {
                src: _vm.baseUrl + "img/share/line@2x.png?" + _vm.appVersion,
                srcset:
                  _vm.baseUrl +
                  "img/share/line.png?" +
                  _vm.appVersion +
                  " 1x, " +
                  _vm.baseUrl +
                  "img/share/line@2x.png?" +
                  _vm.appVersion +
                  " 2x",
              },
            }),
          ]
        ),
        _c("label", [_vm._v(_vm._s("LINE"))]),
      ]),
      _c("div", { staticClass: "button-group" }, [
        _c(
          "button",
          {
            staticStyle: { "background-color": "#0766ff" },
            on: { click: _vm.clickFacebookButton },
          },
          [
            _c("img", {
              attrs: {
                src:
                  _vm.baseUrl + "img/share/facebook@2x.png?" + _vm.appVersion,
                srcset:
                  _vm.baseUrl +
                  "img/share/facebook.png?" +
                  _vm.appVersion +
                  " 1x, " +
                  _vm.baseUrl +
                  "img/share/facebook@2x.png?" +
                  _vm.appVersion +
                  " 2x",
              },
            }),
          ]
        ),
        _c("label", [_vm._v(_vm._s("Facebook"))]),
      ]),
      _c("div", { staticClass: "button-group" }, [
        _c("button", { on: { click: _vm.clickGoogleMapButton } }, [
          _c("img", {
            attrs: {
              src:
                _vm.baseUrl + "img/share/google_map@2x.png?" + _vm.appVersion,
              srcset:
                _vm.baseUrl +
                "img/share/google_map.png?" +
                _vm.appVersion +
                " 1x, " +
                _vm.baseUrl +
                "img/share/google_map@2x.png?" +
                _vm.appVersion +
                " 2x",
            },
          }),
        ]),
        _c("label", [_vm._v(_vm._s("Google Maps"))]),
      ]),
      _vm.isMacIOS
        ? _c("div", { staticClass: "button-group" }, [
            _c("button", { on: { click: _vm.clickAppleMapButton } }, [
              _c("img", {
                attrs: {
                  src:
                    _vm.baseUrl +
                    "img/share/apple_map@2x.png?" +
                    _vm.appVersion,
                  srcset:
                    _vm.baseUrl +
                    "img/share/apple_map.png?" +
                    _vm.appVersion +
                    " 1x, " +
                    _vm.baseUrl +
                    "img/share/apple_map@2x.png?" +
                    _vm.appVersion +
                    " 2x",
                },
              }),
            ]),
            _c("label", [_vm._v(_vm._s("Apple Map"))]),
          ])
        : _vm._e(),
    ]),
    _c(
      "div",
      { staticClass: "link" },
      [
        _c("input", {
          ref: "share-url-input",
          attrs: { type: "link", readonly: "" },
          domProps: { value: _vm.placeUrl },
          on: { click: _vm.clickLink },
        }),
        _c("button", { on: { click: _vm.copyLink } }, [
          _vm._v(_vm._s(_vm.$t("sharePlaceWidget.copy"))),
        ]),
        _c("transition", { attrs: { name: "fade" } }, [
          _vm.isShowCopied
            ? _c("div", { staticClass: "copied" }, [
                _vm._v(_vm._s(_vm.$t("sharePlaceWidget.copy"))),
              ])
            : _vm._e(),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }