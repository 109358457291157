var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLongdoMapV3
    ? _c(
        "div",
        {
          staticClass: "navigation-bar",
          class: { hide: _vm.isHidingNavigationBar },
        },
        [
          _c("div", { staticClass: "ldmap-header" }, [
            _c("div", { staticClass: "nav-header" }, [
              _c("div", { staticClass: "nav-title" }, [
                _c(
                  "a",
                  { attrs: { href: "https://" + _vm.product + ".longdo.com" } },
                  [
                    _c("img", {
                      attrs: {
                        id: "longdo-logo",
                        src: _vm.longdoMapLogo2X,
                        srcset:
                          _vm.longdoMapLogo1X +
                          " 1x, " +
                          _vm.longdoMapLogo2X +
                          " 2x",
                        loading: "lazy",
                        alt: "Longdo Map: Thailand & World Map",
                        title: "Longdo Map: Thailand & World Map",
                      },
                    }),
                  ]
                ),
              ]),
            ]),
            _c("div", { staticClass: "nav-links" }, [
              _c(
                "a",
                {
                  attrs: {
                    href:
                      _vm.product === "traffic"
                        ? "https://traffic.longdo.com/info"
                        : "https://map.longdo.com/longdomap",
                  },
                },
                [
                  _vm._v(
                    " ← " +
                      _vm._s(_vm.$t("navigationBarDesktop.switchSite")) +
                      " "
                  ),
                ]
              ),
              _c("div", { staticClass: "divider" }),
              _c(
                "a",
                {
                  attrs: {
                    href: "https://map.longdo.com/products",
                    target: "_blank",
                  },
                },
                [_vm._v(_vm._s(_vm.$t("navigationSideBar.product")))]
              ),
              _c("div", { staticClass: "divider" }),
              !_vm.isLogin
                ? _c("a", { on: { click: _vm.onLogin } }, [
                    _vm._v(
                      _vm._s(_vm.$t("navigationSideBar.loginAndRegister"))
                    ),
                  ])
                : _c("a", { on: { click: _vm.onShowSidebar } }, [
                    _vm._v(_vm._s(_vm.displayName)),
                  ]),
              _c("div", { staticClass: "divider" }),
              _c(
                "a",
                {
                  staticClass: "ldmap-language",
                  staticStyle: { width: "30px", "text-align": "center" },
                  on: { click: _vm.selectLanguage },
                },
                [
                  _vm.$i18n.locale === "th"
                    ? _c("img", {
                        attrs: {
                          src: "https://map.longdo.com/modules/contributes/localizer/flags/en.png",
                          alt: "th",
                        },
                      })
                    : _c("img", {
                        attrs: {
                          src: "https://map.longdo.com/modules/contributes/localizer/flags/th.png",
                          alt: "en",
                        },
                      }),
                ]
              ),
            ]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }