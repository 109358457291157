var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "search-panel",
      class: {
        hide: _vm.isHidingSearchPanel,
        "routing-mode": !_vm.isHidingInputPanel,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "search-on-map",
          class: {
            active: !_vm.isHidingBackButton,
            "search-map3": _vm.isLongdoMapV3,
          },
        },
        [
          _c("input", {
            ref: "search-input",
            attrs: {
              type: "text",
              placeholder: _vm.$t("searchPanel.searchInputPlaceholder"),
            },
            domProps: { value: _vm.keywordInput },
            on: {
              focus: _vm.focusSearchInput,
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                _vm.isSearchTrafficEventMode
                  ? _vm.searchTrafficEvent()
                  : _vm.search(true, true)
              },
              keydown: _vm.onKeydown,
              input: _vm.changeSearchInput,
            },
          }),
          !_vm.isHidingBackButton
            ? _c(
                "button",
                {
                  staticClass: "back ripple",
                  on: {
                    click: function ($event) {
                      return _vm.clickBack()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "material-icons-round" }, [
                    _vm._v(" arrow_back "),
                  ]),
                ]
              )
            : _vm._e(),
          !_vm.isHidingClearButton
            ? _c(
                "button",
                {
                  staticClass: "clear ripple",
                  on: {
                    click: function ($event) {
                      return _vm.clickClear()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "material-icons-round" }, [
                    _vm._v(" clear "),
                  ]),
                ]
              )
            : _vm._e(),
          _c(
            "button",
            {
              staticClass: "ldmap-search ripple",
              class: {
                active: !_vm.isHidingBackButton,
              },
              on: {
                click: function ($event) {
                  _vm.isSearchTrafficEventMode
                    ? _vm.searchTrafficEvent()
                    : _vm.search(true, true)
                },
              },
            },
            [
              _c("i", { staticClass: "material-icons-round" }, [
                _vm._v(" search "),
              ]),
            ]
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "list-suggest",
          class: {
            "traffic-product": _vm.isTrafficProduct,
            "list-suggest-map3": _vm.isLongdoMapV3,
          },
        },
        [
          _vm.isTrafficProduct
            ? _c(
                "select",
                { on: { change: _vm.jumpToPlace } },
                [
                  _c(
                    "option",
                    {
                      attrs: {
                        value: "",
                        selected: "",
                        disabled: "",
                        hidden: "",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("searchPanel.flashPlaceList.selectArea")
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _vm._l(_vm.flashPlaceList, function (place) {
                    return _c(
                      "option",
                      { key: place, domProps: { value: place } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("searchPanel.flashPlaceList." + place)
                            ) +
                            " "
                        ),
                      ]
                    )
                  }),
                ],
                2
              )
            : _vm._e(),
          _c("div", { staticClass: "tag-list-suggest-bar" }, [
            _vm.suggestTagScrollPosition > 24
              ? _c(
                  "button",
                  {
                    staticClass: "btn-scroll-left",
                    class: {
                      hide:
                        !_vm.isHidingBackButton ||
                        _vm.$route.hash === "#explore",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.scroll("left")
                      },
                    },
                  },
                  [_vm._v(" « ")]
                )
              : _vm._e(),
            _vm.isLongdoMapV3 && !_vm.isTrafficProduct
              ? _c(
                  "div",
                  {
                    ref: "suggestTags",
                    staticClass: "tag-list-suggest flash",
                    class: {
                      hide:
                        !_vm.isHidingBackButton ||
                        _vm.$route.hash === "#explore",
                    },
                    on: { scroll: _vm.searchFromTagScrollHandler },
                  },
                  _vm._l(_vm.flashExploreList, function (flashExplore) {
                    return _c(
                      "button",
                      {
                        key: "flash-tag-explore-" + flashExplore.name,
                        staticClass: "ripple taglist",
                        on: {
                          click: function ($event) {
                            return _vm.searchFromTag({
                              tag: flashExplore.name,
                            })
                          },
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: flashExplore.icon.x2,
                            srcset:
                              flashExplore.icon.x1 +
                              " 1x, " +
                              flashExplore.icon.x2 +
                              " 2x",
                          },
                        }),
                        _vm._v(
                          " " +
                            _vm._s(flashExplore.displayName[_vm.$i18n.locale]) +
                            " "
                        ),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm.isLongdoMapV3 && _vm.isTrafficProduct
              ? _c(
                  "div",
                  {
                    ref: "suggestTags",
                    staticClass: "tag-list-suggest flash",
                    class: { hide: !_vm.isHidingBackButton },
                    on: { scroll: _vm.searchFromTagScrollHandler },
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "ripple",
                        on: { click: _vm.clickRecentIncident },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("trafficIncidentPanel.recentIncident")
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _vm._l(
                      _vm.flashTrafficEventType,
                      function (eventType, index) {
                        return _c(
                          "button",
                          {
                            key: eventType.id,
                            ref: "button" + index,
                            refInFor: true,
                            staticClass: "ripple taglist",
                            on: {
                              click: function ($event) {
                                return _vm.clickTrafficTag(eventType)
                              },
                            },
                          },
                          [
                            _c("img", {
                              staticStyle: { width: "18px", height: "18px" },
                              attrs: {
                                src:
                                  "" +
                                  _vm.baseTrafficIconUrl.replace(
                                    "{{icon}}",
                                    eventType.icon
                                  ),
                              },
                            }),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$i18n.locale === "th"
                                    ? eventType.name
                                    : eventType.name_en
                                ) +
                                " "
                            ),
                          ]
                        )
                      }
                    ),
                  ],
                  2
                )
              : _vm._e(),
            _vm.suggestListWidth + _vm.suggestTagScrollPosition <
              _vm.suggestTagButtonListWidth + 24 && _vm.isLongdoMapV3
              ? _c(
                  "button",
                  {
                    staticClass: "btn-scroll-right",
                    class: {
                      hide:
                        !_vm.isHidingBackButton ||
                        _vm.$route.hash === "#explore",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.scroll("right")
                      },
                    },
                  },
                  [_vm._v(" » ")]
                )
              : _vm._e(),
          ]),
        ]
      ),
      _vm.selectedTag && _vm.isLongdoMapV3 && _vm.$route.hash === ""
        ? _c(
            "button",
            {
              staticClass: "selected-tag-map3 ripple",
              on: {
                click: function ($event) {
                  return _vm.searchFromTag(_vm.selectedTag)
                },
              },
            },
            [
              _c("span", [_vm._v(" ค้นหา ")]),
              _c("span", [
                _c("img", {
                  attrs: {
                    src: _vm.selectedTag.imagefile,
                    srcset:
                      _vm.selectedTag.imagefile.replace(
                        "/icons_2x/",
                        "/icons/"
                      ) +
                      " 1x, " +
                      _vm.selectedTag.imagefile +
                      " 2x",
                    loading: "lazy",
                  },
                }),
              ]),
              _c("b", [
                _vm._v(
                  _vm._s(
                    _vm.selectedTag[
                      _vm.$i18n.locale === "th" ? "name" : "name_en"
                    ]
                  )
                ),
              ]),
              _c("span", [_vm._v(" บริเวณนี้ ")]),
            ]
          )
        : _vm._e(),
      _vm.selectedTag && !_vm.isLongdoMapV3 && _vm.$route.hash === ""
        ? _c(
            "button",
            {
              staticClass: "selected-tag ripple",
              on: {
                click: function ($event) {
                  return _vm.searchFromTag(_vm.selectedTag)
                },
              },
            },
            [
              _c("img", {
                attrs: {
                  src: _vm.selectedTag.imagefile,
                  srcset:
                    _vm.selectedTag.imagefile.replace("/icons_2x/", "/icons/") +
                    " 1x, " +
                    _vm.selectedTag.imagefile +
                    " 2x",
                  loading: "lazy",
                },
              }),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.selectedTag[
                      _vm.$i18n.locale === "th" ? "name" : "name_en"
                    ]
                  ) +
                  " "
              ),
            ]
          )
        : _vm._e(),
      _c(
        "div",
        {
          class: {
            "recommend-container": !_vm.isLongdoMapV3,
            "recommend-container-map3": _vm.isLongdoMapV3,
            hide: _vm.isHidingRecommendContainer,
          },
          on: { click: _vm.closeSuggest },
        },
        [
          _vm.showSearchHistory && !_vm.isTrafficProduct && !_vm.isOnMobileApp
            ? _c(
                "div",
                {
                  staticClass: "search-history-container",
                  class: {
                    hide:
                      (_vm.keywordInput.length != 0) &
                      (_vm.suggestList.length > 0),
                  },
                },
                [
                  _c("div", { staticClass: "topic" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("searchPanel.searchHistory")) + " "
                    ),
                  ]),
                  !_vm.isTrafficProduct
                    ? _c(
                        "div",
                        {
                          ref: "search-history-list",
                          staticClass: "search-panel-history",
                        },
                        _vm._l(_vm.searchHistory, function (history, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "history-list",
                              on: {
                                click: function ($event) {
                                  return _vm.clickHistory(history)
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "material-icons-round" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      history.type === "poi"
                                        ? "history"
                                        : "keyboard"
                                    ) +
                                    " "
                                ),
                              ]),
                              _vm._v(" " + _vm._s(history.name) + " "),
                              _c(
                                "i",
                                {
                                  staticClass:
                                    "material-icons-round remove ripple",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.removeHistory(history)
                                    },
                                  },
                                },
                                [_vm._v("close")]
                              ),
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          _c("div", { staticClass: "example-container" }, [
            _vm.isTrafficProduct
              ? _c("div", { staticClass: "toggle-mode-container" }, [
                  _c(
                    "button",
                    {
                      class: { active: _vm.isSearchTrafficEventMode },
                      attrs: { disabled: _vm.isSearchTrafficEventMode },
                      on: { click: _vm.toggleSearchMode },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("searchPanel.trafficEventMode")) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "button",
                    {
                      class: { active: !_vm.isSearchTrafficEventMode },
                      attrs: { disabled: !_vm.isSearchTrafficEventMode },
                      on: { click: _vm.toggleSearchMode },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("searchPanel.placeMode")) + " "
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
            _c("div", { staticClass: "topic" }, [
              _vm._v(" " + _vm._s(_vm.$t("searchPanel.example")) + " "),
            ]),
            !_vm.isSearchTrafficEventMode
              ? _c("div", { staticClass: "example" }, [
                  _c("div", { staticClass: "topic" }, [
                    _vm._v(" " + _vm._s(_vm.$t("searchPanel.placeName")) + " "),
                  ]),
                  _c("div", { staticClass: "value" }, [
                    _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.searchExample(
                              "บริษัท เมตามีเดีย เทคโนโลยี จำกัด"
                            )
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("searchPanel.placeNameExample[0]")) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                ])
              : _c("div", { staticClass: "example" }, [
                  _c("div", { staticClass: "topic" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("searchPanel.trafficEventName")) + " "
                    ),
                  ]),
                  _c("div", { staticClass: "value" }, [
                    _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.searchExample("รถบรรทุก")
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("searchPanel.trafficEventNameExample[0]")
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                ]),
            !_vm.isSearchTrafficEventMode
              ? _c("div", { staticClass: "example" }, [
                  _c("div", { staticClass: "topic" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("searchPanel.adminBoundary")) + " "
                    ),
                  ]),
                  _c("div", { staticClass: "value" }, [
                    _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.searchExample(
                              "แขวงสีลม เขตบางรัก กรุงเทพมหานคร"
                            )
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("searchPanel.adminBoundaryExample[0]")
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                ])
              : _vm._e(),
            _c("div", { staticClass: "example" }, [
              _c("div", { staticClass: "topic" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "searchPanel." +
                          (_vm.isSearchTrafficEventMode
                            ? "trafficCategory"
                            : "category")
                      )
                    ) +
                    " "
                ),
              ]),
              _c("div", { staticClass: "value" }, [
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        _vm.searchExample(
                          _vm.isSearchTrafficEventMode
                            ? "event:อุบัติเหตุ"
                            : _vm.$t("searchPanel.categoryExample[0]")
                        )
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "searchPanel." +
                              (_vm.isSearchTrafficEventMode
                                ? "trafficCategoryExample[0]"
                                : "categoryExample[0]")
                          )
                        ) +
                        " "
                    ),
                  ]
                ),
                _vm._v(", "),
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        _vm.searchExample(
                          _vm.isSearchTrafficEventMode
                            ? "event:ก่อสร้าง"
                            : _vm.$t("searchPanel.categoryExample[1]")
                        )
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "searchPanel." +
                              (_vm.isSearchTrafficEventMode
                                ? "trafficCategoryExample[1]"
                                : "categoryExample[1]")
                          )
                        ) +
                        " "
                    ),
                  ]
                ),
              ]),
            ]),
            !_vm.isSearchTrafficEventMode
              ? _c("div", { staticClass: "example" }, [
                  _c("div", { staticClass: "topic" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("searchPanel.coordinate")) + " "
                    ),
                  ]),
                  _c("div", { staticClass: "value" }, [
                    _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.searchExample("13.72264,100.52931")
                          },
                        },
                      },
                      [_vm._v(" 13.72264,100.52931 ")]
                    ),
                    _vm._v(" " + _vm._s(_vm.$t("searchPanel.or")) + " "),
                    _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.searchExample("665365,1517575,47N")
                          },
                        },
                      },
                      [_vm._v(" 665365,1517575,47N ")]
                    ),
                  ]),
                ])
              : _vm._e(),
            !_vm.isSearchTrafficEventMode
              ? _c("div", { staticClass: "example" }, [
                  _c("div", { staticClass: "topic" }, [
                    _vm._v(" " + _vm._s(_vm.$t("searchPanel.layer")) + " "),
                  ]),
                  _c("div", { staticClass: "value" }, [
                    _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.clickSuggest({
                              w: "layer: traffic",
                            })
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.layerList[0].displayName[_vm.$i18n.locale]
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _vm._v(", "),
                    _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.clickSuggest({
                              w: "layer: evstation",
                            })
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.layerList[8].displayName[_vm.$i18n.locale]
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                ])
              : _vm._e(),
          ]),
          !_vm.isSearchTrafficEventMode
            ? _c(
                "div",
                { staticClass: "tag-category-container" },
                [
                  _c("div", { staticClass: "topic" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("searchPanel.searchByIcon")) + " "
                    ),
                  ]),
                  _vm._l(_vm.tagCategoryList, function (tagCategory) {
                    return _c(
                      "div",
                      {
                        key: "tag-category-id-" + tagCategory.category_id,
                        staticClass: "tag-category",
                      },
                      [
                        _c("label", [
                          _vm._v(
                            " " +
                              _vm._s(
                                tagCategory["category_name_" + _vm.$i18n.locale]
                              ) +
                              " "
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "tag-list" },
                          [
                            _vm._l(tagCategory.icons, function (tag) {
                              return [
                                tag[
                                  _vm.$i18n.locale === "th" ? "name" : "name_en"
                                ] !== ""
                                  ? _c(
                                      "div",
                                      {
                                        key: "tag-id-" + tag.id,
                                        staticClass: "tag",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "icon",
                                            on: {
                                              click: function ($event) {
                                                return _vm.clickTag(tag)
                                              },
                                            },
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: tag.imagefile,
                                                srcset:
                                                  tag.imagefile.replace(
                                                    "/icons_2x/",
                                                    "/icons/"
                                                  ) +
                                                  " 1x, " +
                                                  tag.imagefile +
                                                  " 2x",
                                                loading: "lazy",
                                              },
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "label",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.clickTag(tag)
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  tag[
                                                    _vm.$i18n.locale === "th"
                                                      ? "name"
                                                      : "name_en"
                                                  ]
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            }),
                          ],
                          2
                        ),
                      ]
                    )
                  }),
                ],
                2
              )
            : _c("div", { staticClass: "tag-category-container" }, [
                _c("div", { staticClass: "topic" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("searchPanel.searchByTrafficIcon")) +
                      " "
                  ),
                ]),
                _c("div", { staticClass: "tag-category" }, [
                  _c(
                    "div",
                    { staticClass: "tag-list" },
                    _vm._l(_vm.trafficEventType, function (eventType) {
                      return _c(
                        "div",
                        { key: eventType.id, staticClass: "tag" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "icon",
                              on: {
                                click: function ($event) {
                                  return _vm.clickTrafficTag(eventType)
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src:
                                    "" +
                                    _vm.baseTrafficIconUrl.replace(
                                      "{{icon}}",
                                      eventType.icon
                                    ),
                                  loading: "lazy",
                                },
                              }),
                            ]
                          ),
                          _c(
                            "label",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.clickTrafficTag(eventType)
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    eventType[
                                      _vm.$i18n.locale === "th"
                                        ? "name"
                                        : "name_en"
                                    ]
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
              ]),
          _vm.keywordInput.length >= 2 &&
          _vm.suggestList.length > 0 &&
          _vm.$route.hash === "#search"
            ? _c(
                "div",
                { staticClass: "suggest-list" },
                _vm._l(_vm.suggestList, function (suggest, index) {
                  return _c("div", {
                    key: suggest.w,
                    staticClass: "suggest",
                    domProps: { innerHTML: _vm._s(suggest.d) },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.clickSuggest(suggest, index)
                      },
                    },
                  })
                }),
                0
              )
            : _vm._e(),
        ]
      ),
      _vm.isLongdoMapV3
        ? _c(
            "div",
            {
              class: {
                "recommend-container-desktop-map3": _vm.isLongdoMapV3,
                hide: _vm.isHidingRecommendContainer,
                "show-suggest":
                  !_vm.isHidingRecommendContainer &&
                  (_vm.keywordInput.length != 0) & (_vm.suggestList.length > 0),
              },
              on: { click: _vm.closeSuggest },
            },
            [
              _vm.showSearchHistory &&
              !_vm.isTrafficProduct &&
              !_vm.isOnMobileApp
                ? _c(
                    "div",
                    {
                      staticClass: "search-history-container",
                      class: {
                        hide:
                          (_vm.keywordInput.length != 0) &
                          (_vm.suggestList.length > 0),
                      },
                    },
                    [
                      _c("div", { staticClass: "topic" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("searchPanel.searchHistory")) +
                            " "
                        ),
                      ]),
                      !_vm.isTrafficProduct
                        ? _c(
                            "div",
                            {
                              ref: "search-history-list",
                              staticClass: "search-panel-history",
                            },
                            _vm._l(
                              _vm.searchHistory,
                              function (history, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass: "history-list",
                                    on: {
                                      click: function ($event) {
                                        return _vm.clickHistory(history)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "i",
                                      { staticClass: "material-icons-round" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              history.type === "poi"
                                                ? "history"
                                                : "keyboard"
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" " + _vm._s(history.name) + " "),
                                    _c(
                                      "i",
                                      {
                                        staticClass:
                                          "material-icons-round remove ripple",
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.removeHistory(history)
                                          },
                                        },
                                      },
                                      [_vm._v("close")]
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          )
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "example-container",
                  class: {
                    hide:
                      _vm.isHidingRecommendContainer ||
                      (_vm.keywordInput.length != 0) &
                        (_vm.suggestList.length > 0),
                  },
                },
                [
                  _vm.isTrafficProduct
                    ? _c("div", { staticClass: "toggle-mode-container" }, [
                        _c(
                          "button",
                          {
                            class: { active: _vm.isSearchTrafficEventMode },
                            attrs: { disabled: _vm.isSearchTrafficEventMode },
                            on: { click: _vm.toggleSearchMode },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("searchPanel.trafficEventMode")) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "button",
                          {
                            class: { active: !_vm.isSearchTrafficEventMode },
                            attrs: { disabled: !_vm.isSearchTrafficEventMode },
                            on: { click: _vm.toggleSearchMode },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("searchPanel.placeMode")) +
                                " "
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm.isMapProduct
                    ? _c("div", { staticClass: "topic" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("searchPanel.example")) + " "
                        ),
                      ])
                    : _c("div", { staticClass: "topic" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("searchPanel.example")) + " "
                        ),
                      ]),
                  _c("div", { staticClass: "example-panel-normal" }, [
                    !_vm.isSearchTrafficEventMode
                      ? _c("div", { staticClass: "example" }, [
                          _c("div", { staticClass: "topic" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("searchPanel.placeName")) +
                                " "
                            ),
                          ]),
                          _c("div", { staticClass: "value" }, [
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.searchExample(
                                      "บริษัท เมตามีเดีย เทคโนโลยี จำกัด"
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("searchPanel.placeNameExample[0]")
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                        ])
                      : _c("div", { staticClass: "example" }, [
                          _c("div", { staticClass: "topic" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("searchPanel.trafficEventName")) +
                                " "
                            ),
                          ]),
                          _c("div", { staticClass: "value" }, [
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.searchExample("รถบรรทุก")
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "searchPanel.trafficEventNameExample[0]"
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                        ]),
                    !_vm.isSearchTrafficEventMode
                      ? _c("div", { staticClass: "example" }, [
                          _c("div", { staticClass: "topic" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("searchPanel.adminBoundary")) +
                                " "
                            ),
                          ]),
                          _c("div", { staticClass: "value" }, [
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.searchExample(
                                      "แขวงสีลม เขตบางรัก กรุงเทพมหานคร"
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "searchPanel.adminBoundaryExample[0]"
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "example" }, [
                      _c("div", { staticClass: "topic" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "searchPanel." +
                                  (_vm.isSearchTrafficEventMode
                                    ? "trafficCategory"
                                    : "category")
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _c("div", { staticClass: "value" }, [
                        _c(
                          "span",
                          {
                            on: {
                              click: function ($event) {
                                _vm.searchExample(
                                  _vm.isSearchTrafficEventMode
                                    ? "event:อุบัติเหตุ"
                                    : _vm.$t("searchPanel.categoryExample[0]")
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "searchPanel." +
                                      (_vm.isSearchTrafficEventMode
                                        ? "trafficCategoryExample[0]"
                                        : "categoryExample[0]")
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _vm._v(", "),
                        _c(
                          "span",
                          {
                            on: {
                              click: function ($event) {
                                _vm.searchExample(
                                  _vm.isSearchTrafficEventMode
                                    ? "event:ก่อสร้าง"
                                    : _vm.$t("searchPanel.categoryExample[1]")
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "searchPanel." +
                                      (_vm.isSearchTrafficEventMode
                                        ? "trafficCategoryExample[1]"
                                        : "categoryExample[1]")
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    !_vm.isSearchTrafficEventMode
                      ? _c("div", { staticClass: "example" }, [
                          _c("div", { staticClass: "topic" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("searchPanel.coordinate")) +
                                " "
                            ),
                          ]),
                          _c("div", { staticClass: "value" }, [
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.searchExample(
                                      "13.72264,100.52931"
                                    )
                                  },
                                },
                              },
                              [_vm._v(" 13.72264,100.52931 ")]
                            ),
                            _vm._v(
                              " " + _vm._s(_vm.$t("searchPanel.or")) + " "
                            ),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.searchExample(
                                      "665365,1517575,47N"
                                    )
                                  },
                                },
                              },
                              [_vm._v(" 665365,1517575,47N ")]
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    !_vm.isSearchTrafficEventMode
                      ? _c("div", { staticClass: "example" }, [
                          _c("div", { staticClass: "topic" }, [
                            _vm._v(
                              " " + _vm._s(_vm.$t("searchPanel.layer")) + " "
                            ),
                          ]),
                          _c("div", { staticClass: "value" }, [
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.clickSuggest({
                                      w: "layer: traffic",
                                    })
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.layerList[0].displayName[
                                        _vm.$i18n.locale
                                      ]
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _vm._v(", "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.clickSuggest({
                                      w: "layer: evstation",
                                    })
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.layerList[8].displayName[
                                        _vm.$i18n.locale
                                      ]
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "tag-category-container",
                  class: {
                    hide:
                      _vm.isHidingRecommendContainer ||
                      (_vm.keywordInput.length != 0) &
                        (_vm.suggestList.length > 0),
                    "traffic-mode": _vm.isSearchTrafficEventMode,
                    "traffic-product": _vm.isTrafficProduct,
                  },
                },
                [
                  _c("div", { staticClass: "topic" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "searchPanel.searchBy" +
                              (_vm.isTrafficProduct ? "Traffic" : "") +
                              "Icon"
                          )
                        ) +
                        " "
                    ),
                  ]),
                  !_vm.isSearchTrafficEventMode
                    ? _c(
                        "div",
                        { ref: "", staticClass: "tag-category-container-list" },
                        _vm._l(_vm.tagCategoryList, function (tagCategory) {
                          return _c(
                            "div",
                            {
                              key: "tag-category-id-" + tagCategory.category_id,
                              staticClass: "tag-category",
                            },
                            [
                              _c("label", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      tagCategory[
                                        "category_name_" + _vm.$i18n.locale
                                      ]
                                    ) +
                                    " "
                                ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "tag-list" },
                                [
                                  _vm._l(tagCategory.icons, function (tag) {
                                    return [
                                      tag[
                                        _vm.$i18n.locale === "th"
                                          ? "name"
                                          : "name_en"
                                      ] !== ""
                                        ? _c(
                                            "div",
                                            {
                                              key: "tag-id-" + tag.id,
                                              staticClass: "tag",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "icon",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.clickTag(tag)
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src: tag.imagefile.replace(
                                                        "/icons_2x/",
                                                        "/icons_4x/"
                                                      ),
                                                      srcset:
                                                        tag.imagefile +
                                                        " 1x, " +
                                                        tag.imagefile.replace(
                                                          "/icons_2x/",
                                                          "/icons_4x/"
                                                        ) +
                                                        " 2x",
                                                      loading: "lazy",
                                                    },
                                                  }),
                                                ]
                                              ),
                                              _c(
                                                "label",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.clickTag(tag)
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        tag[
                                                          _vm.$i18n.locale ===
                                                          "th"
                                                            ? "name"
                                                            : "name_en"
                                                        ]
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  }),
                                ],
                                2
                              ),
                            ]
                          )
                        }),
                        0
                      )
                    : _c(
                        "div",
                        { staticClass: "tag-category-container-list" },
                        [
                          _c("div", { staticClass: "tag-category" }, [
                            _c(
                              "div",
                              { staticClass: "tag-list" },
                              _vm._l(
                                _vm.trafficEventType,
                                function (eventType) {
                                  return _c(
                                    "div",
                                    { key: eventType.id, staticClass: "tag" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "icon",
                                          on: {
                                            click: function ($event) {
                                              return _vm.clickTrafficTag(
                                                eventType
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src:
                                                "" +
                                                _vm.baseTrafficIconUrl.replace(
                                                  "{{icon}}",
                                                  eventType.icon
                                                ),
                                              loading: "lazy",
                                            },
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "label",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.clickTrafficTag(
                                                eventType
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                eventType[
                                                  _vm.$i18n.locale === "th"
                                                    ? "name"
                                                    : "name_en"
                                                ]
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ]),
                        ]
                      ),
                ]
              ),
              _vm.keywordInput.length >= 2 &&
              _vm.suggestList.length > 0 &&
              _vm.$route.hash === "#search"
                ? _c(
                    "div",
                    { staticClass: "suggest-list-desktop" },
                    _vm._l(_vm.suggestList, function (suggest) {
                      return _c("div", {
                        key: suggest.w,
                        staticClass: "suggest-desktop",
                        domProps: { innerHTML: _vm._s(suggest.d) },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.clickSuggest(suggest)
                          },
                        },
                      })
                    }),
                    0
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }