var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "control-traffic-panel",
      class: {
        hide:
          _vm.isHidingControlTrafficPanel ||
          _vm.$route.hash === "#cam" ||
          _vm.$route.hash === "#measurement" ||
          _vm.$route.hash === "#routing" ||
          _vm.$route.hash === "#events" ||
          (!_vm.isHidingExplorePanel && _vm.isExpandingExplorePanel) ||
          (!_vm.isHidingSearchResultPanel &&
            _vm.isExpandingSearchResultPanel) ||
          (!_vm.isHidingMyPlacePanel && _vm.isExpandingMyPlacePanel) ||
          (!_vm.isHidingExploreNearbyPanel &&
            _vm.isExpandingExploreNearbyPanel) ||
          (!_vm.isHidingEventPanel && _vm.isExpandingEventPanel),
        map3: _vm.isLongdoMapV3,
        "showing-popup": _vm.isShowingPopup,
        "place-card-step-full": _vm.placeCardStep === "FULL",
        "place-card-step-mid": _vm.placeCardStep === "MID",
        "place-card-step-mini": _vm.placeCardStep === "MINI",
        "showing-explore-panel":
          !_vm.isHidingExplorePanel && _vm.isExpandingExplorePanel,
        "collapsing-explore-panel":
          !_vm.isHidingExplorePanel && !_vm.isExpandingExplorePanel,
        "showing-search-result-panel":
          !_vm.isHidingSearchResultPanel && _vm.isExpandingSearchResultPanel,
        "collapsing-search-result-panel":
          !_vm.isHidingSearchResultPanel && !_vm.isExpandingSearchResultPanel,
        "showing-my-place-panel":
          !_vm.isHidingMyPlacePanel && _vm.isExpandingMyPlacePanel,
        "collapsing-my-place-panel":
          !_vm.isHidingMyPlacePanel && !_vm.isExpandingMyPlacePanel,
        "showing-explore-nearby-panel":
          !_vm.isHidingExploreNearbyPanel && _vm.isExpandingExploreNearbyPanel,
        "collapsing-explore-nearby-panel":
          !_vm.isHidingExploreNearbyPanel && !_vm.isExpandingExploreNearbyPanel,
        "showing-event-panel":
          !_vm.isHidingEventPanel && _vm.isExpandingEventPanel,
        "collapsing-event-panel":
          !_vm.isHidingEventPanel && !_vm.isExpandingEventPanel,
      },
    },
    [
      _vm.isTrafficProduct
        ? _c(
            "button",
            {
              staticClass: "ripple expand",
              class: { active: _vm.isExpandTrafficProductShortcut },
              on: {
                click: function ($event) {
                  _vm.isExpandTrafficProductShortcut =
                    !_vm.isExpandTrafficProductShortcut
                },
              },
            },
            [
              _c("i", { staticClass: "material-icons" }, [
                _vm._v(" expand_less "),
              ]),
            ]
          )
        : _vm._e(),
      _vm.isTrafficProduct
        ? _c(
            "div",
            {
              staticClass: "traffic-product-shortcut",
              class: { less: !_vm.isExpandTrafficProductShortcut },
            },
            [
              _vm.isActiveDropDownArea
                ? _c(
                    "select",
                    { on: { change: _vm.jumpToPlace } },
                    [
                      _c(
                        "option",
                        {
                          attrs: {
                            value: "",
                            selected: "",
                            disabled: "",
                            hidden: "",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("searchPanel.flashPlaceList.selectArea")
                            )
                          ),
                        ]
                      ),
                      _vm._l(_vm.flashPlaceList, function (place) {
                        return _c(
                          "option",
                          { key: place, domProps: { value: place } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t("searchPanel.flashPlaceList." + place)
                              )
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  )
                : _vm._e(),
              _c(
                "button",
                {
                  class: { active: _vm.isActiveDropDownArea },
                  on: { click: _vm.onClickSelectArea },
                },
                [
                  _c("i", { staticClass: "material-icons-round" }, [
                    _vm._v(" zoom_in_map "),
                  ]),
                ]
              ),
              false
                ? _c(
                    "button",
                    {
                      class: { active: _vm.isActiveTrafficLayer },
                      on: { click: _vm.onClickTrafficLayer },
                    },
                    [
                      _c("i", { staticClass: "material-icons-round" }, [
                        _vm._v(" traffic "),
                      ]),
                    ]
                  )
                : _vm._e(),
              _c(
                "button",
                {
                  class: { active: _vm.isActiveGasStation },
                  on: { click: _vm.onClickGasStation },
                },
                [
                  _c("i", { staticClass: "material-icons-round" }, [
                    _vm._v(" local_gas_station "),
                  ]),
                ]
              ),
              _c(
                "button",
                {
                  class: { active: _vm.isActiveCamera },
                  on: { click: _vm.onClickCamera },
                },
                [
                  _c("i", { staticClass: "material-icons-round" }, [
                    _vm._v(" videocam "),
                  ]),
                ]
              ),
              _c(
                "button",
                {
                  class: { active: _vm.isActiveEvent },
                  on: { click: _vm.onClickEvent },
                },
                [
                  _c("img", {
                    attrs: {
                      src: _vm.baseUrl + "img/traffic_shortcut/event.svg",
                    },
                  }),
                ]
              ),
              _c(
                "button",
                {
                  class: { active: _vm.isActiveAqi },
                  on: { click: _vm.onClickAqi },
                },
                [
                  _c("img", {
                    attrs: {
                      src: _vm.baseUrl + "img/traffic_shortcut/aqi.svg",
                    },
                  }),
                ]
              ),
              _c(
                "button",
                {
                  class: { active: _vm.isActiveRainRadarLayer },
                  on: { click: _vm.onClickRainRadarLayer },
                },
                [
                  _c("img", {
                    attrs: {
                      src: _vm.baseUrl + "img/traffic_shortcut/rain.svg",
                    },
                  }),
                ]
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }