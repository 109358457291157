<template>
  <div>
    <div class="navigation-side-bar" :class="{ active: isShowingNavigationSideBar }"
      @click="isShowingNavigationSideBar = false">
      <div class="container">
        <div class="logo-container">
          <img :src="longdoMapLogo2X" :srcset="longdoMapLogo1X + ' 1x, ' + longdoMapLogo2X + ' 2x'" loading="lazy"
            alt="Longdo Map: Thailand &amp; World Map" title="Longdo Map: Thailand &amp; World Map">
        </div>
        <div class="user">
          <div class="profile-picture-container">
            <img :src="profilePicture" v-if="isLogin" @click="openUserInfo" loading="lazy">
            <i class="material-icons-round ripple" v-if="!isLogin" @click="clickLogin">
              account_circle
            </i>
          </div>
          <div class="profile-info">
            <div class="display-name ripple" v-if="isLogin" @click="openUserInfo">
              {{ displayName }}
            </div>
            <button class="login ripple" @click="clickLogin" v-if="!isLogin">
              <label>{{ $t('navigationSideBar.loginAndRegister') }}</label>
            </button>
          </div>
        </div>
        <div class="menu-list"
          :style="{ backgroundImage: 'url(\'' + baseUrl + 'img/bg-repeat.svg?version=' + appVersion + '\')' }">

          <button class="create-place ripple" @click="clickCreatePlace"
            v-if="isMapProduct && ((isOnMobileApp && isLogin) || !isOnMobileApp)">
            <i class="material-icons-round">
              add_location_alt
            </i>
            <label>{{ $t('navigationSideBar.createPlace') }}</label>
          </button>
          <div class="divider"></div>
          <button class="my-place ripple" @click="clickMyPlace"
            v-if="isMapProduct && ((isOnMobileApp && isLogin) || !isOnMobileApp)">
            <i class="material-icons-round">
              star
            </i>
            <label>{{ $t('navigationSideBar.myPlace') }}</label>
          </button>
          <button class="measurement ripple" @click="clickMeasurement" v-if="!isLongdoMapV3">
            <i class="material-icons-round">
              straighten
            </i>
            <label>{{ $t('navigationSideBar.measurement') }}</label>
          </button>
          <hr class="solid" v-if="(isMapProduct && !isOnMobileApp) || !isLongdoMapV3">

          <a class="raster-map ripple" :href="rasterModeUrl" v-if="isLongdoMapV3 && !isOnMobileApp">
            <i class="material-icons-round">
              grid_on
            </i>
            <label>{{ $t('navigationSideBar.rasterMap') }}</label>
          </a>
          <a class="vector-map ripple" :href="vectorModeUrl" v-if="!isLongdoMapV3">
            <i class="material-icons-round">
              grid_on
            </i>
            <label>{{ $t('navigationSideBar.vectorMap') }}</label>
          </a>
          <hr class="solid" v-if="!isOnMobileApp">

          <a class="api ripple" :href="productWebUrl" target="_blank" v-if="isMapProduct && !isOnMobileApp">
            <i class="material-icons-round">
              widgets
            </i>
            <label>{{ $t('navigationSideBar.product') }}</label>
          </a>
          <a class="api ripple" :href="consoleWebUrl" target="_blank" v-if="isMapProduct && !isOnMobileApp">
            <i class="material-icons-round">
              api
            </i>
            <label>{{ $t('navigationSideBar.ldmapConsole') }}</label>
          </a>
          <a class="api-doc ripple" :href="apiDocWebUrl" target="_blank" v-if="isMapProduct && !isOnMobileApp">
            <i class="material-icons-round">
              lightbulb
            </i>
            <label>{{ $t('navigationSideBar.apiDocument') }}</label>
          </a>
          <a class="extra-api ripple" :href="extraApiWebUrl" target="_blank" v-if="isMapProduct && !isOnMobileApp">
            <i class="material-icons-round">
              tips_and_updates
            </i>
            <label>{{ $t('navigationSideBar.ldmapExtraAPI') }}</label>
          </a>
          <hr class="solid" v-if="isMapProduct && !isOnMobileApp">

          <a class="download ripple" :href="downloadWebUrl" target="_blank" v-if="!isOnMobileApp">
            <i class="material-icons-round">
              file_download
            </i>
            <label>{{ $t('navigationSideBar.download') }}</label>
          </a>
          <a class="all-locations ripple" :href="allLocationsWebUrl" target="_blank" v-if="isMapProduct && !isOnMobileApp">
            <i class="material-icons-round">
              format_list_bulleted
            </i>
            <label>{{ $t('navigationSideBar.allLocations') }}</label>
          </a>
          <a class="misc ripple" :href="miscWebUrl" target="_blank" v-if="isMapProduct && !isOnMobileApp">
            <i class="material-icons-round">
              miscellaneous_services
            </i>
            <label>{{ $t('navigationSideBar.misc') }}</label>
          </a>
          <a class="help ripple" :href="helpWebUrl" target="_blank" v-if="isMapProduct && !isOnMobileApp">
            <i class="material-icons-round">
              support
            </i>
            <label>{{ $t('navigationSideBar.help') }}</label>
          </a>
          <a class="about ripple" @click="onClickUrl(aboutWebUrl)" v-if="isTrafficProduct">
            <i class="material-icons-round">
              info
            </i>
            <label>{{ $t('navigationSideBar.about') }}</label>
          </a>
          <button class="settings ripple" @click="clickSettings">
            <i class="material-icons-round">
              settings
            </i>
            <label>{{ $t('navigationSideBar.settings') }}</label>
          </button>
          <button class="logout ripple" @click="clickLogout" v-if="isLogin">
            <i class="material-icons-round">
              lock_open
            </i>
            <label>{{ $t('navigationSideBar.logout') }}</label>
          </button>
          <hr class="solid" v-if="isLogin">
          <button class="pdpa-management ripple" @click="clickPdpaManagement" v-if="isLogin">
            <i class="material-icons-round">
              security
            </i>
            <label>{{ $t('navigationSideBar.pdpaManagement') }}</label>
          </button>
          <button class="delete-account ripple" @click="clickDeleteLongdoAccount" v-if="isLogin && isIosNativeApp">
            <i class="material-icons-round">
              no_accounts
            </i>
            <label>{{ $t('navigationSideBar.deleteLongdoAccount') }}</label>
          </button>
          <hr class="solid">

          <a class="map-web ripple" @click="onClickUrl(mapWebUrl)" v-if="isTrafficProduct">
            <i class="material-icons-round">
              map
            </i>
            <label>Longdo Map</label>
          </a>
          <a class="traffic-web ripple" v-if="isMapProduct" @click="onClickUrl(trafficWebUrl)">
            <i class="material-icons-round">
              traffic
            </i>
            <label>Longdo Traffic</label>
          </a>
          <a class="traffic-web ripple" @click="onClickUrl(volunteerWebUrl)" v-if="!isOnMobileApp">
            <i class="material-icons-round">
              volunteer_activism
            </i>
            <label>{{ $t('navigationSideBar.volunteer') }}</label>
          </a>
          <a class="traffic-web ripple volunteer" @click="onClickUrl(volunteerWebUrl)" v-if="isTrafficProduct">
              <div class="amount">
                <div>{{$t('navigationSideBar.volunteerAmount')}}</div>
                <div>{{probeAmount}}</div>
              </div>
          </a>
          <a class="traffic-web ripple" @click="onClickUrl(easyMapWebUrl)" v-if="!isOnMobileApp">
            <i class="material-icons-round">
              map
            </i>
            <label>{{ $t('navigationSideBar.easyMap') }}</label>
          </a>
          <a class="blog ripple" @click="onClickUrl(mapBlogWebUrl)">
            <i class="material-icons-round">
              comment
            </i>
            <label>{{ $t('navigationSideBar.blog') }}</label>
          </a>
          <a class="traffic-web ripple" @click="onClickUrl(cameraListUrl)" v-if="isTrafficProduct">
            <i class="material-icons-round">
              videocam
            </i>
            <label>{{ $t('navigationSideBar.camera') }}</label>
          </a>
          <button class="traffic-web ripple" @click="shareWebUrl" v-if="isTrafficProduct">
            <i class="material-icons-round">
              mail
            </i>
            <label>{{ $t('navigationSideBar.share') }}</label>
          </button>
          <a class="blog ripple admin-panel" :href="POIIconsUrl" target="_blank" v-if="isAdmin && !isOnMobileApp">
            <i class="material-icons-round">
              interests
            </i>
            <label>{{ $t('navigationSideBar.POIIcons') }}</label>
          </a>
          <a class="blog ripple admin-panel" :href="userManageUrl" target="_blank" v-if="isAdmin && !isOnMobileApp">
            <i class="material-icons-round">
              manage_accounts
            </i>
            <label>{{ $t('navigationSideBar.userManage') }}</label>
          </a>
          <a class="blog ripple admin-panel" :href="poiManageUrl" target="_blank" v-if="isAdmin && !isOnMobileApp">
            <i class="material-icons-round">
              where_to_vote
            </i>
            <label>{{ $t('navigationSideBar.poiManage') }}</label>
          </a>
          <a class="blog ripple admin-panel" :href="logEntriesUrl" target="_blank" v-if="isAdmin && !isOnMobileApp">
            <i class="material-icons-round">
              assignment
            </i>
            <label>{{ $t('navigationSideBar.logEntries') }}</label>
          </a>
          <a class="blog ripple admin-panel" :href="OOISettingsUrl" target="_blank" v-if="isAdmin && !isOnMobileApp">
            <i class="material-icons-round">
              settings
            </i>
            <label>{{ $t('navigationSideBar.OOISettings') }}</label>
          </a>
        </div>
        <label class="version">Version {{ appVersion }}</label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavigationSideBar',
  data () {
    return {
      width: window.innerWidth,
      isShowingNavigationSideBar: false,
      uid: '',
      profilePicture: '',
      displayName: '',
      isLogin: false,
      isAdmin: false,
      probeAmount: 0
    }
  },
  computed: {
    rasterModeUrl () {
      return `${window.location.origin}${process.env.VUE_APP_BASE_URL}?map=v2`
    },
    vectorModeUrl () {
      return `${window.location.origin}${process.env.VUE_APP_BASE_URL}?map=v3`
    },
    desktopModeUrl () {
      return `${window.location.origin}${process.env.VUE_APP_BASE_URL}?nomobile=1`
    },
    productWebUrl () {
      return 'https://map.longdo.com/products'
    },
    consoleWebUrl () {
      return 'https://map.longdo.com/console'
    },
    apiDocWebUrl () {
      return 'https://map.longdo.com/docs'
    },
    extraApiWebUrl () {
      return 'https://map.longdo.com/extra-api'
    },
    trafficWebUrl () {
      return 'https://traffic.longdo.com'
    },
    mapWebUrl () {
      return 'https://map.longdo.com'
    },
    volunteerWebUrl () {
      return 'https://traffic.longdo.com/volunteer'
    },
    easyMapWebUrl () {
      return 'https://traffic.longdo.com/easymap'
    },
    mapBlogWebUrl () {
      return 'https://map-blog.longdo.com'
    },
    downloadWebUrl () {
      const self = this
      return self.isTrafficProduct ? 'https://traffic.longdo.com/download?' : 'https://map.longdo.com/download/longdomap?'
    },
    cameraListUrl () {
      return 'https://traffic.longdo.com/cameralist'
    },
    allLocationsWebUrl () {
      return 'https://map.longdo.com/poilist'
    },
    miscWebUrl () {
      return 'https://map.longdo.com/tools'
    },
    helpWebUrl () {
      return 'https://map.longdo.com/help'
    },
    aboutWebUrl () {
      return 'https://traffic.longdo.com/about'
    },
    userManageUrl () {
      return `${window.location.origin}/admin/info`
    },
    POIIconsUrl () {
      return `${window.location.origin}/admin/settings/iconfiles`
    },
    logEntriesUrl () {
      return `${window.location.origin}/admin/logs/watchdog`
    },
    OOISettingsUrl () {
      return `${window.location.origin}/admin/settings/ooi`
    },
    poiManageUrl () {
      return 'https://backoffice.longdo.com/poi-management'
    }
  },

  async mounted () {
    const self = this
    const userSession = await self.utility.getUserSession()
    if (userSession) {
      if (userSession.exists === false) {
        await self.clickLogout()
        return false
      } else {
        if (userSession.roles) {
          Object.keys(userSession.roles).forEach((key) => {
            if (userSession.roles[key] === 'webmaster') { self.isAdmin = true }
            if (userSession.roles[key] === 'sales') { self.isAdmin = true }
          })
        }
      }
    }
    if (self.isTrafficProduct) {
      const probe = await self.api.getNumOfProbe()
      self.probeAmount = probe.data?.probe || 0
    }

    self.$root.$on('resize', (size) => {
      self.width = size.width
    })
    self.$root.$on('showNavigationSideBar', self.showNavigationSideBar)
    self.$root.$on('loginComplete', self.onLoginComplete)
  },
  methods: {
    showNavigationSideBar () {
      const self = this
      self.isShowingNavigationSideBar = true
    },
    async onLoginComplete (user) {
      const self = this
      if (self.isOnMobileApp) {
        try {
          const result = await self.lji.addStorage({
            key: 'username',
            value: user.name
          })

          // {Boolean} result
          console.log(result)
        } catch (error) {
          // {<{code: Number, message: String}>} error
          console.log(error)
        }
        try {
          const result = await self.lji.addStorage({
            key: 'ldtoken',
            value: user.longdousertoken
          })

          // {Boolean} result
          console.log(result)
        } catch (error) {
          // {<{code: Number, message: String}>} error
          console.log(error)
        }
        try {
          const result = await self.lji.addStorage({
            key: 'userdata',
            value: JSON.stringify(user)
          })

          // {Boolean} result
          console.log(result)
        } catch (error) {
          // {<{code: Number, message: String}>} error
          console.log(error)
        }
      } else {
        localStorage.username = user.name
        localStorage.ldtoken = user.longdousertoken
        localStorage.userdata = JSON.stringify(user)
        setTimeout(() => {
          localStorage.username = null
          localStorage.ldtoken = null
          localStorage.userdata = null
        }, 1000 * 60 * 2)
      }
      self.uid = user.uid
      self.profilePicture = user !== null ? (process.env.VUE_APP_LONGDO_ACCOUNT_PROFILE_IMAGE + user.uid) : ''
      self.displayName = user !== null ? user.display_name : ''
      self.isLogin = true
      if (user.roles) {
        Object.keys(user.roles).forEach((key) => {
          if (user.roles[key] === 'webmaster') { self.isAdmin = true }
          if (user.roles[key] === 'sales') { self.isAdmin = true }
        })
      }
    },
    shareWebUrl () {
      const self = this
      const map = window.map
      const loc = map.location()
      const zoom = map.zoom()
      const url = `https://${process.env.VUE_APP_PRODUCT}.longdo.com/?lat=${loc.lat.toFixed(6)}&lon=${loc.lon.toFixed(6)}&zoom=${zoom.toFixed(6)}`
      if (typeof window.navigator !== 'undefined') {
        if (window.navigator.share) {
          window.navigator.share({
            url: url,
            title: self.$t(`main.${process.env.VUE_APP_PRODUCT}ShareThisLocationTitle`)
          })
        } else {
          const copytext = document.createElement('input')
          copytext.value = url
          document.body.appendChild(copytext)
          copytext.blur()
          copytext.select()
          document.execCommand('Copy')
          document.body.removeChild(copytext)
          self.$root.$emit('showMessageToast', self.$t('sharePlaceWidget.copy'))
        }
      } else {
        const copytext = document.createElement('input')
        copytext.value = url
        document.body.appendChild(copytext)
        copytext.blur()
        copytext.select()
        document.execCommand('Copy')
        document.body.removeChild(copytext)
        self.$root.$emit('showMessageToast', self.$t('sharePlaceWidget.copy'))
      }
    },
    async clickLogin () {
      const self = this
      if (self.isOnMobileApp) {
        let loginResult
        try {
          loginResult = await self.lji.loginLongdo()

          // {<{username: String, longdoToken: String}>} result
        } catch (error) {
          // {<{code: Number, message: String}>} error
          console.log(error)
        }
        if (loginResult?.username && loginResult?.longdoToken) {
          try {
            const result = await self.lji.addStorage({
              key: 'username',
              value: loginResult.username
            })

            // {Boolean} result
            console.log(result)
          } catch (error) {
          // {<{code: Number, message: String}>} error
            console.log(error)
          }
          try {
            const result = await self.lji.addStorage({
              key: 'ldtoken',
              value: loginResult.longdoToken
            })

            // {Boolean} result
            console.log(result)
          } catch (error) {
          // {<{code: Number, message: String}>} error
            console.log(error)
          }
        }
        const user = await self.utility.getUserSession()
        if (user) {
          self.$root.$emit('loginComplete', user)
        } else {
          console.log('error getUserSession')
        }
      } else {
        self.$root.$emit('showLongdoLoginForm')
      }
    },
    clickCreatePlace () {
      const self = this
      if (self.isLongdoMapV3) {
        window.location = `${window.location.origin}${process.env.VUE_APP_BASE_URL}create?map=v2&lat=${localStorage.lat}&lon=${localStorage.lon}`
      } else {
        self.$router.push({
          name: 'CreatePlace',
          params: {},
          query: {
            lat: Number(localStorage.lat),
            lon: Number(localStorage.lon)
          },
          hash: ''
        })
      }
    },
    clickMyPlace () {
      const self = this
      const _window = window
      if (self.isLongdoMapV3 && self.width > 768 && !self.isOnMobileApp) {
        _window.location = `${process.env.VUE_APP_LONGDO_MAP_USER_INFO}${self.uid}`
      } else {
        self.$router.push({
          name: 'Main',
          hash: '#my-places'
        })
      }
    },
    clickMeasurement () {
      const self = this
      self.$router.push({
        name: 'Main',
        hash: '#measurement'
      })
    },
    clickSettings () {
      const self = this
      self.$router.push({
        path: self.$route.path,
        params: self.$route.params,
        query: self.$route.query,
        hash: '#settings'
      })
    },
    clickPdpaManagement () {
      const self = this
      // self.$router.push({
      //   path: self.$route.path,
      //   params: self.$route.params,
      //   query: self.$route.query,
      //   hash: '#pdpa-management'
      // })
      if (self.isOnMobileApp) {
        self.onClickUrl(`https://longdo.com/${self.$i18n.locale}/user/${self.uid}/edit/Privacy`)
      } else {
        window.location.href = `https://longdo.com/${self.$i18n.locale}/user/${self.uid}/edit/Privacy`
      }
    },
    async clickDeleteLongdoAccount () {
      const self = this

      if (self.isIosNativeApp) {
        try {
          const result = await self.lji.deleteLongdo()
          // {Bool} result
          console.log(result)
          if (result) {
            self.clickLogout()
          }
        } catch (error) {
        // {<{code: Number, message: String}>} error
          console.log(error)
        }
      }
    },
    async clickLogout () {
      const self = this

      if (self.lji !== null) {
        try {
          await self.lji.setCookie({
            domain: '.longdo.com',
            cookie: 'longdouser_uid=; domain=.longdo.com; path=/; Max-Age=86400'
          })
        } catch (e) {
          console.log(e)
        }

        try {
          await self.lji.setCookie({
            domain: '.longdo.com',
            cookie: 'longdouser_username=; domain=.longdo.com; path=/; Max-Age=86400'
          })
        } catch (e) {
          console.log(e)
        }

        try {
          await self.lji.setCookie({
            domain: '.longdo.com',
            cookie: 'longdouser_longdousertoken=; domain=.longdo.com; path=/; Max-Age=86400'
          })
        } catch (e) {
          console.log(e)
        }

        try {
          await self.lji.setCookie({
            domain: '.longdo.com',
            cookie: 'longdouser_displayname=; domain=.longdo.com; path=/; Max-Age=86400'
          })
        } catch (e) {
          console.log(e)
        }
      }

      self.isLogin = false
      self.$root.$emit('logoutComplete')

      if (self.isOnMobileApp) {
        try {
          const result = await self.lji.logoutLongdo()
          // {Bool} result
          console.log(result)
        } catch (error) {
        // {<{code: Number, message: String}>} error
          console.log(error)
        }

        try {
          const result = await self.lji.deleteStorage({
            key: 'username'
          })

          // {Boolean} result
          console.log(result)
        } catch (error) {
          // {<{code: Number, message: String}>} error
          console.log(error)
        }
        try {
          const result = await self.lji.deleteStorage({
            key: 'ldtoken'
          })

          // {Boolean} result
          console.log(result)
        } catch (error) {
          // {<{code: Number, message: String}>} error
          console.log(error)
        }
        try {
          const result = await self.lji.deleteStorage({
            key: 'userdata'
          })

          // {Boolean} result
          console.log(result)
        } catch (error) {
          // {<{code: Number, message: String}>} error
          console.log(error)
        }
      }
      if (self.lji.Util.isAndroidNativeApp()) {
        window.location.assign('/logout')
      } else {
        self.longdoAccount.logout()
      }
    },
    openUserInfo () {
      const self = this
      if (self.isOnMobileApp) {
        self.onClickUrl(process.env.VUE_APP_LONGDO_USER_INFO + self.uid)
      } else {
        window.location.href = process.env.VUE_APP_LONGDO_USER_INFO + self.uid
      }
    },
    async onClickUrl (url) {
      const self = this
      if (self.isOnMobileApp) {
        try {
          const result = await self.lji.openUrl({
            url: url
          })
          console.log(result)
        } catch (error) {
          console.log(error)
        }
      } else {
        window.open(url, '_blank').focus()
      }
    }
  },
  watch: {
    async $route (to, from) {
      const self = this
      if (from.name === 'CreatePlace' || from.name === 'EditPlace' || from.name === 'InfoPlace') {
        if (!self.isLogin) {
          const userSession = await self.utility.getUserSession()
          const user = userSession
          // const user = self.longdoComAPI.getUserInfo()
          if (user) {
            self.onLoginComplete(user)
          }
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
// @import '@/assets/style/variables.scss';

.navigation-side-bar {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: -100%;
  z-index: 200;
  transition: left 0.2s cubic-bezier(0.6, 0.05, 0.28, 0.91) 0s;

  >.container {
    width: calc(100% - 96px);
    max-width: 320px;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    background-color: rgb(255, 255, 255);
    position: fixed;
    top: 0px;
    left: calc(0px - 100% - 96px);
    transition: left 0.3s ease 0s;
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);

    >.logo-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      padding: 6px 6px 0px 0px;
      background-image: linear-gradient(0deg, $primary, $darkPrimary);

      >img {
        height: 24px;
      }
    }

    >.user {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 0px 0px 3px 0px;
      background-color: $primary;
      border-bottom: 4px solid $lightPrimary;
      min-height: 64px;

      >.profile-picture-container {
        width: 64px;
        height: 64px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        >img {
          width: 48px;
          height: 48px;
          object-fit: cover;
          display: block;
          border-radius: 50%;
          overflow: hidden;
          background-color: rgb(255, 255, 255);
        }

        >i {
          width: 48px;
          height: 48px;
          font-size: 48px;
          color: rgb(255, 255, 255);
        }
      }

      >.profile-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: calc(100% - 64px);

        >.display-name {
          font-size: 16px;
          color: rgb(255, 255, 255);
        }

        >button.login {
          background: transparent;
          border: none;
          padding: 0px;
          outline: none;
          appearance: none;

          >label {
            font-size: 16px;
            color: rgb(255, 255, 255);
            font-family: Prompt;
          }
        }
      }
    }

    >.menu-list {
      cursor: pointer;
      height: calc(100% - 101px);
      width: 100%;
      overflow: hidden;
      overflow-y: auto;
      background-size: 128px 128px;
      background-repeat: repeat;
      background-blend-mode: lighten;
      background-color: rgba(255, 255, 255, 0.6);

      hr.solid {
        width: 90%;
        position: relative;
        border: 0.5px solid rgba(225,225,225, 0.4);
      }
      >button,
      a {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        border: none;
        min-height: 54px;
        padding: 12px;
        background: transparent;
        outline: none;
        appearance: none;
        text-decoration: none;
        box-sizing: border-box;

        > * {
          cursor: pointer;
        }

        &.vector-map {
          i {
            transform: perspective(80px) rotateX(40deg);
          }
        }

        >i {
          width: 24px;
          height: 24px;
          font-size: 24px;
          padding: 0px 12px 0px 0px;
          color: $darkGrey;
          text-decoration: none;
          appearance: none;
        }

        >label {
          font-family: Prompt;
          text-decoration: none;
          appearance: none;
          text-align: left;
        }

        &.delete-account > * {
          color: red;
        }
      }
      > .volunteer {
        display: flex;
        justify-content: center;

        > .amount {
          background-color: #F4FCF9;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          padding: 9px 14px;
          border-radius: 6px;
          border-color: $primary;
          border-style: solid;
          border-width: 0.5px;
          gap: 12px;
          width: 140px;

          > div {
            width: fit-content;
            color: $primary;
          }
        }
      }
    }

    >.menu-list::before {
      position: absolute;
      bottom: 0px;
      height: calc(100% - 101px);
      width: 100%;
      content: " ";
      background-image: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 75%, rgba(255, 255, 255, 0) 100%);
      z-index: 0;
    }

    .version {
      position: absolute;
      bottom: 6px;
      right: 6px;
      color: $grey;
    }
  }
}

.navigation-side-bar.active {
  left: 0px;

  >.container {
    left: 0px;
  }
}

.admin-panel {
  background-color: #d6e6ff !important;
}
</style>
