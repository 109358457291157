var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "pdpa-management",
      class: { hide: _vm.$route.hash !== "#pdpa-management" || !_vm.isLogin },
    },
    [
      _c("div", { staticClass: "title-bar" }, [
        _c(
          "button",
          {
            staticClass: "back ripple",
            on: {
              click: function ($event) {
                return _vm.clickBack()
              },
            },
          },
          [
            _c("i", { staticClass: "material-icons-round" }, [
              _vm._v(" arrow_back "),
            ]),
          ]
        ),
        _c("div", { staticClass: "title" }, [
          _vm._v(" " + _vm._s(_vm.$t("pdpaManagement.title")) + " "),
        ]),
      ]),
      _c("div", { staticClass: "content" }, [
        _c("div", {
          staticClass: "banner",
          style: {
            backgroundImage:
              "url('" +
              _vm.baseUrl +
              "img/pdpa-banner.svg?version=" +
              _vm.appVersion +
              "')",
          },
        }),
        _c("div", { staticClass: "info" }, [
          _c("div", [_vm._v(_vm._s(_vm.$t("pdpaManagement.consentedDate")))]),
          _c("div", [_vm._v(_vm._s(_vm.consentedDate))]),
        ]),
        _c("a", { attrs: { href: "https://longdo.com/privacy" } }, [
          _c("button", { staticClass: "view-privacy-policy button ripple" }, [
            _vm._v(_vm._s(_vm.$t("pdpaManagement.viewPrivacy"))),
          ]),
        ]),
        _c(
          "button",
          {
            staticClass: "button withdraw ripple",
            on: { click: _vm.withdraw },
          },
          [_vm._v(_vm._s(_vm.$t("pdpaManagement.withdraw")))]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }