var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "event-panel",
      class: {
        hide: _vm.isHidingEventPanel,
        "expand-less": !_vm.isExpand,
      },
    },
    [
      _c("div", { staticClass: "event-container" }, [
        _c("div", { staticClass: "top-bar" }, [
          _vm.isExpand
            ? _c(
                "button",
                {
                  staticClass: "expand less ripple",
                  on: { click: _vm.clickExpandLess },
                },
                [
                  _c("i", { staticClass: "material-icons-round" }, [
                    _vm._v("expand_more"),
                  ]),
                  _c("label", [
                    _vm._v(_vm._s(_vm.$t("eventPanel.expandLess"))),
                  ]),
                ]
              )
            : _vm._e(),
          !_vm.isExpand
            ? _c(
                "button",
                {
                  staticClass: "expand more ripple",
                  on: { click: _vm.clickExpandMore },
                },
                [
                  _c("i", { staticClass: "material-icons-round" }, [
                    _vm._v("expand_less"),
                  ]),
                  _c("label", [
                    _vm._v(_vm._s(_vm.$t("eventPanel.expandMore"))),
                  ]),
                ]
              )
            : _vm._e(),
        ]),
        _vm.showLoading && _vm.tabNo === 2
          ? _c("div", { staticClass: "loading-container" }, [
              _c("img", {
                attrs: { src: require("@/assets/img/loading.gif") },
              }),
            ])
          : _vm._e(),
        !_vm.showLoading && _vm.eventList !== null && _vm.eventList.length === 0
          ? _c("div", { staticClass: "empty-container" }, [
              _vm._v(" " + _vm._s(_vm.$t("eventPanel.empty")) + " "),
            ])
          : _vm._e(),
        _c("div", { staticClass: "event-tab-container" }, [
          _c("div", { staticClass: "event-tab" }, [
            _c("input", {
              attrs: {
                type: "radio",
                id: "event-now",
                name: "tabs",
                checked: "",
              },
              on: {
                click: function ($event) {
                  return _vm.eventTab(1)
                },
              },
            }),
            _c("label", { staticClass: "tab", attrs: { for: "event-now" } }, [
              _vm._v("เหตุการณ์ล่าสุด"),
            ]),
            _c("input", {
              attrs: { type: "radio", id: "event-all", name: "tabs" },
              on: {
                click: function ($event) {
                  return _vm.eventTab(2)
                },
              },
            }),
            _c("label", { staticClass: "tab", attrs: { for: "event-all" } }, [
              _vm._v("ทั้งหมด"),
            ]),
            _c("span", { staticClass: "glider" }),
          ]),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.tabNo === 1,
                expression: "tabNo === 1",
              },
            ],
            ref: "event-list",
            staticClass: "event-list",
          },
          _vm._l(_vm.eventList, function (event) {
            return _c(
              "div",
              {
                key: event.eid,
                staticClass: "event ripple",
                on: {
                  click: function ($event) {
                    return _vm.onClickIncident(event)
                  },
                },
              },
              [
                _c("div", { staticClass: "icon" }, [
                  _c("img", {
                    attrs: {
                      src: event.icon2x,
                      srcset: event.icon1x + " 1x" + event.icon2x + " 2x",
                      loading: "lazy",
                    },
                  }),
                ]),
                _c("div", { staticClass: "detail" }, [
                  _c(
                    "label",
                    {
                      staticClass: "name",
                      attrs: {
                        title:
                          _vm.$i18n.locale === "en"
                            ? event.title_en
                            : event.title,
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$i18n.locale === "en"
                              ? event.title_en
                              : event.title
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("label", { staticClass: "time" }, [
                    _vm._v(
                      " " +
                        _vm._s(event.start_time) +
                        " " +
                        _vm._s(event.end_time) +
                        " "
                    ),
                  ]),
                ]),
                _c(
                  "a",
                  {
                    staticClass: "info",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.onClickIncident(event)
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "material-icons-round" }, [
                      _vm._v("info"),
                    ]),
                  ]
                ),
              ]
            )
          }),
          0
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.tabNo === 2,
                expression: "tabNo === 2",
              },
            ],
            ref: "event-list-all",
            staticClass: "event-list",
          },
          [
            _vm._l(_vm.eventListAll, function (event) {
              return _c(
                "div",
                {
                  key: event.eid,
                  staticClass: "event ripple",
                  on: {
                    click: function ($event) {
                      return _vm.onClickIncident(event)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "icon" }, [
                    _c("img", {
                      attrs: {
                        src: event.icon2x,
                        srcset: event.icon1x + " 1x" + event.icon2x + " 2x",
                        loading: "lazy",
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "detail" }, [
                    _c(
                      "label",
                      {
                        staticClass: "name",
                        attrs: {
                          title:
                            _vm.$i18n.locale === "en"
                              ? event.title_en
                              : event.title,
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$i18n.locale === "en"
                                ? event.title_en
                                : event.title
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c("label", { staticClass: "time" }, [
                      _vm._v(
                        " " +
                          _vm._s(event.start_time) +
                          " " +
                          _vm._s(event.end_time) +
                          " "
                      ),
                    ]),
                    _c("label", { staticClass: "contributor" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("eventPanel.contributor")) +
                          ": " +
                          _vm._s(event.contributor) +
                          " "
                      ),
                    ]),
                  ]),
                  _c(
                    "a",
                    {
                      staticClass: "info",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.onClickIncident(event)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "material-icons-round" }, [
                        _vm._v("info"),
                      ]),
                    ]
                  ),
                ]
              )
            }),
            _vm.page > 1 && !_vm.showLoading
              ? _c(
                  "button",
                  {
                    staticClass: "ripple",
                    attrs: { title: _vm.$t("eventPanel.moreTitle") },
                    on: {
                      click: function ($event) {
                        return _vm.getEventList(true)
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("eventPanel.more")) + " ")]
                )
              : _vm._e(),
          ],
          2
        ),
      ]),
      _c("div", { staticClass: "event-all" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }