var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        "routing-panel": !_vm.isLongdoMapV3,
        "routing-panel-map3": _vm.isLongdoMapV3,
        hide: _vm.isHidingInputPanel,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "input-panel",
          class: {
            hide: _vm.isHidingInputPanel,
            "full-height":
              _vm.selectedPointInputIndex !== -1 &&
              _vm.$route.hash === "#choose-location-for-routing",
            "minimize-height": _vm.isMinimizeHeightInputPanel,
          },
        },
        [
          _c(
            "div",
            { staticClass: "vehicle-list" },
            [
              _vm._l(_vm.vehicleList, function (vehicle, key) {
                return [
                  vehicle !== null
                    ? _c(
                        "button",
                        {
                          key: key,
                          staticClass: "ripple",
                          class: { active: _vm.selectedVehicle === key },
                          style: {
                            width:
                              "calc(100% / " +
                              Object.values(_vm.vehicleList).filter(function (
                                v
                              ) {
                                return v !== null
                              }).length +
                              ")",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.clickVehicle(key)
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "material-icons-round" }, [
                            _vm._v(_vm._s(vehicle.icon)),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "point-input-list" },
            [
              _vm._l(
                _vm.selectedVehicle === "transit"
                  ? _vm.transitPointInputList
                  : _vm.pointInputList,
                function (pointInput, index) {
                  return [
                    _c(
                      "div",
                      {
                        key: "point-input-" + index + " " + pointInput.name,
                        staticClass: "point-input",
                      },
                      [
                        _c("img", {
                          staticClass: "drag-element",
                          attrs: {
                            id: "drag-element-" + index,
                            src: _vm.utility.getImgPath(
                              "marker/ic_pin_" +
                                (index === 0
                                  ? "red"
                                  : index === _vm.pointInputList.length - 1 ||
                                    _vm.selectedVehicle === "transit"
                                  ? "green"
                                  : "blue_" + index) +
                                ".svg"
                            ),
                            draggable: true,
                          },
                          on: {
                            dragstart: function ($event) {
                              return _vm.dragStartHandler($event, index)
                            },
                            dragend: function ($event) {
                              return _vm.dragEndHandler()
                            },
                          },
                        }),
                        (_vm.selectedVehicle !== "transit" &&
                          index !== _vm.pointInputList.length - 1) ||
                        (_vm.selectedVehicle === "transit" && index === 0)
                          ? _c("div", { staticClass: "dotted-line" })
                          : _vm._e(),
                        _c("input", {
                          class: {
                            "full-width": _vm.pointInputList.length <= 2,
                          },
                          attrs: {
                            type: "text",
                            placeholder:
                              index === 0
                                ? _vm.$t("routingPanel.chooseStartingPoint")
                                : _vm.$t("routingPanel.chooseDestination"),
                            readonly: "",
                          },
                          domProps: {
                            value: pointInput.isCurrentLocation
                              ? _vm.$t("routingPanel.currentLocation")
                              : pointInput.keyword,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.clickPointInput(index)
                            },
                          },
                        }),
                        _vm.pointInputList.length > 2 &&
                        _vm.selectedVehicle !== "transit"
                          ? _c(
                              "button",
                              {
                                staticClass: "ripple",
                                on: {
                                  click: function ($event) {
                                    return _vm.clearPointInput(index)
                                  },
                                },
                              },
                              [
                                _c(
                                  "i",
                                  { staticClass: "material-icons-round" },
                                  [_vm._v(" close ")]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]
                }
              ),
            ],
            2
          ),
          _c("div", { staticClass: "control-container" }, [
            _c(
              "button",
              {
                staticClass: "ripple add",
                style: {
                  visibility:
                    _vm.selectedVehicle !== "transit" &&
                    _vm.pointInputList.length < 11
                      ? "visible"
                      : "hidden",
                },
                attrs: { disabled: _vm.pointInputList.length >= 11 },
                on: { click: _vm.addPointInput },
              },
              [
                _c("i", { staticClass: "material-icons-round" }, [
                  _vm._v("add"),
                ]),
              ]
            ),
            _c(
              "button",
              {
                staticClass: "ripple swap",
                on: {
                  click: function ($event) {
                    return _vm.swapPointInput(0, _vm.pointInputList.length - 1)
                  },
                },
              },
              [
                _c("i", { staticClass: "material-icons-round" }, [
                  _vm._v("swap_vert"),
                ]),
              ]
            ),
            _vm.isShowingSettingButton
              ? _c(
                  "button",
                  {
                    staticClass: "ripple settings",
                    class: { active: _vm.isShowSettingPanel },
                    on: { click: _vm.onClickSettingButton },
                  },
                  [
                    _c("i", { staticClass: "material-icons-round" }, [
                      _vm._v("settings"),
                    ]),
                  ]
                )
              : _vm._e(),
            _c(
              "button",
              { staticClass: "ripple reset", on: { click: _vm.resetRouting } },
              [_vm._v(" " + _vm._s(_vm.$t("routingPanel.clear")) + " ")]
            ),
            _c(
              "button",
              {
                staticClass: "ripple route",
                attrs: { disabled: _vm.hasNullPointInput },
                on: { click: _vm.route },
              },
              [_vm._v(" " + _vm._s(_vm.$t("routingPanel.route")) + " ")]
            ),
          ]),
          _vm.isShowSettingPanel && _vm.selectedVehicle !== "walk"
            ? _c("div", { staticClass: "setting-panel" }, [
                _vm.selectedVehicle !== "walk"
                  ? _c(
                      "div",
                      { staticClass: "route-type" },
                      [
                        _c("div", { staticClass: "title" }, [
                          _vm._v(_vm._s(_vm.$t("routingPanel.routeTypeTitle"))),
                        ]),
                        _vm._l(
                          _vm.vehicleList[_vm.selectedVehicle].routeTypeList,
                          function (routeType) {
                            return _c(
                              "div",
                              {
                                key: routeType.name,
                                staticClass: "choice-group",
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.vehicleList[_vm.selectedVehicle]
                                          .selectedRouteTypeValueList,
                                      expression:
                                        "vehicleList[selectedVehicle].selectedRouteTypeValueList",
                                    },
                                  ],
                                  attrs: {
                                    type: "checkbox",
                                    id: "route-type-" + routeType.value,
                                    name: "route-type",
                                  },
                                  domProps: {
                                    value: routeType.value,
                                    checked: Array.isArray(
                                      _vm.vehicleList[_vm.selectedVehicle]
                                        .selectedRouteTypeValueList
                                    )
                                      ? _vm._i(
                                          _vm.vehicleList[_vm.selectedVehicle]
                                            .selectedRouteTypeValueList,
                                          routeType.value
                                        ) > -1
                                      : _vm.vehicleList[_vm.selectedVehicle]
                                          .selectedRouteTypeValueList,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a =
                                          _vm.vehicleList[_vm.selectedVehicle]
                                            .selectedRouteTypeValueList,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = routeType.value,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.vehicleList[
                                                _vm.selectedVehicle
                                              ],
                                              "selectedRouteTypeValueList",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.vehicleList[
                                                _vm.selectedVehicle
                                              ],
                                              "selectedRouteTypeValueList",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.vehicleList[_vm.selectedVehicle],
                                          "selectedRouteTypeValueList",
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                                _c(
                                  "label",
                                  {
                                    attrs: {
                                      for: "route-type-" + routeType.value,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "routingPanel.routeType." +
                                            routeType.name
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "route-mode" },
                  [
                    _c("div", { staticClass: "title" }, [
                      _vm._v(_vm._s(_vm.$t("routingPanel.routeModeTitle"))),
                    ]),
                    _vm._l(
                      _vm.vehicleList[_vm.selectedVehicle].routeModeList,
                      function (routeMode) {
                        return _c(
                          "div",
                          { key: routeMode.name, staticClass: "choice-group" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.vehicleList[_vm.selectedVehicle]
                                      .selectedRouteModeValue,
                                  expression:
                                    "vehicleList[selectedVehicle].selectedRouteModeValue",
                                },
                              ],
                              attrs: {
                                type: "radio",
                                id: "route-mode-" + routeMode.value,
                                name: "route-mode",
                              },
                              domProps: {
                                value: routeMode.value,
                                checked: _vm._q(
                                  _vm.vehicleList[_vm.selectedVehicle]
                                    .selectedRouteModeValue,
                                  routeMode.value
                                ),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.$set(
                                    _vm.vehicleList[_vm.selectedVehicle],
                                    "selectedRouteModeValue",
                                    routeMode.value
                                  )
                                },
                              },
                            }),
                            _c(
                              "label",
                              {
                                attrs: { for: "route-mode-" + routeMode.value },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "routingPanel.routeMode." + routeMode.name
                                    )
                                  )
                                ),
                              ]
                            ),
                          ]
                        )
                      }
                    ),
                  ],
                  2
                ),
                _c("div", { staticClass: "depart-datetime" }, [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.$t("routingPanel.departDatetimeTitle"))),
                  ]),
                  _c(
                    "div",
                    { staticClass: "vue-datetime-container" },
                    [
                      !_vm.isHidingInputPanel
                        ? _c("VueDatetime", {
                            attrs: {
                              type: "datetime",
                              "input-id": "dapart-datetime-input",
                              "input-class": "datetime-input",
                              title: "",
                              "min-datetime": new Date().toISOString(),
                              phrases: {
                                ok: _vm.$t("routingPanel.okDepartDatetime"),
                                cancel: _vm.$t(
                                  "routingPanel.cancelDepartDatetime"
                                ),
                              },
                            },
                            model: {
                              value: _vm.departDatetime,
                              callback: function ($$v) {
                                _vm.departDatetime = $$v
                              },
                              expression: "departDatetime",
                            },
                          })
                        : _vm._e(),
                      _c(
                        "i",
                        {
                          staticClass: "ripple material-icons-round",
                          on: { click: _vm.clickDepartDatetimeIcon },
                        },
                        [_vm._v("schedule")]
                      ),
                      _vm.departDatetime !== ""
                        ? _c(
                            "button",
                            {
                              staticClass: "reset ripple",
                              on: {
                                click: function ($event) {
                                  _vm.departDatetime = ""
                                },
                              },
                            },
                            [
                              _c(
                                "i",
                                { staticClass: "ripple material-icons-round" },
                                [_vm._v("close")]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "summary-point-input",
              on: { click: _vm.expandMoreInputPanel },
            },
            [
              _c(
                "div",
                { staticClass: "ripple" },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.pointInputList[0].isCurrentLocation
                          ? _vm.$t("routingPanel.currentLocation")
                          : _vm.pointInputList[0].keyword
                      )
                    ),
                  ]),
                  _c("i", { staticClass: "material-icons-round" }, [
                    _vm._v("double_arrow"),
                  ]),
                  _vm.pointInputList.length - 2 > 0
                    ? [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.pointInputList.length -
                                2 +
                                " " +
                                _vm.$t("routingPanel.stopPoint")
                            )
                          ),
                        ]),
                        _c("i", { staticClass: "material-icons-round" }, [
                          _vm._v("double_arrow"),
                        ]),
                      ]
                    : _vm._e(),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.pointInputList[_vm.pointInputList.length - 1]
                          .isCurrentLocation
                          ? _vm.$t("routingPanel.currentLocation")
                          : _vm.pointInputList[_vm.pointInputList.length - 1]
                              .keyword
                      )
                    ),
                  ]),
                ],
                2
              ),
            ]
          ),
        ]
      ),
      _c("ChooseLocationWidget", {
        class: {
          hide: !(
            _vm.selectedPointInputIndex !== -1 &&
            _vm.$route.hash === "#choose-location-for-routing"
          ),
        },
        attrs: {
          initialKeyword:
            _vm.selectedPointInputIndex !== -1
              ? _vm.pointInputList[_vm.selectedPointInputIndex].keyword
              : "",
          trickInit:
            _vm.selectedPointInputIndex !== -1 &&
            _vm.$route.hash === "#choose-location-for-routing",
          lat: _vm.map.location().lat,
          lon: _vm.map.location().lon,
          zoom: _vm.map.zoom(),
        },
        on: {
          clickBack: _vm.onClickBackFromChooseLocationWidget,
          clickSearchResult: _vm.onClickSearchResultFromChooseLocationWidget,
          clickCurrentLocation:
            _vm.onClickCurrentLocationFromChooseLocationWidget,
        },
      }),
      _c(
        "div",
        {
          staticClass: "guide-panel",
          class: {
            hide: _vm.isHidingGuidePanel,
            "full-height": _vm.isFullHeightGuidePanel,
            "minimize-height": _vm.isMinimizeHeightGuidePanel,
            "input-minimize-height": _vm.isMinimizeHeightInputPanel,
          },
        },
        [
          _vm.isRouting
            ? _c("div", { staticClass: "loading-more-container" }, [
                _c("img", {
                  attrs: { src: require("@/assets/img/loading.gif") },
                }),
              ])
            : _vm._e(),
          !_vm.isRouting &&
          _vm.guideList.length > 0 &&
          _vm.selectedVehicle !== "transit" &&
          !_vm.isShowRouteChangeColor
            ? _c("div", { staticClass: "guide" }, [
                _c(
                  "div",
                  {
                    staticClass: "info ripple",
                    on: { click: _vm.clickGuideInfo },
                  },
                  [
                    (!_vm.isFullHeightGuidePanel &&
                      !_vm.isMinimizeHeightGuidePanel) ||
                    _vm.isMinimizeHeightGuidePanel
                      ? _c(
                          "button",
                          {
                            staticClass: "ripple expand-more",
                            style: _vm.isShowRouteChangeColor
                              ? { display: "none" }
                              : {},
                          },
                          [
                            _c("i", { staticClass: "material-icons-round" }, [
                              _vm._v("expand_less"),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm.isFullHeightGuidePanel
                      ? _c("button", { staticClass: "ripple expand-less" }, [
                          _c("i", { staticClass: "material-icons-round" }, [
                            _vm._v("expand_more"),
                          ]),
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "time" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.timeFormat(
                              _vm.guideList[_vm.selectedGuideIndex]
                                .map(function (o) {
                                  return o.interval
                                })
                                .reduce(function (total, t) {
                                  return total + t
                                })
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c("div", { staticClass: "distance" }, [
                      _vm._v(
                        " (" +
                          _vm._s(
                            _vm.kmFormat(
                              _vm.guideList[_vm.selectedGuideIndex]
                                .map(function (o) {
                                  return o.distance
                                })
                                .reduce(function (total, d) {
                                  return total + d
                                })
                            )
                          ) +
                          ") "
                      ),
                    ]),
                    _vm.pointInputList[0].isCurrentLocation &&
                    _vm.pointInputList.length === 2
                      ? _c(
                          "button",
                          {
                            staticClass: "start-navigation ripple",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.onClickStartNavigation.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "material-icons-round" }, [
                              _vm._v("navigation"),
                            ]),
                            _vm._v(
                              " " + _vm._s(_vm.$t("routingPanel.start")) + " "
                            ),
                          ]
                        )
                      : _vm._e(),
                    !_vm.isShowRouteChangeColor &&
                    _vm.isHasSetTrafficIticPermission
                      ? _c(
                          "div",
                          {
                            staticClass: "route-color-change",
                            on: {
                              click: function ($event) {
                                _vm.isShowRouteChangeColor =
                                  !_vm.isShowRouteChangeColor
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t("routingPanel.addColorLine.menuName")
                              )
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "step-list" },
                  [
                    _vm._l(
                      _vm.guideList[_vm.selectedGuideIndex],
                      function (aToB, i) {
                        return [
                          _c(
                            "div",
                            { key: "a-to-b-" + i, staticClass: "a-to-b" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "step from ripple",
                                  on: {
                                    click: function ($event) {
                                      return _vm.focusPoint(
                                        _vm.pointInputList[i]
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._m(0, true),
                                  _c("div", {
                                    staticClass: "name",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        "<span style='color: rgb(56, 148, 238);'>" +
                                          _vm.$t("routingPanel.from") +
                                          "</span> " +
                                          (_vm.pointInputList[i]
                                            .isCurrentLocation
                                            ? _vm.$t(
                                                "routingPanel.currentLocation"
                                              )
                                            : _vm.pointInputList[i].keyword)
                                      ),
                                    },
                                  }),
                                ]
                              ),
                              _vm._l(aToB.guide, function (guide, j) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      key: "a-to-b-" + i + "-step-" + j,
                                      staticClass: "step ripple",
                                      on: {
                                        click: function ($event) {
                                          return _vm.focusStep(i, j)
                                        },
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "icon" }, [
                                        _c("img", {
                                          attrs: {
                                            src: _vm.turnIcon(guide.turn),
                                          },
                                        }),
                                      ]),
                                      _c("div", {
                                        staticClass: "name",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            "<span style='color: rgb(56, 148, 238);'>" +
                                              _vm.$t(
                                                "routingPanel.turnCode[" +
                                                  guide.turn +
                                                  "]"
                                              ) +
                                              "</span> " +
                                              guide.name
                                          ),
                                        },
                                      }),
                                      _c("div", { staticClass: "distance" }, [
                                        _vm._v(
                                          _vm._s(_vm.kmFormat(guide.distance))
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              }),
                              _c(
                                "div",
                                {
                                  staticClass: "step to ripple",
                                  on: {
                                    click: function ($event) {
                                      return _vm.focusPoint(
                                        _vm.pointInputList[i + 1]
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._m(1, true),
                                  _c("div", {
                                    staticClass: "name",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        "<span style='color: rgb(56, 148, 238);'>" +
                                          _vm.$t("routingPanel.to") +
                                          "</span> " +
                                          (_vm.pointInputList[i + 1]
                                            .isCurrentLocation
                                            ? _vm.$t(
                                                "routingPanel.currentLocation"
                                              )
                                            : _vm.pointInputList[i + 1].keyword)
                                      ),
                                    },
                                  }),
                                ]
                              ),
                            ],
                            2
                          ),
                        ]
                      }
                    ),
                  ],
                  2
                ),
              ])
            : _vm._e(),
          _vm.isShowRouteChangeColor && _vm.isHasSetTrafficIticPermission
            ? _c("div", { staticClass: "guide route-color-panel" }, [
                _c("div", { staticClass: "info header-color-panel" }, [
                  _c("div", { staticClass: "time" }, [
                    _vm._v(_vm._s(_vm.$t("routingPanel.addColorLine.title"))),
                  ]),
                  _c(
                    "button",
                    {
                      staticClass: "close-color-route-btn ripple",
                      on: {
                        click: function ($event) {
                          _vm.isShowRouteChangeColor =
                            !_vm.isShowRouteChangeColor
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "material-icons-round" }, [
                        _vm._v("close"),
                      ]),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "color-route-content" }, [
                  _c("div", { staticClass: "btn-color" }, [
                    _c(
                      "div",
                      {
                        staticClass: "btn-route-color selected-color-route",
                        attrs: { id: "R" },
                        on: {
                          click: function ($event) {
                            return _vm.handleColorRouteSelected("R")
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "route-color-btn-red" }),
                        _c("div", { staticClass: "label-color" }, [
                          _vm._v("ติดขัด"),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "btn-route-color",
                        attrs: { id: "Y" },
                        on: {
                          click: function ($event) {
                            return _vm.handleColorRouteSelected("Y")
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "route-color-btn-yellow" }),
                        _c("div", { staticClass: "label-color" }, [
                          _vm._v("หนาแน่น"),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "btn-route-color",
                        attrs: { id: "G" },
                        on: {
                          click: function ($event) {
                            return _vm.handleColorRouteSelected("G")
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "route-color-btn-green" }),
                        _c("div", { staticClass: "label-color" }, [
                          _vm._v("คล่องตัว"),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "btn-route-color",
                        attrs: { id: "K" },
                        on: {
                          click: function ($event) {
                            return _vm.handleColorRouteSelected("K")
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "route-color-btn-black" }),
                        _c("div", { staticClass: "label-color" }, [
                          _vm._v("ปิดถนน"),
                        ]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "input-content" }, [
                    _c("div", { staticClass: "input-detail" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.colorRouteSelectType,
                            expression: "colorRouteSelectType",
                          },
                        ],
                        staticClass: "color-input-time",
                        attrs: {
                          id: "minuteSelected",
                          type: "radio",
                          value: "minuteSelected",
                        },
                        domProps: {
                          checked: _vm._q(
                            _vm.colorRouteSelectType,
                            "minuteSelected"
                          ),
                        },
                        on: {
                          change: function ($event) {
                            _vm.colorRouteSelectType = "minuteSelected"
                          },
                        },
                      }),
                      _c("label", { attrs: { for: "minuteSelected" } }, [
                        _vm._v(
                          _vm._s(_vm.$t("routingPanel.addColorLine.time"))
                        ),
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.colorTimeChoose,
                            expression: "colorTimeChoose",
                          },
                        ],
                        attrs: {
                          type: "number",
                          min: "0",
                          disabled:
                            _vm.colorRouteSelectType !== "minuteSelected",
                        },
                        domProps: { value: _vm.colorTimeChoose },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.colorTimeChoose = $event.target.value
                          },
                        },
                      }),
                      _c("label", [
                        _vm._v(
                          _vm._s(_vm.$t("routingPanel.addColorLine.minute"))
                        ),
                      ]),
                      _c("label", [
                        _vm._v(
                          _vm._s(_vm.$t("routingPanel.addColorLine.weight"))
                        ),
                      ]),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.colorWeightChoose,
                              expression: "colorWeightChoose",
                            },
                          ],
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.colorWeightChoose = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "1" } }, [
                            _vm._v(
                              _vm._s(_vm.$t("routingPanel.addColorLine.normal"))
                            ),
                          ]),
                          _c("option", { attrs: { value: "10" } }, [
                            _vm._v(
                              _vm._s(_vm.$t("routingPanel.addColorLine.high"))
                            ),
                          ]),
                          _c("option", { attrs: { value: "100" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("routingPanel.addColorLine.veryHigh")
                              )
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "input-content" }, [
                    _c("div", { staticClass: "input-detail input-datetime" }, [
                      _c("div", { staticStyle: { width: "100px" } }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.colorRouteSelectType,
                              expression: "colorRouteSelectType",
                            },
                          ],
                          staticClass: "color-input-time",
                          staticStyle: { "vertical-align": "bottom" },
                          attrs: {
                            id: "dateTimeSelected",
                            type: "radio",
                            value: "dateTimeSelected",
                          },
                          domProps: {
                            checked: _vm._q(
                              _vm.colorRouteSelectType,
                              "dateTimeSelected"
                            ),
                          },
                          on: {
                            change: function ($event) {
                              _vm.colorRouteSelectType = "dateTimeSelected"
                            },
                          },
                        }),
                        _c(
                          "label",
                          {
                            staticStyle: {
                              "vertical-align": "-webkit-baseline-middle",
                            },
                            attrs: { for: "dateTimeSelected" },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("routingPanel.addColorLine.until"))
                            ),
                          ]
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "vue-datetime-container" },
                        [
                          _c("VueDatetime", {
                            attrs: {
                              type: "datetime",
                              disabled:
                                _vm.colorRouteSelectType !== "dateTimeSelected",
                              "input-class": "datetime-input",
                              title: "",
                              "min-datetime": new Date().toISOString(),
                              phrases: {
                                ok: _vm.$t("routingPanel.okDepartDatetime"),
                                cancel: _vm.$t(
                                  "routingPanel.cancelDepartDatetime"
                                ),
                              },
                            },
                            model: {
                              value: _vm.colorRouteDateTime,
                              callback: function ($$v) {
                                _vm.colorRouteDateTime = $$v
                              },
                              expression: "colorRouteDateTime",
                            },
                          }),
                          _c(
                            "i",
                            {
                              staticClass: "ripple material-icons-round",
                              on: {
                                click: function ($event) {
                                  return _vm.clickDepartDatetimeIcon("start")
                                },
                              },
                            },
                            [_vm._v("schedule")]
                          ),
                          _vm.colorRouteDateTime !== ""
                            ? _c(
                                "button",
                                {
                                  staticClass: "reset ripple",
                                  on: {
                                    click: function ($event) {
                                      _vm.colorRouteDateTime = ""
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "i",
                                    {
                                      staticClass:
                                        "ripple material-icons-round",
                                      style:
                                        _vm.$i18n.locale === "en"
                                          ? "margin-left: 15px;"
                                          : "",
                                    },
                                    [_vm._v("close")]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "input-content",
                      style: _vm.responseFromColorRoute
                        ? { display: "block" }
                        : { display: "none" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "input-detail text-responseColorRoute" },
                        [
                          _c("label", [
                            _vm._v(_vm._s(_vm.responseFromColorRoute)),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "btn-submit-clear" }, [
                    _c(
                      "button",
                      {
                        staticClass: "clearForm",
                        on: { click: _vm.clearRouteColor },
                      },
                      [
                        _c("label", [
                          _vm._v(_vm._s(_vm.$t("reportEventForm.clear"))),
                        ]),
                      ]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "submitForm",
                        on: { click: _vm.sendRouteColor },
                      },
                      [
                        _c("label", [
                          _vm._v(_vm._s(_vm.$t("reportEventForm.save"))),
                        ]),
                      ]
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "transit-method-panel",
          class: {
            hide: _vm.isHidingTransitMethodPanel,
            "input-minimize-height": _vm.isMinimizeHeightInputPanel,
          },
        },
        [
          _vm.isRouting
            ? _c("div", { staticClass: "loading-more-container" }, [
                _c("img", {
                  attrs: { src: require("@/assets/img/loading.gif") },
                }),
              ])
            : _vm._e(),
          !_vm.isRouting
            ? _c("div", { staticClass: "transit-method-choose-hint" }, [
                _vm._v(_vm._s(_vm.$t("routingPanel.chooseTransitMethod"))),
              ])
            : _vm._e(),
          _vm.selectedVehicle === "transit"
            ? _c(
                "div",
                { staticClass: "transit-method-list" },
                [
                  _vm._l(_vm.guideList, function (method, index) {
                    return [
                      _c("TransitMethodCard", {
                        key: method.id,
                        attrs: { method: method },
                        on: {
                          click: function ($event) {
                            return _vm.clickTransitMethod(index)
                          },
                        },
                      }),
                    ]
                  }),
                ],
                2
              )
            : _vm._e(),
        ]
      ),
      _vm.selectedTransitMethodIndex !== null
        ? _c(
            "div",
            {
              staticClass: "mini-transit-guide-panel",
              class: {
                hide:
                  !_vm.isHidingTransitGuidePanel ||
                  (_vm.$route.hash !== "#routing" &&
                    _vm.$route.hash !== "#choose-location-for-routing"),
              },
              on: { click: _vm.clickMiniTransitGuidePanel },
            },
            [
              _c("TransitMethodCard", {
                attrs: {
                  method: _vm.guideList[_vm.selectedTransitMethodIndex],
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "transit-guide-panel",
          class: {
            hide: _vm.isHidingTransitGuidePanel,
            full: _vm.isFullHeightTransitGuidePanel,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "head",
              on: { click: _vm.clickHeadTransitGuidePanel },
            },
            [
              _c(
                "button",
                {
                  staticClass: "back ripple",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.clickBackFromTransitGuide.apply(
                        null,
                        arguments
                      )
                    },
                  },
                },
                [
                  _c("i", { staticClass: "material-icons-round" }, [
                    _vm._v("arrow_back"),
                  ]),
                ]
              ),
              _vm.selectedTransitMethodIndex !== null
                ? _c("div", { staticClass: "detail" }, [
                    _c("div", [
                      _vm.guideList[_vm.selectedTransitMethodIndex].fee > 0
                        ? _c("div", { staticClass: "fee" }, [
                            _vm._v(
                              _vm._s(
                                _vm.guideList[_vm.selectedTransitMethodIndex]
                                  .fee +
                                  " " +
                                  _vm.$t("routingPanel.baht")
                              )
                            ),
                          ])
                        : _vm._e(),
                      _vm.guideList[_vm.selectedTransitMethodIndex].fee > 0 &&
                      _vm.guideList[_vm.selectedTransitMethodIndex].hasTaxiFee
                        ? _c("div", { staticClass: "fee" }, [_vm._v(" + ")])
                        : _vm._e(),
                      _vm.guideList[_vm.selectedTransitMethodIndex].hasTaxiFee
                        ? _c("div", { staticClass: "fee" }, [
                            _vm._v(_vm._s(_vm.$t("routingPanel.taxiFee"))),
                          ])
                        : _vm._e(),
                    ]),
                    _c("div", [
                      _c("div", { staticClass: "distance" }, [
                        _vm._v(
                          _vm._s(
                            _vm.guideList[_vm.selectedTransitMethodIndex]
                              .displayDistance
                          ) +
                            " " +
                            _vm._s(
                              1000 >
                                _vm.guideList[_vm.selectedTransitMethodIndex]
                                  .distance
                                ? _vm.$t("routingPanel.meter")
                                : _vm.$t("routingPanel.kilometer")
                            ) +
                            " "
                        ),
                      ]),
                      _c("div", { staticClass: "time" }, [
                        _vm._v(
                          _vm._s(
                            (_vm.guideList[_vm.selectedTransitMethodIndex]
                              .hr !== 0
                              ? _vm.guideList[_vm.selectedTransitMethodIndex]
                                  .hr +
                                " " +
                                _vm.$t("routingPanel.hr") +
                                " "
                              : "") +
                              _vm.guideList[_vm.selectedTransitMethodIndex]
                                .min +
                              " " +
                              _vm.$t("routingPanel.min")
                          )
                        ),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ]
          ),
          _vm.selectedTransitMethodIndex !== null
            ? _c(
                "div",
                { staticClass: "transit-guide-list" },
                [
                  _c(
                    "div",
                    {
                      ref: "startPointRender",
                      staticClass: "point ripple",
                      on: { click: _vm.clickTransitStartPoint },
                    },
                    [
                      _c("i", { staticClass: "material-icons-round" }, [
                        _vm._v("radio_button_checked"),
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.pointInputList[0].isCurrentLocation
                              ? _vm.$t("routingPanel.currentLocation")
                              : _vm.pointInputList[0].keyword
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _vm._l(
                    _vm.guideList[_vm.selectedTransitMethodIndex]
                      .guideForRender,
                    function (guideForRender, i) {
                      return [
                        guideForRender instanceof Array
                          ? [
                              guideForRender.length > 0
                                ? [
                                    _c(
                                      "div",
                                      {
                                        key: "groupGuideStep-" + i,
                                        ref: "guideForRender" + i,
                                        refInFor: true,
                                        staticClass: "group-guide",
                                        class: {
                                          walk: !(
                                            "tripId" in guideForRender[0]
                                          ),
                                        },
                                      },
                                      [
                                        _c("div", {
                                          staticClass: "line",
                                          style:
                                            "tripId" in guideForRender[0]
                                              ? {
                                                  borderColor:
                                                    "#" +
                                                    guideForRender[0]
                                                      .routeColor,
                                                }
                                              : {},
                                        }),
                                        "tripId" in guideForRender[0]
                                          ? _c("div", {
                                              staticClass: "point start",
                                              style: {
                                                borderColor:
                                                  "#" +
                                                  guideForRender[0].routeColor,
                                              },
                                            })
                                          : _vm._e(),
                                        "tripId" in guideForRender[0]
                                          ? _c("div", {
                                              staticClass: "point end",
                                              style: {
                                                borderColor:
                                                  "#" +
                                                  guideForRender[0].routeColor,
                                              },
                                            })
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          { staticClass: "group-guide-step" },
                                          [
                                            _vm._l(
                                              guideForRender,
                                              function (
                                                guideStep,
                                                indexOfGuideStep
                                              ) {
                                                return [
                                                  "tripId" in
                                                    guideForRender[0] ||
                                                  (!(
                                                    "tripId" in
                                                    guideForRender[0]
                                                  ) &&
                                                    guideStep.distance > 0)
                                                    ? _c(
                                                        "div",
                                                        {
                                                          key: guideStep.id,
                                                          staticClass:
                                                            "guide-step",
                                                          class: {
                                                            ripple: !(
                                                              "tripId" in
                                                              guideForRender[0]
                                                            ),
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.clickTransitGuideStep(
                                                                i,
                                                                indexOfGuideStep
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "left content",
                                                            },
                                                            [
                                                              "tripId" in
                                                              guideForRender[0]
                                                                ? [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "trip",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "by",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "i",
                                                                              {
                                                                                staticClass:
                                                                                  "material-icons-round",
                                                                                style:
                                                                                  {
                                                                                    color:
                                                                                      "#" +
                                                                                      guideForRender[0]
                                                                                        .routeColor,
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm
                                                                                      .utility
                                                                                      .transitIconList[
                                                                                      guideStep
                                                                                        .by
                                                                                    ]
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "line",
                                                                            class:
                                                                              {
                                                                                "has-icon":
                                                                                  guideStep.by <
                                                                                  6,
                                                                              },
                                                                            style:
                                                                              {
                                                                                backgroundColor:
                                                                                  "#" +
                                                                                  guideForRender[0]
                                                                                    .routeColor,
                                                                                color:
                                                                                  guideForRender[0]
                                                                                    .textColor,
                                                                              },
                                                                          },
                                                                          [
                                                                            guideStep.by <
                                                                            6
                                                                              ? _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "icon",
                                                                                    style:
                                                                                      guideStep.by ===
                                                                                      4
                                                                                        ? {
                                                                                            backgroundColor:
                                                                                              "rgb(255, 255, 255)",
                                                                                          }
                                                                                        : {},
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "img",
                                                                                      {
                                                                                        style:
                                                                                          guideStep.by ===
                                                                                          2
                                                                                            ? {
                                                                                                transform:
                                                                                                  "translateY(-0.5px)",
                                                                                              }
                                                                                            : {},
                                                                                        attrs:
                                                                                          {
                                                                                            src: _vm.calTransitImgPath(
                                                                                              guideStep.by,
                                                                                              guideStep.text,
                                                                                              2
                                                                                            ),
                                                                                            srcset:
                                                                                              _vm.calTransitImgPath(
                                                                                                guideStep.by,
                                                                                                guideStep.text,
                                                                                                1
                                                                                              ) +
                                                                                              _vm.calTransitImgPath(
                                                                                                guideStep.by,
                                                                                                guideStep.text,
                                                                                                2
                                                                                              ),
                                                                                          },
                                                                                      }
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                            guideStep.by <
                                                                              3 ||
                                                                            guideStep.by ===
                                                                              10
                                                                              ? [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        _vm.$t(
                                                                                          "routingPanel." +
                                                                                            guideStep.text
                                                                                        )
                                                                                      ) +
                                                                                      " "
                                                                                  ),
                                                                                ]
                                                                              : [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        guideStep.text
                                                                                      ) +
                                                                                      " "
                                                                                  ),
                                                                                ],
                                                                          ],
                                                                          2
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "terminate",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "routingPanel.terminate"
                                                                                  )
                                                                                ) +
                                                                                " " +
                                                                                _vm._s(
                                                                                  guideStep.tripHeadsign
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                : [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          guideStep.turnText
                                                                        ) +
                                                                        " " +
                                                                        _vm._s(
                                                                          guideStep.text
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                    guideStep.distance >
                                                                    0
                                                                      ? [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.kmFormat(
                                                                                guideStep.distance
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      : _vm._e(),
                                                                  ],
                                                              "tripId" in
                                                              guideForRender[0]
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "count-stop",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            guideStep.count
                                                                          ) +
                                                                          " " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "routingPanel.stopUnit[" +
                                                                                guideStep.by +
                                                                                "]"
                                                                            )
                                                                          ) +
                                                                          " " +
                                                                          _vm._s(
                                                                            _vm.kmFormat(
                                                                              guideStep.distance
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                      guideStep.count >
                                                                      1
                                                                        ? _c(
                                                                            "i",
                                                                            {
                                                                              staticClass:
                                                                                "material-icons-round ripple",
                                                                              class:
                                                                                {
                                                                                  up: guideStep.isShowThruStopList,
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    $event.stopPropagation()
                                                                                    return _vm.toggleThruStopList(
                                                                                      guideStep
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "expand_more"
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "thru-stop-list",
                                                                  class: {
                                                                    hide: !guideStep.isShowThruStopList,
                                                                  },
                                                                },
                                                                [
                                                                  _vm._l(
                                                                    guideStep.thruStopList,
                                                                    function (
                                                                      thruStop
                                                                    ) {
                                                                      return [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            key:
                                                                              indexOfGuideStep +
                                                                              "-" +
                                                                              thruStop.stopId,
                                                                            staticClass:
                                                                              "thru-stop",
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  $event.stopPropagation()
                                                                                  return _vm.clickTransitGuideThruStop(
                                                                                    thruStop
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "thru-point",
                                                                              }
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "name ripple",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    thruStop.stopName
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    }
                                                                  ),
                                                                ],
                                                                2
                                                              ),
                                                            ],
                                                            2
                                                          ),
                                                          "tripId" in
                                                          guideForRender[0]
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "right content",
                                                                },
                                                                [
                                                                  guideStep.interval >
                                                                  0
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "time",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.timeFormat(
                                                                                guideStep.interval
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  guideStep.fee >
                                                                  0
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "fee",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              guideStep.fee +
                                                                                " " +
                                                                                _vm.$t(
                                                                                  "routingPanel.baht"
                                                                                )
                                                                            ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                      ]
                                    ),
                                  ]
                                : [
                                    _c(
                                      "div",
                                      {
                                        key: "groupGuideStep-" + i,
                                        ref: "guideForRender" + i,
                                        refInFor: true,
                                        staticClass: "group-guide walk no-data",
                                      },
                                      [_c("div", { staticClass: "line" })]
                                    ),
                                  ],
                            ]
                          : guideForRender instanceof Object
                          ? _c(
                              "div",
                              {
                                key: guideForRender.id,
                                ref: "guideForRender" + i,
                                refInFor: true,
                                staticClass: "guide-stop ripple",
                                on: {
                                  click: function ($event) {
                                    return _vm.clickTransitGuideStep(i, null)
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(guideForRender.text) + " ")]
                            )
                          : _vm._e(),
                      ]
                    }
                  ),
                  _c(
                    "div",
                    {
                      ref: "endPointRender",
                      staticClass: "point ripple",
                      on: { click: _vm.clickTransitEndPoint },
                    },
                    [
                      _c("i", { staticClass: "material-icons-round" }, [
                        _vm._v("radio_button_checked"),
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.pointInputList[_vm.pointInputList.length - 1]
                              .isCurrentLocation
                              ? _vm.$t("routingPanel.currentLocation")
                              : _vm.pointInputList[
                                  _vm.pointInputList.length - 1
                                ].keyword
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                2
              )
            : _vm._e(),
        ]
      ),
      _vm.$route.hash === "#navigation"
        ? _c("Navigation", {
            attrs: {
              meta: _vm.navigationData.meta,
              guide: _vm.navigationData.guide,
              path: _vm.navigationData.path,
              mainMap: _vm.map,
              baseMap: _vm.baseMap,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon" }, [
      _c("i", { staticClass: "material-icons-round" }, [
        _vm._v("radio_button_checked"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon" }, [
      _c("i", { staticClass: "material-icons-round" }, [
        _vm._v("radio_button_checked"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }