var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "settings",
      class: {
        hide:
          _vm.$route.hash !== "#settings" &&
          _vm.$route.hash !== "#language-setting" &&
          !(
            (_vm.$route.hash === "#show-velocity-setting" ||
              _vm.$route.hash === "#velocity-unit-setting") &&
            _vm.isOnMobileApp
          ),
      },
    },
    [
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.isShowToastRestartApplication
          ? _c("div", { staticClass: "restart-toast" }, [
              _c("div", [
                _vm._v(_vm._s(_vm.$t("settings.plsRestartApplication"))),
              ]),
            ])
          : _vm._e(),
      ]),
      _c("div", { staticClass: "title-bar" }, [
        _c(
          "button",
          {
            staticClass: "back ripple",
            on: {
              click: function ($event) {
                return _vm.clickBack()
              },
            },
          },
          [
            _c("i", { staticClass: "material-icons-round" }, [
              _vm._v(" arrow_back "),
            ]),
          ]
        ),
        _vm.$route.hash === "#settings"
          ? _c("div", { staticClass: "title" }, [
              _vm._v(" " + _vm._s(_vm.$t("settings.title")) + " "),
            ])
          : _vm._e(),
        _vm.$route.hash === "#language-setting"
          ? _c("div", { staticClass: "title" }, [
              _vm._v(" " + _vm._s(_vm.$t("settings.language")) + " "),
            ])
          : _vm._e(),
        _vm.$route.hash === "#show-velocity-setting" && _vm.isOnMobileApp
          ? _c("div", { staticClass: "title" }, [
              _vm._v(" " + _vm._s(_vm.$t("settings.displayVelocity")) + " "),
            ])
          : _vm._e(),
        _vm.$route.hash === "#velocity-unit-setting" && _vm.isOnMobileApp
          ? _c("div", { staticClass: "title" }, [
              _vm._v(" " + _vm._s(_vm.$t("settings.velocityUnit")) + " "),
            ])
          : _vm._e(),
      ]),
      _vm.$route.hash === "#settings"
        ? _c("div", { staticClass: "setting-list" }, [
            _c(
              "button",
              {
                staticClass: "setting-field ripple",
                on: {
                  click: function ($event) {
                    return _vm.openSelect("#language-setting")
                  },
                },
              },
              [
                _c("div", { staticClass: "description" }, [
                  _c("div", { staticClass: "name" }, [
                    _vm._v(" " + _vm._s(_vm.$t("settings.language")) + " "),
                  ]),
                  _c("div", { staticClass: "detail" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$i18n.locale === "th" ? "ไทย" : "English") +
                        " "
                    ),
                  ]),
                ]),
              ]
            ),
            _c(
              "button",
              {
                staticClass: "setting-field zoom-enable ripple",
                on: { click: _vm.toggleEnableZoomButton },
              },
              [
                _c("div", { staticClass: "description" }, [
                  _c("div", { staticClass: "name" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("settings.enableZoomButton")) + " "
                    ),
                  ]),
                  _c("div", { staticClass: "detail" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("settings.detailEnableZoomButton")) +
                        " "
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "toggle" }, [
                  _c("i", { staticClass: "material-icons-round" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.enableZoomButton
                            ? "check_box"
                            : "check_box_outline_blank"
                        ) +
                        " "
                    ),
                  ]),
                ]),
              ]
            ),
            !_vm.isTrafficProduct && !_vm.isLongdoMapV3
              ? _c(
                  "button",
                  {
                    staticClass: "setting-field ripple",
                    on: { click: _vm.toggleEnableMeasurementToolButton },
                  },
                  [
                    _c("div", { staticClass: "description" }, [
                      _c("div", { staticClass: "name" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("settings.enableMeasurementToolButton")
                            ) +
                            " "
                        ),
                      ]),
                      _c("div", { staticClass: "detail" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "settings.detailEnableMeasurementToolButton"
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "toggle" }, [
                      _c("i", { staticClass: "material-icons-round" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.enableMeasurementToolButton
                                ? "check_box"
                                : "check_box_outline_blank"
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                  ]
                )
              : _vm._e(),
            _c(
              "button",
              {
                staticClass: "setting-field ripple",
                on: { click: _vm.toggleShowFavorites },
              },
              [
                _c("div", { staticClass: "description" }, [
                  _c("div", { staticClass: "name" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("settings.showFavoritesButton")) + " "
                    ),
                  ]),
                  _c("div", { staticClass: "detail" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("settings.detailShowFavoritesButton")) +
                        " "
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "toggle" }, [
                  _c("i", { staticClass: "material-icons-round" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.showFavorites
                            ? "check_box"
                            : "check_box_outline_blank"
                        ) +
                        " "
                    ),
                  ]),
                ]),
              ]
            ),
            _c(
              "button",
              {
                staticClass: "setting-field ripple",
                on: { click: _vm.toggleShowCreated },
              },
              [
                _c("div", { staticClass: "description" }, [
                  _c("div", { staticClass: "name" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("settings.showCreatedButton")) + " "
                    ),
                  ]),
                  _c("div", { staticClass: "detail" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("settings.detailShowCreatedButton")) +
                        " "
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "toggle" }, [
                  _c("i", { staticClass: "material-icons-round" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.showCreated
                            ? "check_box"
                            : "check_box_outline_blank"
                        ) +
                        " "
                    ),
                  ]),
                ]),
              ]
            ),
            _c(
              "button",
              {
                staticClass: "setting-field ripple",
                on: { click: _vm.toggleShowMyPlacesName },
              },
              [
                _c("div", { staticClass: "description" }, [
                  _c("div", { staticClass: "name" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("settings.showMyPlacesNameButton")) +
                        " "
                    ),
                  ]),
                  _c("div", { staticClass: "detail" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("settings.detailShowMyPlacesNameButton")
                        ) +
                        " "
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "toggle" }, [
                  _c("i", { staticClass: "material-icons-round" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.showMyPlacesName
                            ? "check_box"
                            : "check_box_outline_blank"
                        ) +
                        " "
                    ),
                  ]),
                ]),
              ]
            ),
            _vm.isOnMobileApp
              ? _c(
                  "button",
                  {
                    staticClass: "setting-field ripple",
                    on: { click: _vm.toggleSpeedCamera },
                  },
                  [
                    _c("div", { staticClass: "description" }, [
                      _c("div", { staticClass: "name" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("settings.speedCameraWarning")) +
                            " "
                        ),
                      ]),
                      _c("div", { staticClass: "detail" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("settings.speedCameraWarningDetail")
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "toggle" }, [
                      _c("i", { staticClass: "material-icons-round" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.showSpeedCamera
                                ? "check_box"
                                : "check_box_outline_blank"
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm.isOnMobileApp
              ? _c(
                  "button",
                  {
                    staticClass: "setting-field ripple",
                    on: { click: _vm.toggleAccidentBlackSpot },
                  },
                  [
                    _c("div", { staticClass: "description" }, [
                      _c("div", { staticClass: "name" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("settings.highRiskWarning")) + " "
                        ),
                      ]),
                      _c("div", { staticClass: "detail" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("settings.highRiskWarningDetail")) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "toggle" }, [
                      _c("i", { staticClass: "material-icons-round" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.showAccidentBlackSpot
                                ? "check_box"
                                : "check_box_outline_blank"
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm.isOnMobileApp
              ? _c("div", { staticClass: "setting-field ripple" }, [
                  _c("div", { staticClass: "name" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("settings.showSpeedCamLevel")) +
                        " " +
                        _vm._s(_vm.showSpeedCameraLevel) +
                        " "
                    ),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.showSpeedCameraLevel,
                        expression: "showSpeedCameraLevel",
                      },
                    ],
                    attrs: { type: "range", min: "1", max: "22", step: "1" },
                    domProps: { value: _vm.showSpeedCameraLevel },
                    on: {
                      touchend: _vm.setSpeedCamShowLevel,
                      __r: function ($event) {
                        _vm.showSpeedCameraLevel = $event.target.value
                      },
                    },
                  }),
                ])
              : _vm._e(),
            _vm.isOnMobileApp
              ? _c(
                  "button",
                  {
                    staticClass: "setting-field ripple",
                    on: {
                      click: function ($event) {
                        return _vm.openSelect("#show-velocity-setting")
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "description" }, [
                      _c("div", { staticClass: "name" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("settings.displayVelocity")) + " "
                        ),
                      ]),
                      _c("div", { staticClass: "detail" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "settings.displayVelocityOptions." +
                                  _vm.showVelocity
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm.isOnMobileApp
              ? _c(
                  "button",
                  {
                    staticClass: "setting-field ripple",
                    on: {
                      click: function ($event) {
                        return _vm.openSelect("#velocity-unit-setting")
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "description" }, [
                      _c("div", { staticClass: "name" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("settings.velocityUnit")) + " "
                        ),
                      ]),
                      _c("div", { staticClass: "detail" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "settings.velocityUnitOptions." +
                                  _vm.velocityUnit
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                  ]
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm.$route.hash === "#language-setting"
        ? _c("div", { staticClass: "language-setting setting-list" }, [
            _c(
              "button",
              {
                staticClass: "setting-field ripple",
                class: { active: _vm.$i18n.locale === "th" },
                on: {
                  click: function ($event) {
                    return _vm.selectLanguage("th")
                  },
                },
              },
              [_vm._m(0), _vm._m(1)]
            ),
            _c(
              "button",
              {
                staticClass: "setting-field ripple",
                class: { active: _vm.$i18n.locale === "en" },
                on: {
                  click: function ($event) {
                    return _vm.selectLanguage("en")
                  },
                },
              },
              [_vm._m(2), _vm._m(3)]
            ),
          ])
        : _vm._e(),
      _vm.$route.hash === "#show-velocity-setting" && _vm.isOnMobileApp
        ? _c("div", { staticClass: "language-setting setting-list" }, [
            _c(
              "button",
              {
                staticClass: "setting-field ripple",
                class: { active: _vm.showVelocity === "always" },
                on: {
                  click: function ($event) {
                    return _vm.selectShowVelocity("always")
                  },
                },
              },
              [
                _c("div", { staticClass: "description" }, [
                  _c("div", { staticClass: "name" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("settings.displayVelocityOptions.always")
                        ) +
                        " "
                    ),
                  ]),
                ]),
                _vm._m(4),
              ]
            ),
            _c(
              "button",
              {
                staticClass: "setting-field ripple",
                class: { active: _vm.showVelocity === "layers" },
                on: {
                  click: function ($event) {
                    return _vm.selectShowVelocity("layers")
                  },
                },
              },
              [
                _c("div", { staticClass: "description" }, [
                  _c("div", { staticClass: "name" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("settings.displayVelocityOptions.layers")
                        ) +
                        " "
                    ),
                  ]),
                ]),
                _vm._m(5),
              ]
            ),
            _c(
              "button",
              {
                staticClass: "setting-field ripple",
                class: { active: _vm.showVelocity === "none" },
                on: {
                  click: function ($event) {
                    return _vm.selectShowVelocity("none")
                  },
                },
              },
              [
                _c("div", { staticClass: "description" }, [
                  _c("div", { staticClass: "name" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("settings.displayVelocityOptions.none")) +
                        " "
                    ),
                  ]),
                ]),
                _vm._m(6),
              ]
            ),
          ])
        : _vm._e(),
      _vm.$route.hash === "#velocity-unit-setting" && _vm.isOnMobileApp
        ? _c("div", { staticClass: "language-setting setting-list" }, [
            _c(
              "button",
              {
                staticClass: "setting-field ripple",
                class: { active: _vm.velocityUnit === "km" },
                on: {
                  click: function ($event) {
                    return _vm.selectVelocityUnit("km")
                  },
                },
              },
              [
                _c("div", { staticClass: "description" }, [
                  _c("div", { staticClass: "name" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("settings.velocityUnitOptions.km")) +
                        " "
                    ),
                  ]),
                ]),
                _vm._m(7),
              ]
            ),
            _c(
              "button",
              {
                staticClass: "setting-field ripple",
                class: { active: _vm.velocityUnit === "mile" },
                on: {
                  click: function ($event) {
                    return _vm.selectVelocityUnit("mile")
                  },
                },
              },
              [
                _c("div", { staticClass: "description" }, [
                  _c("div", { staticClass: "name" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("settings.velocityUnitOptions.mile")) +
                        " "
                    ),
                  ]),
                ]),
                _vm._m(8),
              ]
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "description" }, [
      _c("div", { staticClass: "name" }, [_vm._v("ไทย")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "check" }, [
      _c("i", { staticClass: "material-icons-round" }, [_vm._v(" check ")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "description" }, [
      _c("div", { staticClass: "name" }, [_vm._v("English")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "check" }, [
      _c("i", { staticClass: "material-icons-round" }, [_vm._v(" check ")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "check" }, [
      _c("i", { staticClass: "material-icons-round" }, [_vm._v(" check ")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "check" }, [
      _c("i", { staticClass: "material-icons-round" }, [_vm._v(" check ")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "check" }, [
      _c("i", { staticClass: "material-icons-round" }, [_vm._v(" check ")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "check" }, [
      _c("i", { staticClass: "material-icons-round" }, [_vm._v(" check ")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "check" }, [
      _c("i", { staticClass: "material-icons-round" }, [_vm._v(" check ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }