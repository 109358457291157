var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mobile-enable-permission", class: { hide: _vm.isHiding } },
    [
      _c("div", { staticClass: "background" }),
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "label" }, [
          _c("h1", [_vm._v(_vm._s(_vm.$t("mobilePermissionDialog.title")))]),
          _c("div", [
            _vm._v(_vm._s(_vm.$t("mobilePermissionDialog.description"))),
          ]),
        ]),
        _c("div", { staticClass: "button-list" }, [
          _c(
            "button",
            {
              staticClass: "open-setting",
              on: { click: _vm.onOpenPermissionSetting },
            },
            [_vm._v(_vm._s(_vm.$t("mobilePermissionDialog.openSetting")))]
          ),
          _c("button", { staticClass: "cancel", on: { click: _vm.onClose } }, [
            _vm._v(_vm._s(_vm.$t("mobilePermissionDialog.cancel"))),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }