var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        "choose-location-widget": !_vm.isLongdoMapV3,
        "choose-location-widget-map3": _vm.isLongdoMapV3,
      },
    },
    [
      _c("div"),
      _c("div", { staticClass: "head" }, [
        _c(
          "button",
          { staticClass: "back ripple", on: { click: _vm.onClickBack } },
          [
            _c("i", { staticClass: "material-icons-round" }, [
              _vm._v(" arrow_back "),
            ]),
          ]
        ),
        _c("input", {
          ref: "search-input",
          attrs: {
            type: "text",
            placeholder: _vm.$t("chooseLocationWidget.placeholder"),
          },
          domProps: { value: _vm.keywordInput },
          on: { input: _vm.changeSearchInput },
        }),
        _c(
          "button",
          { staticClass: "clear ripple", on: { click: _vm.onClickClear } },
          [
            _c("i", { staticClass: "material-icons-round" }, [
              _vm._v(" clear "),
            ]),
          ]
        ),
      ]),
      _c(
        "button",
        {
          staticClass: "ripple current-location",
          on: { click: _vm.chooseCurrentLocation },
        },
        [
          _c("i", { staticClass: "material-icons-round" }, [
            _vm._v(" my_location "),
          ]),
          _c("label", [
            _vm._v(
              " " +
                _vm._s(_vm.$t("chooseLocationWidget.chooseCurrentLocation")) +
                " "
            ),
          ]),
        ]
      ),
      _c(
        "button",
        {
          staticClass: "ripple show-map",
          on: {
            click: function ($event) {
              _vm.isChoosingOnMap = true
            },
          },
        },
        [
          _c("i", { staticClass: "material-icons-round" }, [_vm._v(" map ")]),
          _c("label", [
            _vm._v(
              " " + _vm._s(_vm.$t("chooseLocationWidget.chooseOnMap")) + " "
            ),
          ]),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isChoosingOnMap,
              expression: "isChoosingOnMap",
            },
          ],
          staticClass: "choose-on-map-container",
        },
        [
          _c("div", { staticClass: "head" }, [
            _c(
              "button",
              {
                on: {
                  click: function ($event) {
                    _vm.isChoosingOnMap = false
                  },
                },
              },
              [
                _c("i", { staticClass: "material-icons-round" }, [
                  _vm._v(" arrow_back "),
                ]),
              ]
            ),
            _c("label", [
              _vm._v(
                " " + _vm._s(_vm.$t("chooseLocationWidget.moveToChoose")) + " "
              ),
            ]),
            _c(
              "button",
              {
                staticClass: "confirm ripple",
                on: { click: _vm.confirmChooseLocationOnMap },
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t("chooseLocationWidget.confirm")) + " "
                ),
              ]
            ),
          ]),
          _c("img", {
            staticStyle: {
              height: "56px",
              left: "50%",
              position: "absolute",
              top: "50%",
              "z-index": "9999",
              transform: "translate(-50%,-100%)",
            },
            attrs: {
              src: "https://map.longdo.com/mmmap/images/ic_pin.svg",
              alt: "",
            },
          }),
          _c("longdo-map", {
            staticClass: "map-container",
            on: { load: _vm.loadedLongdoMap },
          }),
        ],
        1
      ),
      _c(
        "div",
        { ref: "search-result-list", staticClass: "search-result-list" },
        [
          _vm._l(_vm.searchResultList, function (searchResult, index) {
            return [
              _c(
                "div",
                {
                  key: "search-result-for-choose-location-" + index,
                  staticClass: "ripple search-result",
                  on: {
                    click: function ($event) {
                      return _vm.onClickSearchResult(searchResult)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "icon" }, [
                    _c("img", {
                      attrs: {
                        src: searchResult.markicon2x,
                        srcset:
                          searchResult.markicon +
                          " 1x, " +
                          searchResult.markicon2x +
                          " 2x",
                        loading: "lazy",
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "detail" }, [
                    _c("div", { staticClass: "name" }, [
                      _vm._v(_vm._s(searchResult.name)),
                    ]),
                    _c("div", { staticClass: "address" }, [
                      _vm._v(_vm._s(searchResult.address)),
                    ]),
                  ]),
                  _c("i", { staticClass: "material-icons-round" }, [
                    _vm._v(" input "),
                  ]),
                ]
              ),
            ]
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }