var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "alert-before-save" }, [
    _c("div", { staticClass: "dialog-card" }, [
      _c("div", { staticClass: "msg" }, [
        _c("i", { staticClass: "material-icons-round" }, [_vm._v("warning")]),
        _c("br"),
        _c("div", [_vm._v(_vm._s(_vm.$t("confirmDeleteEventDialog.msg")))]),
      ]),
      _c("div", { staticClass: "action" }, [
        _c(
          "button",
          { staticClass: "ripple", on: { click: _vm.cancelDelete } },
          [
            _vm._v(
              " " + _vm._s(_vm.$t("confirmDeleteEventDialog.cancel")) + " "
            ),
          ]
        ),
        _c(
          "button",
          { staticClass: "ripple outline", on: { click: _vm.confirmDelete } },
          [
            _vm._v(
              " " + _vm._s(_vm.$t("confirmDeleteEventDialog.accept")) + " "
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }