<template>
  <div>
    <div class="longdo-map-vector">
      <div id="longdo-map-vector"></div>
      <!-- <div class="classic-box" v-if="lowPerformance" style="">
        <p>ดูเหมือนว่าคุณจะไม่ได้รับประสบการณ์ที่ดี คุณสามารถกลับไปใช้เวอร์ชันก่อนหน้าได้</p>
        <a href="https://map.longdo.com/longdomap">
          <button class="btn-back-classic">ใช้เวอร์ชันเดิม</button>
        </a>
      </div> -->
    </div>
  </div>
</template>

<script>
import { Settings } from 'luxon'
import * as turf from '@turf/turf'

export default {
  name: 'LongdoMapVector',
  data () {
    return {
      map: null,
      measureMarker: [],
      intervalTime: 0,
      fps: 0,
      frame: 0,
      frameRateAvg: [],
      startTime: new Date(),
      lowPerformance: false
    }
  },
  async mounted () {
    const self = this
    if ('lang' in self.$route.query || 'locale' in self.$route.query) {
      self.$i18n.locale = (self.$route.query.lang || self.$route.query.locale) === 'th' ? 'th' : 'en'
      localStorage.lang = self.$i18n.locale
      Settings.defaultLocale = self.$i18n.locale
    } else {
      if (localStorage.lang) {
        self.$i18n.locale = localStorage.lang
        Settings.defaultLocale = localStorage.lang
      }
    }
    await self.initialScript()
    self.initialMap()
  },
  watch: {
    '$i18n.locale' () {
      const self = this
      self.$nextTick(() => {
        if (self.isLongdoMapV3 && self.map) {
          self.map.language(self.$i18n.locale)
        }
      })
    }
  },
  methods: {
    async initialScript () {
      const self = this
      if (!('longdo3' in window)) {
        if (!self.isOffline) {
          await self.utility.addHtmlTag({
            tag: 'script',
            src: `${process.env.VUE_APP_LONGDO_MAP_API3_LONGDO3}?key=${process.env.VUE_APP_LONGDO_MAP_KEY}`,
            type: 'text/javascript'
          })
        }
        // await self.utility.addHtmlTag({
        //   tag: 'script',
        //   src: 'https://unpkg.com/@turf/turf@6/turf.min.js',
        //   type: 'text/javascript'
        // })
      }
    },
    initialMap () {
      const self = this
      // add overture dataset
      window.longdo3.MapTheme.ui.contextNearPoiOptions.dataset = ' '
      if (!self.isOffline) {
        self.map = new window.longdo3.Map({
          placeholder: document.getElementById('longdo-map-vector'),
          lastView: false,
          zoom: self.product === 'traffic' ? 11 : 7,
          ...(self.product === 'traffic' ? { layer: [window.longdo3.Layers.TRAFFIC] } : {})
        })
      } else {
        self.map = new window.longdo3.Map({
          placeholder: document.getElementById('longdo-map-vector')
        })
      }

      self.map.Event.bind('ready', () => {
        self.map.Renderer.on('load', self.onRederedMap)
        self.map.Ui.LayerSelector.visible(false)
        self.map.Ui.Toolbar.visible(true)
        self.map.Ui.Fullscreen.visible(false)
        self.map.Ui.DPad.visible(true)
        if (self.product === 'traffic') {
          self.map.Layers.add(window.longdo3.Layers.TRAFFIC)
          self.map.Overlays.load(window.longdo3.Overlays.cameras)
          // move to new map
          // self.map.Layers.setBase(window.longdo3.Layers.LIGHT)
        }
        self.enableMeasurementTool()
      })
      self.map.Event.bind(window.longdo3.EventName.BeforeContextmenu, async (event) => {
        // Get extra address
        var goto = document.createElement('div')
        var gofrom = document.createElement('div')
        var moreAddress = document.createElement('div')
        goto.innerHTML = `<i class="material-icons" style="font-size:14px;">my_location</i> <span style="color: #069; vertical-align: text-bottom;">${self.$t('main.driectTo')}</span>`
        goto.classList = 'ldmap-link'
        goto.style.cursor = 'pointer'
        gofrom.innerHTML = `<i class="material-icons" style="font-size:14px;">place</i> <span style="color: #069; vertical-align: text-bottom;">${self.$t('main.directFrom')}</span>`
        gofrom.classList = 'ldmap-link'
        gofrom.style.cursor = 'pointer'

        self.getExtraAddress(event).then((data) => {
          function isEmpty (obj) {
            for (const prop in obj) {
              if (Object.hasOwn(obj, prop)) {
                return false
              }
            }

            return true
          }

          const _detail = data || {}
          var villageName = ''
          var villageNo = ''
          if (self.isUseMwaAndNso) {
            villageName = _detail.village_name ? `${self.$t('createPlace.houseName')}${_detail.village_name}` : ''
            villageNo = _detail.village_no ? ` ${self.$t('createPlace.moo')} ${_detail.village_no}` : ''
            const municipality = _detail.municipality ? `${self.$t('main.area')} ${_detail.municipality}` : ''
            const police = _detail.police ? `${self.$t('main.area')} ${_detail.police}` : ''
            moreAddress.className = 'ldmap-text'
            if (!isEmpty(_detail)) {
              moreAddress.style.paddingBottom = '5px'
              moreAddress.style.borderBottom = '1px solid #eee'
            }
            moreAddress.innerHTML = `
          <div>${villageNo} ${villageName} </div>
          <div>${municipality}</div>
          <div>${police}</div>
          `
          }
        })
        // More address NSO

        goto.onclick = function () {
          if (self.$route.hash !== '#routing') {
            self.$router.push({
              hash: '#routing'
            })
          }
          self.$root.$emit('goto', event.location)
        }
        gofrom.onclick = function () {
          if (self.$route.hash !== '#routing') {
            self.$router.push({
              hash: '#routing'
            })
          }
          self.$root.$emit('gofrom', event.location)
        }
        event.add(moreAddress)
        event.add(gofrom)
        event.add(goto)
      })

      // if (self.product === 'traffic' && self.$route.hash !== '#events') {
      //   self.$router.push({
      //     hash: '#events'
      //   })
      // }
      // self.map.Event.bind('wheel', () => {
      //   console.log(window.maplibregl.getPerformanceMetrics().percentDroppedFrames)
      // })
    },
    async getExtraAddress (event) {
      const self = this
      const params = event.location
      const { data } = await self.api.getExtraAddressGeoService(params)
      return data
    },
    enableMeasurementTool () {
      const self = this
      self.map.Renderer.on('draw.delete', (e) => {
        const features = e.features
        if (features.length > 0) {
          features.map((feature) => {
            self.map.Overlays.remove(self.measureMarker[feature.id])
          })
        }
      })
      self.map.Renderer.on('draw.create', (e) => {
        self.onCreateCalulator('CREATE', e)
      })
      self.map.Renderer.on('draw.update', (e) => {
        self.onCreateCalulator('UPDATE', e)
      })
    },
    onCreateCalulator (type, e) {
      const self = this
      const features = e.features
      // const turf = window.turf

      function measurementLabel (type, unit) {
        switch (type) {
          case 'line':
            return {
              html: `<b style="background-color:#ffc; color:black; padding: 1px 6px; opacity:0.8; border-radius: 6px;">${window.longdo3.Util.formatDistance(Number.parseFloat(unit), self.$i18n.locale)}</b>`,
              offset: { x: 0, y: 0 }
            }
          case 'polygon':
            return {
              html: `
              <p style="background-color:#ffc; color:black; padding: 1px 6px; opacity:0.8; border-radius: 6px;">
               <b>
                ${window.longdo3.Util.formatThaiArea(Number.parseFloat(unit), self.$i18n.locale)}
               </b>
              </p>
              `,
              offset: { x: 0, y: 0 }
            }
          default:
            return {
              html: '<b style="background-color:#ffc; color:black; padding: 1px 6px; opacity:0.8; border-radius: 6px;">INVALID</b>',
              offset: { x: 0, y: 0 }
            }
        }
      }

      if (type === 'UPDATE') {
        if (features.length > 0) {
          features.map((feature) => {
            self.map.Overlays.remove(self.measureMarker[feature.id])
          })
        }
      }
      if (features.length > 0) {
        features.map((feature) => {
          const { type, coordinates } = feature.geometry
          if (type === 'Polygon') {
            const calulatorArea = turf.area(feature)
            const { geometry } = turf.center(feature)
            const { coordinates } = geometry
            const centerLat = coordinates[1]
            const centerLon = coordinates[0]
            self.measureMarker[feature.id] = new window.longdo.Marker({
              lat: centerLat,
              lon: centerLon
            }, {
              icon: measurementLabel('polygon', calulatorArea)
            })
            self.map.Overlays.add(self.measureMarker[feature.id])
          } else if (type === 'LineString') {
            const calulatorDistance = parseFloat(((turf.lineDistance(feature, 'kilometers') * 1000)).toFixed(2))
            if (coordinates.length > 2) {
              var middle = coordinates[Math.round((coordinates.length - 1) / 2)]
              const centerLat = middle[1]
              const centerLon = middle[0]
              self.measureMarker[feature.id] = new window.longdo.Marker({
                lat: centerLat,
                lon: centerLon
              }, {
                icon: measurementLabel('line', calulatorDistance)
              })
              self.map.Overlays.add(self.measureMarker[feature.id])
            } else {
              const pointFeature = turf.midpoint(coordinates[0], coordinates[1])
              const centerLine = pointFeature.geometry.coordinates
              const centerLat = centerLine[1]
              const centerLon = centerLine[0]

              self.measureMarker[feature.id] = new window.longdo.Marker({
                lat: centerLat,
                lon: centerLon
              }, {
                icon: measurementLabel('line', calulatorDistance)
              })
              self.map.Overlays.add(self.measureMarker[feature.id])
            }
          } else {
            // Do something
          }
        })
      }
    },
    onRederedMap () {
      const self = this
      window.longdo = self.utility.mergeDeep(window.longdo, window.longdo3)
      self.$emit('load', self.map, true)
      if (self.product !== 'traffic') {
        setTimeout(() => {
          self.map.Tags.add('%', {
            label: true,
            dataset: ' '
          })
        }, 1500)
      }
      if (self.isOnMobileApp) {
        setTimeout(() => {
          if (localStorage.showSpeedCamera === 'true') {
            const zoom = localStorage.showSpeedCameraLevel ? parseInt(localStorage.showSpeedCameraLevel) : 9
            self.map.Tags.add('speed_camera', {
              label: true,
              visibleRange: {
                min: zoom
              }
            })
          }
          if (localStorage.showAccidentBlackSpot === 'true') {
            const zoom = localStorage.showSpeedCameraLevel ? parseInt(localStorage.showSpeedCameraLevel) : 9
            self.map.Tags.add('accident_black_spot', {
              label: true,
              visibleRange: {
                min: zoom
              }
            })
          }
        }, 1500)
      }
      // self.frameRateDetection(0)
    },
    frameRateDetection (now) {
      const self = this
      if (self.intervalTime >= 2 * 10000) {
        const _frameRateAvg = self.frameRateAvg.reduce((a, b) => a + b, 0) / self.frameRateAvg.length
        if (_frameRateAvg < 20) {
          self.lowPerformance = true
          setTimeout(() => {
            self.lowPerformance = false
          }, 20 * 1000)
        }
      } else {
        var time = Date.now()
        self.frame++
        if (time - self.startTime > 1000) {
          self.fps = (self.frame / ((time - self.startTime) / 1000)).toFixed(1)
          self.frameRateAvg.push(Number.parseFloat(self.fps))
          self.startTime = time
          self.frame = 0
        }
        self.intervalTime = now
        requestAnimationFrame(self.frameRateDetection)
      }
      // try {
      //   var time = Date.now()
      //   self.frame++
      //   if (time - self.startTime > 1000) {
      //     self.fps = (self.frame / ((time - self.startTime) / 1000)).toFixed(1)
      //     self.startTime = time
      //     self.frame = 0
      //   }
      //   requestAnimationFrame(self.frameRateDetection)
      // } catch (error) {
      //   console.warn('Browser not support.')
      // }
    }
  }
}
</script>

<style lang="scss" scoped>
.classic-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  padding: 6px;
  bottom: 34px;
  left: 50%;
  transform: translate(-50%, 0%);
  border-radius: 10px;
  box-shadow: 0px 0px 4px 2px rgba(134, 134, 134, 0.2);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  width: 640px;
  max-width: 96vw !important;
  max-height: 100vh !important;
  max-height: -webkit-fill-available !important;
  background-color: rgba(74, 74, 74, 0.9);

  p {
    color: white;
  }

  .btn-back-classic {
    border: none;
    background: linear-gradient(-180deg, rgb(255, 225, 52) 0%, rgb(217, 185, 0) 100%) rgb(217, 185, 0);
    border-radius: 6px;
    color: #333;
    height: 32px;
    padding: 0px 34px;
    box-sizing: border-box;
    font-size: 13px;
    cursor: pointer;
    display: block;
    font-family: unset;
    font-weight: bold;
    margin-right: 5px;
  }
}

.longdo-map-vector {
  width: 100%;
  height: 100%;
  background-color: #eeebe5;
  position: fixed;
  left: 0px;
  z-index: 75;

  #longdo-map-vector {
    width: 100%;
    height: 100%
  }

  &::v-deep .maplibregl-ctrl-top-left.mapboxgl-ctrl-top-left,
  &::v-deep .maplibregl-ctrl-top-left {
    top: unset;
    right: 11px;
    left: unset;

    .maplibregl-ctrl.ldmap-dpad {
      display: none;
    }
  }
}
</style>

<style lang="scss">
.maplibregl-ctrl-top-left.mapboxgl-ctrl-top-left,
.maplibregl-ctrl-top-left {
  bottom: 64px !important;
}

@media only screen and (min-width: $mobileMaxSize) {

  div.maplibregl-ctrl-top-left.mapboxgl-ctrl-top-left>div:nth-child(5),
  div.maplibregl-ctrl-top-left>div:nth-child(5) {
    display: block;
    position: absolute;
    bottom: 0px;
    right: 50px;

    button {
      border-top: 0px;
    }
  }
}

@media only screen and (max-height: 660px) {

  .maplibregl-ctrl-top-left.mapboxgl-ctrl-top-left,
  .maplibregl-ctrl-top-left {
    display: none;
    position: none;
  }
}

@media only screen and (max-width: $mobileMaxSize) {

  .maplibregl-ctrl-top-left.mapboxgl-ctrl-top-left,
  .maplibregl-ctrl-top-left {
    display: none;
    top: 150px;
  }
}
</style>
