<template>
  <div :class="{
    'search-result-panel': !isLongdoMapV3,
    'search-result-panel-map3': isLongdoMapV3,
    'hide': isHidingSearchResultPanel || $route.hash === '#routing',
    'expand-less': !isExpand
  }">
    <div class="search-result-container">
      <div class="top-bar">
        <button class="expand less ripple" v-if="isExpand" @click="clickExpandLess">
          <i class="material-icons-round">expand_more</i>
        </button>
        <button class="expand more ripple" v-if="!isExpand" @click="clickExpandMore">
          <i class="material-icons-round">expand_less</i>
        </button>
      </div>
      <div class="loading-container" v-if="showLoading">
        <img src="@/assets/img/loading.gif">
      </div>
      <div class="empty-container" v-if="!showLoading && searchResultList !== null && searchResultList.length === 0">
        {{ $t('searchResultPanel.empty') }}
      </div>
      <div class="search-result-list">
        <template>
          <div v-for="(searchResult, index) in searchResultList" :key="searchResult.id || index" class="search-result ripple" @click="clickSearchResult(searchResult, index)">
            <div class="detail-list">
              <div class="icon">
              <img :src="searchMarkIconImage(searchResult.markicon2x)"
                :srcset="searchMarkIconImage(searchResult.markicon) + ' 1x, ' + searchMarkIconImage(searchResult.markicon2x) + ' 2x'" loading="lazy">
              </div>
              <div class="detail">
                <label class="name" :title="$i18n.locale === 'th' ? searchResult.name : searchResult.name_en">
                  {{ $i18n.locale === 'th' ? searchResult.name : searchResult.name_en }}
                </label>
                <label class="short-description"
                  :title="$i18n.locale === 'th' ? searchResult.shortdesc : searchResult.shortdesc_en">
                  {{ $i18n.locale === 'th' ? searchResult.shortdesc : searchResult.shortdesc_en }}
                </label>
              </div>
            </div>
            <!-- <a class="info"
            :href="'https://map.longdo.com/p/' + searchResult.id + '/info/'"
            target="_blank"
            v-if="searchResult.objecttype !== 'tag'"
          >
            <i class="material-icons-round">info</i>
          </a> -->
            <div class="info-list">
              <div class="info" v-if="(searchResult.source || '') === 'google'">
                <div class="source-map" title="Google Maps" :style="{ backgroundImage: `url(${baseUrl}img/google-map-logo-icon.svg)` }"></div>
              </div>
              <div class="info" v-if="(searchResult.id || '').indexOf('OSM') === 0">
                <div class="source-map" title="OpenStreetMap" :style="{ backgroundImage: `url(${baseUrl}img/osm-logo-icon.svg)` }"></div>
              </div>
              <div class="info" v-if="(searchResult.id || '').indexOf('OVM') === 0">
                <div class="source-map" title="Overture Maps" :style="{ backgroundImage: `url(${baseUrl}img/ovm-logo-icon.png)` }"></div>
              </div>
              <a class="info" @click="clickSearchResultInfo($event, searchResult, index)"
                v-if="searchResult.objecttype !== 'tag' && (searchResult.source || '') !== 'google' && searchResult.objecttype !== 'geocoding'">
                <i class="material-icons-round">info</i>
              </a>
            </div>
          </div>
        </template>
        <div class="loading-more-container" v-if="showLoadingMore">
          <img src="@/assets/img/loading.gif">
        </div>
        <button v-if="hasMore && !showLoadingMore" :title="$t('searchResultPanel.moreTitle')" @click="clickHasMore"
          class="ripple">
          {{ $t('searchResultPanel.more') }}
        </button>
        <a @click="clickCreatePlace" class="add-place"
          v-if="product === 'map' && !showLoading && !showLoadingMore && !(!showLoading && searchResultList !== null && searchResultList.length === 0) && !isDisableLoginFeature">
          {{ $t('searchResultPanel.addNewPlace') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchResultPanel',
  props: {
    searchResultList: {
      type: Array,
      default: () => {
        return null
      }
    },
    userData: {
      type: Object,
      default: null
    },
    showLoading: {
      type: Boolean,
      default: false
    },
    showLoadingMore: {
      type: Boolean,
      default: false
    },
    hasMore: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isDisableLoginFeature () {
      const self = this
      if (self.lji === null) {
        return false
      }
      return self.lji.Util.isIosNativeApp()
    }
  },
  data () {
    return {
      isHidingSearchResultPanel: true,
      isExpand: true
    }
  },
  mounted () {
    const self = this
    self.$parent.$on('showSearchResultPanel', self.onShowSearchResult)
    self.$parent.$on('searchLocation', self.onSearchLocation)
    self.$parent.$on('clickMap', self.onClickMap)
    self.$parent.$on('clickMarker', self.onClickMarker)
    self.$parent.$on('clickPolygon', self.onClickPolygon)
    self.$parent.$on('clearSearch', self.onClearSearch)
    self.$root.$emit('changeHidingSearchResultPanel', self.isHidingSearchResultPanel)
    self.$root.$emit('changeExpandSearchResultPanel', self.isExpand)
  },
  methods: {
    clickCreatePlace () {
      const self = this
      if (self.isLongdoMapV3 && self.product !== 'traffic') {
        window.location = `${window.location.origin}${process.env.VUE_APP_BASE_URL}create?map=v2&lat=${localStorage.lat}&lon=${localStorage.lon}`
      } else {
        self.$router.push({
          name: 'CreatePlace',
          params: {},
          query: {
            lat: Number(localStorage.lat),
            lon: Number(localStorage.lon)
          },
          hash: ''
        })
      }
    },
    onClickMap (isShowingMapOnly) {
      const self = this
      if (!isShowingMapOnly && self.$route.name === 'Main' && 'search' in self.$route.query) {
        const keyword = self.$route.query.search
        const latLon = self.utility.validateLatLonFromString(keyword)
        const utm = self.utility.validateUtmFromString(keyword)
        if (latLon || utm) {
          self.isHidingSearchResultPanel = true
        } else {
          self.isHidingSearchResultPanel = false
        }
      } else {
        self.isHidingSearchResultPanel = true
      }
    },
    onShowSearchResult () {
      const self = this
      self.isHidingSearchResultPanel = false
    },
    onClickMarker () {
      const self = this
      self.isHidingSearchResultPanel = true
    },
    onClickPolygon () {
      const self = this
      self.isHidingSearchResultPanel = true
    },
    clickExpandLess () {
      const self = this
      self.isExpand = false
    },
    clickExpandMore () {
      const self = this
      self.isExpand = true
    },
    onClearSearch () {
      const self = this
      self.isHidingSearchResultPanel = true
    },
    clickSearchResult (searchResult, index) {
      const self = this
      if (searchResult.objecttype === 'tag') {
        self.$root.$emit('changeSearchTag', searchResult)
        return
      }
      if (searchResult.objecttype === 'layer') {
        self.isHidingSearchResultPanel = true
      }
      self.$emit('clickSearchResult', searchResult, false)
      window.gtag('event', 'click_search_result', {
        event_category: 'search',
        event_label: 'click_search_result',
        index: index,
        id: searchResult.id,
        name: searchResult.name,
        name_en: searchResult.name_en,
        objecttype: searchResult.objecttype,
        timestamp: (new Date()).toISOString()
      })
    },
    clickSearchResultInfo (event, searchResult, index) {
      const self = this
      // stop clicking event of parent
      event.stopPropagation()
      // self.collectSearchStats(searchResult)
      self.$emit('clickSearchResult', searchResult, true)
      if (searchResult.objecttype === 'layer') {
        self.isHidingSearchResultPanel = true
      }
      window.gtag('event', 'click_search_result_info', {
        event_category: 'search',
        event_label: 'click_search_result_info',
        index: index,
        id: searchResult.id,
        name: searchResult.name,
        name_en: searchResult.name_en,
        objecttype: searchResult.objecttype,
        timestamp: (new Date()).toISOString()
      })
    },
    async collectSearchStats (searchResult) {
      // for collecting search data
      const params = new URLSearchParams(window.location.search)
      const OOIId = searchResult.id
      const lat = params.get('lat')
      const lon = params.get('lon')
      const search = params.get('search')
      console.log(OOIId)
      console.log(lat)
      console.log(lon)
      console.log(search)
      const self = this
      let userSession = await self.utility.getUserSession()
      userSession = userSession || self.userData
      if (userSession) {
        console.log(userSession)
        console.log(userSession.uid)
        console.log(userSession.longdousertoken)
      } else {
        console.log('not login')
      }
    },
    onSearchLocation () {
      const self = this
      self.isHidingSearchResultPanel = true
    },
    clickHasMore () {
      const self = this
      self.$emit('clickHasMore')
      window.gtag('event', 'click_has_more', {
        event_category: 'search',
        event_label: 'click_has_more',
        timestamp: (new Date()).toISOString()
      })
    },
    searchMarkIconImage (markIcon) {
      if (markIcon.indexOf('.png') !== -1) {
        return markIcon
      } else {
        return markIcon + 'reddot.png'
      }
    }
  },
  watch: {
    searchResultList () {
      const self = this
      self.$nextTick(() => {
        if (self.searchResultList !== null) {
          self.isHidingSearchResultPanel = self.searchResultList.length === 1 && self.searchResultList.filter(r => r.source === 'google').length === 0
        }
      })
    },
    $route () {
      const self = this
      self.$nextTick(() => {
        if (self.$route.name === 'Place') {
          self.isHidingSearchResultPanel = true
        }
      })
    },
    isHidingSearchResultPanel () {
      const self = this
      self.$nextTick(() => {
        self.$root.$emit('changeHidingSearchResultPanel', self.isHidingSearchResultPanel)
      })
    },
    isExpand () {
      const self = this
      self.$nextTick(() => {
        self.$root.$emit('changeExpandSearchResultPanel', self.isExpand)
      })
    }
  }
}
</script>

<style scoped lang="scss">
$searchResultPanelHeight: 40%;
$topBarHeight: 32px;

.search-result-panel {
  width: 100%;
  height: $searchResultPanelHeight;
  border-radius: 12px 12px 0px 0px;
  background-color: rgb(255, 255, 255);
  padding: 3px 3px calc(#{$toolBarHeight} + 3px) 0px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
  z-index: 90;
  position: fixed;
  bottom: 0%;
  left: 0px;
  transition: bottom 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
  overflow: hidden;

  >.search-result-container {
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    padding: 0px 0px 0px 0px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    >.top-bar {
      width: 100%;
      height: $topBarHeight;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0px 3px 0px 3px;
      box-sizing: border-box;

      >button.expand {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px;
        border: none;
        background-color: transparent;
        outline: none;
        height: 100%;
        padding: 0px 12px;

        >i {
          color: rgb(168, 168, 168);
          border: 1px solid rgb(168, 168, 168);
          border-radius: 50%;
          font-size: 18px;
          margin: 0px 0px 0px 0px;
        }
      }
    }

    >.loading-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      box-sizing: border-box;
      height: calc(100% - #{$topBarHeight} - 54px);
      width: 100%;
      position: absolute;
      top: $topBarHeight;
      left: 0px;
    }

    >.empty-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      box-sizing: border-box;
      height: calc(100% - #{$topBarHeight} - 54px);
      width: 100%;
      position: absolute;
      top: $topBarHeight;
      left: 0px;
    }

    >.search-result-list {
      display: block;
      overflow: hidden;
      overflow-y: auto;
      box-sizing: border-box;
      height: calc(100% - #{$topBarHeight});

      >.search-result {
        $iconResultWidth: 48px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        padding: 9px 0px;
        cursor: pointer;

        &:first-child {
          padding-top: 0px;
        }
        >.detail-list {
          display: flex;
          width: 100%;
          >.icon {
            width: $iconResultWidth;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            align-self: flex-start;
            padding-top: 3px;

            >img {
              max-width: 28px;
              min-width: 16px;
            }
          }

        >.detail {
          display: flex;
          flex-direction: column;
          width: calc(100% - #{$iconResultWidth} - 36px);

          >.name {
            width: 100%;
            cursor: pointer;
            font-size: 16px;
            font-family: Prompt;
          }

          >.short-description {
            width: 100%;
            font-size: 14px;
            color: $grey;
            text-overflow: ellipsis;
            max-height: 21px;
            overflow: hidden;
            cursor: pointer;
          }
        }
      }

      .info-list {
        display: flex;
        flex-shrink: 0;
        cursor: pointer;
        >.info {
            width: 36px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;

            >i {
              color: $lightPrimary;
              font-size: 24px;
            }

            >.source-map {
              width: 24px;
              height: 24px;
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center;
            }
          }
      }

      }

      >.loading-more-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 6px;
      }

      a.add-place {
        color: $primary;
        text-decoration: none;
        font-family: Prompt;
        text-align: center;
        margin: 12px auto;
        display: block;
        font-size: 14px;
      }

      >button {
        width: calc(100% - 12px);
        border: none;
        border-radius: 3px;
        font-size: 14px;
        padding: 12px 6px;
        margin: 6px;
        cursor: pointer;
        outline: none;
        color: rgb(255, 255, 255);
        background-color: $primary;
        transition: background-color 0.3s ease 0s;
      }

      >button:hover {
        background-color: $darkPrimary;
      }
    }
  }
}

.search-result-panel-map3 {

  @media only screen and (max-width: $mobileMaxSize) {
    width: 100%;
    height: $searchResultPanelHeight;
    border-radius: 12px 12px 0px 0px;
    background-color: rgb(255, 255, 255);
    padding: 3px 3px calc(#{$toolBarHeight} + 3px) 0px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
    z-index: 90;
    position: fixed;
    bottom: 0%;
    left: 0px;
    transition: bottom 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
    overflow: hidden;
  }

  @media only screen and (min-width: $mobileMaxSize) {
    top: 110px;
    width: 460px;
    height: 60%;
    border-radius: 12px;
    background-color: rgb(255, 255, 255);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
    z-index: 90;
    position: fixed;
    left: 10px;
    transition: left 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
    overflow: hidden;
  }

  >.search-result-container {
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    padding: 0px 0px 0px 0px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    >.top-bar {
      width: 100%;
      height: $topBarHeight;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0px 3px 0px 3px;
      box-sizing: border-box;

      >button.expand {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px;
        border: none;
        background-color: transparent;
        outline: none;
        height: 100%;
        padding: 0px 12px;

        >i {
          color: rgb(168, 168, 168);
          border: 1px solid rgb(168, 168, 168);
          border-radius: 50%;
          font-size: 18px;
          margin: 0px 0px 0px 0px;
        }
      }
    }

    >.loading-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      box-sizing: border-box;
      height: calc(100% - #{$topBarHeight} - 54px);
      width: 100%;
      position: absolute;
      top: $topBarHeight;
      left: 0px;
    }

    >.empty-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      box-sizing: border-box;
      height: calc(100% - #{$topBarHeight} - 54px);
      width: 100%;
      position: absolute;
      top: $topBarHeight;
      left: 0px;
    }

    >.search-result-list {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      overflow-y: auto;
      box-sizing: border-box;
      height: calc(100% - #{$topBarHeight});

      >.search-result {
        $iconResultWidth: 48px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 9px 0px;
        cursor: pointer;
        flex-shrink: 0;

        &:first-child {
          padding-top: 0px;
        }

        >.detail-list {
          display: flex;
          width: 100%;
          >.icon {
            width: $iconResultWidth;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            align-self: flex-start;
            padding-top: 3px;
            flex-shrink: 0;

            >img {
              max-width: 16px;
              min-width: 16px;
            }
          }

          >.detail {
            display: flex;
            flex-direction: column;
            flex-shrink: 1;

            >.name {
              width: 100%;
              cursor: pointer;
              font-size: 16px;
              font-family: Prompt;
            }

            >.short-description {
              width: 100%;
              font-size: 14px;
              color: $grey;
              text-overflow: ellipsis;
              //allow to show 1 line
              max-height: 21px;
              overflow: hidden;
              cursor: pointer;
            }
          }
        }

        >.info-list {
          display: flex;
          flex-shrink: 0;
          cursor: pointer;
          >.info {
            width: 36px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            flex-shrink: 0;

            >i {
              color: $lightPrimary;
              font-size: 24px;
            }

            >.source-map {
              width: 24px;
              height: 24px;
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center;
            }
          }
        }

      }

      >.loading-more-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 6px;
      }

      @media only screen and (min-width: $mobileMaxSize) {
        a.add-place {
          color: $primary;
          text-decoration: none;
          font-family: Prompt;
          text-align: center;
          margin: 12px auto;
          display: block;
          font-size: 14px;
        }
      }

      a.add-place {
        color: $primary;
        text-decoration: none;
        font-family: Prompt;
        text-align: center;
        margin: 12px auto;
        display: block;
        font-size: 14px;
      }

      >button {
        width: calc(100% - 12px);
        border: none;
        border-radius: 3px;
        font-size: 14px;
        padding: 12px 6px;
        margin: 6px;
        cursor: pointer;
        outline: none;
        color: rgb(255, 255, 255);
        background-color: $primary;
        transition: background-color 0.3s ease 0s;
        flex-shrink: 0;
      }

      >button:hover {
        background-color: $darkPrimary;
      }
    }
  }
}

.search-result-panel.expand-less {
  bottom: calc(#{$topBarHeight} + #{$toolBarHeight} + 2px - #{$searchResultPanelHeight});
}

.search-result-panel.hide {
  bottom: -#{$searchResultPanelHeight};
  box-shadow: none;
}

.search-result-panel.hide.expand-less {
  bottom: calc(0px - #{$searchResultPanelHeight} - 12px);
}

@media only screen and (min-width: $mobileMaxSize) {
  .search-result-panel-map.hide.expand-less {
    height: $searchResultPanelHeight;
  }

  .search-result-panel-map3.expand-less {
    height: 85%;
  }

  .search-result-panel-map3.hide {
    left: -100%;
    box-shadow: none;
  }
}

@media only screen and (max-width: $mobileMaxSize) {

  .search-result-panel-map.hide.expand-less {
    bottom: calc(0px - #{$searchResultPanelHeight} - 12px);
  }

  .search-result-panel-map3.expand-less {
    bottom: calc(#{$topBarHeight} + #{$toolBarHeight} + 2px - #{$searchResultPanelHeight});
  }

  .search-result-panel-map3.hide {
    bottom: -#{$searchResultPanelHeight};
    box-shadow: none;
  }
}
</style>
