var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        "control-map-panel-v2": !_vm.isLongdoMapV3,
        "control-map-panel": _vm.isLongdoMapV3,
        hide: _vm.isHidingControlMapPanel || _vm.$route.hash === "#cam",
        "showing-popup": _vm.isShowingPopup,
        "place-card-step-full": _vm.placeCardStep === "FULL",
        "place-card-step-mid": _vm.placeCardStep === "MID",
        "place-card-step-mini": _vm.placeCardStep === "MINI",
        "showing-explore-panel":
          !_vm.isHidingExplorePanel && _vm.isExpandingExplorePanel,
        "collapsing-explore-panel":
          !_vm.isHidingExplorePanel && !_vm.isExpandingExplorePanel,
        "showing-search-result-panel":
          !_vm.isHidingSearchResultPanel && _vm.isExpandingSearchResultPanel,
        "collapsing-search-result-panel":
          !_vm.isHidingSearchResultPanel && !_vm.isExpandingSearchResultPanel,
        "showing-my-place-panel":
          !_vm.isHidingMyPlacePanel && _vm.isExpandingMyPlacePanel,
        "collapsing-my-place-panel":
          !_vm.isHidingMyPlacePanel && !_vm.isExpandingMyPlacePanel,
        "showing-explore-nearby-panel":
          !_vm.isHidingExploreNearbyPanel && _vm.isExpandingExploreNearbyPanel,
        "collapsing-explore-nearby-panel":
          !_vm.isHidingExploreNearbyPanel && !_vm.isExpandingExploreNearbyPanel,
        "showing-event-panel":
          !_vm.isHidingEventPanel && _vm.isExpandingEventPanel,
        "collapsing-event-panel":
          !_vm.isHidingEventPanel && !_vm.isExpandingEventPanel,
        routing: _vm.$route.hash === "#routing",
      },
    },
    [
      _c(
        "button",
        {
          staticClass: "ripple",
          class: { tracking: _vm.isTrackingDirection },
          on: {
            touchend: function ($event) {
              return _vm.onClickNorthUp()
            },
            touchstart: _vm.startDragCompass,
            touchmove: _vm.onDragCompass,
          },
        },
        [
          _vm.isTrackingDirection
            ? _c("img", {
                attrs: {
                  id: "map-control-compass",
                  src:
                    _vm.baseUrl +
                    "img/compass-tracking-" +
                    _vm.product +
                    ".svg",
                },
              })
            : _c("img", {
                attrs: {
                  id: "map-control-compass",
                  src: _vm.baseUrl + "img/compass-" + _vm.product + ".svg",
                },
              }),
        ]
      ),
      _c(
        "button",
        {
          staticClass: "ripple",
          class: { tracking: _vm.isTracking },
          attrs: { title: _vm.$t("mapControlPanel.currentLocation") },
          on: {
            click: function ($event) {
              return _vm.onClickGeolocation()
            },
          },
        },
        [
          _c("i", { staticClass: "material-icons" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.isGeolocationMobileAppDisabled
                    ? "location_disabled"
                    : "my_location"
                ) +
                " "
            ),
          ]),
        ]
      ),
      _vm.enableZoomButton
        ? _c(
            "button",
            {
              class: { ripple: !_vm.disableZoomIn },
              attrs: {
                title: _vm.$t("mapControlPanel.zoomIn"),
                disabled: _vm.disableZoomIn,
              },
              on: {
                mousedown: function ($event) {
                  return _vm.onMousedownZoomIn()
                },
                mouseup: function ($event) {
                  return _vm.onMouseupZoomIn()
                },
                mouseleave: function ($event) {
                  return _vm.onMouseupZoomIn()
                },
                touchstart: function ($event) {
                  return _vm.onTouchstartZoomIn()
                },
                touchend: function ($event) {
                  return _vm.onTouchendZoomIn()
                },
              },
            },
            [_c("i", { staticClass: "material-icons" }, [_vm._v(" add ")])]
          )
        : _vm._e(),
      _vm.enableZoomButton
        ? _c(
            "button",
            {
              class: { ripple: !_vm.disableZoomOut },
              attrs: {
                title: _vm.$t("mapControlPanel.zoomOut"),
                disabled: _vm.disableZoomOut,
              },
              on: {
                mousedown: function ($event) {
                  return _vm.onMousedownZoomOut()
                },
                mouseup: function ($event) {
                  return _vm.onMouseupZoomOut()
                },
                mouseleave: function ($event) {
                  return _vm.onMouseupZoomOut()
                },
                touchstart: function ($event) {
                  return _vm.onTouchstartZoomOut()
                },
                touchend: function ($event) {
                  return _vm.onTouchendZoomOut()
                },
              },
            },
            [_c("i", { staticClass: "material-icons" }, [_vm._v(" remove ")])]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }