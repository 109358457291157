var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      key: _vm.method.id,
      staticClass: "transit-method-card transit-method ripple",
      on: { click: _vm.clickTransitMethodCard },
    },
    [
      _c(
        "div",
        { staticClass: "how-to" },
        _vm._l(_vm.method.howTo, function (howTo, j) {
          return _c("div", { key: "how-to-" + j }, [
            _c("i", { staticClass: "material-icons-round" }, [
              _vm._v(_vm._s(_vm.transitIconList[howTo.by])),
            ]),
            howTo.text
              ? _c(
                  "div",
                  {
                    staticClass: "line",
                    class: { "has-icon": howTo.by < 6 },
                    style: howTo.color
                      ? { backgroundColor: howTo.color, color: howTo.textColor }
                      : {},
                  },
                  [
                    howTo.by < 6
                      ? _c(
                          "div",
                          {
                            staticClass: "icon",
                            style:
                              howTo.by === 4
                                ? { backgroundColor: "rgb(255, 255, 255)" }
                                : {},
                          },
                          [
                            _c("img", {
                              style:
                                howTo.by === 2
                                  ? { transform: "translateY(-0.5px)" }
                                  : {},
                              attrs: {
                                src: _vm.calTransitImgPath(
                                  howTo.by,
                                  howTo.text,
                                  2
                                ),
                                srcset:
                                  _vm.calTransitImgPath(
                                    howTo.by,
                                    howTo.text,
                                    1
                                  ) +
                                  _vm.calTransitImgPath(
                                    howTo.by,
                                    howTo.text,
                                    2
                                  ),
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                    howTo.by < 3 || howTo.by === 10
                      ? [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("routingPanel." + howTo.text)) +
                              " "
                          ),
                        ]
                      : [_vm._v(" " + _vm._s(howTo.text) + " ")],
                  ],
                  2
                )
              : _vm._e(),
            howTo.distance
              ? _c("div", { staticClass: "distance" }, [
                  _vm._v(
                    " " +
                      _vm._s(howTo.distance) +
                      " " +
                      _vm._s(_vm.$t("routingPanel.meter")) +
                      " "
                  ),
                ])
              : _vm._e(),
          ])
        }),
        0
      ),
      _c("div", { staticClass: "detail" }, [
        _c("div", [
          _vm.method.fee > 0
            ? _c("div", { staticClass: "fee" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.method.fee + " " + _vm.$t("routingPanel.baht")) +
                    " "
                ),
              ])
            : _vm._e(),
          _vm.method.fee > 0 && _vm.method.hasTaxiFee
            ? _c("div", { staticClass: "fee" }, [_vm._v(" + ")])
            : _vm._e(),
          _vm.method.hasTaxiFee
            ? _c("div", { staticClass: "fee" }, [
                _vm._v(_vm._s(_vm.$t("routingPanel.taxiFee"))),
              ])
            : _vm._e(),
        ]),
        _c("div", [
          _c("div", { staticClass: "distance" }, [
            _vm._v(
              _vm._s(_vm.method.displayDistance) +
                " " +
                _vm._s(
                  1000 > _vm.method.distance
                    ? _vm.$t("routingPanel.meter")
                    : _vm.$t("routingPanel.kilometer")
                )
            ),
          ]),
          _c("div", { staticClass: "time" }, [
            _vm._v(
              _vm._s(
                (_vm.method.hr !== 0
                  ? _vm.method.hr + " " + _vm.$t("routingPanel.hr") + " "
                  : "") +
                  _vm.method.min +
                  " " +
                  _vm.$t("routingPanel.min")
              )
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }