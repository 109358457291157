var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.previewImage && _vm.isLongdoMapV3
    ? _c("div", { staticClass: "images-preview-container" }, [
        _c(
          "button",
          {
            staticClass: "close ripple",
            on: {
              click: function ($event) {
                return _vm.closeImagePreview()
              },
            },
          },
          [_c("i", { staticClass: "material-icons-round" }, [_vm._v("close")])]
        ),
        _c(
          "div",
          { staticClass: "slideshow-container" },
          _vm._l(_vm.images, function (image, i) {
            return _c(
              "div",
              {
                key: i,
                class: {
                  "longdo-slide-hide": i !== _vm.currentImage,
                  "longdo-slide-active": i === _vm.currentImage,
                },
              },
              [
                _c("div", { staticClass: "numbertext" }, [
                  _vm._v(_vm._s(i + 1) + "/ " + _vm._s(_vm.images.length)),
                ]),
                _c("img", {
                  staticStyle: { width: "100%" },
                  attrs: { src: image.original },
                }),
              ]
            )
          }),
          0
        ),
        _c("br"),
        _c(
          "a",
          {
            staticClass: "prev",
            on: {
              click: function ($event) {
                return _vm.changeImageStage(-1)
              },
            },
          },
          [_vm._v("❮")]
        ),
        _c(
          "a",
          {
            staticClass: "next",
            on: {
              click: function ($event) {
                return _vm.changeImageStage(1)
              },
            },
          },
          [_vm._v("❯")]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }