<template>
  <div class="share-place-widget">
    <div class="header">
      <div class="title">{{ $t('sharePlaceWidget.title') }}</div>
      <button class="close ripple" @click="onClickClose">
        <i class="material-icons-round">close</i>
      </button>
    </div>
    <div class="app-list">
      <div class="button-group">
        <button @click="copyLink">
          <i class="material-icons-round">link</i>
        </button>
        <label>{{ $t('sharePlaceWidget.copy') }}</label>
      </div>

      <div class="button-group">
        <button @click="clickLineButton" style="background-color: #03c755;">
          <img :src="`${baseUrl}img/share/line@2x.png?${appVersion}`" :srcset="`${baseUrl}img/share/line.png?${appVersion} 1x, ${baseUrl}img/share/line@2x.png?${appVersion} 2x`">
        </button>
        <label>{{ 'LINE' }}</label>
      </div>

      <div class="button-group">
        <button @click="clickFacebookButton" style="background-color: #0766ff;">
          <img :src="`${baseUrl}img/share/facebook@2x.png?${appVersion}`" :srcset="`${baseUrl}img/share/facebook.png?${appVersion} 1x, ${baseUrl}img/share/facebook@2x.png?${appVersion} 2x`">
        </button>
        <label>{{ 'Facebook' }}</label>
      </div>

      <div class="button-group">
        <button @click="clickGoogleMapButton">
          <img :src="`${baseUrl}img/share/google_map@2x.png?${appVersion}`" :srcset="`${baseUrl}img/share/google_map.png?${appVersion} 1x, ${baseUrl}img/share/google_map@2x.png?${appVersion} 2x`">
        </button>
        <label>{{ 'Google Maps' }}</label>
      </div>

      <div class="button-group" v-if="isMacIOS">
        <button @click="clickAppleMapButton">
          <img :src="`${baseUrl}img/share/apple_map@2x.png?${appVersion}`" :srcset="`${baseUrl}img/share/apple_map.png?${appVersion} 1x, ${baseUrl}img/share/apple_map@2x.png?${appVersion} 2x`">
        </button>
        <label>{{ 'Apple Map' }}</label>
      </div>
    </div>
    <div class="link">
      <input ref="share-url-input" type="link" :value="placeUrl" @click="clickLink" readonly>
      <button @click="copyLink">{{ $t('sharePlaceWidget.copy') }}</button>
      <transition name="fade">
        <div class="copied" v-if="isShowCopied">{{ $t('sharePlaceWidget.copy') }}</div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SharePlaceWidget',
  props: {
    poiId: {
      type: String,
      required: true
    },
    lat: {
      type: Number,
      required: true
    },
    lon: {
      type: Number,
      required: true
    },
    isShareInfoPage: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isShowCopied: false
    }
  },
  computed: {
    placeUrl () {
      var self = this
      return `https://${process.env.VUE_APP_PRODUCT}.longdo.com/main/p/${self.poiId}${self.isShareInfoPage ? '' : ''}`
    },
    isMacIOS () {
      return /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)
    }
  },
  methods: {
    onClickClose () {
      const self = this
      self.$emit('close')
    },
    clickLink () {
      var self = this
      var copyText = self.$refs['share-url-input']
      copyText.select()
    },
    copyLink () {
      var self = this
      var copyText = self.$refs['share-url-input']
      copyText.select()
      document.execCommand('Copy')
      self.isShowCopied = true
      setTimeout(() => {
        self.isShowCopied = false
      }, 2000)
    },
    clickFacebookButton () {
      const self = this
      const url = `https://www.facebook.com/sharer/sharer.php?u=${self.placeUrl}`
      window.open(url)
    },
    clickLineButton () {
      const self = this
      const url = `https://line.me/R/msg/text/?${self.placeUrl}`
      window.open(url)
    },
    clickGoogleMapButton () {
      const self = this
      const url = `https://www.google.com/maps/search/?api=1&query=${self.lat},${self.lon}`
      window.open(url)
    },
    clickAppleMapButton () {
      const self = this
      const url = `https://maps.apple.com/?q=${self.lat},${self.lon}`
      window.open(url)
    }
  }
}
</script>

<style lang="scss" scoped>
.share-place-widget {
  position: fixed;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
  background-color: white;
  width: 400px;
  padding: 12px;
  box-sizing: border-box;
  z-index: 200;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
  border-radius: 10px;

  @media only screen and (max-width: $mobileMaxSize) {
    width: 100%;
    bottom: 0%;
    transform: translate(-50%, 0%);
    border-radius: 0px;
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 6px 0px 12px 6px;
    box-sizing: border-box;

    .title {
      font-family: 'Prompt';
      font-size: 18px;
      color: $primary;
    }

    button.close {
      margin-left: auto;
      border: none;
      background-color: transparent;
      padding: 0px;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        font-size: 32px;
        color: $grey;
      }
    }
  }

  .app-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow: hidden;

    .button-group {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: calc(100% / 4);
      margin: 6px 0px;

      button {
        height: 48px;
        width: 48px;
        margin: auto;
        cursor: pointer;
        border: none;
        border-radius: 9px;
        background-color: $extraLightGrey;
        padding: 0px;
        outline: none;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 48px;
          height: 48px;
          display: block;
        }
      }

      label {
        text-align: center;
        font-family: 'Prompt';
        margin-top: 3px;
      }
    }
  }

  .link {
    margin-top: 12px;
    display: flex;
    flex-direction: row;

    input {
      border: 1px solid $lightGrey;
      border-radius: 6px 0px 0px 6px;
      height: 36px;
      flex: 1;
      outline: none;
      padding: 3px 12px;
      box-sizing: border-box;
      width: calc(100% - 80px);
      appearance: none;
      font-size: 16px;
    }

    button {
      border-radius: 0px 6px 6px 0px;
      background-color: $darkPrimary;
      cursor: pointer;
      outline: none;
      padding: 0px 12px;
      height: 36px;
      border: none;
      color: rgb(255, 255, 255);
      width: 80px;
      font-family: 'Prompt';
      font-size: 16px;
    }

    .fade-enter-active, .fade-leave-active {
      transition: opacity 2s;
    }

    .fade-enter, .fade-leave-to {
      opacity: 0;
    }

    .copied {
      position: absolute;
      color: white;
      background-color: rgba(0, 0, 0, 0.75);
      width: fit-content;
      border-radius: 16px;
      width: 75px;
      height: 32px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      left: 50%;
      bottom: 65px;
      transform: translate(-50%, 0%);
      font-family: 'Prompt';
    }
  }
}
</style>
