/**
 * 
 * Name: Longdo Js Interface
 * Version: 2.7
 * Author: Suraphat (suraphat@longdo.com)
 * Repository: https://git.longdo.com/mm/longdo-js-interface
 * 
 */

export default function LongdoJsInterface(options) {
  let transaction = 1
  let cachedTile = {}
  const identifyAndroid = (options || {}).identifyAndroid || ''
  const identifyIos = (options || {}).identifyIos || 'LongdoJsInterface'

  const onRefreshFCMTokenCallbackName = '_longdoJsInterface_onRefreshFCMToken'
  window[onRefreshFCMTokenCallbackName] = () => { }

  const oMessageFCMCallbackName = '_longdoJsInterface_onMessageFCM'
  window[oMessageFCMCallbackName] = () => { }

  this.Util = {
    getQueryString: function (obj) {
      let str = []
      for (let p in obj) {
        if (obj[p] !== undefined) {
          if (obj[p] instanceof Array) {
            for (var i = 0; i < obj[p].length; i++) {
              str.push(encodeURIComponent(p) + '[' + i + ']=' + encodeURIComponent(JSON.stringify(obj[p][i])))
            }
          } else {
            str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
          }
        }
      }
      return str.join('&')
    },
    isAndroidNativeApp: function () {
      const ua = window.navigator.userAgent || window.navigator.vendor || window.opera || ''
      return ua.indexOf('Android') > -1 && ua.indexOf(identifyAndroid) > -1 && (('flutter_inappwebview' in window) || (typeof Android !== 'undefined'))
    },
    isIosNativeApp: function () {
      const ua = window.navigator.userAgent || window.navigator.vendor || window.opera || ''
      return ua.indexOf('Mac') > -1 && ua.indexOf(identifyIos) > -1 && ua.indexOf('Version') < 0
    },
    isOnLine: function () {
      if (window.navigator) {
        if ('onLine' in window.navigator) {
          return window.navigator.onLine
        }
      }
      return null
    },
    addScriptTag: function (option) {
      option = option || {}
      return new Promise((resolve, reject) => {
        let script = document.createElement('script')
        script.onload = (e) => {
          resolve(e)
        }
        script.onreadystatechange = (e) => {
          resolve(e)
        }
        script.onerror = (e) => {
          reject(e)
        }
        for (var key in option) {
          script[key] = option[key]
        }
        document.head.appendChild(script)
      })
    },
    cacheLongdoMapTheme: async (longdoObj, cacheName) => {
      const keyList = [
        'menu.arrowActive',
        'menu.arrowDown',
        'menu.arrowUp',
        'poi.addressIcon',
        'poi.lineIcon',
        'poi.openIcon',
        'poi.poiLabel.en.obsoleted',
        'poi.poiLabel.en.unverified',
        'poi.poiLabel.th.obsoleted',
        'poi.poiLabel.th.unverified',
        'poi.tagIcon',
        'poi.telIcon',
        'poi.urlIcon',
        'search.zoomIcon',
        'ui.clearOff',
        'ui.clearOn',
        'ui.dpad',
        'ui.fullscreenDown',
        'ui.fullscreenUp',
        'ui.geolocation',
        'ui.geolocationMarkerIcon.url',
        'ui.geolocationMarkerIcon.urlHD',
        'ui.legendTraffic.en',
        'ui.legendTraffic.th',
        'ui.measureOff',
        'ui.measureOn',
        'ui.rangeOff',
        'ui.rangeOn',
        'ui.zoomBar',
        'ui.zoomIn',
        'ui.zoomInMini',
        'ui.zoomOut',
        'ui.zoomOutMini',
        'ui.zoomSlider'
      ]

      if (this.Util.isOnLine() === true) {
        // online
        for (let i = 0; i < keyList.length; i++) {
          let temp = longdoObj.MapTheme
          const key = keyList[i]
          const splitedName = key.split('.')
          for (let j = 0; j < splitedName.length - 1; j++) {
            temp = temp[splitedName[j]]
          }
          try {
            await this.addCache({
              cacheName: cacheName,
              url: temp[splitedName[splitedName.length - 1]],
            })
          } catch (error) {
            console.warn(error)
          }
        }
      } else {
        // offline
        for (let i = 0; i < keyList.length; i++) {
          let temp = longdoObj.MapTheme
          const key = keyList[i];
          const splitedName = key.split('.')
          for (let j = 0; j < splitedName.length - 1; j++) {
            temp = temp[splitedName[j]]
          }
          try {
            const newUrl = await this.getCache({
              cacheName: cacheName,
              url: temp[splitedName[splitedName.length - 1]],
              returnType: 'stringUrl'
            })
            temp[splitedName[splitedName.length - 1]] = newUrl
          } catch (error) {
            console.warn(error)
          }
        }
      }
    },
    cacheLongdoMapLayer: (longdoObj, cacheName, longdoMapObj) => {
      const overrideLayer = (layerType) => {
        const layerName = layerType.name()
        return new longdoObj.Layer(layerName, {
          type: longdoObj.LayerType.Custom,
          defer: async (element, projection, tile, zoom, hd) => {
            let url = layerType.image(projection, tile, hd)
            url = url.replace('longdo.com', 'simplethai.net')
            if (this.Util.isAndroidNativeApp() || this.Util.isIosNativeApp()) {
              const key = `_${layerName}:x:${tile.u}:y:${tile.v}:z:${zoom}`
              cachedTile[key] = cachedTile[key] || {}

              // cache tile once when online
              if (this.Util.isOnLine() === true && !cachedTile[key].url) {
                if (!(cachedTile[key] && cachedTile[key].added)) {
                  try {
                    await this.addCache({
                      cacheName: cacheName,
                      url: url
                    })
                    cachedTile[key] = Object.assign(cachedTile[key] || {}, {
                      added: true
                    })
                  } catch (error) {
                    console.warn(error)
                  }
                }
              }

              // get cache to object variable
              if (!cachedTile[key].url) {
                try {
                  const localUrl = await this.getCache({
                    cacheName: cacheName,
                    url: url,
                    returnType: this.Util.isOnLine() === true ? 'stringContent' : 'stringUrl',
                  })
                  if (localUrl) {
                    cachedTile[key] = Object.assign(cachedTile[key] || {}, {
                      url: localUrl
                    })
                    cachedTile[key] = Object.assign(cachedTile[key] || {}, {
                      added: true
                    })
                  }
                } catch (error) {
                  console.warn(error)
                }
              }

              element.src = this.Util.isOnLine() === true ? `data:image/png;base64,${cachedTile[key].url}` : cachedTile[key].url
            } else {
              element.src = url
            }
          }
        })
      }

      const setBase = longdoMapObj.Layers.setBase
      longdoMapObj.Layers.setBase = function (layer) {
        setBase(overrideLayer(layer))
      }
      const add = longdoMapObj.Layers.add
      longdoMapObj.Layers.add = function (layer) {
        add(overrideLayer(layer))
      }
      const remove = longdoMapObj.Layers.remove
      longdoMapObj.Layers.remove = function (layer) {
        remove(overrideLayer(layer))
      }
      longdoMapObj.Layers.setBase(longdoObj.Layers.POI)
      return longdoMapObj
    },
    isObject: function (obj) {
      return typeof obj === 'object' && !Array.isArray(obj) && obj !== null
    },
    toSoundId: function (name) {
      const ref = {
        'newMail': { iOS: 1000, Android: 1000 },
        'sendMail': { iOS: 1001, Android: 1001 },
        'newMessage': { iOS: 1003, Android: 1003 },
        'sendMessage': { iOS: 1004, Android: 1004 },
        'alarm': { iOS: 1005, Android: 1005 },
        'lowPower': { iOS: 1006, Android: 1006 },
        'callDropped': { iOS: 1051, Android: 1051 },
        'beep': { iOS: 1052, Android: 1052 },
        'lock': { iOS: 1100, Android: 1100 },
        'tink': { iOS: 1103, Android: 1103 },
        'tock': { iOS: 1104, Android: 1104 },
        'photoShutter': { iOS: 1108, Android: 1108 },
        'shake': { iOS: 1109, Android: 1109 },
        'beginRecord': { iOS: 1113, Android: 1113 },
        'endRecord': { iOS: 1114, Android: 1114 },
      }
      if (this.isAndroidNativeApp()) {
        return (ref[name] || {}).Android
      }
      else if (this.isIosNativeApp()) {
        return (ref[name] || {}).iOS || 1052
      }
      else {
        return null
      }
    },
    initMethod: function ({
      name,
      title,
      params,
      supportiOS = true,
      supportAndroid = true,
      preCheck
    }) {
      return (resolve, reject) => {
        if (!(resolve instanceof Function)) {
          resolve = null
        }
        if (!(reject instanceof Function)) {
          reject = null
        }
        if (preCheck) {
          const result = preCheck()
          if (resolve && result.success === true) {
            resolve(result.result)
          } else if (reject && result.success === false) {
            reject(result.result)
          }
          params = result
        }
        const callbackName = `_${name}Callback${new Date().getTime()}${transaction++}`
        if (params && params.callback) {
          if (!(params.callback instanceof Function)) {
            if (reject) {
              reject({
                code: 2,
                message: 'callback must be function'
              })
            } else {
              return {
                code: 2,
                message: 'callback must be function'
              }
            }
          }
          const callback = params.callback
          window[callbackName] = (result) => {
            if (resolve) {
              resolve(callback(result))
            } else {
              return callback(result)
            }
          }
          delete params.callback
        } else {
          window[callbackName] = (result) => {
            if (resolve) {
              if (this.isObject(result)) {
                resolve(result)
              } else {
                try {
                  resolve(JSON.parse(result))
                } catch (_) {
                  resolve(result)
                }
              }
            } else {
              if (this.isObject(result)) {
                return result
              } else {
                try {
                  return JSON.parse(result)
                } catch (_) {
                  return result
                }
              }
            }
          }
        }

        const errorCallbackName = `_error${name}Callback${new Date().getTime()}${transaction++}`
        if (params && params.errorCallback) {
          if (!(params.errorCallback instanceof Function)) {
            if (reject) {
              reject({
                code: 2,
                message: 'errorCallback must be function'
              })
            } else {
              return {
                code: 2,
                message: 'errorCallback must be function'
              }
            }
          }
          const callback = params.errorCallback
          window[errorCallbackName] = (errorMessage, errorCode = 0) => {
            if (reject) {
              reject(callback({
                code: errorCode,
                message: errorMessage
              }))
            } else {
              return callback({
                code: errorCode,
                message: errorMessage
              })
            }
          }
          delete params.errorCallback
        } else {
          window[errorCallbackName] = (errorMessage, errorCode = 0) => {
            if (reject) {
              reject({
                code: errorCode,
                message: errorMessage
              })
            } else {
              return {
                code: errorCode,
                message: errorMessage
              }
            }
          }
        }

        const sendParams = Object.assign(params || {}, {
          callbackName,
          errorCallbackName
        })
        if (supportAndroid && this.isAndroidNativeApp()) {
          if (name === 'capture') { //force unsupport parameters
            delete sendParams.width
            delete sendParams.height
          } else if (name === 'openUrl') { //force unsupport parameters
            delete sendParams.title
            Object.assign(sendParams, {
              title: params.title
            })
          }
          if ('flutter_inappwebview' in window) {
            window.flutter_inappwebview.callHandler(name, ...Object.values(sendParams));
          } else {
            Android[name](...Object.values(sendParams))
          }
        } else if (supportiOS && this.isIosNativeApp() && 'webkit' in window) {
          window.webkit.messageHandlers[name].postMessage(sendParams)
        } else if (reject) {
          reject({
            code: 1,
            message: `Unsupport ${title}`
          })
        } else {
          return {
            code: 1,
            message: `Unsupport ${title}`
          }
        }
      }
    }
  }

  this.setOffline = ({
    fileList,
    baseFileIndex = 0
  }) => new Promise(this.Util.initMethod({
    name: 'setOffline',
    title: 'set offline',
    params: {
      fileList,
      baseFileIndex: parseInt(baseFileIndex)
    },
    preCheck: () => {
      if (!(fileList instanceof Array)) {
        return {
          result: {
            code: 2,
            message: 'fileList must be {Array.<{fileName: String, path: String, url: String}>}'
          },
          success: false
        }
      }
      if (fileList.length === 0) {
        return {
          result: true,
          success: true
        }
      }
      if (this.Util.isAndroidNativeApp()) {
        fileList = JSON.stringify(fileList)
      }
      return {
        fileList,
        baseFileIndex
      }
    }
  }))

  this.addCache = ({
    cacheName,
    url
  }) => new Promise(this.Util.initMethod({
    name: 'addCache',
    title: 'add cache',
    params: {
      cacheName,
      url
    }
  }))

  this.getCache = ({
    cacheName,
    url,
    returnType
  }) => new Promise(this.Util.initMethod({
    name: 'getCache',
    title: 'get cache',
    params: {
      cacheName,
      url,
      returnType,
      callback: (result) => {
        if (this.Util.isObject(result)) {
          return result.result
        } else {
          return result
        }
      }
    }
  }))

  this.deleteCache = ({
    cacheName,
    url
  }) => new Promise(this.Util.initMethod({
    name: 'deleteCache',
    title: 'delete cache',
    params: {
      cacheName,
      url
    },
    preCheck: () => {
      if (typeof cacheName !== 'string') {
        return {
          result: {
            code: 2,
            message: 'please specify cacheName'
          },
          success: false
        }
      }
      return {
        cacheName,
        url
      }
    }
  }))

  this.addStorage = ({
    key,
    value
  }) => new Promise(this.Util.initMethod({
    name: 'addStorage',
    title: 'add storage',
    params: {
      key,
      value
    }
  }))

  this.getStorage = ({
    key
  }) => new Promise(this.Util.initMethod({
    name: 'getStorage',
    title: 'get storage',
    params: {
      key,
      callback: (result) => {
        if (this.Util.isObject(result)) {
          return result.result
        } else {
          return result
        }
      }
    }
  }))

  this.deleteStorage = ({
    key
  }) => new Promise(this.Util.initMethod({
    name: 'deleteStorage',
    title: 'delete storage',
    params: {
      key
    }
  }))

  this.getUniqueDeviceId = () => new Promise(this.Util.initMethod({
    name: 'getUniqueDeviceId',
    title: 'get unique device id'
  }))

  this.getFCMToken = () => {
    if (this.Util.isAndroidNativeApp()) {
      Android.getFCMToken()
      return true
    } else if (this.Util.isIosNativeApp() && 'webkit' in window) {
      window.webkit.messageHandlers.getFCMToken.postMessage({})
      return true
    }
    return false
  }

  this.setOnRefreshFCMToken = ({
    onRefreshFCMToken
  }) => {
    if (!(onRefreshFCMToken instanceof Function)) {
      return false
    }
    window[onRefreshFCMTokenCallbackName] = onRefreshFCMToken
    return true
  }

  this.setOnMessageFCM = ({
    onMessageFCM
  }) => {
    if (!(onMessageFCM instanceof Function)) {
      return false
    }
    window[oMessageFCMCallbackName] = onMessageFCM
    return true
  }

  this.readyOnMessageFCM = () => {
    if (this.Util.isIosNativeApp() && 'webkit' in window) {
      window.webkit.messageHandlers.readyOnMessageFCM.postMessage({})
      return true
    }
    return false
  }

  this.setBadgeNotification = ({
    numberOfNotification
  }) => new Promise(this.Util.initMethod({
    name: 'setBadgeNotification',
    title: 'set badge notification',
    params: {
      numberOfNotification: parseInt(numberOfNotification)
    }
  }))

  this.getGeolocation = () => new Promise(this.Util.initMethod({
    name: 'getGeolocation',
    title: 'get geololocation'
  }))

  this.setOnWatchGeolocation = ({
    callback,
    errorCallback
  }) => this.Util.initMethod({
    name: 'getGeolocation',
    title: 'set on watch geololocation',
    params: {
      callback,
      errorCallback
    }
  })()

  this.stopWatchGeolocation = () => new Promise(this.Util.initMethod({
    name: 'stopGetGeolocation',
    title: 'stop watch geololocation'
  }))

  this.openUrl = ({
    url,
    title = 'Open with'
  }) => new Promise(this.Util.initMethod({
    name: 'openUrl',
    title: 'open url',
    params: {
      url,
      title
    },
    preCheck: () => {
      if (typeof url !== 'string') {
        return {
          result: {
            code: 2,
            message: 'please specify url'
          },
          success: false
        }
      }
      return {
        url,
        title
      }
    }
  }))

  this.selectImageFile = () => new Promise(this.Util.initMethod({
    name: 'selectImageFile',
    title: 'select image file'
  }))

  this.capture = (option = {
    width: 150,
    height: 50
  }) => new Promise(this.Util.initMethod({
    name: 'capture',
    title: 'capture',
    params: {
      width: parseInt(option.width) || 150,
      height: parseInt(option.height) || 50
    }
  }))

  this.saveImage = ({
    url,
    base64
  }) => new Promise(this.Util.initMethod({
    name: 'saveImage',
    title: 'save image',
    params: {
      encodeUrlOrBase64: (url || base64),
      type: url === null ? 'base64' : 'url'
    },
    preCheck: () => {
      if (typeof url !== 'string' && typeof base64 !== 'string') {
        return {
          result: {
            code: 2,
            message: 'please specify url or base64'
          },
          success: false
        }
      }
      return {
        encodeUrlOrBase64: (url || base64),
        type: url === null ? 'base64' : 'url'
      }
    }
  }))

  this.shareUrl = (url) => new Promise(this.Util.initMethod({
    name: 'shareUrl',
    title: 'share URL',
    params: {
      url
    },
    preCheck: () => {
      if (typeof url !== 'string') {
        return {
          result: {
            code: 2,
            message: 'please specify url'
          },
          success: false
        }
      }
      return {
        url
      }
    }
  }))

  this.getAppVersion = () => new Promise(this.Util.initMethod({
    name: 'getAppVersion',
    title: 'get app version'
  }))

  this.getLanguage = () => new Promise(this.Util.initMethod({
    name: 'getLanguage',
    title: 'get language'
  }))

  this.setLanguage = ({
    lang = 'th'
  }) => new Promise(this.Util.initMethod({
    name: 'setLanguage',
    title: 'set language',
    params: {
      lang
    }
  }))

  this.setCookie = ({
    domain,
    cookie
  }) => new Promise(this.Util.initMethod({
    name: 'setCookie',
    title: 'set cookie',
    params: {
      domain,
      cookie
    },
    supportiOS: false
  }))

  this.flushCookie = () => new Promise(this.Util.initMethod({
    name: 'flushCookie',
    title: 'flush cookie',
    supportiOS: false
  }))

  this.speak = ({
    text = '',
    lang = 'th'
  }) => new Promise(this.Util.initMethod({
    name: 'speak',
    title: 'speak',
    params: {
      text,
      lang
    }
  }))

  this.alwaysOn = ({
    on = true
  }) => new Promise(this.Util.initMethod({
    name: 'alwaysOn',
    title: 'always on',
    params: {
      on
    }
  }))

  this.scanQrCode = () => new Promise(this.Util.initMethod({
    name: 'scanQrCode',
    title: 'scan qr code',
    params: {
      callback: (result) => {
        if (this.Util.isObject(result)) {
          return result.content
        } else {
          return result
        }
      }
    }
  }))

  this.loginLongdo = () => new Promise(this.Util.initMethod({
    name: 'loginLongdo',
    title: 'login longdo'
  }))

  this.getLongdoInfo = () => new Promise(this.Util.initMethod({
    name: 'getLongdoInfo',
    title: 'get longdo info'
  }))

  this.logoutLongdo = () => new Promise(this.Util.initMethod({
    name: 'logoutLongdo',
    title: 'logout longdo'
  }))

  this.deleteLongdo = () => new Promise(this.Util.initMethod({
    name: 'deleteLongdo',
    title: 'delete longdo'
  }))

  this.startListen = ({
    lang = 'th',
    callback,
    errorCallback
  }) => this.Util.initMethod({
    name: 'startListen',
    title: 'start listen',
    params: {
      lang,
      callback,
      errorCallback
    }
  })()

  this.stopListen = () => new Promise(this.Util.initMethod({
    name: 'stopListen',
    title: 'stop listen'
  }))

  this.openSetting = () => new Promise(this.Util.initMethod({
    name: 'openSetting',
    title: 'open setting'
  }))

  this.vibrate = ({
    type = ""
  }) => new Promise(this.Util.initMethod({
    name: 'vibrate',
    title: 'vibrate',
    params: {
      type
    }
  }))

  this.sound = ({
    soundId
  }) => new Promise(this.Util.initMethod({
    name: 'sound',
    title: 'sound',
    params: {
      soundId: this.Util.toSoundId(soundId)
    }
  }))

  this.sendToProbe = ({
    on = true
  }) => new Promise(this.Util.initMethod({
    name: 'sendToProbeChange',
    title: 'send to probe',
    params: {
      on
    }
  }))

  this.updateToken = ({
    on = true
  }) => new Promise(this.Util.initMethod({
    name: 'updateTokenChange',
    title: 'update token',
    params: {
      on
    }
  }))

  this.hideLaunchscreen = () => new Promise(this.Util.initMethod({
    name: 'hideLaunchscreen',
    title: 'hide launchscreen'
  }))

  this.manageActivityTrackingPermissions = () => new Promise(this.Util.initMethod({
    name: 'manageActivityTrackingPermissions',
    title: 'manage activity tracking permissions',
    supportAndroid: false
  }))

  this.logEvent = ({
    name,
    parameters
  }) => new Promise(this.Util.initMethod({
    name: 'logEvent',
    title: 'log event',
    params: {
      name,
      parameters
    },
    preCheck: () => {
      if (typeof name !== 'string') {
        return {
          result: {
            code: 2,
            message: 'please specify name'
          },
          success: false
        }
      }
      if (typeof parameters !== 'object') {
        return {
          result: {
            code: 2,
            message: 'please specify parameters as object'
          },
          success: false
        }
      }
      if (this.Util.isAndroidNativeApp()) {
        parameters = JSON.stringify(parameters)
      }
      return {
        name,
        parameters
      }
    }
  }))

  this.screenView = ({
    name
  }) => new Promise(this.Util.initMethod({
    name: 'screenView',
    title: 'screen view',
    params: {
      name
    },
    preCheck: () => {
      if (typeof name !== 'string') {
        return {
          result: {
            code: 2,
            message: 'please specify name'
          },
          success: false
        }
      }
      return {
        name
      }
    }
  }))

  this.setUserProperty = ({
    name,
    value
  }) => new Promise(this.Util.initMethod({
    name: 'setUserProperty',
    title: 'set user property',
    params: {
      name,
      value
    },
    preCheck: () => {
      if (typeof name !== 'string') {
        return {
          result: {
            code: 2,
            message: 'please specify name'
          },
          success: false
        }
      }
      return {
        name,
        value
      }
    }
  }))

  // this.detectLight = ({
  //   detect = true,
  //   callback,
  //   errorCallback
  // }) => new Promise(this.Util.initMethod({
  //   name: 'detectLight',
  //   title: 'detect light',
  //   params: {
  //     detect,
  //     callback,
  //     errorCallback
  //   }
  // }))
}