var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        class: {
          toggle: _vm.isToggle,
          "badge-info-list": !_vm.isLongdoMapV3,
          "badge-info-list-map3": _vm.isLongdoMapV3,
          "traffic-on": _vm.layerSelected.includes("traffic"),
        },
      },
      [
        _vm.isShowingVelocityBadge
          ? _c("div", { staticClass: "badge-velocity" }, [
              _c("div", { staticClass: "badge-box" }, [
                _c("div", { staticClass: "index" }, [
                  _vm._v(
                    _vm._s(
                      _vm.velocityBadgeUnit === "mile"
                        ? Math.round(_vm.currentVelocity * 0.621)
                        : Math.round(_vm.currentVelocity)
                    ) + " "
                  ),
                ]),
                _c("label", { staticClass: "description" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.velocityBadgeUnit === "mile" ? "mile/hr" : "km/hr"
                      ) +
                      " "
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
        _c(
          "a",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isTrafficProduct,
                expression: "isTrafficProduct",
              },
            ],
            class: {
              "no-other-badge": !_vm.isShowingAnyAqiBadge,
              "traffic-index": !_vm.isLongdoMapV3,
              "traffic-index-map3": _vm.isLongdoMapV3,
            },
            on: {
              click: function ($event) {
                return _vm.onClickUrl("https://traffic.longdo.com/trafficindex")
              },
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "badge-box",
                style: { borderColor: _vm.trafficIndexColor },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "index",
                    style: { color: _vm.trafficIndexColor },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.trafficIndex ? _vm.trafficIndex.toFixed(1) : "-.-"
                      ) + " "
                    ),
                  ]
                ),
                _c("label", { staticClass: "description" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("badgeInformation.trafficIndex")) + " "
                  ),
                ]),
              ]
            ),
          ]
        ),
        _vm.badgeData !== null &&
        (_vm.layerSelected.includes("aqi") || _vm.showAqiV2)
          ? _c("div", { staticClass: "badge-information" }, [
              _c("div", [
                _c(
                  "div",
                  {
                    staticClass: "badge-box",
                    style: { backgroundColor: _vm.badgeData.aqi_attr.bgcolor },
                  },
                  [
                    _c("div", { staticClass: "head" }, [
                      _c(
                        "div",
                        {
                          staticClass: "title",
                          style: { color: _vm.badgeData.aqi_attr.fontcolor },
                        },
                        [_vm._v("AQI")]
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "value",
                        style: { color: _vm.badgeData.aqi_attr.fontcolor },
                      },
                      [_vm._v(" " + _vm._s(_vm.badgeData.aqi_value) + " ")]
                    ),
                  ]
                ),
              ]),
            ])
          : _vm._e(),
        _vm.gistdaData !== null && _vm.layerSelected.includes("gistda_pm")
          ? _c("div", { staticClass: "badge-gistda-pm" }, [
              _c("div", [
                _c(
                  "div",
                  { staticClass: "badge-box", style: _vm.gistdaColor("BG") },
                  [
                    _c("div", { staticClass: "head" }, [
                      _c("div", { staticClass: "title" }, [
                        _c(
                          "div",
                          {
                            staticStyle: { display: "flex" },
                            style: _vm.gistdaColor("TEXT"),
                          },
                          [
                            _vm._v(" AQI "),
                            _c("img", {
                              staticStyle: {
                                width: "20px",
                                "margin-left": "3px",
                                height: "20px",
                              },
                              attrs: {
                                src: "https://www.gistda.or.th/assets/img/favicon.png",
                                alt: "",
                              },
                            }),
                          ]
                        ),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "value", style: _vm.gistdaColor("TEXT") },
                      [
                        _vm._v(
                          " " +
                            _vm._s(Math.floor(_vm.gistdaData.pm25) || "-") +
                            " "
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
            ])
          : _vm._e(),
      ]
    ),
    _vm.layerSelected.includes("traffic")
      ? _c("div", { staticClass: "traffic-legend" }, [
          _c("img", {
            attrs: {
              src: "https://api.longdo.com/map/images/ui/legend-traffic-th-2x.png",
              alt: "",
            },
          }),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }