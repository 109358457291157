var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        "search-result-panel": !_vm.isLongdoMapV3,
        "search-result-panel-map3": _vm.isLongdoMapV3,
        hide: _vm.isHidingSearchResultPanel || _vm.$route.hash === "#routing",
        "expand-less": !_vm.isExpand,
      },
    },
    [
      _c("div", { staticClass: "search-result-container" }, [
        _c("div", { staticClass: "top-bar" }, [
          _vm.isExpand
            ? _c(
                "button",
                {
                  staticClass: "expand less ripple",
                  on: { click: _vm.clickExpandLess },
                },
                [
                  _c("i", { staticClass: "material-icons-round" }, [
                    _vm._v("expand_more"),
                  ]),
                ]
              )
            : _vm._e(),
          !_vm.isExpand
            ? _c(
                "button",
                {
                  staticClass: "expand more ripple",
                  on: { click: _vm.clickExpandMore },
                },
                [
                  _c("i", { staticClass: "material-icons-round" }, [
                    _vm._v("expand_less"),
                  ]),
                ]
              )
            : _vm._e(),
        ]),
        _vm.showLoading
          ? _c("div", { staticClass: "loading-container" }, [
              _c("img", {
                attrs: { src: require("@/assets/img/loading.gif") },
              }),
            ])
          : _vm._e(),
        !_vm.showLoading &&
        _vm.searchResultList !== null &&
        _vm.searchResultList.length === 0
          ? _c("div", { staticClass: "empty-container" }, [
              _vm._v(" " + _vm._s(_vm.$t("searchResultPanel.empty")) + " "),
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "search-result-list" },
          [
            _vm._l(_vm.searchResultList, function (searchResult, index) {
              return _c(
                "div",
                {
                  key: searchResult.id || index,
                  staticClass: "search-result ripple",
                  on: {
                    click: function ($event) {
                      return _vm.clickSearchResult(searchResult, index)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "detail-list" }, [
                    _c("div", { staticClass: "icon" }, [
                      _c("img", {
                        attrs: {
                          src: _vm.searchMarkIconImage(searchResult.markicon2x),
                          srcset:
                            _vm.searchMarkIconImage(searchResult.markicon) +
                            " 1x, " +
                            _vm.searchMarkIconImage(searchResult.markicon2x) +
                            " 2x",
                          loading: "lazy",
                        },
                      }),
                    ]),
                    _c("div", { staticClass: "detail" }, [
                      _c(
                        "label",
                        {
                          staticClass: "name",
                          attrs: {
                            title:
                              _vm.$i18n.locale === "th"
                                ? searchResult.name
                                : searchResult.name_en,
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$i18n.locale === "th"
                                  ? searchResult.name
                                  : searchResult.name_en
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "label",
                        {
                          staticClass: "short-description",
                          attrs: {
                            title:
                              _vm.$i18n.locale === "th"
                                ? searchResult.shortdesc
                                : searchResult.shortdesc_en,
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$i18n.locale === "th"
                                  ? searchResult.shortdesc
                                  : searchResult.shortdesc_en
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "info-list" }, [
                    (searchResult.source || "") === "google"
                      ? _c("div", { staticClass: "info" }, [
                          _c("div", {
                            staticClass: "source-map",
                            style: {
                              backgroundImage:
                                "url(" +
                                _vm.baseUrl +
                                "img/google-map-logo-icon.svg)",
                            },
                            attrs: { title: "Google Maps" },
                          }),
                        ])
                      : _vm._e(),
                    (searchResult.id || "").indexOf("OSM") === 0
                      ? _c("div", { staticClass: "info" }, [
                          _c("div", {
                            staticClass: "source-map",
                            style: {
                              backgroundImage:
                                "url(" + _vm.baseUrl + "img/osm-logo-icon.svg)",
                            },
                            attrs: { title: "OpenStreetMap" },
                          }),
                        ])
                      : _vm._e(),
                    (searchResult.id || "").indexOf("OVM") === 0
                      ? _c("div", { staticClass: "info" }, [
                          _c("div", {
                            staticClass: "source-map",
                            style: {
                              backgroundImage:
                                "url(" + _vm.baseUrl + "img/ovm-logo-icon.png)",
                            },
                            attrs: { title: "Overture Maps" },
                          }),
                        ])
                      : _vm._e(),
                    searchResult.objecttype !== "tag" &&
                    (searchResult.source || "") !== "google" &&
                    searchResult.objecttype !== "geocoding"
                      ? _c(
                          "a",
                          {
                            staticClass: "info",
                            on: {
                              click: function ($event) {
                                return _vm.clickSearchResultInfo(
                                  $event,
                                  searchResult,
                                  index
                                )
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "material-icons-round" }, [
                              _vm._v("info"),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]
              )
            }),
            _vm.showLoadingMore
              ? _c("div", { staticClass: "loading-more-container" }, [
                  _c("img", {
                    attrs: { src: require("@/assets/img/loading.gif") },
                  }),
                ])
              : _vm._e(),
            _vm.hasMore && !_vm.showLoadingMore
              ? _c(
                  "button",
                  {
                    staticClass: "ripple",
                    attrs: { title: _vm.$t("searchResultPanel.moreTitle") },
                    on: { click: _vm.clickHasMore },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("searchResultPanel.more")) + " ")]
                )
              : _vm._e(),
            _vm.product === "map" &&
            !_vm.showLoading &&
            !_vm.showLoadingMore &&
            !(
              !_vm.showLoading &&
              _vm.searchResultList !== null &&
              _vm.searchResultList.length === 0
            ) &&
            !_vm.isDisableLoginFeature
              ? _c(
                  "a",
                  {
                    staticClass: "add-place",
                    on: { click: _vm.clickCreatePlace },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("searchResultPanel.addNewPlace")) +
                        " "
                    ),
                  ]
                )
              : _vm._e(),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }