var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        "explore-panel": !_vm.isLongdoMapV3,
        "explore-panel-map3": _vm.isLongdoMapV3,
        hide: _vm.isHidingExplorePanel,
        "expand-less": !_vm.isExpand,
      },
    },
    [
      _vm.exploreTagscrollPosition >= 50
        ? _c(
            "button",
            {
              staticClass: "btn-scroll-left",
              on: {
                click: function ($event) {
                  return _vm.scroll("left")
                },
              },
            },
            [_vm._v("«")]
          )
        : _vm._e(),
      _c(
        "div",
        {
          ref: "exploreTags",
          staticClass: "tag-list flash",
          on: { scroll: _vm.searchFromTagScrollHandler },
        },
        [
          _vm._l(_vm.flashExploreList, function (flashExplore) {
            return [
              _c(
                "button",
                {
                  key: "flash-tag-explore-" + flashExplore.name,
                  staticClass: "ripple",
                  class: {
                    active:
                      _vm.tagFilterList
                        .map(function (t) {
                          return t.toLowerCase()
                        })
                        .indexOf(flashExplore.name.toLowerCase()) !== -1,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.toggleTagFilter(flashExplore.name)
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      alt: "icon",
                      src: flashExplore.icon.x2,
                      srcset:
                        flashExplore.icon.x1 +
                        " 1x, " +
                        flashExplore.icon.x2 +
                        " 2x",
                    },
                  }),
                  _vm._v(
                    " " +
                      _vm._s(flashExplore.displayName[_vm.$i18n.locale]) +
                      " "
                  ),
                ]
              ),
            ]
          }),
        ],
        2
      ),
      ((460 + _vm.exploreTagscrollPosition < 850 &&
        _vm.width >= _vm.mobileMaxSize) ||
        (_vm.width + _vm.exploreTagscrollPosition < 848 &&
          _vm.width < _vm.mobileMaxSize)) &&
      _vm.isLongdoMapV3
        ? _c(
            "button",
            {
              staticClass: "btn-scroll-right",
              on: {
                click: function ($event) {
                  return _vm.scroll("right")
                },
              },
            },
            [_vm._v("»")]
          )
        : _vm._e(),
      _c(
        "button",
        { staticClass: "explore-area ripple", on: { click: _vm.explore } },
        [_c("i", { staticClass: "material-icons-round" }, [_vm._v("refresh")])]
      ),
      _c(
        "button",
        {
          staticClass: "more-tag",
          class: { active: _vm.isShowingMoreTagList },
          on: { click: _vm.clickMoreTag },
        },
        [
          _c("i", { staticClass: "material-icons-round" }, [
            _vm._v("filter_list"),
          ]),
          _vm.tagFilterList.length > 0
            ? _c("span", [_vm._v(" " + _vm._s(_vm.tagFilterList.length) + " ")])
            : _vm._e(),
        ]
      ),
      _vm.isShowingMoreTagList
        ? _c(
            "button",
            {
              staticClass: "filter-tag-name ripple",
              class: { active: _vm.isActiveFilterTagName },
              on: { click: _vm.onClickFilterTagNameIcon },
            },
            [
              _c("i", { staticClass: "material-icons-round" }, [
                _vm._v("search"),
              ]),
            ]
          )
        : _vm._e(),
      _vm.isShowingMoreTagList
        ? _c(
            "div",
            {
              staticClass: "filter-tag-name-input-container",
              class: { active: _vm.isActiveFilterTagName },
            },
            [
              _vm.filterTagName !== "" && _vm.isActiveFilterTagName
                ? _c(
                    "button",
                    {
                      staticClass: "clear ripple",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          _vm.filterTagName = ""
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "material-icons-round" }, [
                        _vm._v("clear"),
                      ]),
                    ]
                  )
                : _vm._e(),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.filterTagName,
                    expression: "filterTagName",
                  },
                ],
                ref: "filter-tag-name-input",
                attrs: {
                  type: "text",
                  placeholder: _vm.$t("explorePanel.filterTagName"),
                },
                domProps: { value: _vm.filterTagName },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.filterTagName = $event.target.value
                  },
                },
              }),
            ]
          )
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isShowingMoreTagList,
              expression: "isShowingMoreTagList",
            },
          ],
          staticClass: "tag-category-list",
          class: { "active-filter-tag-name": _vm.isActiveFilterTagName },
        },
        [
          _vm._l(_vm.tagCategoryList, function (tagCategory) {
            return [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        tagCategory.icons.filter(function (tag) {
                          return (
                            tag.name
                              .toLowerCase()
                              .indexOf(_vm.filterTagName.toLowerCase()) >= 0 ||
                            tag.name_en
                              .toLowerCase()
                              .indexOf(_vm.filterTagName.toLowerCase()) >= 0
                          )
                        }).length > 0,
                      expression:
                        "tagCategory.icons.filter(tag => {\n        return tag.name.toLowerCase().indexOf(filterTagName.toLowerCase()) >= 0 || tag.name_en.toLowerCase().indexOf(filterTagName.toLowerCase()) >= 0\n      }).length > 0",
                    },
                  ],
                  key: "more-tag-category-" + tagCategory.category_id,
                  staticClass: "tag-category",
                },
                [
                  _c("label", [
                    _vm._v(
                      " " +
                        _vm._s(
                          tagCategory["category_name_" + _vm.$i18n.locale]
                        ) +
                        " "
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "tag-list" },
                    [
                      _vm._l(tagCategory.icons, function (tag) {
                        return [
                          tag[
                            _vm.$i18n.locale === "th" ? "name" : "name_en"
                          ] !== ""
                            ? _c(
                                "button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        tag.name
                                          .toLowerCase()
                                          .indexOf(
                                            _vm.filterTagName.toLowerCase()
                                          ) >= 0 ||
                                        tag.name_en
                                          .toLowerCase()
                                          .indexOf(
                                            _vm.filterTagName.toLowerCase()
                                          ) >= 0,
                                      expression:
                                        "tag.name.toLowerCase().indexOf(filterTagName.toLowerCase()) >= 0 || tag.name_en.toLowerCase().indexOf(filterTagName.toLowerCase()) >= 0",
                                    },
                                  ],
                                  key: "more-tag-" + tag.id,
                                  staticClass: "tag ripple",
                                  class: {
                                    active:
                                      _vm.tagFilterList
                                        .map(function (t) {
                                          return t.toLowerCase()
                                        })
                                        .indexOf(tag.tag.toLowerCase()) !== -1,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.toggleTagFilter(tag.tag)
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      alt: "image-file",
                                      src: tag.imagefile,
                                      srcset:
                                        tag.imagefile.replace(
                                          "/icons_2x/",
                                          "/icons/"
                                        ) +
                                        " 1x, " +
                                        tag.imagefile +
                                        " 2x",
                                      loading: "lazy",
                                    },
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        tag[
                                          _vm.$i18n.locale === "th"
                                            ? "name"
                                            : "name_en"
                                        ]
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  ),
                ]
              ),
            ]
          }),
        ],
        2
      ),
      _c("div", { staticClass: "explore-container" }, [
        _c("div", { staticClass: "top-bar" }, [
          _vm.isExpand
            ? _c(
                "button",
                {
                  staticClass: "expand less ripple",
                  on: { click: _vm.clickExpandLess },
                },
                [
                  _c("i", { staticClass: "material-icons-round" }, [
                    _vm._v("expand_more"),
                  ]),
                ]
              )
            : _vm._e(),
          !_vm.isExpand
            ? _c(
                "button",
                {
                  staticClass: "expand more ripple",
                  on: { click: _vm.clickExpandMore },
                },
                [
                  _c("i", { staticClass: "material-icons-round" }, [
                    _vm._v("expand_less"),
                  ]),
                ]
              )
            : _vm._e(),
        ]),
        _vm.showLoading
          ? _c("div", { staticClass: "loading-container" }, [
              _c("img", {
                attrs: {
                  alt: "loading",
                  src: require("@/assets/img/loading.gif"),
                },
              }),
            ])
          : _vm._e(),
        !_vm.showLoading &&
        _vm.exploreResultList !== null &&
        _vm.exploreResultList.length === 0
          ? _c("div", { staticClass: "empty-container" }, [
              _vm._v(" " + _vm._s(_vm.$t("explorePanel.empty")) + " "),
            ])
          : _vm._e(),
        _c(
          "div",
          { ref: "explore-result-list", staticClass: "explore-result-list" },
          [
            _vm._l(_vm.exploreResultList, function (exploreResult, index) {
              return [
                _c(
                  "div",
                  {
                    key: index + "-" + exploreResult.id,
                    staticClass: "explore-result ripple",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "icon",
                        on: {
                          click: function ($event) {
                            return _vm.clickExploreResult(exploreResult)
                          },
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            alt: "markdown",
                            src: exploreResult.markicon2x,
                            srcset:
                              exploreResult.markicon +
                              " 1x, " +
                              exploreResult.markicon2x +
                              " 2x",
                            loading: "lazy",
                          },
                        }),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "detail",
                        on: {
                          click: function ($event) {
                            return _vm.clickExploreResult(exploreResult)
                          },
                        },
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "name",
                            attrs: { title: exploreResult.name },
                          },
                          [_vm._v(_vm._s(exploreResult.name))]
                        ),
                        _c(
                          "label",
                          {
                            staticClass: "short-description",
                            attrs: { title: exploreResult.shortdesc },
                          },
                          [_vm._v(_vm._s(exploreResult.address))]
                        ),
                      ]
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "info",
                        on: {
                          click: function ($event) {
                            return _vm.clickExploreResultInfo(exploreResult)
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "material-icons-round" }, [
                          _vm._v("info"),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]
            }),
            _vm.showLoadingMore
              ? _c("div", { staticClass: "loading-more-container" }, [
                  _c("img", {
                    attrs: {
                      alt: "loading",
                      src: require("@/assets/img/loading.gif"),
                    },
                  }),
                ])
              : _vm._e(),
            _vm.hasMore && !_vm.showLoadingMore
              ? _c(
                  "button",
                  {
                    staticClass: "ripple",
                    attrs: { title: _vm.$t("explorePanel.moreTitle") },
                    on: { click: _vm.clickHasMore },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("explorePanel.more")) + " ")]
                )
              : _vm._e(),
            _vm.product === "map" &&
            !_vm.showLoading &&
            !_vm.showLoadingMore &&
            !(
              !_vm.showLoading &&
              _vm.exploreResultList !== null &&
              _vm.exploreResultList.length === 0
            ) &&
            !_vm.isDisableLoginFeature
              ? _c(
                  "a",
                  {
                    staticClass: "add-place",
                    on: { click: _vm.clickCreatePlace },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("explorePanel.addNewPlace")) + " "
                    ),
                  ]
                )
              : _vm._e(),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }