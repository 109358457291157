<template>
  <div :class="{
    'explore-panel': !isLongdoMapV3,
    'explore-panel-map3': isLongdoMapV3,
    'hide': isHidingExplorePanel,
    'expand-less': !isExpand
  }">
    <button class="btn-scroll-left" @click="scroll('left')" v-if="(exploreTagscrollPosition >= 50) " >&laquo;</button>
    <div ref="exploreTags" @scroll="searchFromTagScrollHandler" class="tag-list flash">
      <template v-for="flashExplore in flashExploreList">
        <button :key="'flash-tag-explore-' + flashExplore.name" @click="toggleTagFilter(flashExplore.name)" class="ripple"
          :class="{ active: tagFilterList.map(t => t.toLowerCase()).indexOf(flashExplore.name.toLowerCase()) !== -1 }">
          <img alt="icon" :src="flashExplore.icon.x2" :srcset="flashExplore.icon.x1 + ' 1x, ' + flashExplore.icon.x2 + ' 2x'">
          {{ flashExplore.displayName[$i18n.locale] }}
        </button>
      </template>
    </div>
    <button class="btn-scroll-right" @click="scroll('right')" v-if="((460 + exploreTagscrollPosition < 850 && width >= mobileMaxSize ) || (width + exploreTagscrollPosition < 848 && width < mobileMaxSize )) && isLongdoMapV3">&raquo;</button>
    <button class="explore-area ripple" @click="explore">
      <i class="material-icons-round">refresh</i>
    </button>
    <button class="more-tag" :class="{ active: isShowingMoreTagList }" @click="clickMoreTag">
      <i class="material-icons-round">filter_list</i>
      <span v-if="tagFilterList.length > 0">
        {{ tagFilterList.length }}
      </span>
    </button>
    <button class="filter-tag-name ripple" v-if="isShowingMoreTagList" :class="{ active: isActiveFilterTagName }"
      @click="onClickFilterTagNameIcon">
      <i class="material-icons-round">search</i>
    </button>
    <div class="filter-tag-name-input-container" v-if="isShowingMoreTagList" :class="{ active: isActiveFilterTagName }">
      <button class="clear ripple" v-if="filterTagName !== '' && isActiveFilterTagName" @click.stop="filterTagName = ''">
        <i class="material-icons-round">clear</i>
      </button>
      <input ref="filter-tag-name-input" type="text" v-model="filterTagName"
        :placeholder="$t('explorePanel.filterTagName')">
    </div>
    <div class="tag-category-list" v-show="isShowingMoreTagList"
      :class="{ 'active-filter-tag-name': isActiveFilterTagName }">
      <template v-for="tagCategory in tagCategoryList">
        <div class="tag-category" :key="'more-tag-category-' + tagCategory.category_id" v-show="tagCategory.icons.filter(tag => {
          return tag.name.toLowerCase().indexOf(filterTagName.toLowerCase()) >= 0 || tag.name_en.toLowerCase().indexOf(filterTagName.toLowerCase()) >= 0
        }).length > 0">
          <label>
            {{ tagCategory['category_name_' + $i18n.locale] }}
          </label>
          <div class="tag-list">
            <template v-for="tag in tagCategory.icons">
              <button class="tag ripple" :key="'more-tag-' + tag.id"
                :class="{ active: tagFilterList.map(t => t.toLowerCase()).indexOf(tag.tag.toLowerCase()) !== -1 }"
                @click="toggleTagFilter(tag.tag)"
                v-show="tag.name.toLowerCase().indexOf(filterTagName.toLowerCase()) >= 0 || tag.name_en.toLowerCase().indexOf(filterTagName.toLowerCase()) >= 0"
                v-if="tag[$i18n.locale === 'th' ? 'name' : 'name_en'] !== ''"
              >
                <img alt="image-file" :src="tag.imagefile"
                  :srcset="tag.imagefile.replace('/icons_2x/', '/icons/') + ' 1x, ' + tag.imagefile + ' 2x'"
                  loading="lazy">
                {{ tag[$i18n.locale === 'th' ? 'name' : 'name_en'] }}
              </button>
            </template>
          </div>
        </div>
      </template>
    </div>
    <div class="explore-container">
      <div class="top-bar">
        <button class="expand less ripple" v-if="isExpand" @click="clickExpandLess">
          <i class="material-icons-round">expand_more</i>
        </button>
        <button class="expand more ripple" v-if="!isExpand" @click="clickExpandMore">
          <i class="material-icons-round">expand_less</i>
        </button>
      </div>
      <div class="loading-container" v-if="showLoading">
        <img alt="loading" src="@/assets/img/loading.gif">
      </div>
      <div class="empty-container" v-if="!showLoading && exploreResultList !== null && exploreResultList.length === 0">
        {{ $t('explorePanel.empty') }}
      </div>
      <div ref="explore-result-list" class="explore-result-list">
        <template v-for="(exploreResult, index) in exploreResultList">
          <div :key="index + '-' + exploreResult.id" class="explore-result ripple">
            <div class="icon" @click="clickExploreResult(exploreResult)">
              <img alt="markdown" :src="exploreResult.markicon2x"
                :srcset="exploreResult.markicon + ' 1x, ' + exploreResult.markicon2x + ' 2x'" loading="lazy">
            </div>
            <div class="detail" @click="clickExploreResult(exploreResult)">
              <label class="name" :title="exploreResult.name">{{ exploreResult.name }}</label>
              <label class="short-description" :title="exploreResult.shortdesc">{{ exploreResult.address }}</label>
            </div>
            <a class="info" @click="clickExploreResultInfo(exploreResult)">
              <i class="material-icons-round">info</i>
            </a>
          </div>
        </template>
        <div class="loading-more-container" v-if="showLoadingMore">
          <img alt="loading" src="@/assets/img/loading.gif">
        </div>
        <button v-if="hasMore && !showLoadingMore" :title="$t('explorePanel.moreTitle')" @click="clickHasMore"
          class="ripple">
          {{ $t('explorePanel.more') }}
        </button>
        <a @click="clickCreatePlace" class="add-place"
          v-if="product === 'map' && !showLoading && !showLoadingMore && !(!showLoading && exploreResultList !== null && exploreResultList.length === 0) && !isDisableLoginFeature">
          {{ $t('explorePanel.addNewPlace') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExplorePanel',
  props: {
    map: Object
  },
  data () {
    return {
      isExpand: true,
      exploreResultList: null,
      showLoading: false,
      showLoadingMore: false,
      hasMore: false,
      width: window.innerWidth,
      exploreLocation: {
        lat: localStorage.lat ? Number(localStorage.lat) : Number(process.env.VUE_APP_DEFAULT_LAT),
        lon: localStorage.lon ? Number(localStorage.lon) : Number(process.env.VUE_APP_DEFAULT_LON)
      },
      offset: 0,
      limit: 20,
      cacheData: [],
      tagFilterList: [],
      isShowingMapOnly: false,
      isShowingMoreTagList: false,
      flashExploreList: [],
      isActiveFilterTagName: false,
      filterTagName: '',
      exploreTagscrollPosition: 0
    }
  },
  computed: {
    isHidingExplorePanel () {
      const self = this
      const isDesktop = self.width >= 768
      return self.$route.hash !== '#explore' || (self.isShowingMapOnly && !isDesktop)
    },
    // isHidingDesktopExplorePanel () {
    //   const self = this
    //   return self.$route.hash !== '#explore'
    // },
    isDisableLoginFeature () {
      const self = this
      if (self.lji === null) {
        return false
      }
      return self.lji.Util.isIosNativeApp()
    }
  },
  async created () {
    const self = this
    self.flashExploreList = (await self.api.getFlashExploreList()).data
  },
  mounted () {
    const self = this
    self.$parent.$on('clickMap', self.onClickMap)
    self.$root.$on('resize', (size) => {
      self.width = size.width
    })
    if (self.$route.hash === '#explore') {
      self.explore()
    }
    self.$root.$emit('changeHidingExplorePanel', self.isHidingExplorePanel)
    self.$root.$emit('changeExpandExplorePanel', self.isExpand)
  },
  methods: {
    clickCreatePlace () {
      const self = this
      if (self.isLongdoMapV3 && self.product !== 'traffic') {
        window.location = `${window.location.origin}${process.env.VUE_APP_BASE_URL}create?map=v2&lat=${localStorage.lat}&lon=${localStorage.lon}`
      } else {
        self.$router.push({
          name: 'CreatePlace',
          params: {},
          query: {
            lat: Number(localStorage.lat),
            lon: Number(localStorage.lon)
          },
          hash: ''
        })
      }
    },
    onClickMap (isShowingMapOnly) {
      const self = this
      self.isShowingMapOnly = isShowingMapOnly
    },
    clickExpandLess () {
      const self = this
      self.isExpand = false
    },
    clickExpandMore () {
      const self = this
      self.isExpand = true
    },
    clickExploreResult (exploreResult) {
      const self = this
      self.$emit('clickExploreResult', exploreResult, false)
    },
    clickExploreResultInfo (exploreResult) {
      const self = this
      self.$emit('clickExploreResult', exploreResult, true)
    },
    clickHasMore () {
      const self = this
      self.exploreMore()
    },
    async explore () {
      const self = this
      self.$emit('beforeExplore')
      self.showLoading = true
      self.exploreLocation = {
        lat: self.$route.query.lat ? Number(self.$route.query.lat) : Number(process.env.VUE_APP_DEFAULT_LAT),
        lon: self.$route.query.lon ? Number(self.$route.query.lon) : Number(process.env.VUE_APP_DEFAULT_LON)
      }
      self.offset = 0
      const params = {
        lat: self.exploreLocation.lat,
        lon: self.exploreLocation.lon,
        offset: self.offset,
        limit: self.limit,
        locale: self.$i18n.locale,
        dataset: 'poi-changed-today,poi-contrib,main2p,osm-points,overture2p'
      }
      if (self.tagFilterList.length > 0) {
        params.tag = self.tagFilterList.join(',')
      }
      const cacheKey = JSON.stringify(params)
      const cacheIndex = self.cacheData.map(c => c.key).indexOf(cacheKey)
      let result
      if (cacheIndex !== -1) {
        result = self.cacheData[cacheIndex].value
      } else {
        result = await self.api.searchNearbyPlace(params)
        result.data.data.forEach(item => {
          item.markicon = process.env.VUE_APP_LONGDO_MAP_ICON + item.icon
          item.markicon2x = process.env.VUE_APP_LONGDO_MAP_HD_ICON + item.icon
        })
        self.cacheData.push({
          key: cacheKey,
          value: result
        })
      }
      // Zoom map
      if (self.map.zoom() <= 14) {
        self.map.location({ lon: self.exploreLocation.lon, lat: self.exploreLocation.lat }, true)
        self.map.zoom(14, true)
      }
      self.exploreResultList = result.data.data
      self.hasMore = result.data.meta.hasmore
      self.showLoading = false
      self.$refs['explore-result-list'].scrollTop = 0
      self.$emit('exploreResult', result.data)
    },
    async exploreMore () {
      const self = this
      self.$emit('beforeExploreMore')
      self.showLoadingMore = true
      self.offset += self.limit
      const params = {
        lat: self.exploreLocation.lat,
        lon: self.exploreLocation.lon,
        offset: self.offset,
        limit: self.limit,
        locale: self.$i18n.locale,
        dataset: 'poi-changed-today,poi-contrib,main2p,osm-points,overture2p'
      }
      if (self.tagFilterList.length > 0) {
        params.tag = self.tagFilterList.join(',')
      }
      const cacheKey = JSON.stringify(params)
      const cacheIndex = self.cacheData.map(c => c.key).indexOf(cacheKey)
      let result
      if (cacheIndex !== -1) {
        result = self.cacheData[cacheIndex].value
      } else {
        result = await self.api.searchNearbyPlace(params)
        result.data.data.forEach(item => {
          item.markicon = process.env.VUE_APP_LONGDO_MAP_ICON + item.icon
          item.markicon2x = process.env.VUE_APP_LONGDO_MAP_HD_ICON + item.icon
        })
        self.cacheData.push({
          key: cacheKey,
          value: result
        })
      }
      self.exploreResultList = self.exploreResultList.concat(result.data.data)
      self.hasMore = result.data.meta.hasmore
      self.showLoadingMore = false
      self.$emit('exploreMoreResult', result.data)
    },
    toggleTagFilter (tagName, isActive) {
      const self = this
      const index = self.tagFilterList.map(t => t.toLowerCase()).indexOf(tagName.toLowerCase())
      if (index === -1) {
        if (isActive === undefined || isActive === null || isActive === true) {
          self.tagFilterList.push(tagName)
          self.storeEventToNativeAnalytic('explore_tag_filter_on', {
            tagName: tagName
          })
        }
      } else {
        if (isActive === undefined || isActive === null || isActive === false) {
          self.tagFilterList.splice(index, 1)
          self.storeEventToNativeAnalytic('explore_tag_filter_off', {
            tagName: tagName
          })
        }
      }
      self.explore()
    },
    clickMoreTag () {
      const self = this
      self.isShowingMoreTagList = !self.isShowingMoreTagList
    },
    onClickFilterTagNameIcon () {
      const self = this
      self.isActiveFilterTagName = !self.isActiveFilterTagName
      if (self.isActiveFilterTagName) {
        self.$refs['filter-tag-name-input'].focus()
      }
    },
    searchFromTagScrollHandler () {
      const self = this
      const exploreTags = self.$refs.exploreTags
      self.exploreTagscrollPosition = exploreTags.scrollLeft
    },
    scroll (direction) {
      const self = this
      const exploreTags = self.$refs.exploreTags
      switch (direction) {
        case 'left':
          exploreTags.scrollLeft = exploreTags.scrollLeft - 100
          self.exploreTagscrollPosition = exploreTags.scrollLeft
          break
        case 'right':
          if (self.width > 460) {
            exploreTags.scrollLeft = exploreTags.scrollLeft + 100 + 460 > 850 ? 400 : exploreTags.scrollLeft + 100
          } else {
            exploreTags.scrollLeft = exploreTags.scrollLeft + 100 + self.width > 850 ? 850 - self.width : exploreTags.scrollLeft + 100
          }
          self.exploreTagscrollPosition = exploreTags.scrollLeft
          break
      }
    }
  },
  watch: {
    isHidingExplorePanel () {
      const self = this
      self.$nextTick(() => {
        if (!self.isHidingExplorePanel) {
          setTimeout(() => {
            self.explore()
          }, 300)
        } else {
          self.isShowingMoreTagList = false
        }
        self.$root.$emit('changeHidingExplorePanel', self.isHidingExplorePanel)
        if (!self.isHidingExplorePanel && self.isExpand) {
          const el = document.querySelector('.tag-list > button.active')
          if (el) {
            el.scrollIntoView(false)
          }
        }
      })
    },
    isExpand () {
      const self = this
      self.$nextTick(() => {
        self.$root.$emit('changeExpandExplorePanel', self.isExpand)
        if (!self.isHidingExplorePanel && self.isExpand) {
          const el = document.querySelector('.tag-list > button.active')
          if (el) {
            el.scrollIntoView(false)
          }
        }
      })
    },
    tagFilterList () {
      const self = this
      self.$nextTick(() => {
        const el = document.querySelector('.tag-list > button.active')
        if (el) {
          el.scrollIntoView(false)
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
$explorePanelHeight: 40%;
$topBarHeight: 32px;

.explore-panel {
  width: 100%;
  height: $explorePanelHeight;
  border-radius: 12px 12px 0 0;
  background-color: rgb(255, 255, 255);
  padding: 3px 3px calc(#{$toolBarHeight} + 3px) 0px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.2);
  z-index: 90;
  position: fixed;
  bottom: 0;
  left: 0;
  transition: bottom 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;

  >button.explore-area {
    position: absolute;
    top: calc(0px - 38px - 36px - 6px);
    right: 48px;
    width: 36px;
    height: 36px;
    padding: 0;
    border-radius: 50%;
    border: none;
    background-color: rgba(255, 255, 255, 0.95);
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;

    >i {
      color: $primary;
      font-size: 24px;
    }
  }

  >button.more-tag {
    position: absolute;
    top: calc(0px - 38px - 36px - 6px);
    right: 6px;
    width: 36px;
    height: 36px;
    padding: 0;
    border-radius: 50%;
    border: none;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;

    >i {
      font-size: 24px;
      color: $grey;
    }

    >span {
      position: absolute;
      box-sizing: border-box;
      top: 0;
      left: 0;
      padding: 0;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: rgb(255, 255, 255);
      font-size: 10px;
      transform: translate(-35%, -35%);
      box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
      font-family: "Prompt",serif;
    }
  }

  >button.more-tag.active {
    background-color: $primary;

    >i {
      color: rgb(255, 255, 255);
    }

    >span {
      background-color: $primary;
      color: rgb(255, 255, 255);
    }
  }

  >.tag-list {
    position: absolute;
    top: -38px;
    left: 0px;
    width: 100%;
    height: 38px;
    padding: 2px 3px 6px 3px;
    box-sizing: border-box;
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    white-space: nowrap;
    transition: top 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;

    >button {
      height: 30px;
      padding: 0px 12px;
      box-sizing: border-box;
      border: 2px solid white;
      border-radius: 15px;
      flex: 0 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-size: 13px;
      margin: 0 3px;
      box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
      background-color: rgb(255, 255, 255);
      cursor: pointer;
      outline: none;
      user-select: none;
      filter: grayscale(0.85);
      color: rgba(40, 40, 40, 0.7);
      transition: box-shadow 0.3s ease 0s,
        background-color 0.3s ease 0s,
        border 0.3s ease 0s;
      font-family: 'Prompt', serif;

      >img {
        height: 16px;
        padding: 0;
        box-sizing: border-box;
        margin-right: 6px;
        opacity: 0.7;
      }
    }

    >button.active {
      color: white;
      filter: grayscale(0);
      box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.3);
      background-color: $darkPrimary;
      border: 2px solid $primary;

      >img {
        opacity: 1;
        filter: drop-shadow(0px 0px 2px white);
      }
    }
  }

  >.tag-list::-webkit-scrollbar {
    display: none;
  }

  >button.filter-tag-name {
    position: absolute;
    top: calc(12px - 38px);
    right: 12px;
    width: 36px;
    height: 36px;
    padding: 0;
    border-radius: 50%;
    border: none;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
    z-index: 4;
    transform: translate(0%, 0%);
    transition: right 0.3s ease 0s;

    >i {
      font-size: 24px;
      color: $grey;
    }

    &.active {
      right: calc(100% - 12px - 36px);
      box-shadow: none;

      i {
        color: $primary;
      }
    }
  }

  >.filter-tag-name-input-container {
    position: absolute;
    top: calc(12px - 38px - 1px);
    right: 12px;
    width: 0;
    height: 38px;
    padding: 0;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
    z-index: 3;
    transition: width 0.3s ease 0s;

    &.active {
      width: calc(100% - 12px - 12px);

      input {
        padding: 0 38px 0px 38px;
        box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
      }
    }

    input {
      width: 100%;
      height: 100%;
      padding: 0 0;
      outline: none;
      font-size: 16px;
      line-height: 36px;
      border: none;
      box-sizing: border-box;
      border-radius: 18px;
    }

    button.clear {
      position: absolute;
      top: 0;
      right: 0;
      border: none;
      width: 38px;
      height: 38px;
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        color: $grey;
      }
    }
  }

  >.tag-category-list {
    width: 100%;
    height: calc(100% - 54px - 3px + 38px + 3px);
    background-color: rgb(255, 255, 255);
    padding: 0 0 0 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 12px 12px 0 0;
    position: absolute;
    top: -38px;
    left: 0;
    overflow-y: auto;
    z-index: 2;
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.2);
    transition: padding-top 0.3s ease 0s;

    &.active-filter-tag-name {
      padding-top: calc(12px + 38px);
    }

    >.tag-category {
      padding: 12px;

      >label {
        font-weight: bold;
      }

      >.tag-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        margin-top: 12px;

        >button.tag {
          height: 30px;
          padding: 6px 12px;
          box-sizing: border-box;
          border: none;
          border-radius: 15px;
          flex: 0 0 auto;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          font-size: 13px;
          margin: 6px;
          box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.15);
          background-color: rgb(255, 255, 255);
          cursor: pointer;
          outline: none;
          user-select: none;
          filter: grayscale(0.85);
          color: rgba(40, 40, 40, 0.7);

          >img {
            max-height: 16px;
            max-width: 16px;
            padding: 0;
            box-sizing: border-box;
            margin-right: 6px;
            opacity: 0.7;
          }
        }

        >button.tag.active {
          color: white;
          filter: grayscale(0);
          box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.3);
          background-color: $darkPrimary;
          border: 2px solid $primary;

          >img {
            opacity: 1;
            filter: drop-shadow(0px 0px 2px white);
          }
        }
      }
    }
  }

  >.explore-container {
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    padding: 0 0 0 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 12px 12px 0 0;
    z-index: 1;

    >.top-bar {
      width: 100%;
      height: $topBarHeight;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0 3px 0 3px;
      box-sizing: border-box;
      position: relative;

      >button.expand {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border: none;
        background-color: transparent;
        outline: none;
        height: 100%;
        padding: 0px 12px;

        >i {
          color: rgb(168, 168, 168);
          border: 1px solid rgb(168, 168, 168);
          border-radius: 50%;
          font-size: 18px;
          margin: 0px 0px 0px 0px;
        }
      }
    }

    >.loading-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      box-sizing: border-box;
      height: calc(100% - #{$topBarHeight} - 54px);
      width: 100%;
      position: absolute;
      top: $topBarHeight;
      left: 0;
    }

    >.empty-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      box-sizing: border-box;
      height: calc(100% - #{$topBarHeight} - 54px);
      width: 100%;
      position: absolute;
      top: $topBarHeight;
      left: 0;
      font-size: 16px;
    }

    >.explore-result-list {
      display: block;
      overflow: hidden;
      overflow-y: auto;
      box-sizing: border-box;
      height: calc(100% - #{$topBarHeight});

      >.explore-result {
        $iconResultWidth: 48px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        padding: 9px 0;
        cursor: pointer;

        &:first-child {
          padding-top: 0px;
        }

        >.icon {
          width: $iconResultWidth;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          align-self: flex-start;
          padding-top: 3px;

          >img {
            max-width: 36px;
            min-width: 16px;
          }
        }

        >.detail {
          display: flex;
          flex-direction: column;
          width: calc(100% - #{$iconResultWidth} - 36px);

          >.name {
            width: 100%;
            cursor: pointer;
            font-size: 16px;
            font-family: "Prompt", serif;
          }

          >.short-description {
            width: 100%;
            font-size: 14px;
            color: $grey;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            cursor: pointer;
          }
        }

        >.info {
          width: 36px;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          text-decoration: none;

          >i {
            color: $lightPrimary;
            font-size: 24px;
          }
        }
      }

      >.loading-more-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 6px;
      }

      a.add-place {
        color: $primary;
        text-decoration: none;
        font-family: "Prompt",serif;
        text-align: center;
        margin: 12px auto;
        display: block;
        font-size: 14px;
      }

      >button {
        width: calc(100% - 12px);
        border: none;
        border-radius: 3px;
        font-size: 14px;
        padding: 12px 6px;
        margin: 6px;
        cursor: pointer;
        outline: none;
        color: rgb(255, 255, 255);
        background-color: $primary;
        transition: background-color 0.3s ease 0s;
      }

      >button:hover {
        background-color: $darkPrimary;
      }
    }
  }
}

.explore-panel.expand-less {
  bottom: calc(#{$topBarHeight} + #{$toolBarHeight} + 2px - #{$explorePanelHeight});

  >button.explore-area {
    top: calc(#{$topBarHeight} + #{$toolBarHeight});
  }

  >button.more-tag {
    top: calc(#{$topBarHeight} + #{$toolBarHeight});
  }

  >.tag-list {
    top: calc(#{$topBarHeight} + #{$toolBarHeight});
  }
}

.explore-panel.hide {
  bottom: -#{$explorePanelHeight};
  box-shadow: none;

  >button.explore-area {
    top: calc(#{$topBarHeight} + #{$toolBarHeight});
  }

  >button.more-tag {
    top: calc(#{$topBarHeight} + #{$toolBarHeight});
  }

  >.tag-list {
    top: calc(#{$topBarHeight} + #{$toolBarHeight});
  }
}

.explore-panel.hide.expand-less {
  bottom: calc(0px - #{$explorePanelHeight} - 12px);
}

.explore-panel-map3 {

  @media only screen and (min-width: $mobileMaxSize) {

    top: 140px;
    width: 460px;
    height: 60%;
    border-radius: 12px;
    background-color: white;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
    z-index: 90;
    position: fixed;
    left: 10px;
    -webkit-transition: right 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
    transition: right 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;

    .tag-list {
      scrollbar-width: none;
      display: none;
      cursor: none;
    }

    .btn-scroll-left {
      top: -43px;
      left: 0;
      position: absolute;
      z-index: 100;
      height: 30px;
      padding: 3px 12px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      border: 2px solid white;
      border-radius: 15px;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      font-size: 1.5em;
      margin: 7px 3px 0px;
      -webkit-box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
      background-color: white;
      cursor: pointer;
      outline: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      color: rgba(40, 40, 40, 0.7);
      -webkit-transition: background-color 0.3s ease 0s, border 0.3s ease 0s, -webkit-box-shadow 0.3s ease 0s;
      //transition: background-color 0.3s ease 0s, border 0.3s ease 0s, -webkit-box-shadow 0.3s ease 0s;
      //transition: box-shadow 0.3s ease 0s, background-color 0.3s ease 0s, border 0.3s ease 0s;
      transition: box-shadow 0.3s ease 0s, background-color 0.3s ease 0s, border 0.3s ease 0s, -webkit-box-shadow 0.3s ease 0s;
      font-family: "Prompt",serif;
      &.hide {
        pointer-events: none;
        transition: opacity 0.3s ease 0s;
        opacity: 0;
      }
    }

    .btn-scroll-right {
      top: -43px;
      position: absolute;
      left: 420px;
      z-index: 100;
      height: 30px;
      padding: 0px 12px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      border: 2px solid white;
      border-radius: 15px;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      font-size: 1.5em;
      margin: 7px 3px 0px;
      -webkit-box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
      background-color: white;
      cursor: pointer;
      outline: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      color: rgba(40, 40, 40, 0.7);
      -webkit-transition: background-color 0.3s ease 0s, border 0.3s ease 0s, -webkit-box-shadow 0.3s ease 0s;
      //transition: background-color 0.3s ease 0s, border 0.3s ease 0s, -webkit-box-shadow 0.3s ease 0s;
      //transition: box-shadow 0.3s ease 0s, background-color 0.3s ease 0s, border 0.3s ease 0s;
      transition: box-shadow 0.3s ease 0s, background-color 0.3s ease 0s, border 0.3s ease 0s, -webkit-box-shadow 0.3s ease 0s;
      font-family: "Prompt", serif;
      &.hide {
        pointer-events: none;
        transition: opacity 0.3s ease 0s;
        opacity: 0;
      }
    }

  }

  @media only screen and (max-width: $mobileMaxSize) {
    width: 100%;
    height: $explorePanelHeight;
    border-radius: 12px 12px 0 0;
    background-color: rgb(255, 255, 255);
    padding: 3px 3px calc(#{$toolBarHeight} + 3px) 0px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.2);
    z-index: 90;
    position: fixed;
    bottom: 0;
    left: 0;
    transition: bottom 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;

    .btn-scroll-left {
      top: -43px;
      left: 0px;
      position: absolute;
      z-index: 100;
      height: 30px;
      padding: 3px 12px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      border: 2px solid white;
      border-radius: 15px;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      font-size: 1.5em;
      margin: 7px 3px 0;
      -webkit-box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
      background-color: white;
      cursor: pointer;
      outline: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      color: rgba(40, 40, 40, 0.7);
      -webkit-transition: background-color 0.3s ease 0s, border 0.3s ease 0s, -webkit-box-shadow 0.3s ease 0s;
      //transition: background-color 0.3s ease 0s, border 0.3s ease 0s, -webkit-box-shadow 0.3s ease 0s;
      //transition: box-shadow 0.3s ease 0s, background-color 0.3s ease 0s, border 0.3s ease 0s;
      transition: box-shadow 0.3s ease 0s, background-color 0.3s ease 0s, border 0.3s ease 0s, -webkit-box-shadow 0.3s ease 0s;
      font-family: "Prompt", serif;
      &.hide {
        pointer-events: none;
        transition: opacity 0.3s ease 0s;
        opacity: 0;
      }
    }

    .btn-scroll-right {
      top: -43px;
      position: absolute;
      right: 0;
      z-index: 100;
      height: 30px;
      padding: 0 12px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      border: 2px solid white;
      border-radius: 15px;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      font-size: 1.5em;
      margin: 7px 3px 0px;
      -webkit-box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
      background-color: white;
      cursor: pointer;
      outline: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      color: rgba(40, 40, 40, 0.7);
      -webkit-transition: background-color 0.3s ease 0s, border 0.3s ease 0s, -webkit-box-shadow 0.3s ease 0s;
      //transition: background-color 0.3s ease 0s, border 0.3s ease 0s, -webkit-box-shadow 0.3s ease 0s;
      //transition: box-shadow 0.3s ease 0s, background-color 0.3s ease 0s, border 0.3s ease 0s;
      transition: box-shadow 0.3s ease 0s, background-color 0.3s ease 0s, border 0.3s ease 0s, -webkit-box-shadow 0.3s ease 0s;
      font-family: "Prompt", serif;
      &.hide {
        pointer-events: none;
        transition: opacity 0.3s ease 0s;
        opacity: 0;
      }
    }

  }

  @media only screen and (min-width: $mobileMaxSize) {
    >button.explore-area {
      position: absolute;
      top: calc(0px - 38px - 36px - 15px);
      left: 470px;
      width: 36px;
      height: 36px;
      padding: 0px;
      border-radius: 50%;
      border: none;
      background-color: rgba(255, 255, 255, 0.95);
      box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      user-select: none;

      >i {
        color: $primary;
        font-size: 24px;
      }
    }

    >button.more-tag {
      position: absolute;
      top: calc(0px - 38px - 36px - 15px);
      left: 510px;
      width: 36px;
      height: 36px;
      padding: 0px;
      border-radius: 50%;
      border: none;
      background-color: rgb(255, 255, 255);
      box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      user-select: none;

      >i {
        font-size: 24px;
        color: $grey;
      }

      >span {
        position: absolute;
        box-sizing: border-box;
        top: 0;
        left: 0;
        padding: 0;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: rgb(255, 255, 255);
        font-size: 10px;
        transform: translate(-35%, -35%);
        box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
        font-family: "Prompt", serif;
      }
    }

    >button.more-tag.active {
      background-color: $primary;

      >i {
        color: rgb(255, 255, 255);
      }

      >span {
        background-color: $primary;
        color: rgb(255, 255, 255);
      }
    }
  }

  @media only screen and (max-width: $mobileMaxSize) {
    >button.explore-area {
      position: absolute;
      top: calc(0px - 38px - 36px - 6px);
      right: 48px;
      width: 36px;
      height: 36px;
      padding: 0px;
      border-radius: 50%;
      border: none;
      background-color: rgba(255, 255, 255, 0.95);
      box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      user-select: none;

      >i {
        color: $primary;
        font-size: 24px;
      }
    }

    >button.more-tag {
      position: absolute;
      top: calc(0px - 38px - 36px - 6px);
      right: 6px;
      width: 36px;
      height: 36px;
      padding: 0;
      border-radius: 50%;
      border: none;
      background-color: rgb(255, 255, 255);
      box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      user-select: none;

      >i {
        font-size: 24px;
        color: $grey;
      }

      >span {
        position: absolute;
        box-sizing: border-box;
        top: 0;
        left: 0;
        padding: 0;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: rgb(255, 255, 255);
        font-size: 10px;
        transform: translate(-35%, -35%);
        box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
        font-family: "Prompt", serif;
      }
    }

    >button.more-tag.active {
      background-color: $primary;

      >i {
        color: rgb(255, 255, 255);
      }

      >span {
        background-color: $primary;
        color: rgb(255, 255, 255);
      }
    }
  }

  >.tag-list {
    position: absolute;
    top: -38px;
    left: 0px;
    width: 100%;
    height: 38px;
    padding: 2px 3px 6px 3px;
    box-sizing: border-box;
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    white-space: nowrap;
    transition: top 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;

    >button {
      height: 30px;
      padding: 0px 12px;
      box-sizing: border-box;
      border: 2px solid white;
      border-radius: 15px;
      flex: 0 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-size: 13px;
      margin: 0 3px;
      box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
      background-color: rgb(255, 255, 255);
      cursor: pointer;
      outline: none;
      user-select: none;
      filter: grayscale(0.85);
      color: rgba(40, 40, 40, 0.7);
      transition: box-shadow 0.3s ease 0s,
        background-color 0.3s ease 0s,
        border 0.3s ease 0s;
      font-family: "Prompt", serif;

      >img {
        height: 16px;
        padding: 0;
        box-sizing: border-box;
        margin-right: 6px;
        opacity: 0.7;
      }
    }

    >button.active {
      color: white;
      filter: grayscale(0);
      box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.3);
      background-color: $darkPrimary;
      border: 2px solid $primary;

      >img {
        opacity: 1;
        filter: drop-shadow(0px 0px 2px white);
      }
    }
  }

  >.tag-list::-webkit-scrollbar {
    display: none;
  }

  >button.filter-tag-name {
    position: absolute;
    top: calc(12px - 38px);
    right: 12px;
    width: 36px;
    height: 36px;
    padding: 0px;
    border-radius: 50%;
    border: none;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
    z-index: 4;
    transform: translate(0%, 0%);
    transition: right 0.3s ease 0s;

    >i {
      font-size: 24px;
      color: $grey;
    }

    &.active {
      right: calc(100% - 12px - 36px);
      box-shadow: none;

      i {
        color: $primary;
      }
    }
  }

  >.filter-tag-name-input-container {
    position: absolute;
    top: calc(12px - 38px - 1px);
    right: 12px;
    width: 0px;
    height: 38px;
    padding: 0px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
    z-index: 3;
    transition: width 0.3s ease 0s;

    &.active {
      width: calc(100% - 12px - 12px);

      input {
        padding: 0px 38px 0px 38px;
        box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
      }
    }

    input {
      width: 100%;
      height: 100%;
      padding: 0 0;
      outline: none;
      font-size: 16px;
      line-height: 36px;
      border: none;
      box-sizing: border-box;
      border-radius: 18px;
    }

    button.clear {
      position: absolute;
      top: 0;
      right: 0;
      border: none;
      width: 38px;
      height: 38px;
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        color: $grey;
      }
    }
  }

  >.tag-category-list {
    width: 100%;
    height: calc(100% - 54px - 3px + 38px + 3px);
    background-color: rgb(255, 255, 255);
    padding: 0 0 0 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 12px 12px 0 0;
    position: absolute;
    top: -38px;
    left: 0;
    overflow-y: auto;
    z-index: 2;
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.2);
    transition: padding-top 0.3s ease 0s;

    &.active-filter-tag-name {
      padding-top: calc(12px + 38px);
    }

    >.tag-category {
      padding: 12px;

      >label {
        font-weight: bold;
      }

      >.tag-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        margin-top: 12px;

        >button.tag {
          height: 30px;
          padding: 6px 12px;
          box-sizing: border-box;
          border: none;
          border-radius: 15px;
          flex: 0 0 auto;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          font-size: 13px;
          margin: 6px;
          box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.15);
          background-color: rgb(255, 255, 255);
          cursor: pointer;
          outline: none;
          user-select: none;
          filter: grayscale(0.85);
          color: rgba(40, 40, 40, 0.7);

          >img {
            max-height: 16px;
            max-width: 16px;
            padding: 0px;
            box-sizing: border-box;
            margin-right: 6px;
            opacity: 0.7;
          }
        }

        >button.tag.active {
          color: white;
          filter: grayscale(0);
          box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.3);
          background-color: $darkPrimary;
          border: 2px solid $primary;

          >img {
            opacity: 1;
            filter: drop-shadow(0px 0px 2px white);
          }
        }
      }
    }
  }

  >.explore-container {
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    padding: 0px 0px 0px 0px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 12px;
    z-index: 1;

    >.top-bar {
      width: 100%;
      height: $topBarHeight;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0px 3px 0px 3px;
      box-sizing: border-box;
      position: relative;

      >button.expand {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border: none;
        background-color: transparent;
        outline: none;
        height: 100%;
        padding: 0px 12px;

        >i {
          color: rgb(168, 168, 168);
          border: 1px solid rgb(168, 168, 168);
          border-radius: 50%;
          font-size: 18px;
          margin: 0px 0px 0px 0px;
        }
      }
    }

    >.loading-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      box-sizing: border-box;
      height: calc(100% - #{$topBarHeight} - 54px);
      width: 100%;
      position: absolute;
      top: $topBarHeight;
      left: 0px;
    }

    >.empty-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      box-sizing: border-box;
      height: calc(100% - #{$topBarHeight} - 54px);
      width: 100%;
      position: absolute;
      top: $topBarHeight;
      left: 0px;
      font-size: 16px;
    }

    >.explore-result-list {
      display: block;
      overflow: hidden;
      overflow-y: auto;
      box-sizing: border-box;
      height: calc(100% - #{$topBarHeight});

      >.explore-result {
        $iconResultWidth: 48px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        padding: 9px 0px;
        cursor: pointer;

        &:first-child {
          padding-top: 0px;
        }

        >.icon {
          width: $iconResultWidth;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          align-self: flex-start;
          padding-top: 3px;

          >img {
            max-width: 36px;
            min-width: 16px;
          }
        }

        >.detail {
          display: flex;
          flex-direction: column;
          width: calc(100% - #{$iconResultWidth} - 36px);

          >.name {
            width: 100%;
            cursor: pointer;
            font-size: 16px;
            font-family: "Prompt", serif;
          }

          >.short-description {
            width: 100%;
            font-size: 14px;
            color: $grey;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            cursor: pointer;
          }
        }

        >.info {
          width: 36px;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          text-decoration: none;

          >i {
            color: $lightPrimary;
            font-size: 24px;
          }
        }
      }

      >.loading-more-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 6px;
      }

      a.add-place {
        cursor: pointer;
        color: $primary;
        text-decoration: none;
        font-family: "Prompt", serif;
        text-align: center;
        margin: 12px auto;
        display: block;
        font-size: 14px;
      }

      >button {
        width: calc(100% - 12px);
        border: none;
        border-radius: 3px;
        font-size: 14px;
        padding: 12px 6px;
        margin: 6px;
        cursor: pointer;
        outline: none;
        color: rgb(255, 255, 255);
        background-color: $primary;
        transition: background-color 0.3s ease 0s;
      }

      >button:hover {
        background-color: $darkPrimary;
      }
    }
  }
}

.explore-panel-map3.expand-less {

  @media only screen and (max-width: $mobileMaxSize) {
    bottom: calc(#{$topBarHeight} + #{$toolBarHeight} + 2px - #{$explorePanelHeight});

    >button.explore-area {
      top: calc(#{$topBarHeight} + #{$toolBarHeight});
    }

    >button.more-tag {
      top: calc(#{$topBarHeight} + #{$toolBarHeight});
    }

    >.tag-list {
      top: calc(#{$topBarHeight} + #{$toolBarHeight});
    }

    >.btn-scroll-left {
      display: none;
    }

    >.btn-scroll-right {
      display: none;
    }
  }

  @media only screen and (min-width: $mobileMaxSize) {
    height: 75%;
  }
}

.explore-panel-map3.hide {

  @media only screen and (max-width: $mobileMaxSize) {

    bottom: -150%;
    box-shadow: none;

  }

  @media only screen and (min-width: $mobileMaxSize) {

    left: 150%;
    box-shadow: none;

  }

  >button.explore-area {
    top: calc(#{$topBarHeight} + #{$toolBarHeight});
  }

  >button.more-tag {
    top: calc(#{$topBarHeight} + #{$toolBarHeight});
  }

  >.tag-list {
    top: calc(#{$topBarHeight} + #{$toolBarHeight});
  }
}

.explore-panel-map3.hide.expand-less {
  bottom: calc(0px - #{$explorePanelHeight} - 12px);
}
</style>
