<template>
  <div>
    <div
      :class="{ 'toggle': isToggle, 'badge-info-list': !isLongdoMapV3, 'badge-info-list-map3': isLongdoMapV3, 'traffic-on': layerSelected.includes('traffic') }">
      <div class="badge-velocity" v-if="isShowingVelocityBadge">
        <div class="badge-box">
          <div class="index">{{ velocityBadgeUnit === 'mile' ? Math.round(currentVelocity * 0.621) : Math.round(currentVelocity) }}
          </div>
          <label class="description">
            {{ velocityBadgeUnit === 'mile' ? 'mile/hr' : 'km/hr' }}
          </label>
        </div>
      </div>
      <a @click="onClickUrl('https://traffic.longdo.com/trafficindex')"
        :class="{ 'no-other-badge': !isShowingAnyAqiBadge, 'traffic-index': !isLongdoMapV3, 'traffic-index-map3': isLongdoMapV3 }"
        v-show="isTrafficProduct">
        <div class="badge-box" :style="{ borderColor: trafficIndexColor }">
          <div class="index" :style="{ color: trafficIndexColor }">{{ trafficIndex ? trafficIndex.toFixed(1) : '-.-' }}
          </div>
          <label class="description">
            {{ $t('badgeInformation.trafficIndex') }}
          </label>
        </div>
      </a>
      <div class="badge-information" v-if="badgeData !== null && (layerSelected.includes('aqi') || showAqiV2)">
        <div>
          <div class="badge-box" :style="{ backgroundColor: badgeData.aqi_attr.bgcolor }">
            <div class="head">
              <div class="title" :style="{ color: badgeData.aqi_attr.fontcolor }">AQI</div>
            </div>
            <div class="value" :style="{ color: badgeData.aqi_attr.fontcolor }">
              {{ badgeData.aqi_value }}
            </div>
          </div>
        </div>
      </div>
      <div class="badge-gistda-pm" v-if="gistdaData !== null && layerSelected.includes('gistda_pm')">
        <div>
          <div class="badge-box" :style="gistdaColor('BG')">
            <div class="head">
              <div class="title">
                <div style="display: flex;" :style="gistdaColor('TEXT')">
                  AQI
                  <img style="width: 20px;margin-left: 3px;height: 20px;"
                    src="https://www.gistda.or.th/assets/img/favicon.png" alt="">
                </div>
              </div>
            </div>
            <div class="value" :style="gistdaColor('TEXT')">
              {{ Math.floor(gistdaData.pm25) || '-' }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="traffic-legend" v-if="layerSelected.includes('traffic')">
      <img src="https://api.longdo.com/map/images/ui/legend-traffic-th-2x.png" alt="">
    </div>
  </div>
</template>
<script>
import LongdoJsInterface from '@/longdo-js-interface/index.js'
export default {
  name: 'BadgeInformation',
  props: {
    map: {
      type: Object
    },
    isGeolocationMobileAppDisabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    trafficIndexColor () {
      const self = this
      if (self.trafficIndex) {
        return `rgb( ${self.trafficIndex > 5.0 ? 255 : (self.trafficIndex * 44.4) + 34} , ${self.trafficIndex > 5.0 ? (204 - (self.trafficIndex - 5) * 40.8) : 204} , 21)`
      } else {
        return 'rgb(80,80,80)'
      }
    },
    showAqiV2 () {
      const self = this
      if (!self.longdoMapV3) {
        for (let i = 0; i < self.layerSelected.length; i++) {
          if (self.layerSelected[i].name === 'aqi') {
            return true
          }
        }
      }
      return false
    },
    isShowingAnyAqiBadge () {
      const self = this
      return (self.badgeData !== null && (self.layerSelected.includes('aqi') || self.showAqiV2)) || (self.gistdaData !== null && self.layerSelected.includes('gistda_pm'))
    },
    isShowingVelocityBadge () {
      const self = this
      if (!self.isOnMobileApp) {
        return false
      }
      if (self.isGeolocationMobileAppDisabled) {
        return false
      }
      const velocityCondition = self.currentVelocity && self.currentVelocity > 1
      switch (self.velocityBadgeCondition) {
        case 'always':
          return velocityCondition
        case 'layers':
          return self.layerSelected.includes('traffic') && velocityCondition
        case 'none':
          return false
      }
      return false
    }
  },
  data () {
    return {
      layerSelected: [],
      isToggle: false,
      badgeData: null,
      gistdaData: null,
      isInBkkBbox: true,
      trafficIndex: null,
      currentVelocity: null,
      velocityBadgeCondition: 'always',
      velocityBadgeUnit: ''
    }
  },
  mounted () {
    const self = this
    self.lji = new LongdoJsInterface()
    // self.$parent.$on('clickMap', self.toggleDisplay)
    self.$root.$on('updateSelectedLayer', (layers) => {
      // to trigger computed
      self.layerSelected = []
      layers.forEach((layer, index) => {
        self.$set(self.layerSelected, index, layer)
      })
      // self.layerSelected = layer
    })
    self.$root.$on('onShowBadge', (obj) => {
      const self = this
      self.badgeData = obj
    })
    self.$root.$on('gistda_pm_badge', (obj) => {
      const { status, data } = obj
      if (status === 200) {
        self.gistdaData = data
      }
    })
    self.$root.$on('change_show_velocity_badge_setting', (value) => {
      self.velocityBadgeCondition = value
    })
    self.$root.$on('change_velocity_unit_setting', (value) => {
      self.velocityBadgeUnit = value
    })
    if (self.isTrafficProduct) {
      self.getTrafficIndex()
      self.updateShowingTrafficIndex()
      setInterval(() => {
        self.getTrafficIndex()
      }, 1000 * 60 * 5)
    }
    if (self.isOnMobileApp) {
      if (localStorage.showVelocity) {
        self.velocityBadgeCondition = localStorage.showVelocity
      }
      if (localStorage.velocityUnit) {
        self.velocityBadgeUnit = localStorage.velocityUnit
      }
      self.currentVelocity = window.speed
      setInterval(() => { self.currentVelocity = window.speed }, 1000)
    }
  },
  methods: {
    async onClickUrl (url) {
      const self = this
      if (self.isOnMobileApp) {
        try {
          await self.lji.openUrl({
            url: url
          })
        } catch (error) {
          console.log(error)
        }
      } else {
        window.open(url, '_blank').focus()
      }
    },
    toggleDisplay () {
      const self = this
      self.isToggle = !self.isToggle
    },
    gistdaColor (type) {
      const self = this
      if (type === 'BG') {
        if (self.gistdaData.pm25 <= 15) {
          return 'backgroundColor: rgb(59, 204, 255)'
        } else if (self.gistdaData.pm25 <= 25.0) {
          return 'backgroundColor: rgb(146, 208, 80)'
        } else if (self.gistdaData.pm25 <= 37.5) {
          return 'backgroundColor: rgb(255, 255, 0)'
        } else if (self.gistdaData.pm25 <= 75) {
          return 'backgroundColor: rgb(255, 162, 0)'
        } else if (self.gistdaData.pm25 > 75) {
          return 'backgroundColor: rgb(255, 59, 59)'
        } else {
          return 'backgroundColor: rgb(0, 0, 0)'
        }
      } else {
        if (self.gistdaData.pm25 >= 25 && self.gistdaData.pm25 <= 37.5) {
          return 'color: rgb(0,0,0)'
        } else {
          return 'color: rgb(255,255,255)'
        }
      }
    },
    updateShowingTrafficIndex () {
      const self = this
      self.isInBkkBbox = window.longdo.Util.contains(self.map.location(), [
        { lat: 13.9551981792133, lon: 100.327912387173 },
        { lat: 13.9551981792133, lon: 100.938516257044 },
        { lat: 13.4933895713799, lon: 100.938516257044 },
        { lat: 13.4933895713799, lon: 100.327912387173 },
        { lat: 13.9551981792133, lon: 100.327912387173 }
      ])
    },
    async getTrafficIndex () {
      const self = this
      const result = await self.api.getTrafficIndex()
      if (result.status === 200) {
        if (result.data) {
          self.trafficIndex = result.data.index || 0.0
        }
      }
    }
  }

}
</script>

<style lang="scss" scoped>
// both version
.traffic-legend {
  position: fixed;
  z-index: 80;
  bottom: 50px;
  left: 5px;

  @media only screen and(min-width: $mobileMaxSize) {
    img {
      height: 180px;
    }
  }

  @media only screen and(max-width: $mobileMaxSize) {
    img {
      display: none;
      height: 120px;
    }
  }
}

.badge-velocity {
  .badge-box {
    @media only screen and(max-width: $mobileMaxSize) {
      position: fixed;
      top: 60px;
      right: 12px;
    }
    -webkit-box-shadow: 0 0 2px 1px rgba(0, 0, 0, .2);
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, .2);
    z-index: 10;
    border-radius: 10px;
    border: 2px solid $primary;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-bottom: 12px;
    width: 64px;
    height: 64px;
    text-align: center;
    background-color: white;

    .index {
      color: $primary;
      font-weight: bold;
      font-size: 26px;
    }

    .description {
      font-size: 12px;
      font-weight: 700;
    }
  }
}

.badge-gistda-pm {
  .badge-box {
    // padding: 6px 6px 3px 6px;
    -webkit-box-shadow: 0 0 2px 1px rgba(0, 0, 0, .2);
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, .2);
    z-index: 10;
    // border-radius: 12px;
    border-radius: 10px;
    // border: 2px solid hsla(0, 0%, 100%, .75);
    border: 0.5px solid white;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    opacity: .85;
    margin-bottom: 12px;
    // width: 63px;
    // height: 63px;
    width: 64px;
    height: 64px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 9px;

    .head {
      text-align: center;
      font-size: 16px;
    }

    .value {
      font-weight: bold;
      text-align: center;
      font-size: 16px;
    }
  }
}

.badge-information {
  .badge-box {
    // padding: 6px 6px 3px 6px;
    -webkit-box-shadow: 0 0 2px 1px rgba(0, 0, 0, .2);
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, .2);
    z-index: 10;
    // border-radius: 12px;
    border-radius: 10px;
    // border: 2px solid hsla(0, 0%, 100%, .75);
    border: 0.5px solid white;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    opacity: .85;
    margin-bottom: 12px;
    // width: 63px;
    // height: 63px;
    width: 64px;
    height: 64px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 9px;

    .head {
      // padding-top: 5px;
      text-align: center;
      font-size: 16px;
    }

    .value {
      font-weight: bold;
      text-align: center;
      font-size: 16px;
    }
  }
}

// v3
.traffic-on {
  bottom: 250px !important;
}

.badge-info-list-map3 {
  width: 75px;
  transition: top 0.25s ease 0s;
  height: fit-content;

  @media only screen and(min-width: $mobileMaxSize) {
    position: fixed;
    top: 320px;
    right: 5px;
    z-index: 80;
  }

  @media only screen and (min-width: $mobileMaxSize) and(max-height: 820px) {
    position: fixed !important;
    top: unset;
    bottom: 50px;
    left: 10px;
    z-index: 80;
  }

  @media only screen and(max-width: $mobileMaxSize) {
    &.toggle {
      top: 10px;
    }

    position: fixed;
    top: 60px;
    left: 12px;
    z-index: 80;
  }
}

.traffic-index-map3 {
  // @media only screen and(max-width: $mobileMaxSize) {
  //   position: absolute;
  //   left: calc(100px - 100vw);
  //   z-index: 80;
  // }

  text-decoration: none;

  .badge-box {
    -webkit-box-shadow: 0 0 2px 1px rgba(0, 0, 0, .2);
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, .2);
    z-index: 10;
    border-radius: 10px;
    border: 2px solid white;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-bottom: 12px;
    width: 64px;
    height: 64px;
    text-align: center;
    background-color: white;

    .index {
      color: white;
      font-weight: bold;
      font-size: 26px;
    }

    .description {
      font-size: 12px;
      font-weight: 700;
    }
  }
}

// v2
.badge-info-list {
  transition: top 0.25s ease 0s;
  height: fit-content;

  &.toggle {
    top: 10px;
  }

  position: fixed;
  top: 80px;
  right: 15px;
  z-index: 100;
}

.traffic-index {
  position: absolute;
  left: calc(100px - 100vw);
  z-index: 100;
  * {
    cursor: pointer;
  }

  &.no-other-badge {
    left: calc(27px - 100vw) !important;
  }

  text-decoration: none;

  .badge-box {
    -webkit-box-shadow: 0 0 2px 1px rgba(0, 0, 0, .2);
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, .2);
    z-index: 10;
    border-radius: 10px;
    border: 2px solid white;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-bottom: 12px;
    width: 64px;
    height: 64px;
    text-align: center;
    background-color: white;

    .index {
      color: white;
      font-weight: bold;
      font-size: 24px;
    }

    .description {
      font-weight: 700;
      font-size: 14px;
    }
  }
}</style>
