var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        map: _vm.product === "map",
        traffic: _vm.product === "traffic",
      },
      attrs: { id: "app" },
    },
    [
      _c("NavigationBar"),
      _c("NavigationSideBar"),
      _vm.isOnMobileApp ? _c("MobileEnablePermission") : _vm._e(),
      _c("div", { staticClass: "content" }, [_c("router-view")], 1),
      !_vm.isOffline
        ? _c(
            "div",
            { staticStyle: { display: "none" }, attrs: { id: "truehits" } },
            [
              _c("iframe", {
                key: "iframe-" + _vm.trueHits + "?page=" + _vm.page,
                attrs: {
                  id: _vm.trueHits + "?page=" + _vm.page,
                  src: _vm.trueHits + "?page=" + _vm.page,
                  width: "14",
                  height: "17",
                  frameborder: "0",
                  marginheight: "0",
                  marginwidth: "0",
                  scrolling: "no",
                },
              }),
            ]
          )
        : _vm._e(),
      _vm.updateExists
        ? _c("div", { staticClass: "update-alert" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("app.alertUpdate." + _vm.product)) +
                " (" +
                _vm._s(_vm.appVersion) +
                ") "
            ),
            _c(
              "button",
              { staticClass: "ripple", on: { click: _vm.refreshApp } },
              [_vm._v(" " + _vm._s(_vm.$t("app.update")) + " ")]
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }