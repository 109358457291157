var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "warning-pop-up",
      class: { hide: _vm.isHiding || _vm.isShowingMapOnly },
    },
    [
      _c("div", { staticClass: "right-corner" }, [
        _c(
          "button",
          { staticClass: "jump ripple", on: { click: _vm.onClickFlyTo } },
          [_c("i", { staticClass: "material-icons-round" }, [_vm._v("info")])]
        ),
        _c(
          "button",
          { staticClass: "close ripple", on: { click: _vm.onClickClose } },
          [_c("i", { staticClass: "material-icons-round" }, [_vm._v("close")])]
        ),
      ]),
      _c("div", [
        _c("div", { staticClass: "header" }, [
          _c("div", { staticClass: "distance number" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.warningPoint ? _vm.warningPoint.currentDistance : "-.-"
                )
            ),
          ]),
          _c("div", { staticClass: "distance unit" }, [
            _vm._v(_vm._s(_vm.$t("mobileWarningPopup.meter"))),
          ]),
          _vm.warningPoint
            ? _c("img", { attrs: { src: _vm.imgPathUrl } })
            : _vm._e(),
        ]),
        _c("div", { staticClass: "vertical-line" }),
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "tag" }, [
            _c("div", [
              _vm._v(
                _vm._s(
                  _vm.tagName !== "-.-"
                    ? _vm.$t("mobileWarningPopup." + _vm.tagName)
                    : _vm.tagName
                )
              ),
            ]),
          ]),
          _c("div", { staticClass: "name" }, [
            _vm._v(_vm._s(_vm.warningPoint ? _vm.warningPoint.name : "-.-")),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }