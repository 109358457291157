var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "my-place-panel",
      class: {
        hide: _vm.isHidingMyPlacePanel,
        "expand-less": !_vm.isExpand,
      },
    },
    [
      _c("div", { staticClass: "my-place-container" }, [
        _c("div", { staticClass: "top-bar" }, [
          _vm.isExpand
            ? _c(
                "button",
                {
                  staticClass: "expand less ripple",
                  on: { click: _vm.clickExpandLess },
                },
                [
                  _c("i", { staticClass: "material-icons-round" }, [
                    _vm._v("expand_more"),
                  ]),
                ]
              )
            : _vm._e(),
          !_vm.isExpand
            ? _c(
                "button",
                {
                  staticClass: "expand more ripple",
                  on: { click: _vm.clickExpandMore },
                },
                [
                  _c("i", { staticClass: "material-icons-round" }, [
                    _vm._v("expand_less"),
                  ]),
                ]
              )
            : _vm._e(),
        ]),
        _vm.showLoading
          ? _c("div", { staticClass: "loading-container" }, [
              _c("img", {
                attrs: { src: require("@/assets/img/loading.gif") },
              }),
            ])
          : _vm._e(),
        !_vm.showLoading &&
        _vm.myPlaceList !== null &&
        _vm.myPlaceList.length === 0
          ? _c("div", { staticClass: "empty-container" }, [
              _vm._v(" " + _vm._s(_vm.$t("myPlacePanel.empty")) + " "),
            ])
          : _vm._e(),
        _c(
          "div",
          { ref: "my-place-list", staticClass: "my-place-list" },
          [
            _vm._l(_vm.myPlaceList, function (myPlace) {
              return [
                _c(
                  "div",
                  { key: myPlace.ooiid, staticClass: "my-place ripple" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "icon",
                        on: {
                          click: function ($event) {
                            return _vm.clickMyPlace(myPlace)
                          },
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: myPlace.markicon2x,
                            srcset:
                              myPlace.markicon +
                              " 1x, " +
                              myPlace.markicon2x +
                              " 2x",
                            loading: "lazy",
                          },
                        }),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "detail",
                        on: {
                          click: function ($event) {
                            return _vm.clickMyPlace(myPlace)
                          },
                        },
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "name",
                            attrs: {
                              title: myPlace["title_" + _vm.$i18n.locale],
                            },
                          },
                          [_vm._v(_vm._s(myPlace["title_" + _vm.$i18n.locale]))]
                        ),
                        _c(
                          "label",
                          {
                            staticClass: "short-description",
                            attrs: {
                              title: myPlace["teaser_" + _vm.$i18n.locale],
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(myPlace["teaser_" + _vm.$i18n.locale])
                            ),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "info",
                        on: {
                          click: function ($event) {
                            return _vm.clickMyPlaceInfo(myPlace)
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "material-icons-round" }, [
                          _vm._v("info"),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]
            }),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }