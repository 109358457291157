var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showDialog
    ? _c(
        "div",
        {
          staticClass: "back-drop",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.onClickClose()
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "dialog-card",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return function () {}.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "button",
                {
                  staticClass: "close ripple",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.onClickClose()
                    },
                  },
                },
                [_c("i", { staticClass: "material-icons" }, [_vm._v("close")])]
              ),
              _vm.location
                ? _c("div", { staticClass: "identify" }, [
                    _c("div", { staticClass: "text" }, [
                      _vm._v(
                        _vm._s(_vm.kmDisplay) + " " + _vm._s(_vm.addressDisplay)
                      ),
                    ]),
                    _c("div", { staticClass: "text" }, [
                      _vm._v(
                        _vm._s(_vm.location.lat.toFixed(6)) +
                          ", " +
                          _vm._s(_vm.location.lon.toFixed(6))
                      ),
                    ]),
                    _c("div", { staticClass: "text" }, [
                      _vm._v(_vm._s(_vm.demDisplay)),
                    ]),
                    _c(
                      "div",
                      { staticClass: "action" },
                      [
                        _c("input", {
                          ref: "input-for-copy",
                          attrs: {
                            id: "input-for-copy",
                            type: "link",
                            readonly: "",
                          },
                          domProps: { value: _vm.copyText },
                        }),
                        _c(
                          "button",
                          {
                            staticClass: "ripple",
                            on: {
                              click: function ($event) {
                                return _vm.copyLatLon(false)
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "material-icons" }, [
                              _vm._v("content_copy"),
                            ]),
                            _c("span", [_vm._v("Lat, Lon")]),
                          ]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "ripple",
                            on: {
                              click: function ($event) {
                                return _vm.copyLatLon(true)
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "material-icons" }, [
                              _vm._v("content_copy"),
                            ]),
                            _c("span", [_vm._v("Lon, Lat")]),
                          ]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "ripple",
                            on: {
                              click: function ($event) {
                                return _vm.share()
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "material-icons" }, [
                              _vm._v("share"),
                            ]),
                            _c("span", [_vm._v("Share")]),
                          ]
                        ),
                        _c("transition", { attrs: { name: "copyText" } }, [
                          _vm.isShowCopied
                            ? _c("div", { staticClass: "copied" }, [
                                _vm._v(_vm._s(_vm.$t("sharePlaceWidget.copy"))),
                              ])
                            : _vm._e(),
                        ]),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }