import { render, staticRenderFns } from "./BadgeInformation.vue?vue&type=template&id=eebddc54&scoped=true&"
import script from "./BadgeInformation.vue?vue&type=script&lang=js&"
export * from "./BadgeInformation.vue?vue&type=script&lang=js&"
import style0 from "./BadgeInformation.vue?vue&type=style&index=0&id=eebddc54&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eebddc54",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('eebddc54')) {
      api.createRecord('eebddc54', component.options)
    } else {
      api.reload('eebddc54', component.options)
    }
    module.hot.accept("./BadgeInformation.vue?vue&type=template&id=eebddc54&scoped=true&", function () {
      api.rerender('eebddc54', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Layers/BadgeInformation.vue"
export default component.exports