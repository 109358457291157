<template>
  <div>
    <div class="layer-panel" :class="{
      hide: isHidingLayerPanel,
    }">
      <label class="panel-name">
        {{ $t("layerPanel.name") }}
      </label>
      <button class="close ripple" @click="clickClose">
        <i class="material-icons-round">close</i>
      </button>
      <div class="divider-h"></div>
      <div class="layer-list">
        <div v-for="layer in layerList.filter((layer) => {
          return layer.v3 === true;
        })" :key="layer.name" class="layer-container">
          <button @click="addLayer(layer)" class="layer" :class="{ active: selectedLayer.indexOf(layer.name) !== -1 }">
            <div>
              <div class="title">
                {{ layer.displayName[$i18n.locale] }}
                <i class="material-icons-round" v-if="layer.type !== 'link' &&
                  ('description' in layer ||
                    'legend' in layer ||
                    'source' in layer)
                  " @click.stop="toggleInfo(layer)">
                  help_outline
                </i>
              </div>
              <i class="material-icons-round close"> close </i>
              <i class="material-icons-round link" v-if="layer.type === 'link'">
                open_in_new
              </i>
            </div>
            <div class="control-traffic" v-if="layer.type === 'traffic' &&
              selectedLayer.indexOf(layer.name) !== -1
              ">
              <button class="toggle-input" @click.stop="isShowingCamera = !isShowingCamera"
                :class="{ active: isShowingCamera }">
                <div class="toggle">
                  <div class="track"></div>
                  <div class="thumb"></div>
                </div>
                <label>{{ $t("layerPanel.camera") }}</label>
              </button>
              <button class="toggle-input" @click.stop="isShowingEvents = !isShowingEvents"
                :class="{ active: isShowingEvents }">
                <div class="toggle">
                  <div class="track"></div>
                  <div class="thumb"></div>
                </div>
                <label>{{ $t("layerPanel.events") }}</label>
              </button>
            </div>
            <div class="input-container" @click.stop="() => { }" v-if="layer.type !== 'link' && 'opacity' in layer">
              <input type="range" min="0" max="1" step="0.1" v-model="layer.opacity" @input="changeOpacity(layer)" />
            </div>
            <img :src="baseUrl +
              'img/layer/' +
              layer.name.replace(':', '') +
              '.jpg?version=' +
              appVersion
              " loading="lazy" />
          </button>
          <div class="layer-info" v-if="layer.isShowingInfo &&
            layer.type !== 'link' &&
            selectedLayer.indexOf(layer.name) !== -1 &&
            ('description' in layer || 'legend' in layer || 'source' in layer)
            ">
            <div class="field description" v-if="'description' in layer">
              <label>{{ $t("layerPanel.description") }}</label>
              <div v-html="layer.description[$i18n.locale]"></div>
            </div>
            <div class="field legend" v-if="'legend' in layer">
              <img :src="layer.legend[$i18n.locale]" :style="{ maxWidth: layer.legend.maxWidth }"
                @click="clickLegend(layer.legend[$i18n.locale])" />
            </div>
            <div class="field source" v-if="'source' in layer">
              <label>{{ $t("layerPanel.source") }}</label>
              <div v-html="layer.source[$i18n.locale]"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="selectedLayer.indexOf('rainviewer') != -1">
      <div id="rain-radar-panel" v-show="true">
        <div>
          <div id="time-radar"></div>
        </div>
        <div class="action">
          <button class="ripple">
            <i class="material-icons-round" @click="rainRadarV3.rainBack()">skip_previous</i>
          </button>
          <button class="ripple" @click="playRainRadar()">
            <i class="material-icons-round" v-if="!playAnimation">play_arrow</i>
            <i class="material-icons-round" v-if="playAnimation">stop</i>
          </button>
          <button class="ripple">
            <i class="material-icons-round" @click="rainRadarV3.rainNext()">skip_next</i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Map3Layer',
  props: {
    map: Object,
    baseMap: String
  },
  data () {
    return {
      isMouseChangeOpacity: false,
      selectedLayer: [],
      safeLayer: ['traffic', 'aqicn', 'evstation'], // When basemap or language change is not effect,
      isShowingEvents: true,
      isShowingCamera: true,
      isShiftTop: false,
      nearestAqiAir4Thai: null,
      nearestAqiCN: null,
      playAnimation: false
    }
  },
  computed: {
    isHidingLayerPanel () {
      const self = this
      return self.$route.hash !== '#layers'
    }
  },
  watch: {
    '$i18n.locale' () {
      const self = this
      const layerObj = self.selectedLayer.map((layerName) => {
        return self.layerList.filter((layers) => {
          return (
            layers.name === layerName && self.safeLayer.indexOf(layers.type) === -1
          )
        })
      })
      setTimeout(() => {
        layerObj.map((layer) => {
          if (layer) {
            self.addLayer(layer[0], true)
          }
        })
      }, 1000)
    },
    baseMap (to, from) {
      const self = this
      if (from) {
        self.storeEventToNativeAnalytic('change_base_map', {
          to: to,
          from: from
        })
      }
      const layerObj = self.selectedLayer.map((layerName) => {
        return self.layerList.filter((layers) => {
          return (
            layers.name === layerName && self.safeLayer.indexOf(layers.type) === -1
          )
        })
      })
      setTimeout(() => {
        layerObj.map((layer) => {
          if (layer) {
            self.addLayer(layer[0], true)
          }
        })
      }, 2000)
    },
    isShowingCamera (to, from) {
      const self = this
      if (!to && self.selectedLayer.indexOf('traffic') !== -1) {
        self.map.Overlays.unload(window.longdo3.Overlays.cameras)
      } else {
        self.map.Overlays.load(window.longdo3.Overlays.cameras)
      }
    },
    isShowingEvents (to, from) {
      const self = this
      if (!to && self.selectedLayer.indexOf('traffic') !== -1) {
        if (self.isTrafficProduct) {
          self.$emit('hideTrafficEvent')
        } else {
          self.map.Overlays.unload(window.longdo3.Overlays.events)
        }
      } else {
        if (self.isTrafficProduct) {
          self.$emit('showTrafficEvent')
        } else {
          self.map.Overlays.load(window.longdo3.Overlays.events)
        }
      }
    },
    selectedLayer () {
      const self = this
      if (self.isOnMobileApp) {
        self.$nextTick(() => {
          const temp = document.querySelectorAll('.field.source')
          temp.forEach((elem) => {
            const link = elem.querySelectorAll('a')
            link.forEach((a) => {
              if (a.onclick === null) {
                const temp = a.href
                a.onclick = () => { self.onClickUrl(temp) }
                a.style.textDecoration = 'underline'
                a.removeAttribute('href')
              }
            })
          })
        })
      }
    }
  },
  mounted () {
    const self = this
    self.$root.$on('openLayer', self.onOpenLayer)
    self.$root.$on('hideLayer', self.onHideLayer)
    self.$root.$on('openCameraOnTrafficLayer', () => {
      self.isShowingCamera = true
    })
    self.$root.$on('hideCameraOnTrafficLayer', () => {
      self.isShowingCamera = false
    })
    self.$root.$on('openEventOnTrafficLayer', () => {
      self.isShowingEvents = true
    })
    self.$root.$on('hideEventOnTrafficLayer', () => {
      self.isShowingEvents = false
    })
    self.map.Renderer.on('dragend', () => {
      if (self.selectedLayer.indexOf('aqi') !== -1) {
        self.updateNearestAqiCN()
      }
    })
  },
  methods: {
    async onClickUrl (url) {
      const self = this
      if (self.isOnMobileApp) {
        try {
          await self.lji.openUrl({
            url: url
          })
        } catch (error) {
          console.log(error)
        }
      }
    },
    onOpenLayer (layerName) {
      const self = this
      const layerObj = self.layerList.filter((l) => {
        return l.name === layerName
      })[0]
      self.addLayer(layerObj)
    },
    onHideLayer (layerName) {
      const self = this
      if (self.selectedLayer.indexOf(layerName) >= 0) {
        self.onOpenLayer(layerName)
      }
    },
    async addLayer (layerObj, isChangeBaseMap = false) {
      const self = this
      if (!layerObj) {
        return
      }
      const layerName = layerObj.name
      const layerType = layerObj.type
      const { type } = layerObj

      let eventName = ''
      if (self.selectedLayer.indexOf(layerName) !== -1) {
        eventName = 'remove_layer'
      } else {
        if (type === 'link') {
          eventName = 'open_link_layer'
        } else {
          eventName = 'add_layer'
        }
      }
      self.storeEventToNativeAnalytic(eventName, {
        layerName: layerName
      })

      if (self.isMouseChangeOpacity) {
        return
      }

      if (type === 'link') {
        if (self.isOnMobileApp) {
          self.onClickUrl(layerObj.url)
        } else {
          window.open(layerObj.url)
        }
        return
      }
      // Non link
      if (!isChangeBaseMap) {
        self.toggleInfo(layerObj)
      }
      switch (layerType) {
        case 'traffic':
          if (self.selectedLayer.indexOf(layerName) === -1) {
            self.map.Layers.add(window.longdo3.Layers.TRAFFIC)
            if (self.isTrafficProduct) {
              self.$emit('showTrafficEvent')
            } else {
              self.map.Overlays.load(window.longdo3.Overlays.events)
            }
            self.map.Overlays.load(window.longdo3.Overlays.cameras)
            self.selectedLayer.push(layerName)
          } else {
            if (self.isTrafficProduct) {
              self.$emit('hideTrafficEvent')
            } else {
              self.map.Overlays.unload(window.longdo3.Overlays.events)
            }
            self.map.Overlays.unload(window.longdo3.Overlays.cameras)
            self.selectedLayer.splice(self.selectedLayer.indexOf(layerName), 1)
            try {
              self.map.Layers.remove(window.longdo3.Layers.TRAFFIC)
            } catch (error) {
              if (self.map.Renderer.getLayer('traffic-forward') && self.map.Renderer.getLayer('traffic-reverse')) {
                self.map.Renderer.removeLayer('traffic-forward')
                self.map.Renderer.removeLayer('traffic-reverse')
              }
            }
          }
          break
        case 'WMS':
          var url =
            layerObj.url +
            '?bbox={bbox-epsg-3857}&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&transparent=true&width=256&height=256&layers=' +
            layerName +
            '&styles'
          self.addRasterLayer(layerName, url, 512, layerObj.opacity)
          break
        case 'rainviewer':
          self.addRainViwer()
          break
        case 'aqiair4thai':
          self.api
            .getAqiAir4Thai({
              locale: self.$i18n.locale
            })
            .then((result) => {
              if (result.status === 200) {
                // console.log(result.data.result)
              }
            })
          break
        case 'aqicn':
          if (self.selectedLayer.indexOf(layerName) === -1) {
            self.map.Tags.add(self.getAqiCN)
            self.selectedLayer.push(layerName)
            self.$root.$emit('afterOpenLayer', layerName)
          } else {
            self.selectedLayer.splice(self.selectedLayer.indexOf(layerName), 1)
            self.map.Tags.remove(self.getAqiCN)
            self.map.Overlays.list().forEach((o) => {
              if ('aqiCNData' in o) {
                self.map.Overlays.remove(o)
              }
            })
            self.$root.$emit('afterHideLayer', layerName)
          }
          break
        case 'dol':
          var dolUrl = `${layerObj.url}?&zoom={z}&x={x}&y={y}&mode=dol_hd`
          self.addRasterLayer(layerName, dolUrl, 512, layerObj.opacity)
          break
        case 'gistda_pm':
          // var gistdaPM25Url = `${layerObj.url}data/services/pm_check/pm25_hourly_raster/MapServer/WMSServer?SERVICE=WMS&REQUEST=GetMap&VERSION=1.1.1&SRS=EPSG:3857&BBOX={bbox-epsg-3857}&WIDTH=256&HEIGHT=256&LAYERS=0&STYLES=&TRANSPARENT=true&TILED=true&FORMAT=image/png&EXCEPTIONS=BLANK`
          var gistdaPM25Url = `${layerObj.url}//api_geoserver/geoserver/pm25_hourly_raster_24hr/wms?SERVICE=WMS&REQUEST=GetMap&VERSION=1.1.1&SRS=EPSG:3857&BBOX={bbox-epsg-3857}&WIDTH=256&HEIGHT=256&LAYERS=0&STYLES=&TRANSPARENT=true&TILED=true&FORMAT=image/png&EXCEPTIONS=BLANK`
          self.addRasterLayer(layerName, gistdaPM25Url, 512, layerObj.opacity)
          self.updateNearestGistdaPM()
          self.map.Renderer.on('dragend', () => {
            if (self.selectedLayer.indexOf('gistda_pm') !== -1) {
              self.updateNearestGistdaPM()
            }
          })
          break
        case 'evstation':
          if (self.selectedLayer.indexOf(layerName) === -1) {
            self.map.Tags.add(self.getChargerStation)
            self.map.Tags.add('Ev_charging_station')
            self.selectedLayer.push(layerName)
          } else {
            self.selectedLayer.splice(self.selectedLayer.indexOf(layerName), 1)
            self.map.Tags.remove(self.getChargerStation)
            self.map.Tags.remove('Ev_charging_station')
            self.map.Overlays.list().forEach((o) => {
              if ('evChargerData' in o) {
                self.map.Overlays.remove(o)
              }
            })
          }
          break
        case 'Custom':
          if (
            layerName === 'cu_uav' &&
            !self.selectedLayer.includes('cu_uav')
          ) {
            if (self.map.zoom() < 12) {
              self.map.zoom(12, false)
            }
            self.map.location({ lat: 13.740194, lon: 100.529905 }, false)
          }
          var customUrl = `${layerObj.url}&zoom={z}&x={x}&y={y}`
          self.addRasterLayer(layerName, customUrl, 512, layerObj.opacity)
          break
      }
      self.$root.$emit('updateSelectedLayer', self.selectedLayer)
    },
    async addRainViwer () {
      const self = this
      var rainViewerID = 'rainviewer'
      if (!self.rainRadarV3) {
        await self.lji.Util.addScriptTag({
          src: `${self.baseUrl}rainradarv3.js?v=${self.appVersion}`
        })
        self.rainRadarV3 = new window.RainRadarV3(self.map, {
          opacity: 0.5,
          timeDisplay: 'time-radar',
          speed: 1000,
          color: 8,
          locale: self.$i18n.locale === 'th' ? 'th-Th' : 'en-Us'
        })
      }
      var layerIdx = self.selectedLayer.indexOf(rainViewerID)
      if (layerIdx !== -1) {
        self.rainRadarV3.stopAnimation()
        self.playAnimation = false
        self.rainRadarV3.removeLayer()
        self.selectedLayer.splice(layerIdx, 1)
      } else {
        self.rainRadarV3.reload()
        self.selectedLayer.push(rainViewerID)
      }
    },
    async addRasterLayer (layerName, url, size, opacity) {
      const self = this
      var rasterSource = self.map.Renderer.getSource(layerName)
      var rasterLayer = self.map.Renderer.getLayer(layerName)
      const layerIdx = self.selectedLayer.indexOf(layerName)
      if (layerIdx !== -1) {
        self.selectedLayer.splice(layerIdx, 1)
      }
      if (!rasterSource) {
        self.map.Renderer.addSource(layerName, {
          type: 'raster',
          tiles: [url],
          tileSize: size
        })
      } else {
        rasterSource.tiles.url = url
      }
      if (!rasterLayer) {
        self.map.Renderer.addLayer({
          id: layerName,
          type: 'raster',
          source: layerName,
          paint: {
            'raster-opacity': Number.parseFloat(opacity || 1)
          }
        })
        self.selectedLayer.push(layerName)
      } else {
        const layerIdx = self.selectedLayer.indexOf(layerName)
        if (layerIdx !== -1) {
          self.selectedLayer.splice(layerIdx, 1)
          self.map.Renderer.removeLayer(layerName)
        } else {
          self.map.Renderer.removeLayer(layerName)
        }
      }
    },
    toggleInfo (layer) {
      const self = this
      const isActive = self.selectedLayer.indexOf(layer.name) !== -1
      if (isActive) {
        const index = self.layerList.map((l) => l.name).indexOf(layer.name)
        self.layerList[index].isShowingInfo =
          !self.layerList[index].isShowingInfo
        self.$set(self.layerList, index, self.layerList[index])
      } else {
        const index = self.layerList.map((l) => l.name).indexOf(layer.name)
        self.layerList[index].isShowingInfo =
          !self.layerList[index].isShowingInfo
        self.$set(self.layerList, index, self.layerList[index])
      }
    },
    changeOpacity (layer) {
      const self = this
      if (self.selectedLayer.indexOf(layer.name) !== -1) {
        if (layer.name === 'traffic') {
          self.map.Renderer.setPaintProperty(
            'traffic-forward',
            'line-opacity',
            Number.parseFloat(layer.opacity)
          )
          self.map.Renderer.setPaintProperty(
            'traffic-reverse',
            'line-opacity',
            Number.parseFloat(layer.opacity)
          )
        } if (layer.name === 'rainviewer') {
          self.rainRadarV3.setOpacity(Number.parseFloat(layer.opacity))
        } else {
          self.map.Renderer.setPaintProperty(
            layer.name,
            'raster-opacity',
            Number.parseFloat(layer.opacity)
          )
        }
      }
    },
    async getAqiCN (tile, zoom) {
      const self = this
      var result = await self.api.getAqiCN({
        locale: self.$i18n.locale,
        minLat: tile.bbox.south,
        minLon: tile.bbox.west,
        maxLat: tile.bbox.north,
        maxLon: tile.bbox.east
      })
      if (result.status === 200) {
        result.data.result.forEach((d) => {
          const aqiMarkerList = self.map.Overlays.list().filter(
            (o) => 'aqiCNData' in o
          )
          const index = aqiMarkerList.map((a) => a.aqiCNData.id).indexOf(d.id)
          if (index >= 0) {
            return false
          }
          const aqiMarker = new window.longdo.Marker(
            {
              lat: Number(d.location.lat),
              lon: Number(d.location.lon)
            },
            {
              icon: {
                html: `<div class="aqi-cn-marker" style="color: ${d.aqi_attr.fontcolor}; background-image: url('${d.aqi_attr.iconmarker}');">${d.aqi_value}</div>`,
                offset: {
                  x: 0,
                  y: -16
                }
              },
              popup: {
                size: { width: 300, height: 300 },
                detail: `
                <div class="aqi-head-map3">
                  <div class="aqi-value" style="background-color: ${d.aqi_attr.bgcolor
                  };">
                    <div class="title" style="color: ${d.aqi_attr.fontcolor
                  };">${d.aqi_attr.status_text}</div>
                    <div class="icon" style="background-image: url('${d.aqi_attr.iconstatus
                  }')"></div>
                    <div class="value" style="color: ${d.aqi_attr.fontcolor
                  };">${d.aqi_value}</div>
                  </div>
                  <div class="aqi-owner">
                    <div class="name">${d.name}</div>
                    <div class="last-update">${self.$t(
                    'layerPanel.lastUpdate'
                  )} ${self.utility.getDateTimeStringFromUnixTimestamp(
                    d.lastupdate,
                    self.$i18n.locale
                  )}</div>
                  </div>
                </div>
                <div class="aqi-info-row">
                  <div class="aqi-info PM10">
                    <div class="title">PM₁₀</div>
                    <img class="loading" src="${require('@/assets/img/loading.gif')}">
                    <div class="value"><span class="unit"></span></div>
                  </div>
                  <div class="aqi-info PM25">
                    <div class="title">PM₂₅</div>
                    <img class="loading" src="${require('@/assets/img/loading.gif')}">
                    <div class="value"><span class="unit"></span></div>
                  </div>
                  <div class="aqi-info SO2">
                    <div class="title">SO₂</div>
                    <img class="loading" src="${require('@/assets/img/loading.gif')}">
                    <div class="value"><span class="unit"></span></div>
                  </div>
                </div>
                <div class="aqi-info-row">
                  <div class="aqi-info CO">
                    <div class="title">CO</div>
                    <img class="loading" src="${require('@/assets/img/loading.gif')}">
                    <div class="value"><span class="unit"></span></div>
                  </div>
                  <div class="aqi-info NO2">
                    <div class="title">NO₂</div>
                    <img class="loading" src="${require('@/assets/img/loading.gif')}">
                    <div class="value"><span class="unit"></span></div>
                  </div>
                  <div class="aqi-info O3">
                    <div class="title">O₃</div>
                    <img class="loading" src="${require('@/assets/img/loading.gif')}">
                    <div class="value"><span class="unit"></span></div>
                  </div>
                </div>
              `,
                loadDetail: async (element) => {
                  const result = await self.api.getAqiCNInfo({
                    id: d.id
                  })
                  if (result.status === 200) {
                    for (const key in result.data.result) {
                      element
                        .querySelector('.' + key + ' img.loading')
                        .remove()
                      element.querySelector('.' + key + ' .value').innerHTML =
                        result.data.result[key].value +
                        ' ' +
                        element.querySelector('.' + key + ' .value').innerHTML
                      element.querySelector('.' + key + ' .unit').innerHTML =
                        result.data.result[key].unit
                    }
                  }
                }
              }
            }
          )
          aqiMarker.aqiCNData = d
          self.map.Overlays.add(aqiMarker)
          self.updateNearestAqiCN()
        })
      }
    },
    updateNearestAqiCN () {
      const self = this
      const aqiMarkerList = self.map.Overlays.list().filter(
        (o) => 'aqiCNData' in o
      )
      if (aqiMarkerList.length > 0) {
        const location = self.map.location()
        let nearestAqiMarkerIndex = 0
        let minDistance = window.longdo.Util.distance([
          location,
          aqiMarkerList[nearestAqiMarkerIndex].location()
        ])
        for (let i = 1; i < aqiMarkerList.length; i++) {
          const distance = window.longdo.Util.distance([
            location,
            aqiMarkerList[i].location()
          ])
          if (minDistance > distance) {
            nearestAqiMarkerIndex = i
            minDistance = distance
          }
        }
        if (minDistance < 50000) {
          self.nearestAqiCN = aqiMarkerList[nearestAqiMarkerIndex].aqiCNData
        } else {
          self.nearestAqiCN = null
        }
      } else {
        self.nearestAqiCN = null
      }
      self.$root.$emit('onShowBadge', self.nearestAqiCN)
    },
    updateNearestGistdaPM () {
      const self = this
      const location = self.map.location()
      self.api
        .getAqiGistda(location)
        .then(({ data }) => {
          self.$root.$emit('gistda_pm_badge', data)
        })
    },
    async getChargerStation (tile, zoom) {
      const self = this
      var result = await self.api.getEVCharger({
        locale: self.$i18n.locale,
        boundingBox: `(${tile.bbox.south},${tile.bbox.west}),(${tile.bbox.north},${tile.bbox.east})`
      })
      if (result.status === 200) {
        result.data.forEach((d) => {
          const evChargerMarkerList = self.map.Overlays.list().filter(
            (o) => 'evChargerData' in o
          )
          const index = evChargerMarkerList
            .map((a) => a.evChargerData.ID)
            .indexOf(d.id)
          if (index >= 0) {
            return false
          }
          const evStationMarker = new window.longdo.Marker(
            {
              lat: Number(d.AddressInfo.Latitude),
              lon: Number(d.AddressInfo.Longitude)
            },
            {
              icon: {
                html: `<div class="aqi-cn-marker" style="color: white; background-image: url('${d.IsRecentlyVerified
                    ? 'https://map.longdo.com/mmmap/images/layers/ic_aqicn_green.png'
                    : 'https://map.longdo.com/mmmap/images/layers/ic_aqicn_blue.png'
                  }');">
                <i class="material-icons-round" style="font-size:14px">
                    electrical_services
                </i>
                </div>`,
                offset: {
                  x: 0,
                  y: 0
                }
              },
              popup: {
                size: { width: 300, height: 300 },
                detail: `
                <div>
                  <div class="aqi-head-map3">
                    <div class="aqi-owner">
                    <div class="name">${d.AddressInfo.Title}</div>
                    <div class="last-update">
                      <a target="_blank" href="${d.AddressInfo.RelatedURL || '#'
                  }"> ${d.AddressInfo.RelatedURL || ''} </a>
                      </div>
                  </div>
                  </div>
                    <div class="aqi-info-row">
                    ${d.Connections.map((e) => {
                    return `<div style="width: 100%">
                            <div class="aqi-info" style="width:100%;margin-bottom: 10px;">
                              <div class="title">Power</div>
                              <div class="value">${e.PowerKW || '-'
                      }<span class="unit">KW</span></div>
                            </div>
                            <div class="aqi-info" style="width:100%;margin-bottom: 10px;">
                              <div class="title">Voltage</div>
                              <div class="value">${e.Voltage || '-'
                      }<span class="unit">Voltage</span></div>
                            </div>
                            <div class="aqi-info" style="width:100%; margin-bottom:10px;">
                              <div class="title">Amps</div>
                              <div class="value">${e.Amps || '-'
                      }<span class="unit">Amps</span></div>
                            </div>
                        </div>`
                  })}
                </div>
                </div>
              `.replace(',', '&nbsp;&nbsp;')
              }
            }
          )
          evStationMarker.evChargerData = d
          self.map.Overlays.add(evStationMarker)
        })
      }
    },
    clickClose () {
      const self = this
      self.$emit('clickClose')
    },
    playRainRadar () {
      const self = this
      self.rainRadarV3.playAnimation()
      self.playAnimation = self.rainRadarV3.animation
    }
  }
}
</script>

<style lang="scss" scoped>
@media only screen and (max-width: $mobileMaxSize) {
  .layer-panel {
    width: 100%;
    height: 75%;
    border-radius: 12px 12px 0px 0px;
    background-color: rgb(255, 255, 255);
    padding: 12px 0px 54px 0px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
    z-index: 90;
    position: fixed;
    bottom: 0%;
    left: 0px;
    transition: bottom 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;

    >label.panel-name {
      font-family: Prompt;
      font-size: 18px;
      color: $primary;
    }

    >button.close {
      padding: 0px;
      position: absolute;
      top: 12px;
      right: 12px;
      border: none;
      background-color: transparent;
      cursor: pointer;

      i {
        color: $grey;
      }
    }
  }

  .layer-panel.hide {
    bottom: calc(0px - 75% - 12px);
    box-shadow: none;
  }
}

@media only screen and (min-width: $mobileMaxSize) {
  .layer-panel {
    width: 385px;
    height: 70%;
    border-radius: 12px 12px 12px 12px;
    background-color: white;
    padding: 12px 0px 10px 0px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
    z-index: 90;
    position: fixed;
    top: 120px;
    right: 105px;
    opacity: 1;
    transition: right 0.2s cubic-bezier(0.5, 0.9, 0.9, 1) 0s,
      opacity 0.2s cubic-bezier(0.5, 0.9, 0.9, 1) 0s;

    >label.panel-name {
      font-family: Prompt;
      font-size: 18px;
      color: $primary;
    }

    >button.close {
      padding: 0px;
      position: absolute;
      top: 12px;
      right: 12px;
      border: none;
      background-color: transparent;
      cursor: pointer;

      i {
        color: $grey;
      }
    }
  }

  .layer-panel.hide {
    opacity: 0;
    pointer-events: none;
    right: calc(120px - 0%);
    box-shadow: none;
  }
}

.layer-list {
  width: 100%;
  height: auto;
  padding: 12px;
  box-sizing: border-box;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  >.layer-container {
    margin: 12px auto;
    width: 100%;

    >button.layer {
      position: relative;
      width: 100%;
      padding: 16px;
      min-height: 90px;
      border: 2px solid $lightGrey;
      border-radius: 6px;
      box-sizing: border-box;
      outline: none;
      appearance: none;
      overflow: hidden;
      background-image: linear-gradient(90deg,
          $darkPrimary 0%,
          transparent 60%);
      transition: background-image 0.3s ease 0s, width 0.1s ease 0s,
        height 0.1s ease 0s, border 0.1s ease 0s;

      >div {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        z-index: 1;

        >.title {
          text-align: left;
          display: inline-block;
          font-family: Prompt;
          font-size: 20px;
          z-index: 1;
          text-shadow: 2px 2px 3px rgb(0, 0, 0);
          color: rgb(255, 255, 255);

          >i {
            color: rgb(255, 255, 255);
            font-size: 24px;
            opacity: 0;
            transition: opacity 0.3s ease 0s;
            z-index: 1;
            display: none;
            vertical-align: middle;
          }
        }

        >i {
          color: rgb(255, 255, 255);
          font-size: 24px;
          opacity: 0;
          transition: opacity 0.3s ease 0s;
          align-self: flex-start;
          z-index: 1;
        }

        >i.close {
          margin-left: auto;
          color: rgb(253, 216, 53);
          font-weight: bold;
          z-index: 1;
        }

        >i.link {
          margin-top: 6px;
          margin-left: auto;
          z-index: 1;
          color: rgb(255, 255, 255);
        }
      }

      >.input-container {
        z-index: 1;
        width: 100%;

        * {
          z-index: 1;
        }

        >input[type="range"] {
          width: 100%;
          display: none;
          margin-top: 18px;
          z-index: 1;
        }
      }

      >img {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center bottom;
        z-index: 0;
        opacity: 0.7;
      }

      .control-traffic {
        z-index: 1;

        * {
          z-index: 1;
        }
      }

      .layer-panel.hide {
        bottom: calc(0px - 75% - 12px);
        box-shadow: none;
      }

      input[type="range"] {
        width: 100%;
        margin: 6px 0;
        background-color: transparent;
        appearance: none;
      }

      input[type="range"]:focus {
        outline: none;
      }

      input[type="range"]::-webkit-slider-runnable-track {
        background: rgb(255, 255, 255);
        border: 0.1px solid rgba(0, 0, 0, 0);
        width: 100%;
        height: 6px;
        cursor: pointer;
        border-radius: 3px;
      }

      input[type="range"]::-webkit-slider-thumb {
        margin-top: -6.1px;
        width: 18px;
        height: 18px;
        background: $darkPrimary;
        border: 3px solid rgba(255, 255, 255, 1);
        border-radius: 9px;
        cursor: pointer;
        -webkit-appearance: none;
      }

      input[type="range"]:focus::-webkit-slider-runnable-track {
        background: rgb(255, 255, 255);
      }

      input[type="range"]::-moz-range-track {
        background: rgb(255, 255, 255);
        border: 0.1px solid rgba(0, 0, 0, 0);
        width: 100%;
        height: 6px;
        border-radius: 3px;
        cursor: pointer;
      }

      input[type="range"]::-moz-range-thumb {
        width: 18px;
        height: 18px;
        background: $darkPrimary;
        border: 3px solid rgba(255, 255, 255, 1);
        border-radius: 9px;
        cursor: pointer;
      }

      input[type="range"]::-ms-track {
        background: transparent;
        border-color: transparent;
        border-width: 6px 0;
        color: transparent;
        width: 100%;
        height: 6px;
        border-radius: 3px;
        cursor: pointer;
      }

      input[type="range"]::-ms-fill-lower {
        background: rgb(255, 255, 255);
        border: 0.1px solid rgba(0, 0, 0, 0);
      }

      input[type="range"]::-ms-fill-upper {
        background: rgb(255, 255, 255);
        border: 0.1px solid rgba(0, 0, 0, 0);
      }

      input[type="range"]::-ms-thumb {
        width: 18px;
        height: 18px;
        background: $darkPrimary;
        border: 3px solid rgba(255, 255, 255, 1);
        border-radius: 9px;
        cursor: pointer;
        margin-top: 0px;
        /*Needed to keep the Edge thumb centred*/
      }

      input[type="range"]:focus::-ms-fill-lower {
        background: rgb(255, 255, 255);
      }

      input[type="range"]:focus::-ms-fill-upper {
        background: rgb(255, 255, 255);
      }

      /*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
how to remove the virtical space around the range input in IE*/
      @supports (-ms-ime-align: auto) {

        /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
        input[type="range"] {
          margin: 0;
          /*Edge starts the margin from the thumb, not the track as other browsers do*/
        }
      }
    }

    >button.layer.active {
      border: 2px solid $primary;
      background-image: linear-gradient(90deg, $darkPrimary 0%, $primary 100%);

      >div {
        >.title {
          padding-right: 24px;
          color: rgb(255, 255, 255);

          >i {
            opacity: 1;
            display: inline-block;
          }
        }

        >i {
          opacity: 1;
        }
      }

      >.input-container {
        >input[type="range"] {
          display: block;
        }
      }

      >img {
        opacity: 0.25;
      }

      .control-traffic {
        display: block;
        margin-top: 6px;

        .toggle-input {
          height: 32px;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          background-color: transparent;
          padding: 0px;
          border: none;

          >.toggle {
            position: relative;
            width: 30px;
            height: 100%;

            >.track {
              background-color: rgb(255, 255, 255);
              width: 100%;
              height: 18px;
              border-radius: 9px;
              position: absolute;
              left: 0px;
              top: 50%;
              transform: translateY(-50%);
            }

            >.thumb {
              width: 18px;
              height: 18px;
              border-radius: 9px;
              background-color: $lightGrey;
              position: absolute;
              top: 50%;
              left: 0px;
              transform: translateY(-50%);
              border: 3px solid rgb(255, 255, 255);
              box-sizing: border-box;
              transition: left 0.3s ease 0s, background-color 0.3s ease 0s;
            }
          }

          >label {
            color: rgb(255, 255, 255);
            padding-left: 12px;
            font-size: 16px;
          }
        }

        .toggle-input.active {
          >.toggle {
            >.thumb {
              background-color: $darkPrimary;
              left: calc(100% - 18px);
            }
          }
        }
      }
    }

    >button.layer.link {
      >div {
        >label {
          color: rgb(255, 255, 255);
        }

        >i {
          opacity: 1;
          color: rgb(255, 255, 255);
          text-shadow: 0px 0px 6px rgb(0, 0, 0);
        }
      }
    }

    >.layer-info {
      width: 100%;
      padding: 0px 6px;
      box-sizing: border-box;

      >.field {
        margin-top: 12px;

        >label {
          font-weight: bold;
          font-size: 16px;
        }

        >div {
          font-size: 16px;
        }
      }

      >.legend {
        width: 100%;
        text-align: center;

        >img {
          width: 100%;
        }
      }
    }
  }
}

#rain-radar-panel {
  position: fixed;
  z-index: 80;
  transform: translate(-50%, 0%);
  bottom: 96px;
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6px;
  box-sizing: border-box;
  border-radius: 6px;
  pointer-events: none;

  #time-radar {
    pointer-events: none;
    text-align: center;
    padding: 1px 9px;
    background: rgba(0, 0, 0, 0.45);
    margin-bottom: 6px;
    border-radius: 12px;
    font-family: Prompt;
    font-size: 14px;
    color: white;
  }

  .action {
    display: flex;
    flex-direction: row;
    pointer-events: visible;

    button {
      background-color: white;
      border: 1px solid $extraLightGrey;
      border-radius: 0px;
      box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
      width: 36px;
      height: 36px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;

      &:first-child {
        border-radius: 6px 0px 0px 6px;
      }

      &:last-child {
        border-radius: 0px 6px 6px 0px;
      }

      i {
        width: 24px;
        height: 24px;
        font-size: 24px;
        color: $primary;
      }
    }
  }
}
</style>

<style lang="scss">
.divider-h {
  margin: 10px;
  height: 1px;
  width: calc(100% - 20px);
  background-color: #e6e6e6;
}
</style>
