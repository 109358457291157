var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "layer-panel",
      class: {
        hide: _vm.isHidingLayerPanel,
      },
    },
    [
      _c("label", { staticClass: "panel-name" }, [
        _vm._v(" " + _vm._s(_vm.$t("layerPanel.name")) + " "),
      ]),
      _c(
        "button",
        { staticClass: "close ripple", on: { click: _vm.clickClose } },
        [_c("i", { staticClass: "material-icons-round" }, [_vm._v("close")])]
      ),
      _c(
        "div",
        { staticClass: "layer-list" },
        [
          _vm._l(
            _vm.layerList.filter(function (layer) {
              return layer.v2 === true
            }),
            function (layer) {
              return [
                _c("div", { key: layer.name, staticClass: "layer-container" }, [
                  _c(
                    "button",
                    {
                      key: layer.name,
                      staticClass: "layer",
                      class: {
                        link: layer.type === "link",
                        active:
                          _vm.selectedLayer
                            .map(function (l) {
                              return l.name
                            })
                            .indexOf(layer.name) !== -1,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.toggleLayer(layer)
                        },
                      },
                    },
                    [
                      _c("div", [
                        _c("div", { staticClass: "title" }, [
                          _vm._v(
                            " " +
                              _vm._s(layer.displayName[_vm.$i18n.locale]) +
                              " "
                          ),
                          layer.type !== "link" &&
                          ("description" in layer ||
                            "legend" in layer ||
                            "source" in layer)
                            ? _c(
                                "i",
                                {
                                  staticClass: "material-icons-round",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.toggleInfo(layer)
                                    },
                                  },
                                },
                                [_vm._v(" help_outline ")]
                              )
                            : _vm._e(),
                        ]),
                        layer.type !== "link" &&
                        _vm.selectedLayer
                          .map(function (l) {
                            return l.name
                          })
                          .indexOf(layer.name) !== -1
                          ? _c(
                              "i",
                              { staticClass: "material-icons-round close" },
                              [_vm._v(" close ")]
                            )
                          : _vm._e(),
                        layer.type === "link"
                          ? _c(
                              "i",
                              { staticClass: "material-icons-round link" },
                              [_vm._v(" open_in_new ")]
                            )
                          : _vm._e(),
                      ]),
                      layer.type === "traffic" &&
                      _vm.selectedLayer
                        .map(function (l) {
                          return l.name
                        })
                        .indexOf(layer.name) !== -1
                        ? _c("div", { staticClass: "control-traffic" }, [
                            _c(
                              "button",
                              {
                                staticClass: "toggle-input",
                                class: { active: _vm.isShowingCamera },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    _vm.isShowingCamera = !_vm.isShowingCamera
                                  },
                                },
                              },
                              [
                                _vm._m(0, true),
                                _c("label", [
                                  _vm._v(_vm._s(_vm.$t("layerPanel.camera"))),
                                ]),
                              ]
                            ),
                            _c(
                              "button",
                              {
                                staticClass: "toggle-input",
                                class: { active: _vm.isShowingEvents },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    _vm.isShowingEvents = !_vm.isShowingEvents
                                  },
                                },
                              },
                              [
                                _vm._m(1, true),
                                _c("label", [
                                  _vm._v(_vm._s(_vm.$t("layerPanel.events"))),
                                ]),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      layer.type !== "link" && "opacity" in layer
                        ? _c("div", { staticClass: "input-container" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: layer.opacity,
                                  expression: "layer.opacity",
                                },
                              ],
                              attrs: {
                                type: "range",
                                min: "0",
                                max: "1",
                                step: "0.1",
                              },
                              domProps: { value: layer.opacity },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.clickOpacityInput.apply(
                                    null,
                                    arguments
                                  )
                                },
                                input: function ($event) {
                                  return _vm.changeOpacity(layer)
                                },
                                __r: function ($event) {
                                  return _vm.$set(
                                    layer,
                                    "opacity",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ])
                        : _vm._e(),
                      _c("img", {
                        attrs: {
                          src:
                            _vm.baseUrl +
                            "img/layer/" +
                            layer.name.replace(":", "") +
                            ".jpg?version=" +
                            _vm.appVersion,
                          loading: "lazy",
                        },
                      }),
                    ]
                  ),
                  layer.isShowingInfo &&
                  layer.type !== "link" &&
                  _vm.selectedLayer
                    .map(function (l) {
                      return l.name
                    })
                    .indexOf(layer.name) !== -1 &&
                  ("description" in layer ||
                    "legend" in layer ||
                    "source" in layer)
                    ? _c("div", { staticClass: "layer-info" }, [
                        "description" in layer
                          ? _c("div", { staticClass: "field description" }, [
                              _c("label", [
                                _vm._v(
                                  _vm._s(_vm.$t("layerPanel.description"))
                                ),
                              ]),
                              _c("div", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    layer.description[_vm.$i18n.locale]
                                  ),
                                },
                              }),
                            ])
                          : _vm._e(),
                        "legend" in layer
                          ? _c("div", { staticClass: "field legend" }, [
                              _c("img", {
                                style: { maxWidth: layer.legend.maxWidth },
                                attrs: { src: layer.legend[_vm.$i18n.locale] },
                                on: {
                                  click: function ($event) {
                                    return _vm.clickLegend(
                                      layer.legend[_vm.$i18n.locale]
                                    )
                                  },
                                },
                              }),
                            ])
                          : _vm._e(),
                        "source" in layer
                          ? _c("div", { staticClass: "field source" }, [
                              _c("label", [
                                _vm._v(_vm._s(_vm.$t("layerPanel.source"))),
                              ]),
                              _c("div", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    layer.source[_vm.$i18n.locale]
                                  ),
                                },
                              }),
                            ])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ]),
              ]
            }
          ),
        ],
        2
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                _vm.selectedLayer.filter(function (l) {
                  return l.type === "rainviewer"
                }).length > 0 &&
                ["#cam", "#layers", "#routing"].indexOf(_vm.$route.hash) < 0 &&
                Object.getOwnPropertyNames(_vm.$route.query).indexOf("search") <
                  0 &&
                [
                  "Place",
                  "Event",
                  "Cam",
                  "AqiAir4Thai",
                  "AqiCN",
                  "Rainfall",
                ].indexOf(_vm.$route.name) < 0,
              expression:
                "selectedLayer.filter(l => l.type === 'rainviewer').length > 0 &&\n      ['#cam', '#layers', '#routing'].indexOf($route.hash) < 0 &&\n      Object.getOwnPropertyNames($route.query).indexOf('search') < 0 &&\n      ['Place', 'Event', 'Cam', 'AqiAir4Thai', 'AqiCN', 'Rainfall'].indexOf($route.name) < 0",
            },
          ],
          attrs: { id: "rain-radar-panel" },
        },
        [
          _c("div", { attrs: { id: "time-radar" } }),
          _c("div", { staticClass: "action" }, [
            _c(
              "button",
              {
                staticClass: "ripple",
                on: {
                  click: function ($event) {
                    return _vm.rainRadar.rainBack()
                  },
                },
              },
              [
                _c("i", { staticClass: "material-icons-round" }, [
                  _vm._v("skip_previous"),
                ]),
              ]
            ),
            _c(
              "button",
              {
                staticClass: "ripple",
                on: {
                  click: function ($event) {
                    return _vm.playRainRadar()
                  },
                },
              },
              [
                !_vm.isAnimation
                  ? _c("i", { staticClass: "material-icons-round" }, [
                      _vm._v("play_arrow"),
                    ])
                  : _vm._e(),
                _vm.isAnimation
                  ? _c("i", { staticClass: "material-icons-round" }, [
                      _vm._v("stop"),
                    ])
                  : _vm._e(),
              ]
            ),
            _c(
              "button",
              {
                staticClass: "ripple",
                on: {
                  click: function ($event) {
                    return _vm.rainRadar.rainNext()
                  },
                },
              },
              [
                _c("i", { staticClass: "material-icons-round" }, [
                  _vm._v("skip_next"),
                ]),
              ]
            ),
          ]),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                _vm.nearestAqiAir4Thai !== null || _vm.nearestAqiCN !== null,
              expression:
                "nearestAqiAir4Thai !== null || nearestAqiCN !== null",
            },
          ],
          ref: "nearest-aqi",
          staticClass: "nearest-aqi",
          class: { "shift-top": _vm.isShiftTop },
        },
        [
          _vm.nearestAqiAir4Thai !== null
            ? _c(
                "div",
                {
                  ref: "nearest-aqi-air4thai",
                  staticClass: "nearest-aqi-air4thai",
                  style:
                    _vm.nearestAqiAir4Thai !== null
                      ? {
                          backgroundColor:
                            _vm.nearestAqiAir4Thai.aqi_attr.bgcolor,
                        }
                      : {},
                },
                [
                  _c("div", { staticClass: "head" }, [
                    _c(
                      "div",
                      {
                        staticClass: "title",
                        style:
                          _vm.nearestAqiAir4Thai !== null
                            ? {
                                color:
                                  _vm.nearestAqiAir4Thai.aqi_attr.fontcolor,
                              }
                            : {},
                      },
                      [_vm._v("AQI")]
                    ),
                    _c("div", { staticClass: "icon" }),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "value",
                      style:
                        _vm.nearestAqiAir4Thai !== null
                          ? { color: _vm.nearestAqiAir4Thai.aqi_attr.fontcolor }
                          : {},
                    },
                    [_vm._v(_vm._s(_vm.nearestAqiAir4Thai.aqi_value))]
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "toggle" }, [
      _c("div", { staticClass: "track" }),
      _c("div", { staticClass: "thumb" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "toggle" }, [
      _c("div", { staticClass: "track" }),
      _c("div", { staticClass: "thumb" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }