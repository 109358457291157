var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "navigation" }, [
    _vm.currentTurnCode !== null
      ? _c("div", { attrs: { id: "guide" } }, [
          _c("div", { staticClass: "detail left" }, [
            _c("div", {
              staticClass: "direction",
              style: {
                backgroundImage: "url(" + _vm.directionImagePath + ")",
              },
            }),
            _vm.distanceDisplay
              ? _c("div", { staticClass: "distance" }, [
                  _vm._v(" " + _vm._s(_vm.distanceDisplay)),
                  _c("span", { staticClass: "unit" }, [
                    _vm._v(_vm._s(_vm.distanceUnit)),
                  ]),
                ])
              : _vm._e(),
          ]),
          _c("div", { staticClass: "detail right" }, [
            _vm._v(" " + _vm._s(_vm.guideDetail) + " "),
          ]),
          _vm.nextTurnCode !== null && !_vm.isFinishNavigation
            ? _c("div", { attrs: { id: "next-guide" } }, [
                _c("div", {
                  staticClass: "direction",
                  style: {
                    backgroundImage: "url(" + _vm.nextDirectionImagePath + ")",
                  },
                }),
                _vm._v(" " + _vm._s(_vm.$t("routingPanel.nextTurn")) + " "),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
    _c("div", { attrs: { id: "map" } }),
    _c("div", { attrs: { id: "control" } }, [
      _c(
        "button",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isShowReRouteButton,
              expression: "isShowReRouteButton",
            },
          ],
          staticClass: "perspective-view ripple",
          on: { click: _vm.onClickReRoute },
        },
        [_c("i", { staticClass: "material-icons-round" }, [_vm._v("refresh")])]
      ),
      _c(
        "button",
        {
          staticClass: "ripple",
          class: { active: _vm.isActiveSpeak },
          on: { click: _vm.onClickSpeakButton },
        },
        [
          _c("i", { staticClass: "material-icons-round" }, [
            _vm._v(_vm._s(_vm.isActiveSpeak ? "volume_up" : "volume_off")),
          ]),
        ]
      ),
      _c(
        "button",
        {
          staticClass: "perspective-view ripple",
          class: { active: _vm.isActivePerspectiveView },
          on: { click: _vm.onClickPerspectiveButton },
        },
        [_c("i", { staticClass: "material-icons-round" }, [_vm._v("grid_on")])]
      ),
      _vm._m(0),
    ]),
    _c(
      "button",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isShowReCenterButton,
            expression: "isShowReCenterButton",
          },
        ],
        staticClass: "ripple",
        attrs: { id: "re-center" },
        on: { click: _vm.onClickReCenter },
      },
      [_vm._v("re-center")]
    ),
    _c(
      "button",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isReRouting,
            expression: "isReRouting",
          },
        ],
        staticClass: "ripple",
        attrs: { id: "re-routing" },
      },
      [_vm._v("Rerouting...")]
    ),
    _c("div", { attrs: { id: "footer" } }, [
      !_vm.isFinishNavigation
        ? _c("div", { staticClass: "summary" }, [
            _c("div", { staticClass: "main" }, [
              _vm._v(_vm._s(_vm.totalDuration)),
            ]),
            _c("div", { staticClass: "sub" }, [
              _vm._v(
                _vm._s(_vm.totalDistance) + " - " + _vm._s(_vm.arrivalTime)
              ),
            ]),
          ])
        : _vm._e(),
      !_vm.isFinishNavigation
        ? _c(
            "button",
            {
              staticClass: "ripple view-all",
              on: { click: _vm.onClickViewAllButton },
            },
            [
              _c("i", { staticClass: "material-icons-round" }, [
                _vm._v("route"),
              ]),
            ]
          )
        : _vm._e(),
      !_vm.isFinishNavigation
        ? _c(
            "button",
            {
              staticClass: "ripple exit-navi",
              on: {
                click: function ($event) {
                  return _vm.onClickExitNavi()
                },
              },
            },
            [
              _c("i", { staticClass: "material-icons-round" }, [
                _vm._v("logout"),
              ]),
            ]
          )
        : _vm._e(),
      _vm.isFinishNavigation
        ? _c(
            "button",
            {
              staticClass: "ripple return-main",
              on: {
                click: function ($event) {
                  return _vm.returnToMainPage()
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("routingPanel.finished")) + " ")]
          )
        : _vm._e(),
    ]),
    _c("audio", { attrs: { controls: "", id: "tts-audio" } }),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "longdo-map-logo",
        attrs: { href: "https://map.longdo.com/terms", target: "_blank" },
      },
      [
        _c("img", {
          attrs: {
            src: "https://api.longdo.com/map/images/logo.png",
            srcset: "https://api.longdo.com/map/images/logo-2x.png" + " 2x",
            alt: "Longdo Map",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }