var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showDialog
    ? _c("div", { staticClass: "back-drop" }, [
        _c("div", { staticClass: "dialog-card" }, [
          _c("div", { staticClass: "title" }, [
            _vm._v(" " + _vm._s(_vm.$t("nearbyDialog.title")) + " "),
          ]),
          _c("div", {
            staticClass: "content",
            domProps: { innerHTML: _vm._s(_vm.message) },
          }),
          _c("div", { staticClass: "action" }, [
            _c(
              "button",
              { staticClass: "ripple", on: { click: _vm.onClickClose } },
              [_vm._v(" " + _vm._s(_vm.$t("nearbyDialog.close")) + " ")]
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }