<template>
  <div :class="{
    'routing-panel': !isLongdoMapV3,
    'routing-panel-map3': isLongdoMapV3,
    'hide': isHidingInputPanel
  }">
    <div class="input-panel" :class="{
        'hide': isHidingInputPanel,
        'full-height': selectedPointInputIndex !== -1 && $route.hash === '#choose-location-for-routing',
        'minimize-height': isMinimizeHeightInputPanel
      }">
      <div class="vehicle-list">
        <template v-for="(vehicle, key) in vehicleList">
          <button @click="clickVehicle(key)" v-if="vehicle !== null" :key="key" class="ripple"
            :class="{ active: selectedVehicle === key }"
            :style="{ width: 'calc(100% / ' + Object.values(vehicleList).filter(v => v !== null).length + ')' }">
            <i class="material-icons-round">{{ vehicle.icon }}</i>
          </button>
        </template>
      </div>
      <div class="point-input-list">
        <template v-for="(pointInput, index) in selectedVehicle === 'transit' ? transitPointInputList : pointInputList">
          <div class="point-input" :key="'point-input-' + index + ' ' + pointInput.name">
            <img :id="'drag-element-'+index" class="drag-element" :src="utility.getImgPath(`marker/ic_pin_${index === 0? 'red': index === pointInputList.length-1 || selectedVehicle === 'transit' ? 'green' : `blue_${(index)}`}.svg`)"
            :draggable="true" @dragstart="dragStartHandler($event,index)" @dragend="dragEndHandler()">
            <div class="dotted-line" v-if="(selectedVehicle !== 'transit' && index !== pointInputList.length - 1) || (selectedVehicle === 'transit' && index === 0)"></div>
            <input type="text" :class="{ 'full-width': pointInputList.length <= 2 }"
              :value="pointInput.isCurrentLocation ? $t('routingPanel.currentLocation') : pointInput.keyword"
              @click="clickPointInput(index)"
              :placeholder="index === 0 ? $t('routingPanel.chooseStartingPoint') : $t('routingPanel.chooseDestination')"
              readonly>
            <button class="ripple" v-if="pointInputList.length > 2 && selectedVehicle !== 'transit'" @click="clearPointInput(index)">
              <i class="material-icons-round">
                close
              </i>
            </button>
          </div>
        </template>
      </div>
      <div class="control-container">
        <button class="ripple add" @click="addPointInput"
          :disabled="pointInputList.length >= 11"
          :style="{ 'visibility': selectedVehicle !== 'transit' && pointInputList.length < 11 ? 'visible' : 'hidden'}">
          <i class="material-icons-round">add</i>
        </button>
        <button class="ripple swap" @click="swapPointInput(0, pointInputList.length - 1)">
          <i class="material-icons-round">swap_vert</i>
        </button>
        <button class="ripple settings" :class="{ active: isShowSettingPanel }"
          @click="onClickSettingButton" v-if="isShowingSettingButton">
          <i class="material-icons-round">settings</i>
        </button>
        <button class="ripple reset" @click="resetRouting">
          {{ $t('routingPanel.clear') }}
        </button>
        <button class="ripple route" @click="route" :disabled="hasNullPointInput">
          {{ $t('routingPanel.route') }}
        </button>
      </div>
      <div class="setting-panel" v-if="isShowSettingPanel && selectedVehicle !== 'walk'">
        <div class="route-type" v-if="selectedVehicle !== 'walk'">
          <div class="title">{{ $t('routingPanel.routeTypeTitle') }}</div>
          <div class="choice-group" v-for="routeType in vehicleList[selectedVehicle].routeTypeList" :key="routeType.name">
            <input type="checkbox" :id="'route-type-' + routeType.value" name="route-type" :value="routeType.value"
              v-model="vehicleList[selectedVehicle].selectedRouteTypeValueList">
            <label :for="'route-type-' + routeType.value">{{ $t('routingPanel.routeType.' + routeType.name) }}</label>
          </div>
        </div>
        <div class="route-mode">
          <div class="title">{{ $t('routingPanel.routeModeTitle') }}</div>
          <div class="choice-group" v-for="routeMode in vehicleList[selectedVehicle].routeModeList" :key="routeMode.name">
            <input type="radio" :id="'route-mode-' + routeMode.value" name="route-mode" :value="routeMode.value"
              v-model="vehicleList[selectedVehicle].selectedRouteModeValue">
            <label :for="'route-mode-' + routeMode.value">{{ $t('routingPanel.routeMode.' + routeMode.name) }}</label>
          </div>
        </div>
        <div class="depart-datetime">
          <div class="title">{{ $t('routingPanel.departDatetimeTitle') }}</div>
          <div class="vue-datetime-container">
            <VueDatetime v-model="departDatetime" type="datetime" input-id="dapart-datetime-input"
              input-class="datetime-input" title="" :min-datetime="new Date().toISOString()" :phrases="{
                  ok: $t('routingPanel.okDepartDatetime'),
                  cancel: $t('routingPanel.cancelDepartDatetime')
                }" v-if="!isHidingInputPanel" />
            <i class="ripple material-icons-round" @click="clickDepartDatetimeIcon">schedule</i>
            <button class="reset ripple" v-if="departDatetime !== ''" @click="departDatetime = ''">
              <i class="ripple material-icons-round">close</i>
            </button>
          </div>
        </div>
      </div>
      <div class="summary-point-input" @click="expandMoreInputPanel">
        <div class="ripple">
          <span>{{ pointInputList[0].isCurrentLocation ? $t('routingPanel.currentLocation') : pointInputList[0].keyword
          }}</span>
          <i class="material-icons-round">double_arrow</i>
          <template v-if="pointInputList.length - 2 > 0">
            <span>{{ (pointInputList.length - 2) + ' ' + $t('routingPanel.stopPoint') }}</span>
            <i class="material-icons-round">double_arrow</i>
          </template>
          <span>{{ pointInputList[pointInputList.length - 1].isCurrentLocation ? $t('routingPanel.currentLocation') :
            pointInputList[pointInputList.length - 1].keyword }}</span>
        </div>
      </div>
    </div>
    <ChooseLocationWidget @clickBack="onClickBackFromChooseLocationWidget"
      @clickSearchResult="onClickSearchResultFromChooseLocationWidget"
      @clickCurrentLocation="onClickCurrentLocationFromChooseLocationWidget"
      :initialKeyword="selectedPointInputIndex !== -1 ? pointInputList[selectedPointInputIndex].keyword : ''"
      :trickInit="selectedPointInputIndex !== -1 && $route.hash === '#choose-location-for-routing'"
      :class="{ hide: !(selectedPointInputIndex !== -1 && $route.hash === '#choose-location-for-routing') }"
      :lat="map.location().lat" :lon="map.location().lon" :zoom="map.zoom()"/>
    <!-- <a class="longdo-map-logo" :class="{
      'hide': isHidingGuidePanel,
      'full-height': isFullHeightGuidePanel,
      'minimize-height': isMinimizeHeightGuidePanel
    }" href="https://map.longdo.com/terms" target="_blank">
      <img src="https://apix.longdo.com/map/images/logo.png" srcset="https://apix.longdo.com/map/images/logo-2x.png 2x"
        alt="Longdo Map">
    </a> -->
    <div class="guide-panel" :class="{
        'hide': isHidingGuidePanel,
        'full-height': isFullHeightGuidePanel,
        'minimize-height': isMinimizeHeightGuidePanel,
        'input-minimize-height': isMinimizeHeightInputPanel,
      }">
      <div class="loading-more-container" v-if="isRouting">
        <img src="@/assets/img/loading.gif">
      </div>
      <div class="guide" v-if="!isRouting && guideList.length > 0 && selectedVehicle !== 'transit' && !isShowRouteChangeColor">
        <div class="info ripple" @click="clickGuideInfo">
           <button class="ripple expand-more" :style="isShowRouteChangeColor ? {display: 'none'} : {}"
            v-if="(!isFullHeightGuidePanel && !isMinimizeHeightGuidePanel) || isMinimizeHeightGuidePanel">
            <i class="material-icons-round">expand_less</i>
          </button>
          <button class="ripple expand-less" v-if="isFullHeightGuidePanel">
            <i class="material-icons-round">expand_more</i>
          </button>
          <div class="time">
            {{ timeFormat(guideList[selectedGuideIndex].map(o => o.interval).reduce((total, t) => (total + t))) }}
          </div>
          <div class="distance">
            ({{ kmFormat(guideList[selectedGuideIndex].map(o => o.distance).reduce((total, d) => (total + d))) }})
          </div>
          <button class="start-navigation ripple" v-if="pointInputList[0].isCurrentLocation && pointInputList.length === 2" @click.stop="onClickStartNavigation">
            <i class="material-icons-round">navigation</i>
            {{ $t('routingPanel.start') }}
          </button>
          <div class="route-color-change" v-if="!isShowRouteChangeColor && isHasSetTrafficIticPermission" @click="isShowRouteChangeColor = !isShowRouteChangeColor">{{ $t('routingPanel.addColorLine.menuName') }}</div>
        </div>
        <div class="step-list">
          <template v-for="(aToB, i) in guideList[selectedGuideIndex]">
            <div class="a-to-b" :key="'a-to-b-' + i">
              <div class="step from ripple" @click="focusPoint(pointInputList[i])">
                <div class="icon">
                  <i class="material-icons-round">radio_button_checked</i>
                </div>
                <div class="name"
                  v-html="'<span style=\'color: rgb(56, 148, 238);\'>' + $t('routingPanel.from') + '</span> ' + (pointInputList[i].isCurrentLocation ? $t('routingPanel.currentLocation') : pointInputList[i].keyword)">
                </div>
              </div>
              <template v-for="(guide, j) in aToB.guide">
                <div class="step ripple" :key="'a-to-b-' + i + '-step-' + j" @click="focusStep(i, j)">
                  <div class="icon">
                    <img :src="turnIcon(guide.turn)">
                  </div>
                  <div class="name"
                    v-html="'<span style=\'color: rgb(56, 148, 238);\'>' + $t('routingPanel.turnCode[' + guide.turn + ']') + '</span> ' + guide.name">
                  </div>
                  <div class="distance">{{ kmFormat(guide.distance) }}</div>
                </div>
              </template>
              <div class="step to ripple" @click="focusPoint(pointInputList[i + 1])">
                <div class="icon">
                  <i class="material-icons-round">radio_button_checked</i>
                </div>
                <div class="name"
                  v-html="'<span style=\'color: rgb(56, 148, 238);\'>' + $t('routingPanel.to') + '</span> ' + (pointInputList[i + 1].isCurrentLocation ? $t('routingPanel.currentLocation') : pointInputList[i + 1].keyword)">
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="guide route-color-panel" v-if="isShowRouteChangeColor && isHasSetTrafficIticPermission">
        <div class="info header-color-panel">
          <div class="time">{{ $t('routingPanel.addColorLine.title') }}</div>
          <button class="close-color-route-btn ripple" @click="isShowRouteChangeColor = !isShowRouteChangeColor">
            <i class="material-icons-round">close</i>
          </button>
        </div>
        <div class="color-route-content">
          <div class="btn-color">
          <div class="btn-route-color selected-color-route" id="R" @click="handleColorRouteSelected('R')">
            <div class="route-color-btn-red"></div>
            <div class="label-color">ติดขัด</div>
          </div>
          <div class="btn-route-color" id="Y" @click="handleColorRouteSelected('Y')">
            <div class="route-color-btn-yellow"></div>
            <div class="label-color">หนาแน่น</div>
          </div>
          <div class="btn-route-color" id="G" @click="handleColorRouteSelected('G')">
            <div class="route-color-btn-green"></div>
            <div class="label-color">คล่องตัว</div>
          </div>
          <div class="btn-route-color" id="K" @click="handleColorRouteSelected('K')">
            <div class="route-color-btn-black"></div>
            <div class="label-color">ปิดถนน</div>
          </div>
          </div>
          <div class="input-content">
            <div class="input-detail">
              <input id="minuteSelected" type="radio" value="minuteSelected" v-model="colorRouteSelectType" class="color-input-time">
              <label for="minuteSelected">{{ $t('routingPanel.addColorLine.time') }}</label>
              <input type="number" v-model="colorTimeChoose" min="0" :disabled="colorRouteSelectType !== 'minuteSelected'"><label>{{ $t('routingPanel.addColorLine.minute') }}</label>
              <label>{{ $t('routingPanel.addColorLine.weight') }}</label><select v-model="colorWeightChoose">
                <option value="1">{{ $t('routingPanel.addColorLine.normal') }}</option>
                <option value="10">{{ $t('routingPanel.addColorLine.high') }}</option>
                <option value="100">{{ $t('routingPanel.addColorLine.veryHigh') }}</option>
              </select>
            </div>
          </div>
          <div class="input-content">
            <div class="input-detail input-datetime">
              <div style="width: 100px;">
                <input id="dateTimeSelected" style="vertical-align: bottom;" type="radio" value="dateTimeSelected" v-model="colorRouteSelectType" class="color-input-time">
                <label for="dateTimeSelected" style="vertical-align: -webkit-baseline-middle;">{{ $t('routingPanel.addColorLine.until') }}</label>
              </div>
              <div class="vue-datetime-container">
                <VueDatetime v-model="colorRouteDateTime" type="datetime" :disabled="colorRouteSelectType !== 'dateTimeSelected'"
                  input-class="datetime-input" title="" :min-datetime="new Date().toISOString()" :phrases="{
                      ok: $t('routingPanel.okDepartDatetime'),
                      cancel: $t('routingPanel.cancelDepartDatetime')
                    }"/>
                <i class="ripple material-icons-round" @click="clickDepartDatetimeIcon('start')">schedule</i>
                <button class="reset ripple" v-if="colorRouteDateTime !== ''" @click="colorRouteDateTime = ''">
                  <i class="ripple material-icons-round" :style="$i18n.locale === 'en'? 'margin-left: 15px;': ''">close</i>
                </button>
              </div>
            </div>
          </div>
          <div class="input-content" :style="responseFromColorRoute ? {display: 'block'} : {display: 'none'}">
            <div class="input-detail text-responseColorRoute">
              <label>{{ responseFromColorRoute }}</label>
            </div>
          </div>
          <div class="btn-submit-clear">
            <button class="clearForm" @click="clearRouteColor">
              <label>{{ $t('reportEventForm.clear') }}</label>
            </button>
            <button class="submitForm" @click="sendRouteColor">
              <label>{{ $t('reportEventForm.save') }}</label>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="transit-method-panel" :class="{
        'hide': isHidingTransitMethodPanel,
        'input-minimize-height': isMinimizeHeightInputPanel,
      }">
      <div class="loading-more-container" v-if="isRouting">
        <img src="@/assets/img/loading.gif">
      </div>
      <div class="transit-method-choose-hint" v-if="!isRouting">{{ $t('routingPanel.chooseTransitMethod') }}</div>
      <div class="transit-method-list" v-if="selectedVehicle === 'transit'">
        <template v-for="(method, index) in guideList">
          <TransitMethodCard :key="method.id" :method="method" @click="clickTransitMethod(index)" />
        </template>
      </div>
    </div>
    <div v-if="selectedTransitMethodIndex !== null" @click="clickMiniTransitGuidePanel" class="mini-transit-guide-panel"
      :class="{
          'hide': !isHidingTransitGuidePanel || ($route.hash !== '#routing' && $route.hash !== '#choose-location-for-routing')
        }">
      <TransitMethodCard :method="guideList[selectedTransitMethodIndex]" />
    </div>
    <div class="transit-guide-panel" :class="{
        'hide': isHidingTransitGuidePanel,
        'full': isFullHeightTransitGuidePanel
      }">
      <div class="head" @click="clickHeadTransitGuidePanel">
        <button class="back ripple" @click.stop="clickBackFromTransitGuide">
          <i class="material-icons-round">arrow_back</i>
        </button>
        <div v-if="selectedTransitMethodIndex !== null" class="detail">
          <div>
              <div class="fee" v-if="guideList[selectedTransitMethodIndex].fee > 0">{{
              guideList[selectedTransitMethodIndex].fee + ' ' + $t('routingPanel.baht') }}</div>
            <div class="fee"
              v-if="guideList[selectedTransitMethodIndex].fee > 0 && guideList[selectedTransitMethodIndex].hasTaxiFee"> +
            </div>
            <div class="fee" v-if="guideList[selectedTransitMethodIndex].hasTaxiFee">{{ $t('routingPanel.taxiFee') }}</div>
          </div>
          <div>
              <div class="distance">{{ guideList[selectedTransitMethodIndex].displayDistance }} {{ 1000 >
              guideList[selectedTransitMethodIndex].distance ? $t('routingPanel.meter') : $t('routingPanel.kilometer') }}
            </div>
             <div class="time">{{ (guideList[selectedTransitMethodIndex].hr !== 0 ? (guideList[selectedTransitMethodIndex].hr
              + ' ' + $t('routingPanel.hr') + ' ') : '') + guideList[selectedTransitMethodIndex].min + ' ' +
              $t('routingPanel.min') }}</div>
          </div>
        </div>
      </div>
      <div v-if="selectedTransitMethodIndex !== null" class="transit-guide-list">
        <div ref="startPointRender" class="point ripple" @click="clickTransitStartPoint">
          <i class="material-icons-round">radio_button_checked</i>
          {{ (pointInputList[0].isCurrentLocation ? $t('routingPanel.currentLocation') : pointInputList[0].keyword) }}
        </div>
        <template v-for="(guideForRender, i) in guideList[selectedTransitMethodIndex].guideForRender">
          <template v-if="(guideForRender instanceof Array)">
            <template v-if="guideForRender.length > 0">
              <div :ref="'guideForRender' + i" :key="'groupGuideStep-' + i" class="group-guide"
                :class="{ walk: !('tripId' in guideForRender[0]) }">
                <div class="line"
                  :style="'tripId' in guideForRender[0] ? { borderColor: '#' + guideForRender[0].routeColor } : {}"></div>
                <div class="point start" v-if="'tripId' in guideForRender[0]"
                  :style="{ borderColor: '#' + guideForRender[0].routeColor }"></div>
                <div class="point end" v-if="'tripId' in guideForRender[0]"
                  :style="{ borderColor: '#' + guideForRender[0].routeColor }"></div>
                <div class="group-guide-step">
                  <template v-for="(guideStep, indexOfGuideStep) in guideForRender">
                    <div :key="guideStep.id" class="guide-step" :class="{ ripple: !('tripId' in guideForRender[0]) }"
                      v-if="'tripId' in guideForRender[0] || (!('tripId' in guideForRender[0]) && guideStep.distance > 0)"
                      @click="clickTransitGuideStep(i, indexOfGuideStep)">
                      <div class="left content">
                        <template v-if="'tripId' in guideForRender[0]">
                          <div class="trip">
                            <div class="by">
                              <i class="material-icons-round" :style="{ color: '#' + guideForRender[0].routeColor }">{{
                                utility.transitIconList[guideStep.by] }}</i>
                            </div>
                            <div class="line" :class="{ 'has-icon': guideStep.by < 6 }"
                              :style="{ backgroundColor: '#' + guideForRender[0].routeColor, color: guideForRender[0].textColor }">
                              <div v-if="guideStep.by < 6" class="icon"
                                :style="guideStep.by === 4 ? { backgroundColor: 'rgb(255, 255, 255)' } : {}">
                                <img
                                  :src="calTransitImgPath(guideStep.by, guideStep.text, 2)"
                                  :srcset="calTransitImgPath(guideStep.by, guideStep.text, 1) + calTransitImgPath(guideStep.by, guideStep.text, 2)"
                                  :style="guideStep.by === 2 ? { transform: 'translateY(-0.5px)' } : {}">
                              </div>
                              <template v-if="guideStep.by < 3 || guideStep.by === 10">
                                {{ $t('routingPanel.' + guideStep.text) }}
                              </template>
                              <template v-else>
                                {{ guideStep.text }}
                              </template>
                            </div>
                            <div class="terminate">
                              {{ $t('routingPanel.terminate') }} {{ guideStep.tripHeadsign }}
                            </div>
                          </div>
                        </template>
                        <template v-else>
                          {{ guideStep.turnText }} {{ guideStep.text }} <template v-if="guideStep.distance > 0">{{
                            kmFormat(guideStep.distance) }}</template>
                        </template>
                        <div class="count-stop" v-if="'tripId' in guideForRender[0]">
                          {{ guideStep.count }} {{ $t('routingPanel.stopUnit[' + guideStep.by + ']') }} {{
                            kmFormat(guideStep.distance) }}
                          <i class="material-icons-round ripple" :class="{ up: guideStep.isShowThruStopList }"
                            v-if="guideStep.count > 1" @click.stop="toggleThruStopList(guideStep)">expand_more</i>
                        </div>
                        <div class="thru-stop-list" :class="{ hide: !guideStep.isShowThruStopList }">
                          <template v-for="thruStop in guideStep.thruStopList">
                            <div :key="indexOfGuideStep + '-' + thruStop.stopId" class="thru-stop"
                              @click.stop="clickTransitGuideThruStop(thruStop)">
                              <div class="thru-point"></div>
                              <div class="name ripple">{{ thruStop.stopName }}</div>
                            </div>
                          </template>
                        </div>
                      </div>
                      <div class="right content" v-if="'tripId' in guideForRender[0]">
                        <div class="time" v-if="guideStep.interval > 0">{{ timeFormat(guideStep.interval) }}</div>
                        <div class="fee" v-if="guideStep.fee > 0">{{ guideStep.fee + ' ' + $t('routingPanel.baht') }}
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </template>
            <template v-else>
              <div :ref="'guideForRender' + i" :key="'groupGuideStep-' + i" class="group-guide walk no-data">
                <div class="line"></div>
              </div>
            </template>
          </template>
          <div :ref="'guideForRender' + i" v-else-if="(guideForRender instanceof Object)" :key="guideForRender.id"
            class="guide-stop ripple" @click="clickTransitGuideStep(i, null)">
            {{ guideForRender.text }}
          </div>
        </template>
        <div ref="endPointRender" class="point ripple" @click="clickTransitEndPoint">
          <i class="material-icons-round">radio_button_checked</i>
          {{ (pointInputList[pointInputList.length - 1].isCurrentLocation ? $t('routingPanel.currentLocation') :
            pointInputList[pointInputList.length - 1].keyword) }}
        </div>
      </div>
    </div>
    <Navigation v-if="$route.hash === '#navigation'" :meta="navigationData.meta" :guide="navigationData.guide"
      :path="navigationData.path" :mainMap="map" :baseMap="baseMap" />
  </div>
</template>

<script>
import { Datetime } from 'vue-datetime'
import ChooseLocationWidget from '@/components/ChooseLocationWidget.vue'
import TransitMethodCard from '@/components/TransitMethodCard.vue'
import Navigation from '@/components/Navigation.vue'
import axios from 'axios'

export default {
  name: 'RoutingPanel',
  components: {
    ChooseLocationWidget,
    TransitMethodCard,
    VueDatetime: Datetime,
    Navigation
  },
  props: {
    map: {
      type: Object
    },
    baseMap: {
      type: String
    },
    userData: {
      type: Object
    },
    isGeolocationMobileAppDisabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isShowingMapOnly: false,
      pointInputList: [{
        keyword: '',
        name: '',
        address: '',
        location: {
          lat: null,
          lon: null
        },
        isCurrentLocation: true
      }, {
        keyword: '',
        name: '',
        address: '',
        location: null,
        isCurrentLocation: false
      }],
      selectedPointInput: null,
      selectedPointInputIndex: -1,
      selectedVehicle: 'car',
      vehicleList: {
        car: {
          icon: 'directions_car',
          routeModeList: [{
            name: 'fastest',
            value: 'c'
          }, {
            name: 'shortcut',
            value: 'd'
          }, {
            name: 'traffic_fastest',
            value: 't'
          }, {
            name: 'traffic_shortcut',
            value: 'e'
          }],
          selectedRouteModeValue: 't',
          routeRestrict: 0,
          routeTypeList: [{
            name: 'road',
            value: 1
          }, {
            name: 'ferry',
            value: 8
          }, {
            name: 'tollway',
            value: 16
          }],
          selectedRouteTypeValueList: [1, 8, 16]
        },
        // transit: null,
        transit: {
          icon: 'directions_transit',
          routeModeList: [{
            name: 'price_bus',
            value: 'price'
          }, {
            name: 'distance_bus',
            value: 'distance'
          }, {
            name: 'fastest_bus',
            value: 'time'
          }],
          selectedRouteModeValue: 'time',
          routeTypeList: [{
            name: 'walk',
            value: 'walk'
          }, {
            name: 'bus',
            value: 'bus'
          }, {
            name: 'train',
            value: 'train'
          }, {
            name: 'metro',
            value: 'metro'
          }, {
            name: 'ferry',
            value: 'ferry'
          }],
          selectedRouteTypeValueList: ['walk', 'bus', 'train', 'metro', 'ferry']
        },
        motorbike: {
          icon: 'two_wheeler',
          routeModeList: [{
            name: 'fastest',
            value: 'c'
          }, {
            name: 'shortcut',
            value: 'd'
          }, {
            name: 'traffic_fastest',
            value: 't'
          }, {
            name: 'traffic_shortcut',
            value: 'e'
          }],
          selectedRouteModeValue: 't',
          routeRestrict: 1,
          routeTypeList: [{
            name: 'road',
            value: 1
          }, {
            name: 'ferry',
            value: 8
          }, {
            name: 'tollway',
            value: 16
          }],
          selectedRouteTypeValueList: [1, 8, 16]
        }
        // walk: {
        //   icon: 'directions_walk',
        //   routeModeList: [{
        //     name: 'walk',
        //     value: 'w'
        //   }],
        //   selectedRouteModeValue: 'w',
        //   routeRestrict: 0,
        //   routeTypeList: [{
        //     name: 'road',
        //     value: 1
        //   }],
        //   selectedRouteTypeValueList: [1]
        // }
      },
      cacheGuideData: [],
      guideList: [],
      cachePathData: [],
      isShowingGuidePanel: false,
      isShowingTransitMethodPanel: false,
      isFullHeightGuidePanel: false,
      isMinimizeHeightGuidePanel: false,
      isMinimizeHeightInputPanel: false,
      isRouting: false,
      selectedGuideIndex: 0,
      markerList: [],
      selectedTransitMethodIndex: null,
      isFullHeightTransitGuidePanel: false,
      thruStopList: [],
      isShowSettingPanel: false,
      selectedRouteType: [],
      selectedRouteMode: '',
      departDatetime: '',
      navigationData: {},
      cancleTokenSourceList: [],
      dragMarkerIndex: -1,
      dragMarkerOffset: {
        x: 0,
        y: 0
      },
      isShowRouteChangeColor: false,
      colorRouteChoose: 'R',
      colorTimeChoose: 0,
      colorWeightChoose: 1,
      colorRouteSelectType: 'minuteSelected',
      colorRouteDateTime: '',
      permissions: [],
      responseFromColorRoute: ''
    }
  },
  computed: {
    longdo_pin_url () {
      return process.env.VUE_APP_LONGDO_MAP_PIN
    },
    isHidingInputPanel () {
      const self = this
      return self.$route.hash !== '#routing' && self.$route.hash !== '#choose-location-for-routing' && self.$route.hash !== '#navigation'
    },
    isHidingGuidePanel () {
      const self = this
      return (self.$route.hash !== '#routing' && self.$route.hash !== '#choose-location-for-routing') || !self.isShowingGuidePanel || self.hasNullPointInput || self.selectedVehicle === 'transit'
    },
    isHidingTransitMethodPanel () {
      const self = this
      return (self.$route.hash !== '#routing' && self.$route.hash !== '#choose-location-for-routing') || !self.isShowingTransitMethodPanel || self.hasNullPointInput || self.selectedVehicle !== 'transit' || self.selectedTransitMethodIndex !== null
    },
    isHidingTransitGuidePanel () {
      const self = this
      return (self.$route.hash !== '#routing' && self.$route.hash !== '#choose-location-for-routing') || self.selectedTransitMethodIndex === null || self.hasNullPointInput || self.selectedVehicle !== 'transit' || self.isShowingMapOnly
    },
    isShowingSettingButton () {
      const self = this
      return self.selectedVehicle !== 'walk'
    },
    hasNullPointInput () {
      const self = this
      return self.pointInputList.filter(pointInput => pointInput.location === null).length > 0
    },
    isHasSetTrafficIticPermission () {
      const self = this
      if (process.env.VUE_APP_PRODUCT === 'traffic') {
        if (self.userData) return self.permissions.includes('set traffic itic')
        else return false
      } else return false
    },
    transitPointInputList () {
      const self = this
      return [
        self.pointInputList[0],
        self.pointInputList[self.pointInputList.length - 1]
      ]
    }
  },
  mounted () {
    const self = this
    self.$parent.$on('routeTo', self.onRouteTo)
    self.$on('reRoute', self.reRoute)
    self.$root.$on('goto', (location) => {
      const routeObj = {
        keyword: `${location.lat.toFixed(6)}, ${location.lon.toFixed(6)}`,
        name: `${location.lat.toFixed(6)}, ${location.lon.toFixed(6)}`,
        address: '',
        location,
        isCurrentLocation: false
      }
      self.pointInputList[self.pointInputList.length - 1].location === null
        ? self.$set(self.pointInputList, self.pointInputList.length - 1, routeObj)
        : self.pointInputList.push(routeObj)
      if (!self.hasNullPointInput) {
        self.route()
      }
    })
    self.$root.$on('gofrom', (location) => {
      const routeObj = {
        keyword: `${location.lat.toFixed(6)}, ${location.lon.toFixed(6)}`,
        name: `${location.lat.toFixed(6)}, ${location.lon.toFixed(6)}`,
        address: '',
        location,
        isCurrentLocation: false
      }
      self.pointInputList.length <= 2 && self.pointInputList[0].isCurrentLocation
        ? self.$set(self.pointInputList, 0, routeObj)
        : self.pointInputList.unshift(routeObj)
      if (!self.hasNullPointInput) {
        self.route()
      }
    })
    self.$parent.$on('clickMap', self.onClickMap)
    self.$parent.$on('dropRoutingMarker', self.onMarkerDragAndDrop)
    self.map.Event.bind('overlayDrop', self.onMarkerDrop)
    self.map.Event.bind('overlayClick', self.onOverlayClick)
    window.addEventListener('resize', self.updatedTransitGuideRender)

    // support routing by url
    if (self.$route.name === 'Go') {
      window.navigator.geolocation.getCurrentPosition(
        e => {
          console.log(e)
          window.currentLocation = {
            lat: e.coords.latitude,
            lon: e.coords.longitude
          }
          self.onRouteTo({
            lat: Number(self.$route.query.tlat),
            lon: Number(self.$route.query.tlon)
          })
        },
        e => {
          console.log(e)
          self.onRouteTo({
            lat: Number(self.$route.query.tlat),
            lon: Number(self.$route.query.tlon)
          })
        },
        {
          maximumAge: 1e3,
          timeout: 15e3,
          enableHighAccuracy: true
        }
      )
    }
    if (self.isGeolocationMobileAppDisabled && JSON.stringify(self.pointInputList[0]) === JSON.stringify({
      keyword: '',
      name: '',
      address: '',
      location: {
        lat: null,
        lon: null
      },
      isCurrentLocation: true
    })) {
      self.$set(self.pointInputList, 0, {
        keyword: '',
        name: '',
        address: '',
        location: null,
        isCurrentLocation: false
      })
    }
  },
  methods: {
    onClickSettingButton () {
      const self = this
      self.isShowSettingPanel = !self.isShowSettingPanel
      self.storeEventToNativeAnalytic(`${self.isShowSettingPanel ? 'open' : 'close'}_route_setting`)
    },
    async getPermission () {
      const self = this
      const userInfo = await self.api.getUserInfo({ username: self.userData.name, ldtoken: self.userData.longdousertoken })
      self.permissions = userInfo.data.data.permissions
    },
    dragStartHandler (event, index) {
      const self = this
      self.dragMarkerIndex = self.selectedVehicle === 'transit' ? (index === 0 ? 0 : self.pointInputList.length - 1) : index
      const dragMarker = document.getElementById('drag-element-' + index)
      const boundingRect = dragMarker.getBoundingClientRect()
      self.dragMarkerOffset.x = event.clientX - boundingRect.left - boundingRect.width / 2
      self.dragMarkerOffset.y = event.clientY - boundingRect.top - boundingRect.height
      event.dataTransfer.effectAllowed = 'move'
      event.dataTransfer.dropEffect = 'move'
    },
    dragEndHandler () {
      const self = this
      self.dragMarkerIndex = -1
    },
    onMarkerDragAndDrop (event) {
      const self = this
      // if dragging from input panel
      if (self.dragMarkerIndex !== -1) {
        let marker
        if (self.isLongdoMapV3) {
          marker = new window.longdo.Marker(self.map.location({ x: event.clientX - self.dragMarkerOffset.x, y: event.clientY - self.dragMarkerOffset.y + 6 }))
        } else {
          marker = new window.longdo.Marker(self.map.location({ x: event.clientX - self.dragMarkerOffset.x - 12.5, y: event.clientY - self.dragMarkerOffset.y - 66 }))
        }
        marker.destIndex = self.dragMarkerIndex
        self.onMarkerDrop(marker)
      }
    },
    // chooseLocationLocationChange (loc) {
    //   const self = this
    //   self.map.location(loc, false)
    // },
    // chooseLocationZoomChange (zoom) {
    //   const self = this
    //   self.map.zoom(zoom, false)
    // },
    turnIcon (code) {
      const url = process.env.VUE_APP_LONGDO_MAP_TURN_IMAGE
      return url.replace('{{code}}', code)
    },
    kmFormat (meter) {
      const self = this
      if (meter < 1000) {
        return meter + ' ' + self.$t('routingPanel.meter')
      } else {
        const km = (meter / 1000.0).toLocaleString(self.$i18n.locale, {
          maximumFractionDigits: 1
        })
        return km + ' ' + self.$t('routingPanel.kilometer')
      }
    },
    timeFormat (sec) {
      const self = this
      if (sec < 60) {
        return 1 + self.$t('routingPanel.min')
      } else {
        const min = Math.floor(sec / 60)
        if (min < 60) {
          return min + ' ' + self.$t('routingPanel.min')
        } else {
          const hr = Math.floor(min / 60)
          return hr + ' ' + self.$t('routingPanel.hr') + ((min - (hr * 60)) > 0 ? ' ' + (min - (hr * 60)) + ' ' + self.$t('routingPanel.min') : '')
        }
      }
    },
    async reRoute () {
      const self = this
      await self.route(true)
      var path = []
      self.guideList[self.selectedGuideIndex].forEach(g => {
        path = path.concat(g.path)
      })
      const from = path[0][0]
      const to = path[path.length - 1][path[path.length - 1].length - 1]
      self.navigationData = {
        meta: {
          from: {
            lat: Number(from.lat),
            lon: Number(from.lon)
          },
          to: {
            lat: Number(to.lat),
            lon: Number(to.lon)
          }
        },
        guide: self.guideList[self.selectedGuideIndex],
        path: path
      }
      self.$emit('redrawNavigationPath')
    },
    onRouteTo (destination) {
      const self = this
      let selectedPoi = null
      self.map.Overlays.list().forEach(o => {
        if ('searchObjectData' in o) {
          if (o.searchObjectData.id === self.$route.params.poiId) {
            selectedPoi = o.searchObjectData
          }
        }
      })
      if (selectedPoi === null) {
        selectedPoi = {
          name: `${Number(destination.lat).toFixed(6)}, ${Number(destination.lon).toFixed(6)}`,
          name_en: `${Number(destination.lat).toFixed(6)}, ${Number(destination.lon).toFixed(6)}`
        }
      }
      if (self.$route.hash !== '#routing') {
        self.$router.push({
          name: 'Main',
          query: {},
          hash: '#routing'
        })
      }
      self.pointInputList = [{
        keyword: '',
        name: '',
        address: '',
        location: {
          lat: null,
          lon: null
        },
        isCurrentLocation: true
      }, {
        keyword: self.$i18n.locale === 'th' ? selectedPoi.name : selectedPoi.name_en,
        name: self.$i18n.locale === 'th' ? selectedPoi.name : selectedPoi.name_en,
        address: '',
        location: destination,
        isCurrentLocation: false
      }]
    },
    onClickMap (isShowingMapOnly) {
      const self = this
      self.isShowingMapOnly = isShowingMapOnly
      if (self.selectedVehicle !== 'transit') {
        // if (isShowingMapOnly && self.pointInputList[0].location !== null && self.pointInputList[self.pointInputList.length - 1].location !== null) {
        if (isShowingMapOnly && !self.hasNullPointInput) {
          if (window.innerWidth <= self.mobileMaxSize) {
            self.isMinimizeHeightInputPanel = true
          }
          self.isMinimizeHeightGuidePanel = true
        } else {
          self.isMinimizeHeightGuidePanel = false
        }
      } else {
        if (isShowingMapOnly && !self.hasNullPointInput) {
          if (window.innerWidth <= self.mobileMaxSize) {
            self.isMinimizeHeightInputPanel = true
          }
        }
      }
    },
    async onMarkerDrop (marker) {
      const self = this
      if (marker instanceof window.longdo.Marker && 'destIndex' in marker) {
        const markerIndex = marker.destIndex
        const loc = self.isLongdoMapV3 ? marker._geojson.geometry.coordinates : marker.location()
        const lat = self.isLongdoMapV3 ? loc[1] : loc.lat
        const lon = self.isLongdoMapV3 ? loc[0] : loc.lon
        const params = {
          lat: lat,
          lon: lon,
          offset: 0,
          limit: 1,
          locale: self.$i18n.locale,
          dataset: 'poi-changed-today,poi-contrib,main2p,osm-points',
          span: '20m'
        }
        const res = await self.api.searchNearbyPlace(params)
        let newPoint
        if (res.status === 200 && res.data.data.length > 0) {
          newPoint = {
            keyword: res.data.data[0].name !== '' ? res.data.data[0].name : `${lat.toFixed(6)}, ${lon.toFixed(6)}`,
            name: res.data.data[0].name !== '' ? res.data.data[0].name : `${lat.toFixed(6)}, ${lon.toFixed(6)}`,
            address: res.data.data[0].address,
            location: { lon: res.data.data[0].lon, lat: res.data.data[0].lat },
            isCurrentLocation: false
          }
        } else {
          const address = await self.getAddressString(lat, lon)
          newPoint = {
            keyword: `${lat.toFixed(6)}, ${lon.toFixed(6)} (${address})`,
            name: `${lat.toFixed(6)}, ${lon.toFixed(6)} (${address})`,
            address: '',
            location: { lon: lon, lat: lat },
            isCurrentLocation: false
          }
        }
        self.$set(self.pointInputList, markerIndex, newPoint)
        // self.pointInputList[markerIndex] = newPoint
        if (!self.hasNullPointInput) {
          self.route()
        }
      }
    },
    async getAddressString (lat, lon) {
      const self = this
      const addressKeyList = ['aoi', 'water', 'house_num', 'moo_name', 'moo', 'road', 'subdistrict', 'district', 'province', 'postcode', 'country']
      const result = await self.api.getAddress({
        lat: lat,
        lon: lon,
        locale: self.$i18n.locale
      })
      let address = ''
      if (result.status === 200) {
        addressKeyList.forEach(addressKey => {
          address += (addressKey in result.data) ? (result.data[addressKey] + (self.$i18n.locale === 'th' ? ' ' : ', ')) : ''
        })
        address = address.substr(0, address.length - (self.$i18n.locale === 'th' ? 1 : 2))
      }
      return address
    },
    onOverlayClick (e) {
      const self = this
      // need to draw blue line at last so it won't be below gray line
      // https://mmtech.slack.com/archives/C02FDEMLP/p1697530439936389?thread_ts=1697519726.651539&cid=C02FDEMLP
      if (e instanceof window.longdo.Polyline) {
        if ('guideIndex' in e) {
          self.selectedGuideIndex = e.guideIndex
          self.guideList.forEach((guide, guideIndex) => {
            guide.forEach(aToB => {
              if (guideIndex !== e.guideIndex) {
                let locationList = []
                aToB.path.forEach(path => {
                  locationList = locationList.concat(path)
                })
                const newPolyline = new window.longdo.Polyline(locationList, {
                  lineWidth: 6,
                  lineColor: 'rgb(175, 175, 175)',
                  borderWidth: 3,
                  borderColor: 'rgb(150, 150, 150)'
                })
                newPolyline.guideIndex = guideIndex
                self.map.Overlays.remove(aToB.polyline)
                aToB.polyline = newPolyline
                self.map.Overlays.add(aToB.polyline)
              }
            })
          })
          self.guideList[e.guideIndex].forEach(aToB => {
            let locationList = []
            aToB.path.forEach(path => {
              locationList = locationList.concat(path)
            })
            const newPolyline = new window.longdo.Polyline(locationList, {
              lineWidth: 8,
              lineColor: 'rgb(56, 148, 238)',
              borderWidth: 3,
              borderColor: 'rgb(255, 255, 255)'
            })
            newPolyline.guideIndex = e.guideIndex
            self.map.Overlays.remove(aToB.polyline)
            aToB.polyline = newPolyline
            self.map.Overlays.add(aToB.polyline)
          })
        }
      }
    },
    clickVehicle (vehicle) {
      const self = this
      self.selectedVehicle = vehicle
      self.$root.$emit('froceDoNotShowingMapOnly')
      if (!self.hasNullPointInput) {
        self.route()
      }
    },
    clickPointInput (index) {
      const self = this
      self.selectedPointInputIndex = index
      // self.selectedPointInput = self.pointInputList[index]
      // also set index in selectedpoint input
      self.$router.push({
        name: 'Main',
        hash: '#choose-location-for-routing'
      })
      self.$root.$emit('froceDoNotShowingMapOnly')
    },
    clearPointInput (index) {
      const self = this
      self.pointInputList.splice(index, 1)
      self.removeAllMarker()
      self.markerList = []
      self.removeAllPolyline()
      self.guideList = []
      if (!self.hasNullPointInput) {
        self.route()
      }
    },
    onClickBackFromChooseLocationWidget () {
      const self = this
      self.selectedPointInputIndex = -1
      // self.selectedPointInput = null
      self.$router.replace({
        name: 'Main',
        hash: '#routing'
      })
    },
    onClickSearchResultFromChooseLocationWidget (searchResult) {
      const self = this
      const newPoint = {}
      const isLatLon = self.utility.validateLatLonFromString(searchResult.name)
      newPoint.keyword = isLatLon ? `${searchResult.name} (${searchResult.address})` : `${searchResult.name}`
      newPoint.name = isLatLon ? `${searchResult.name} (${searchResult.address})` : `${searchResult.name}`
      newPoint.address = searchResult.address
      newPoint.location = {
        lat: null,
        lon: null
      }
      newPoint.isCurrentLocation = false
      newPoint.location.lat = searchResult.lat
      newPoint.location.lon = searchResult.lon
      self.$set(self.pointInputList, self.selectedPointInputIndex, newPoint)
      self.selectedPointInputIndex = -1
      self.$router.replace({
        name: 'Main',
        hash: '#routing'
      })
      if (!self.hasNullPointInput) {
        self.route()
      }
    },
    onClickCurrentLocationFromChooseLocationWidget () {
      const self = this
      if (!self.isGeolocationMobileAppDisabled) {
        const newPoint = {}
        newPoint.keyword = ''
        newPoint.name = ''
        newPoint.address = ''
        newPoint.location = {
          lat: null,
          lon: null
        }

        newPoint.isCurrentLocation = true
        self.$set(self.pointInputList, self.selectedPointInputIndex, newPoint)
        self.selectedPointInputIndex = -1
        self.$router.replace({
          name: 'Main',
          hash: '#routing'
        })
        if (!self.hasNullPointInput) {
          self.route()
        }
      } else {
        self.$root.$emit('openMobilePermissionDialog')
      }
    },
    swapPointInput (i, j) {
      const self = this
      const temp = self.pointInputList[i]
      self.$set(self.pointInputList, i, self.pointInputList[j])
      self.$set(self.pointInputList, j, temp)
      if (!self.hasNullPointInput) {
        self.route()
      }
    },
    addPointInput () {
      const self = this
      self.pointInputList.push({
        keyword: '',
        name: '',
        address: '',
        location: null,
        isCurrentLocation: false
      })
      self.selectedTransitMethodIndex = null
    },
    removeAllMarker () {
      const self = this
      self.markerList.forEach(marker => {
        self.map.Overlays.remove(marker)
      })
    },
    removeAllPolyline () {
      const self = this
      for (let i = 0; i < self.guideList.length; i++) {
        if (self.guideList[i] instanceof Array) {
          for (let j = 0; j < self.guideList[i].length; j++) {
            self.map.Overlays.remove(self.guideList[i][j].polyline)
          }
        }
        self.map.Overlays.list().forEach(p => {
          if ('transitGuideIndex' in p) {
            self.map.Overlays.remove(p)
          }
        })
      }
    },
    resetRouting () {
      const self = this
      if (!self.isGeolocationMobileAppDisabled) {
        self.pointInputList = [{
          keyword: '',
          name: '',
          address: '',
          location: {
            lat: null,
            lon: null
          },
          isCurrentLocation: true
        }, {
          keyword: '',
          name: '',
          address: '',
          location: null,
          isCurrentLocation: false
        }]
      } else {
        self.pointInputList = [{
          keyword: '',
          name: '',
          address: '',
          location: null,
          isCurrentLocation: false
        }, {
          keyword: '',
          name: '',
          address: '',
          location: null,
          isCurrentLocation: false
        }]
      }
      self.removeAllMarker()
      self.markerList = []
      self.removeAllPolyline()
      self.guideList = []
      self.isMinimizeHeightInputPanel = false
      self.isShowingGuidePanel = false
      self.selectedTransitMethodIndex = null
    },
    async route () {
      // since our input is not async but method is async we cancel former request
      const self = this
      if (self.cancleTokenSourceList.length !== 0) {
        self.cancleTokenSourceList.forEach((source) => {
          source.cancel()
        })
        self.cancleTokenSourceList = []
      }
      const currentLocation = window.currentLocation
      self.isRouting = true
      if (window.innerWidth <= self.mobileMaxSize) {
        self.isMinimizeHeightInputPanel = true
      }
      self.removeAllMarker()
      self.markerList = []
      self.removeAllPolyline()
      self.selectedTransitMethodIndex = null
      self.$root.$emit('froceDoNotShowingMapOnly')

      self.pointInputList.forEach((pointInput, index) => {
        try {
          const imagePath = self.utility.getImgPath(`marker/ic_pin_${index > 0 && index < self.pointInputList.length - 1 ? `blue_${(index)}` : index === self.pointInputList.length - 1 ? 'green' : 'red'}.svg`)
          const marker = new window.longdo.Marker(pointInput.isCurrentLocation ? currentLocation : pointInput.location, {
            icon: {
              html: '<div style="position: relative; width: ' + process.env.VUE_APP_LONGDO_MAP_PIN_WIDTH + 'px; height: calc(' + process.env.VUE_APP_LONGDO_MAP_PIN_HEIGHT + 'px + 10px);"><img style="position: absolute; top: 0px; left: 0px; width: ' + process.env.VUE_APP_LONGDO_MAP_PIN_WIDTH + 'px; height: ' + process.env.VUE_APP_LONGDO_MAP_PIN_HEIGHT + 'px; z-index: 2;" src="' + imagePath + '">',
              offset: (this.$route.query.map || 'v3') === 'v3' ? {
                x: 0,
                y: -(Number.parseInt(process.env.VUE_APP_LONGDO_MAP_PIN_HEIGHT) / 2)
              } : {
                x: process.env.VUE_APP_LONGDO_MAP_PIN_WIDTH / 2,
                y: process.env.VUE_APP_LONGDO_MAP_PIN_HEIGHT
              }
            },
            draggable: !self.isOnMobileApp
          })
          marker.destIndex = index
          self.markerList.push(marker)
          self.map.Overlays.add(marker)
        } catch (err) {}
      })

      const vehicle = self.vehicleList[self.selectedVehicle]
      let locationListForBound = []
      if (self.selectedVehicle !== 'transit') {
        self.isShowingGuidePanel = true
        self.isShowingTransitMethodPanel = false

        // [[]] only one choice for A -> B -> C -> ... -> Destination
        // [] 3 choices for A -> B
        const isMultipleRoute = self.pointInputList.length > 2
        self.guideList = isMultipleRoute ? [[]] : []
        const maxResult = isMultipleRoute ? 1 : 1

        for (let i = 0; i < self.pointInputList.length - 1; i++) {
          if (self.pointInputList[i].isCurrentLocation) {
            self.pointInputList[i].location = currentLocation
          }
          if (self.pointInputList[i + 1].isCurrentLocation) {
            self.pointInputList[i + 1].location = currentLocation
          }
          const params = {
            flon: self.pointInputList[i].location.lon,
            flat: self.pointInputList[i].location.lat,
            tlon: self.pointInputList[i + 1].location.lon,
            tlat: self.pointInputList[i + 1].location.lat,
            mode: vehicle.selectedRouteModeValue,
            type: vehicle.selectedRouteTypeValueList.reduce((total, n) => {
              return total + n
            }),
            locale: self.$i18n.locale,
            restrict: vehicle.routeRestrict,
            time: self.departDatetime === '' ? '' : Math.round((+self.utility.getDateFromString(self.departDatetime)) / 1000),
            maxresult: maxResult
          }
          const paramsString = JSON.stringify(params)
          const index = self.cacheGuideData.map(c => c[0]).indexOf(paramsString)
          const isFoundCacheGuideData = index !== -1
          let resultGuide = null
          if (isFoundCacheGuideData) {
            resultGuide = self.cacheGuideData[index][1]
          } else {
            const routeGuideCancelToken = axios.CancelToken
            const routeGuideSource = routeGuideCancelToken.source()
            self.cancleTokenSourceList.push(routeGuideSource)
            const res = await self.api.getRouteGuide(params, routeGuideSource.token)
            if (res.status === 200) {
              resultGuide = res.data.data
              self.cacheGuideData.push([
                paramsString,
                resultGuide
              ])
            }
          }
          if (resultGuide) {
            resultGuide.forEach(g => {
              g.guide.splice(0, 0, {
                turn: 6,
                name: g.guide[0].name,
                distance: Math.ceil(g.fdistance),
                interval: 0
              })
              g.guide.splice(g.guide.length, 0, {
                turn: 6,
                name: self.$t('routingPanel.destination'),
                distance: Math.ceil(g.tdistance),
                interval: 0
              })
              if (isMultipleRoute) {
                self.guideList[0].push(g)
              } else {
                self.guideList.push([g])
              }
            })
          }
        }

        // index จาก length ไป 0 i-- เพราะให้เส้นสีฟ้าอยู่บนสุด
        for (let i = self.guideList.length - 1; i >= 0; i--) {
          for (let j = 0; j < self.guideList[i].length; j++) {
            const guideAToB = self.guideList[i][j]
            const index = self.cachePathData.map(c => c[0]).indexOf(guideAToB.id)
            const isFoundCachePathData = index !== -1
            let resultPath = null
            if (isFoundCachePathData) {
              resultPath = self.cachePathData[index][1]
            } else {
              const params = {
                id: guideAToB.id
              }
              const routePathCancelToken = axios.CancelToken
              const routePathCancelSource = routePathCancelToken.source()
              self.cancleTokenSourceList.push(routePathCancelSource)
              const res = await self.api.getRoutePath(params, routePathCancelSource.token)
              if (res.status === 200) {
                resultPath = res.data.data
                const firstPath = resultPath[0]
                resultPath.splice(0, 0, [
                  self.pointInputList[j].location,
                  firstPath[0]
                ])
                const lastPath = resultPath[resultPath.length - 1]
                resultPath.push([
                  lastPath[lastPath.length - 1],
                  self.pointInputList[j + 1].location
                ])
                self.cachePathData.push([
                  guideAToB.id,
                  resultPath
                ])
              }
            }
            if (resultPath) {
              guideAToB.path = resultPath
              guideAToB.polyline = null

              let locationList = []
              guideAToB.path.forEach(path => {
                locationList = locationList.concat(path)
              })
              const polyline = new window.longdo.Polyline(locationList, {
                lineWidth: i === 0 ? 8 : 6,
                lineColor: i === 0 ? 'rgb(56, 148, 238)' : 'rgb(175, 175, 175)',
                weight: i === 0 ? 1 : 0,
                borderWidth: 3,
                borderColor: i === 0 ? 'rgb(255, 255, 255)' : 'rgb(150, 150, 150)'
              })
              polyline.guideIndex = i
              guideAToB.polyline = polyline
              self.map.Overlays.add(polyline)
              locationListForBound = locationListForBound.concat(locationList)
              self.selectedGuideIndex = 0
              if (self.guideList.length > 0) {
                self.focusStep(0, 0)
                const boundingBox = window.longdo.Util.locationBound(locationListForBound)
                const maxLat = boundingBox.maxLat + ((boundingBox.maxLat - boundingBox.minLat) * 0.32)
                const minLat = boundingBox.minLat - ((boundingBox.maxLat - boundingBox.minLat) * 0.37)
                boundingBox.maxLat = maxLat
                boundingBox.minLat = minLat
                self.map.bound(boundingBox)
              }
              self.isRouting = false
            }
          }
        }
        // for 2 point we need 3 route but at top we show 1 first to make it fast
        // then we draw 3 route later
        if (!isMultipleRoute) {
          for (let i = 0; i < self.pointInputList.length - 1; i++) {
            if (self.pointInputList[i].isCurrentLocation) {
              self.pointInputList[i].location = currentLocation
            }
            if (self.pointInputList[i + 1].isCurrentLocation) {
              self.pointInputList[i + 1].location = currentLocation
            }
            const params = {
              flon: self.pointInputList[i].location.lon,
              flat: self.pointInputList[i].location.lat,
              tlon: self.pointInputList[i + 1].location.lon,
              tlat: self.pointInputList[i + 1].location.lat,
              mode: vehicle.selectedRouteModeValue,
              type: vehicle.selectedRouteTypeValueList.reduce((total, n) => {
                return total + n
              }),
              locale: self.$i18n.locale,
              restrict: vehicle.routeRestrict,
              time: self.departDatetime === '' ? '' : Math.round((+self.utility.getDateFromString(self.departDatetime)) / 1000),
              maxresult: maxResult
            }
            const paramsString = JSON.stringify(params)
            const index = self.cacheGuideData.map(c => c[0]).indexOf(paramsString)
            const isFoundCacheGuideData = index !== -1
            let resultGuide = null
            if (isFoundCacheGuideData) {
              resultGuide = self.cacheGuideData[index][1]
            } else {
              const routeGuideCancelToken = axios.CancelToken
              const routeGuideSource = routeGuideCancelToken.source()
              self.cancleTokenSourceList.push(routeGuideSource)
              const res = await self.api.getRouteGuide(params, routeGuideSource.token)
              if (res.status === 200) {
                resultGuide = res.data.data
                self.cacheGuideData.push([
                  paramsString,
                  resultGuide
                ])
              }
            }
            if (resultGuide) {
              resultGuide.forEach((g, index) => {
                if (index !== 0) {
                  g.guide.splice(0, 0, {
                    turn: 6,
                    name: g.guide[0].name,
                    distance: Math.ceil(g.fdistance),
                    interval: 0
                  })
                  g.guide.splice(g.guide.length, 0, {
                    turn: 6,
                    name: self.$t('routingPanel.destination'),
                    distance: Math.ceil(g.tdistance),
                    interval: 0
                  })
                  self.guideList.push([g])
                }
              })
            }
          }
          for (let i = self.guideList.length - 1; i >= 0; i--) {
            for (let j = 0; j < self.guideList[i].length; j++) {
              const guideAToB = self.guideList[i][j]
              const index = self.cachePathData.map(c => c[0]).indexOf(guideAToB.id)
              const isFoundCachePathData = index !== -1
              let resultPath = null
              if (isFoundCachePathData) {
                resultPath = self.cachePathData[index][1]
              } else {
                const params = {
                  id: guideAToB.id
                }
                const routePathCancelToken = axios.CancelToken
                const routePathCancelSource = routePathCancelToken.source()
                self.cancleTokenSourceList.push(routePathCancelSource)
                const res = await self.api.getRoutePath(params, routePathCancelSource.token)
                if (res.status === 200) {
                  resultPath = res.data.data
                  const firstPath = resultPath[0]
                  resultPath.splice(0, 0, [
                    self.pointInputList[j].location,
                    firstPath[0]
                  ])
                  const lastPath = resultPath[resultPath.length - 1]
                  resultPath.push([
                    lastPath[lastPath.length - 1],
                    self.pointInputList[j + 1].location
                  ])
                  self.cachePathData.push([
                    guideAToB.id,
                    resultPath
                  ])
                }
              }
              if (resultPath) {
                if (i === 0) {
                  self.map.Overlays.remove(self.guideList[i][j].polyline)
                }
                guideAToB.path = resultPath
                guideAToB.polyline = null

                let locationList = []
                guideAToB.path.forEach((path) => {
                  locationList = locationList.concat(path)
                })
                const polyline = new window.longdo.Polyline((locationList), {
                  lineWidth: i === 0 ? 8 : 6,
                  lineColor: i === 0 ? 'rgb(56, 148, 238)' : 'rgb(175, 175, 175)',
                  weight: i === 0 ? 1 : 0,
                  borderWidth: 3,
                  borderColor: i === 0 ? 'rgb(255, 255, 255)' : 'rgb(150, 150, 150)'
                })
                polyline.guideIndex = i
                guideAToB.polyline = polyline
                self.map.Overlays.add(polyline)
                locationListForBound = locationListForBound.concat(locationList)
                self.selectedGuideIndex = 0
                if (self.guideList.length > 0) {
                  self.focusStep(0, 0)
                  const boundingBox = window.longdo.Util.locationBound(locationListForBound)
                  const maxLat = boundingBox.maxLat + ((boundingBox.maxLat - boundingBox.minLat) * 0.32)
                  const minLat = boundingBox.minLat - ((boundingBox.maxLat - boundingBox.minLat) * 0.37)
                  boundingBox.maxLat = maxLat
                  boundingBox.minLat = minLat
                  self.map.bound(boundingBox)
                }
              }
            }
          }
        }
      } else { // transit
        self.isShowingGuidePanel = false
        self.isShowingTransitMethodPanel = true

        self.guideList = []

        for (let i = 0; i < self.transitPointInputList.length - 1; i++) {
          if (self.transitPointInputList[i].isCurrentLocation) {
            self.transitPointInputList[i].location = currentLocation
          }
          if (self.transitPointInputList[i + 1].isCurrentLocation) {
            self.transitPointInputList[i + 1].location = currentLocation
          }
          const params = {
            startLon: self.transitPointInputList[i].location.lon,
            startLat: self.transitPointInputList[i].location.lat,
            finishLon: self.transitPointInputList[i + 1].location.lon,
            finishLat: self.transitPointInputList[i + 1].location.lat,
            departureTime: self.departDatetime === '' ? '' : Math.round((+self.utility.getDateFromString(self.departDatetime)) / 1000),
            sort: vehicle.selectedRouteModeValue, // best/time/price/distance
            walk: vehicle.selectedRouteTypeValueList.indexOf('walk') < 0 ? 0 : 1,
            bus: vehicle.selectedRouteTypeValueList.indexOf('bus') < 0 ? 0 : 1,
            train: vehicle.selectedRouteTypeValueList.indexOf('train') < 0 ? 0 : 1,
            metro: vehicle.selectedRouteTypeValueList.indexOf('metro') < 0 ? 0 : 1,
            ferry: vehicle.selectedRouteTypeValueList.indexOf('ferry') < 0 ? 0 : 1,
            locale: self.$i18n.locale,
            maxWalk: 1000, // meter
            wheelchair: 0
          }
          const paramsString = JSON.stringify(params)
          const index = self.cacheGuideData.map(c => c[0]).indexOf(paramsString)
          const isFoundCacheGuideData = index !== -1
          let resultGuide = null
          if (isFoundCacheGuideData) {
            resultGuide = self.cacheGuideData[index][1]
          } else {
            const transitGuideCancelToken = axios.CancelToken
            const transitGuideSource = transitGuideCancelToken.source()
            self.cancleTokenSourceList.push(transitGuideSource)
            const result = await self.api.getTransitGuide(params, transitGuideSource.token)
            if (result.status === 200) {
              resultGuide = result.data.data
              self.cacheGuideData.push([
                paramsString,
                resultGuide
              ])
            }
          }
          resultGuide.forEach(method => {
            method.howTo = []
            method.guideForRender = [[]]

            let fDistance = method.fdistance
            let tDistance = method.tdistance
            let fIndex = 0
            // if (fDistance > 0 && method.guide.length > 0) {
            if (method.guide.length > 0) {
              method.guideForRender[method.guideForRender.length - 1].push({
                distance: Math.ceil(fDistance),
                turnText: self.$t('routingPanel.walk')
              })
            } else if (fDistance > 0 && method.guide.length === 0) {
              method.guideForRender[method.guideForRender.length - 1].push({
                distance: Math.ceil(fDistance + tDistance),
                turnText: self.$t('routingPanel.walk')
              })
            }
            while (fIndex < method.guide.length && 'turnText' in method.guide[fIndex] && !('useTaxi' in method.guide[fIndex])) {
              fDistance += method.guide[fIndex].distance
              fIndex++
            }
            if (tDistance > 0 && fIndex === method.guide.length) {
              fDistance += tDistance
            }
            if (fDistance > 0) {
              method.howTo.push({
                by: 9, // walk
                distance: Math.ceil(fDistance)
              })
            }

            method.guide.forEach(async (guide, index) => {
              if ('stopId' in guide) {
                method.guideForRender.push(guide)
                method.guideForRender.push([])
              } else {
                method.guideForRender[method.guideForRender.length - 1].push(guide)
              }

              if ('useTaxi' in guide) {
                if (guide.useTaxi && (index === 0 || !('useTaxi' in method.guide[index - 1]))) {
                  method.howTo.push({
                    by: 10, // taxi
                    text: 'taxi',
                    color: '#F0F0F0',
                    textColor: '#7D7D7D'
                  })
                  guide.by = 10
                  guide.textColor = '#7D7D7D'
                }
              } else if ('tripId' in guide) {
                const by = Number(method.guide[index - 1].stopIconUrl.replace('images/stop_icon/1x/', '').replace('.png', ''))
                method.howTo.push({
                  by: by, // 1=bts 2=mrt 3=arl 4=srt 5=brt 6=bus 7=minibus 8=ferry
                  text: guide.text,
                  color: '#' + guide.routeColor,
                  textColor: self.utility.getContrastYIQ(guide.routeColor)
                })
                guide.by = by
                guide.textColor = self.utility.getContrastYIQ(guide.routeColor)
                const tripDetailResult = await self.api.getTripDetail(guide.tripId, {
                  locale: self.$i18n.locale
                })
                guide.isShowThruStopList = false
                if (tripDetailResult.data.code === 200) {
                  guide.tripDetail = tripDetailResult.data.data
                  const indexOfFromStop = guide.tripDetail.stopList.map(s => s.stopId).indexOf(method.guide[index - 1].stopId)
                  const indexOfToStop = guide.tripDetail.stopList.map(s => s.stopId).indexOf(method.guide[index + 1].stopId)
                  guide.thruStopList = (indexOfFromStop + 1 === indexOfToStop) ? [] : guide.tripDetail.stopList.slice(indexOfFromStop + 1, indexOfToStop)
                }
              } else {
                guide.by = 9
              }
            })

            // if (tDistance > 0 && method.guide.length > 0) {
            if (method.guide.length > 0) {
              method.guideForRender[method.guideForRender.length - 1].push({
                distance: Math.ceil(tDistance),
                turnText: self.$t('routingPanel.walk')
              })
            }
            if (method.guideForRender[method.guideForRender.length - 1] instanceof Array) {
              if (method.guideForRender[method.guideForRender.length - 1].length === 0) {
                method.guideForRender.pop()
              }
            }

            let tIndex = method.guide.length - 1
            while (tIndex > fIndex && 'turnText' in method.guide[tIndex] && !('useTaxi' in method.guide[tIndex])) {
              tDistance += method.guide[tIndex].distance
              tIndex--
            }
            if (tDistance > 0 && fIndex !== method.guide.length) {
              method.howTo.push({
                by: 9,
                distance: Math.ceil(tDistance)
              })
            }
            method.hasTaxiFee = method.howTo.map(h => h.by).filter(by => by === 10).length > 0

            const totalDistance = method.distance + method.fdistance + method.tdistance
            method.displayDistance = totalDistance < 1000 ? Math.ceil(totalDistance) : (totalDistance / 1000.00).toLocaleString('th', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })
            method.hr = Math.floor(method.interval / 3600)
            method.min = Math.ceil((method.interval - (method.hr * 3600)) / 60)
          })
          self.guideList = resultGuide
          self.isRouting = false
        }
      }
      self.storeEventToNativeAnalytic('route_complete', {
        pointList: self.pointInputList,
        vehicle: self.selectedVehicle
      })
    },
    clickGuideInfo () {
      const self = this
      if (!self.isFullHeightGuidePanel && !self.isMinimizeHeightGuidePanel) {
        self.expandMoreGiudePanel()
      } else if (self.isFullHeightGuidePanel) {
        self.expandLessGiudePanel()
      } else {
        self.$root.$emit('froceDoNotShowingMapOnly')
      }
    },
    expandMoreGiudePanel () {
      const self = this
      if (!self.isMinimizeHeightGuidePanel) {
        self.isFullHeightGuidePanel = true
        if (window.innerWidth <= self.mobileMaxSize) {
          self.isMinimizeHeightInputPanel = true
        }
      }
    },
    expandLessGiudePanel () {
      const self = this
      self.isFullHeightGuidePanel = false
    },
    expandMoreInputPanel () {
      const self = this
      self.isMinimizeHeightInputPanel = false
    },
    focusPoint (point) {
      const self = this
      if (point.isCurrentLocation) {
        if (window.currentLocation) {
          self.map.location(window.currentLocation)
        } else {
          return false
        }
      } else {
        self.map.location(point.location)
      }
    },
    focusStep (i, j) {
      const self = this
      const path = self.guideList[self.selectedGuideIndex][i].path[j]
      const boundingBox = window.longdo.Util.locationBound(path)
      self.map.bound(boundingBox)
    },
    clickTransitMethod (index) {
      const self = this
      self.selectedTransitMethodIndex = index
      self.$root.$emit('froceDoNotShowingMapOnly')
    },
    updatedTransitGuideRender () {
      const self = this
      if (self.selectedTransitMethodIndex !== null) {
        self.guideList[self.selectedTransitMethodIndex].guideForRender.forEach((guideForRender, i) => {
          const element = self.$refs['guideForRender' + i][0]
          if (guideForRender instanceof Array) {
            if (i === 0) {
              const beforeElement = self.$refs.startPointRender
              if (self.utility.hasClass('walk', element)) {
                element.querySelector('.line').style.height = 'calc(100% + 4.5px + ' + ((beforeElement.clientHeight * 0.5) - 16.5) + 'px)'
                element.querySelector('.line').style.top = '-' + ((beforeElement.clientHeight * 0.5) - 16.5) + 'px'
              }
            } else if (i === self.guideList[self.selectedTransitMethodIndex].guideForRender.length - 1) {
              const beforeElement = self.$refs['guideForRender' + (i - 1)][0]
              const afterElement = self.$refs.endPointRender
              if (self.utility.hasClass('walk', element)) {
                element.querySelector('.line').style.height = 'calc(100% + ' + ((afterElement.clientHeight * 0.5) - 16.5) + 'px + ' + (beforeElement.clientHeight - 31.5) + 'px)'
                element.querySelector('.line').style.top = '-' + (beforeElement.clientHeight - 31.5) + 'px'
              }
            } else {
              const beforeElement = self.$refs['guideForRender' + (i - 1)][0]
              if (self.utility.hasClass('walk', element)) {
                element.querySelector('.line').style.height = 'calc(100% + 4.5px + ' + (beforeElement.clientHeight - 31.5) + 'px)'
                element.querySelector('.line').style.top = '-' + (beforeElement.clientHeight - 31.5) + 'px'
              } else {
                element.querySelector('.line').style.height = 'calc(100% + 18px + ' + (beforeElement.clientHeight - 18) + 'px)'
                element.querySelector('.line').style.top = '-' + (beforeElement.clientHeight - 18) + 'px'
                element.querySelector('.point.start').style.top = '-' + (beforeElement.clientHeight - 11) + 'px'
              }
            }
          }
        })
      }
    },
    clickHeadTransitGuidePanel () {
      const self = this
      if (window.innerWidth <= self.mobileMaxSize) {
        self.isMinimizeHeightInputPanel = true
        self.isFullHeightTransitGuidePanel = !self.isFullHeightTransitGuidePanel
      }
    },
    clickBackFromTransitGuide () {
      const self = this
      self.isFullHeightTransitGuidePanel = false
      self.selectedTransitMethodIndex = null
    },
    toggleThruStopList (guide) {
      const self = this
      guide.isShowThruStopList = !guide.isShowThruStopList
      self.$set(self.guideList, self.selectedTransitMethodIndex, JSON.parse(JSON.stringify(self.guideList[self.selectedTransitMethodIndex])))
    },
    clickMiniTransitGuidePanel () {
      const self = this
      if (window.innerWidth <= self.mobileMaxSize) {
        self.isMinimizeHeightInputPanel = true
      }
      self.$root.$emit('froceDoNotShowingMapOnly')
    },
    clickTransitGuideStep (i, j) {
      const self = this
      const guideStep = j !== null ? self.guideList[self.selectedTransitMethodIndex].guideForRender[i][j] : self.guideList[self.selectedTransitMethodIndex].guideForRender[i]
      if ('path' in guideStep) {
        const boundingBox = window.longdo.Util.locationBound(guideStep.path)
        const minLat = boundingBox.minLat - ((boundingBox.maxLat - boundingBox.minLat) * 1.5)
        boundingBox.minLat = minLat
        self.map.bound(boundingBox)
      }
    },
    clickTransitStartPoint () {
      const self = this
      self.map.location(self.pointInputList[0].location)
    },
    clickTransitEndPoint () {
      const self = this
      self.map.location(self.pointInputList[self.pointInputList.length - 1].location)
    },
    clickTransitGuideThruStop (thruStop) {
      const self = this
      self.map.location(thruStop.location)
    },
    clickDepartDatetimeIcon () {
      window.document.querySelector('#dapart-datetime-input').click()
    },
    async onClickStartNavigation () {
      const self = this
      var path = []
      self.guideList[self.selectedGuideIndex].forEach(g => {
        path = path.concat(g.path)
      })
      const from = path[0][0]
      const to = path[path.length - 1][path[path.length - 1].length - 1]
      self.navigationData = {
        meta: {
          from: {
            lat: Number(from.lat),
            lon: Number(from.lon)
          },
          to: {
            lat: Number(to.lat),
            lon: Number(to.lon)
          }
        },
        guide: self.guideList[self.selectedGuideIndex],
        path: path
      }
      self.$router.push({
        hash: '#navigation'
      })
    },
    sendRouteColor () {
      const self = this
      let ageDateTIme = 0
      if (self.colorRouteSelectType === 'dateTimeSelected') {
        const dateSelected = new Date(self.colorRouteDateTime)
        const dateNow = new Date()
        ageDateTIme = (dateSelected - dateNow) / 1000
      }
      self.pointInputList.forEach(async (item) => {
        const params = {
          level: self.colorRouteChoose,
          age: self.colorRouteSelectType === 'dateTimeSelected' ? ageDateTIme : self.colorTimeChoose * 60,
          weight: self.colorWeightChoose,
          'lat[]': item.location.lat,
          'lon[]': item.location.lon,
          username: self.userData.name,
          token: self.userData.longdousertoken
        }
        const response = await self.api.routeColorChange(params)
        self.responseFromColorRoute = response.data.result
      })
      self.clearRouteColor()
    },
    clearRouteColor () {
      const self = this
      document.getElementById(self.colorRouteChoose).classList.remove('selected-color-route')
      self.colorRouteDateTime = ''
      self.colorRouteChoose = 'R'
      document.getElementById(self.colorRouteChoose).classList.add('selected-color-route')
      self.colorRouteSelectType = 'minuteSelected'
      self.colorTimeChoose = 0
      self.colorWeightChoose = 1
    },
    handleColorRouteSelected (color) {
      const self = this
      document.getElementById(color).classList.add('selected-color-route')
      document.getElementById(self.colorRouteChoose).classList.remove('selected-color-route')
      self.colorRouteChoose = color
    },
    redrawRouteMarker () {
      const self = this
      if (self.$route.hash !== '#routing') {
        return
      }
      self.$nextTick(() => {
        const currentLocation = window.currentLocation
        self.removeAllMarker()
        self.markerList = []

        self.pointInputList.forEach((pointInput, index) => {
          try {
            if (self.selectedVehicle !== 'transit') {
              const imagePath = self.utility.getImgPath(`marker/ic_pin_${index > 0 && index < self.pointInputList.length - 1 ? `blue_${(index)}` : index === self.pointInputList.length - 1 ? 'green' : 'red'}.svg`)
              const marker = new window.longdo.Marker(pointInput.isCurrentLocation ? currentLocation : pointInput.location, {
                icon: {
                  html: '<div style="position: relative; width: ' + process.env.VUE_APP_LONGDO_MAP_PIN_WIDTH + 'px; height: calc(' + process.env.VUE_APP_LONGDO_MAP_PIN_HEIGHT + 'px + 10px);"><img style="position: absolute; top: 0px; left: 0px; width: ' + process.env.VUE_APP_LONGDO_MAP_PIN_WIDTH + 'px; height: ' + process.env.VUE_APP_LONGDO_MAP_PIN_HEIGHT + 'px; z-index: 2;" src="' + imagePath + '">',
                  offset: (this.$route.query.map || 'v3') === 'v3' ? {
                    x: 0,
                    y: -(Number.parseInt(process.env.VUE_APP_LONGDO_MAP_PIN_HEIGHT) / 2)
                  } : {
                    x: process.env.VUE_APP_LONGDO_MAP_PIN_WIDTH / 2,
                    y: process.env.VUE_APP_LONGDO_MAP_PIN_HEIGHT
                  }
                },
                draggable: !self.isOnMobileApp
              })
              marker.destIndex = index
              self.markerList.push(marker)
              self.map.Overlays.add(marker)
            } else { // transit
              if (index === 0 || index === self.pointInputList.length - 1) {
                const imagePath = self.utility.getImgPath(`marker/ic_pin_${index > 0 && index < self.pointInputList.length - 1 ? `blue_${(index)}` : index === self.pointInputList.length - 1 ? 'green' : 'red'}.svg`)
                const marker = new window.longdo.Marker(pointInput.isCurrentLocation ? currentLocation : pointInput.location, {
                  icon: {
                    html: '<div style="position: relative; width: ' + process.env.VUE_APP_LONGDO_MAP_PIN_WIDTH + 'px; height: calc(' + process.env.VUE_APP_LONGDO_MAP_PIN_HEIGHT + 'px + 10px);"><img style="position: absolute; top: 0px; left: 0px; width: ' + process.env.VUE_APP_LONGDO_MAP_PIN_WIDTH + 'px; height: ' + process.env.VUE_APP_LONGDO_MAP_PIN_HEIGHT + 'px; z-index: 2;" src="' + imagePath + '">',
                    offset: (this.$route.query.map || 'v3') === 'v3' ? {
                      x: 0,
                      y: -(Number.parseInt(process.env.VUE_APP_LONGDO_MAP_PIN_HEIGHT) / 2)
                    } : {
                      x: process.env.VUE_APP_LONGDO_MAP_PIN_WIDTH / 2,
                      y: process.env.VUE_APP_LONGDO_MAP_PIN_HEIGHT
                    }
                  },
                  draggable: !self.isOnMobileApp
                })
                marker.destIndex = index
                self.markerList.push(marker)
                self.map.Overlays.add(marker)
              }
            }
          } catch (err) {}
        })
      })
    },
    calTransitImgPath (by, text, size) {
      const self = this
      return self.baseUrl + 'img/routing/transit_' + by + (by === 2 && text === 'Blue' ? '_blue' : '') + '@' + size + 'x.png?version=' + self.appVersion
    }
  },
  updated () {
    const self = this
    self.updatedTransitGuideRender()
  },
  watch: {
    $route (to, from) {
      const self = this
      if (to.hash === '#routing') {
        self.redrawRouteMarker()
      }
    },
    isHidingInputPanel () {
      const self = this
      self.$nextTick(() => {
        if (self.selectedVehicle !== 'transit') {
          if (self.isHidingInputPanel) {
            self.removeAllMarker()
            self.markerList = []
            self.removeAllPolyline()
            self.guideList = []
            self.isMinimizeHeightInputPanel = false
            self.isShowingGuidePanel = false
          } else {
            if (!self.hasNullPointInput) {
              self.route()
            }
          }
        } else {
          if (self.isHidingInputPanel) {
            self.removeAllMarker()
            self.markerList = []
            self.selectedTransitMethodIndex = null
            self.removeAllPolyline()
            self.guideList = []
          } else {
            if (!self.hasNullPointInput) {
              self.route()
            }
          }
        }
      })
    },
    isHidingGuidePanel () {
      const self = this
      self.$nextTick(() => {
        if (self.selectedVehicle !== 'transit') {
          if (self.isHidingGuidePanel) {
            self.removeAllMarker()
            self.markerList = []
            self.removeAllPolyline()
            self.guideList = []
            self.isMinimizeHeightInputPanel = false
            self.isShowingGuidePanel = false
          }
        }
      })
    },
    selectedVehicle (to, from) {
      if (to === 'transit' || from === 'transit') {
        const self = this
        self.redrawRouteMarker()
      }
    },
    pointInputList () {
      const self = this
      self.redrawRouteMarker()
    },
    // isHidingTransitMethodPanel () {
    //   const self = this
    //   self.$nextTick(async () => {
    //     if (self.selectedVehicle === 'transit') {
    //       if (self.isHidingTransitMethodPanel && self.isHidingTransitGuidePanel) {
    //         self.removeAllMarker()
    //         self.markerList = []
    //         self.selectedTransitMethodIndex = null
    //         self.removeAllPolyline()
    //         self.guideList = []
    //       }
    //     }
    //   })
    // },
    // isHidingTransitGuidePanel () {
    //   const self = this
    //   self.$nextTick(async () => {
    //     if (self.selectedVehicle === 'transit') {
    //       if (self.isHidingTransitMethodPanel && self.isHidingTransitGuidePanel) {
    //         self.removeAllMarker()
    //         self.markerList = []
    //         self.selectedTransitMethodIndex = null
    //         self.removeAllPolyline()
    //         self.guideList = []
    //       }
    //     }
    //   })
    // },
    selectedTransitMethodIndex () {
      const self = this
      self.$nextTick(async () => {
        self.thruStopList.forEach(s => {
          self.map.Overlays.remove(s)
        })
        self.thruStopList = []
        self.map.Overlays.list().forEach(p => {
          if ('transitGuideIndex' in p) {
            self.map.Overlays.remove(p)
          }
        })
        if (self.selectedTransitMethodIndex !== null) {
          const result = await self.api.getTransitPath({
            pathId: self.guideList[self.selectedTransitMethodIndex].id
          })
          const guide = self.guideList[self.selectedTransitMethodIndex].guide
          const pathList = result.data.data

          let locationListForBound = []
          let m = 0
          let n = 0
          pathList.forEach((path, index) => {
            if (index === 0) {
              const formPolyline = new window.longdo.Polyline([self.pointInputList[0].location, path[0]], {
                lineWidth: 8,
                lineColor: self.isLongdoMapV3 ? 'rgb(56, 148, 238)' : 'rgb(255, 255, 255)',
                lineStyle: window.longdo.LineStyle.Dot,
                borderWidth: 3,
                borderColor: 'rgb(56, 148, 238)'
              })
              formPolyline.transitGuideIndex = index
              self.map.Overlays.add(formPolyline)
              locationListForBound = locationListForBound.concat(formPolyline.location())
              self.guideList[self.selectedTransitMethodIndex].guideForRender[0][0].path = formPolyline.location()
              m = 0
              n = 0
            }
            if (index === pathList.length - 1) {
              const toPolyline = new window.longdo.Polyline([path[path.length - 1], self.pointInputList[self.pointInputList.length - 1].location], {
                lineWidth: 8,
                lineColor: self.isLongdoMapV3 ? 'rgb(56, 148, 238)' : 'rgb(255, 255, 255)',
                lineStyle: window.longdo.LineStyle.Dot,
                borderWidth: 3,
                borderColor: 'rgb(56, 148, 238)'
              })
              toPolyline.transitGuideIndex = index
              self.map.Overlays.add(toPolyline)
              locationListForBound = locationListForBound.concat(toPolyline.location())
              const i = self.guideList[self.selectedTransitMethodIndex].guideForRender.length - 1
              const j = self.guideList[self.selectedTransitMethodIndex].guideForRender[i].length - 1
              self.guideList[self.selectedTransitMethodIndex].guideForRender[i][j].path = toPolyline.location()
            }
            const polyline = new window.longdo.Polyline(path, {
              lineWidth: guide[index].by < 9 ? 8 : 8,
              lineColor: guide[index].by < 9 ? ('#' + guide[index].routeColor) : self.isLongdoMapV3 ? 'rgb(56, 148, 238)' : 'rgb(255, 255, 255)',
              lineStyle: guide[index].by < 9 ? window.longdo.LineStyle.Solid : window.longdo.LineStyle.Dot,
              borderWidth: 3,
              borderColor: guide[index].by < 9 ? 'rgb(255, 255, 255)' : 'rgb(56, 148, 238)'
            })
            polyline.transitGuideIndex = index
            self.map.Overlays.add(polyline)
            locationListForBound = locationListForBound.concat(polyline.location())

            if ('thruStopList' in guide[index]) {
              guide[index].thruStopList.forEach(s => {
                const thruStop = new window.longdo.Marker(s.location, {
                  icon: {
                    html: '<img style="width: 16px; height: 16px;" src="' + self.baseUrl + 'img/routing/transit_' + `${guide[index].by + guide[index].by === 2 && guide[index].text === 'Blue' ? '_blue' : ''}` + '@2x.png?version=' + self.appVersion + '" srcset="' + self.baseUrl + 'img/routing/transit_' + guide[index].by + `${guide[index].by === 2 && guide[index].text === 'Blue' ? '_blue' : ''}` + '@1x.png?version=' + self.appVersion + ' 1x, ' + self.baseUrl + 'img/routing/transit_' + guide[index].by + `${guide[index].by === 2 && guide[index].text === 'Blue' ? '_blue' : ''}` + '@2x.png?version=' + self.appVersion + ' 2x">',
                    offset: {
                      x: 8,
                      y: 8
                    }
                  }
                })
                self.thruStopList.push(thruStop)
                self.map.Overlays.add(thruStop)
              })
            }

            // mapping polyline path into guide step list for clicking focus on map
            if (self.guideList[self.selectedTransitMethodIndex].guideForRender[m] instanceof Array) {
              if (self.guideList[self.selectedTransitMethodIndex].guideForRender[m].length > 0) {
                if (n < self.guideList[self.selectedTransitMethodIndex].guideForRender[m].length - 1) {
                  n++
                } else {
                  m++
                  if (self.guideList[self.selectedTransitMethodIndex].guideForRender[m] instanceof Array) {
                    n = 0
                  } else {
                    n = -1
                  }
                }
              } else {
                m++
                self.guideList[self.selectedTransitMethodIndex].guideForRender[m].path = self.guideList[self.selectedTransitMethodIndex].guideForRender[m - 2].path
                m++
                if (self.guideList[self.selectedTransitMethodIndex].guideForRender[m] instanceof Array) {
                  n = 0
                } else {
                  n = -1
                }
              }
            } else {
              m++
              if (self.guideList[self.selectedTransitMethodIndex].guideForRender[m] instanceof Array) {
                n = 0
              } else {
                n = -1
              }
            }
            if (m < self.guideList[self.selectedTransitMethodIndex].guideForRender.length) {
              if (n === -1) {
                self.guideList[self.selectedTransitMethodIndex].guideForRender[m].path = polyline.location()
              } else {
                if (self.guideList[self.selectedTransitMethodIndex].guideForRender[m].length > 0) {
                  self.guideList[self.selectedTransitMethodIndex].guideForRender[m][n].path = polyline.location()
                }
              }
            }
          })
          if (locationListForBound.length > 0) {
            const boundingBox = window.longdo.Util.locationBound(locationListForBound)
            const maxLat = boundingBox.maxLat + ((boundingBox.maxLat - boundingBox.minLat) * 0.32)
            const minLat = boundingBox.minLat - ((boundingBox.maxLat - boundingBox.minLat) * 0.37)
            boundingBox.maxLat = maxLat
            boundingBox.minLat = minLat
            self.map.bound(boundingBox)
          }
        }
      })
    },
    async userData () {
      const self = this
      await self.getPermission()
    },
    isGeolocationMobileAppDisabled (value) {
      const self = this
      if (value) {
        // remove any point that is current location in pointInputList
        self.pointInputList.forEach((point, index) => {
          if (point.isCurrentLocation) {
            const newPoint = {
              keyword: '',
              name: '',
              address: '',
              location: null,
              isCurrentLocation: false
            }
            self.$set(self.pointInputList, index, newPoint)
          }
        })
      } else {
        // if first point in pointInputList is empty point change it to current location
        if (JSON.stringify(self.pointInputList[0]) === JSON.stringify({
          keyword: '',
          name: '',
          address: '',
          location: null,
          isCurrentLocation: false
        })) {
          self.$set(self.pointInputList, 0, {
            keyword: '',
            name: '',
            address: '',
            location: {
              lat: null,
              lon: null
            },
            isCurrentLocation: true
          })
        }
      }
    }
  },
  destroyed () {
    window.removeEventListener('resize', self.updatedTransitGuideRender)
  }
}
</script>

<style scoped lang="scss">
$routeModeList: 54px;

//mobile v3
@media only screen and (max-width: $mobileMaxSize) {
  .routing-panel-map3 {
  .input-panel {
    position: fixed;
    z-index: 150;
    width: 100%;
    top: 0px;
    left: 0px;
    transform: translateY(0%);
    transition: transform 0.3s ease 0s;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.2);
    padding-top: 54px;
  }

  >.input-panel.hide {
    transform: translateY(-100%);
    box-shadow: none;
  }

  .vehicle-list {
    height: $routeModeList;
    width: 100%;
    background-color: $darkPrimary;
    z-index: 150;
    box-sizing: border-box;
    position: absolute;
    top: 0px;
    left: 0px;

    >button {
      height: $routeModeList;
      border: none;
      background-color: transparent;
      border-bottom: 4px solid $darkPrimary;
      box-sizing: border-box;
      transition: border-bottom-color 0.3s ease 0s;

      >i {
        color: rgba(255, 255, 255, 0.4);
        font-size: 28px;
        transition: color 0.3s ease 0s;
      }
    }

    >button.active {
      border-bottom: 4px solid $lightPrimary;

      >i {
        color: rgba(255, 255, 255, 1);
      }
    }
  }

  .point-input-list {
    width: 100%;
    max-height: 150px;
    overflow: hidden;
    overflow-y: auto;
    background-color: rgb(255, 255, 255);
    z-index: 150;
    padding-top: 6px;
    display: block;

    >.point-input {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 6px 0px;
      position: relative;

      >.drag-element {
        cursor: grab;
        padding: 0px 8px;
        width: 30px;
        height: 42px;
        z-index: 10;
      }
      >.interval-point {
        filter: hue-rotate(215deg) brightness(1.2);
      }

      >.end-point {
        filter: hue-rotate(125deg) brightness(1.6);
      }

      >i {
        font-size: 16px;
        width: 48px;
        color: $primary;
        text-align: center;
      }

      >.dotted-line {
        width: 0px;
        height: calc(60px - 24px);
        border: none;
        border-left: 3px dotted $extraLightPrimary;
        position: absolute;
        top: calc(50% + 12px);
        left: 22px;
      }

      >input {
        outline: none;
        font-size: 18px;
        padding: 0px 12px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border: 1px solid #e6e6e6;
        border-radius: 8px;
        width: calc(100% - 48px - 48px);
        height: 48px;
        line-height: 48px;
        appearance: none;
        user-select: none;
      }

      >input.full-width {
        width: calc(100% - 48px - 12px);
      }

      >button {
        width: 48px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        outline: none;
        background-color: transparent;
        padding: 0px;
        cursor: pointer;

        >i {
          color: $grey;
        }
      }
    }

    >.point-input:last-child {
      >i {
        font-size: 24px;
      }
    }
  }

  .control-container {
    width: 100%;
    background-color: rgb(255, 255, 255);
    z-index: 150;
    transform: translateY(0%);
    transition: transform 0.2s ease 0s;
    box-sizing: border-box;
    padding: 6px 12px 6px 12px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    box-sizing: border-box;

    >button {
      width: 28px;
      height: 28px;
      padding: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $primary;
      border: none;
      border-radius: 50%;
      margin-left: 12px;
      transition: background-color 0.3s ease 0s;

      &.active {
        background-color: $darkPrimary;

        >i {
          color: rgb(253, 216, 53);
        }
      }

      >i {
        color: rgb(255, 255, 255);
        font-size: 18px;
        transition: color 0.3s ease 0s;
      }
    }

    >button:first-child {
      margin-left: 0px;
    }

    >button.reset {
      margin-left: auto;
    }

    >button.reset,
    >button.route {
      width: unset;
      padding: 0px 12px;
      border-radius: 14px;
      color: rgb(255, 255, 255);
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-family: Prompt;
      transition: background-color 0.3s ease 0s;
    }

    >button.route:disabled {
      background-color: $lightGrey;
    }
  }

  .summary-point-input {
    display: none;
  }

  .setting-panel {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px 12px 12px 12px;
    background-color: rgb(247, 247, 247);

    .choice-group {
      padding: 3px 0px 3px 6px;

      label {
        margin-left: 3px;
      }
    }

    .route-type {
      width: 50%;
      max-width: 200px;
      padding: 12px 0px 0px 0px;

      .title {
        font-family: Prompt;
        color: $primary;
      }
    }

    .route-mode {
      width: fit-content;
      max-width: 300px;
      padding: 12px 0px 0px 0px;

      .title {
        font-family: Prompt;
        color: $primary;
      }
    }

    .depart-datetime {
      padding: 12px 0px 0px 0px;

      .title {
        font-family: Prompt;
        color: $primary;
      }

      .vue-datetime-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        position: relative;

        i {
          cursor: pointer;
          padding: 6px;
          background-color: $primary;
          border-radius: 0px 8px 8px 0px;
          color: rgb(255, 255, 255);
          border: 1px solid $extraLightGrey;
          border-left: none;
          box-sizing: border-box;
          font-size: 18px;
        }

        button.reset {
          width: 30px;
          height: 30px;
          position: absolute;
          top: 0px;
          right: 32px;
          cursor: pointer;
          border: none;
          background-color: transparent;
          padding: 0px;

          i {
            cursor: pointer;
            font-size: 18px;
            padding: 7px;
            box-sizing: border-box;
            background-color: transparent;
            border: none;
            color: $darkGrey;
          }
        }
      }
    }
  }

  >.input-panel.full-height {
    height: 100%;
  }

  >.input-panel.minimize-height {
    >.point-input-list {
      display: none;
    }

    >.control-container {
      display: none;
    }

    >.setting-panel {
      display: none;
    }

    >.summary-point-input {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: nowrap;
      height: 60px;
      width: 100%;
      box-sizing: border-box;
      overflow: hidden;
      background-color: $darkPrimary;

      >div {
        border: 1px solid $extraLightGrey;
        border-radius: 8px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: nowrap;
        width: calc(100% - 12px);
        height: calc(100% - 12px);
        margin: 6px;
        overflow: hidden;
        overflow-x: auto;
        background-color: rgb(255, 255, 255);

        >span {
          font-size: 18px;
          flex-grow: 0;
          flex-shrink: 0;
          padding: 0px 12px;
          // max-width: calc(100% + 5px);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          line-height: 2;
        }

        >i {
          flex-grow: 0;
          flex-shrink: 0;
          color: $lightPrimary;
          text-align: center;
        }
      }

      >div::-webkit-scrollbar {
        display: none;
      }
    }
  }

  >.choose-location-widget {
    transform: translateX(0%);
    transition: transform 0.2s ease 0s;
  }

  >.choose-location-widget.hide {
    transform: translateX(100%);
  }

  >.choose-location-widget-map3 {
    transform: translateX(0%);
    transition: transform 0.2s ease 0s;
  }

  >.choose-location-widget-map3.hide {
    transform: translateX(100%);
  }

  .longdo-map-logo {
    position: fixed;
    bottom: 35%;
    right: 0px;
    z-index: 90;

    &.hide {
      display: none;
    }

    &.full-height {
      display: none;
    }

    &.minimize-height {
      display: none;
    }
  }
  >.guide-panel {
    position: fixed;
    height: 40%;
    width: 100%;
    bottom: 0%;
    left: 0px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.2);
    z-index: 90;
    transition: height 0.3s ease 0s;
    overflow: hidden;
    padding-bottom: 57px;
    box-sizing: border-box;

    >.loading-more-container {
      position: absolute;
      top: calc((100% - 57px) / 2);
      left: 50%;
      transform: translate(-50%, -50%);
    }
    >.guide, .guide.route-color-panel{
      height: 100%;
      overflow: hidden;
      >.header-color-panel.info {
        display: flex;
        justify-content: space-between;
      }
      >.color-route-content {
        height: calc(100% - 52px);
        box-sizing: border-box;
        overflow: hidden;
        overflow-y: auto;
        >.btn-color {
          display: flex;
          gap: 10px;
          padding: 25px 34px 0px 34px;
          justify-content: center;
          >.btn-route-color {
            background-color: #F9F9F9;
            padding: 10px;
            border-radius: 12px;
            .route-color-btn-red, .route-color-btn-yellow, .route-color-btn-green, .route-color-btn-black {
              width: 55px;
              height: 35px;
              border:1px solid #D2D2D2;
              border-radius: 6px;
            }
            >.label-color {
              text-align: center;
              font-size: 14px;
              font-family: 'Prompt';
            }
            >.route-color-btn-red {
              background-color: #EA351E;
            }
            >.route-color-btn-yellow {
              background-color: #FFFD52;
            }
            >.route-color-btn-green {
              background-color: #377D22;
            }
            >.route-color-btn-black {
              background-color: black;
            }
          }
          .btn-route-color.selected-color-route {
            border: 3px solid #16A668;
          }
        }
        >.input-content {
          display: flex;
          justify-content: center;
          >.input-detail {
            margin-top: 15px;
            label {
              padding: 0px 5px;
            }
            input[type="number"] {
              width: 30px;
            }
            input[type="radio"] {
              accent-color: green;
            }
          }
          .input-detail.text-responseColorRoute {
            text-align: center;
          }
          >.input-detail.input-datetime {
            display: flex;
          }
        }
        >.btn-submit-clear {
          display: flex;
          justify-content: center;
          gap: 12px;
          margin-top: 24px;
          >.submitForm {
            border: none;
            border-radius: 99px;
            width: 100px;
            height: 35px;
            background-color: #16a668;
            padding: 7px 22px 7px 22px;
            cursor: pointer;
            label {
              color: white;
            }
          }
          >.clearForm {
            cursor: pointer;
            border: none;
            border-radius: 99px;
            width: 100px;
            height: 35px;
            background-color: #F4FCF9;
            padding: 7px 22px 7px 22px;
            label {
              color: #16a668;
            }
          }
        }
        .vue-datetime-container {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          position: relative;
          i {
            cursor: pointer;
            padding: 6px;
            background-color: $primary;
            border-radius: 0px 8px 8px 0px;
            color: rgb(255, 255, 255);
            border: 1px solid $extraLightGrey;
            border-left: none;
            box-sizing: border-box;
            font-size: 18px;
          }

          button.reset {
            width: 30px;
            height: 30px;
            position: absolute;
            top: 0px;
            right: 26%;
            cursor: pointer;
            border: none;
            background-color: transparent;
            padding: 0px;

            i {
              cursor: pointer;
              font-size: 18px;
              padding: 7px;
              box-sizing: border-box;
              background-color: transparent;
              border: none;
              color: $darkGrey;
            }
          }
        }
      }
      >.info {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 13px;
        background-color: #16A668;
        >button.expand-more, button.expand-less {
          z-index: 1;
          position: absolute;
          top: 0px;
          left: 50%;
          transform: translate(-50%, 0%);
          padding: 0px;
          width: 36px;
          height: 18px;
          border: none;
          border-radius: 0px 0px 50% 50%;
          background-color: rgb(255, 255, 255);
          box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.05);

          >i {
            font-size: 18px;
            color: $grey;
          }
        }
        >.distance {
          font-family: Prompt;
          font-size: 18px;
          color: rgb(255, 255, 255);
          margin-left: 6px;
          margin-right: auto;
        }

        >.time {
          font-family: Prompt;
          font-size: 18px;
          color: rgb(255, 255, 255);
        }

        button.start-navigation {
          background-color: white;
          color: $primary;
          border: none;
          border-radius: 14px;
          padding: 0px 12px;
          height: 28px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          font-family: Prompt;
          font-size: 16px;
          box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.05);

          i {
            font-size: 18px;
            margin-right: 3px;
            color: $primary;
          }
        }
        >.close-color-route-btn {
          border: none;
          background-color: transparent;
          i {
            color: white
          }
        }
        >.color-input-time {
          input[type="number"] {
            padding: 5px;
          }
        }
        >.route-color-change {
          border-radius: 99px;
          display: flex;
          padding: 0px 12px;
          background-color: white;
          color: #16A668;
          cursor: pointer;
          margin-left: 5px;
          font-size: 16px;
          height: 28px;
          align-items: center;
          font-family: 'Prompt';
        }
      }

      >.step-list {
        height: calc(100% - 52px);
        box-sizing: border-box;
        overflow: hidden;
        overflow-y: auto;

        >.a-to-b {
          >.step {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 12px;

            >.icon {
              width: 16px;
              margin-left: 30px;
              box-sizing: border-box;
            }

            >.name {
              padding: 0px 0px 0px 12px;
              box-sizing: border-box;
              margin-right: auto;
            }

            >.distance {
              padding: 0px 0px 0px 6px;
              box-sizing: border-box;
              margin-left: auto;
              min-width: 54px;
              text-align: right;
              color: $grey;
            }
          }

          >.step.from,
          >.step.to {
            background-color: rgb(240, 240, 240);

            >.icon {
              width: 24px;
              margin-left: 0px;
              display: flex;
              justify-content: center;
              align-items: center;

              >i {
                font-size: 20px;
                color: $darkPrimary;
              }
            }

            >.name {
              padding: 0px 0px 0px 6px;
              width: calc(100% - 24px);
            }
          }
        }
      }
    }
  }

  >.guide-panel.hide {
    height: 0%;
    padding-bottom: 0px;
    box-shadow: none;
  }

  >.guide-panel.full-height {
    height: 100%;
    z-index: 160;
    padding-bottom: 0px;
  }

  >.guide-panel.minimize-height:not(.hide) {
    height: 52px;
    overflow: hidden;
    overflow-y: hidden;
    padding-bottom: 0px;
  }

  >.transit-method-panel {
    >.transit-method-choose-hint {
      padding: 12px;
      font-size: 18px;
      background-color: $darkPrimary;
      color: white;
    }
    position: fixed;
    height: calc(100% - 220px);
    width: 100%;
    bottom: 0%;
    left: 0px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.2);
    z-index: 90;
    transition: height 0.3s ease 0s;
    overflow: hidden;
    padding-bottom: 57px;
    box-sizing: border-box;

    >.loading-more-container {
      position: absolute;
      top: calc((100% - 57px) / 2);
      left: 50%;
      transform: translate(-50%, -50%);
    }

    >.transit-method-list {
      width: 100%;
      height: calc(100% - 48px);
      overflow-y: scroll;
    }
  }
    >.transit-method-panel.input-minimize-height {
        height: calc(100% - 54px - 60px);
    }

  >.transit-method-panel.hide {
    height: 0%;
    padding-bottom: 0px;
    box-shadow: none;
  }

  >.mini-transit-guide-panel {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: 90;
    opacity: 1;
    transition: opacity 0.15s ease 0s;

    &.hide {
      opacity: 0;
    }
  }

  >.transit-guide-panel {

      position: fixed;
      height: calc((100% - 54px - 60px) * 0.6);
      width: 100%;
      bottom: 0%;
      left: 0px;
      background-color: rgb(255, 255, 255);
      box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.2);
      z-index: 90;
      transition: height 0.3s ease 0s,
      border-radius 0.3s ease 0s,
      opacity 0.3s ease 0s;
      overflow: hidden;
      padding-bottom: 57px;
      box-sizing: border-box;
      border-radius: 12px 12px 0px 0px;
      opacity: 1;
    &.hide {
      height: 0%;
      padding-bottom: 0px;
      opacity: 0;
    }

    &.full {
      height: 100%;
      z-index: 160;
      border-radius: 0px 0px 0px 0px;
      padding-bottom: 00px;
    }

    >.head {
      height: 46px;
      width: 100%;
      background: linear-gradient(90.56deg, $primary 0%, $darkPrimary 100%);
      border-bottom: 4px solid $lightPrimary;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      box-sizing: border-box;

      >button.back {
        width: 36px;
        height: 36px;
        border: none;
        background-color: transparent;
        padding: 0px;
        margin: 3px 0px 3px 6px;

        >i {
          color: rgb(255, 255, 255);
          font-size: 36px;
        }
      }

      >.detail {
        height: 100%;
        width: calc(100% - 6px - 36px);
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        padding: 0px 12px 0px 12px;

        div {
          display: flex;
          align-items: center;
            >* {
            font-family: Prompt;
            color: rgb(255, 255, 255);
          }

          >.fee {
            margin-right: 6px;
          }

          >.distance {
            opacity: 0.6;
            margin-right: 6px;
          }

          >.time {
            font-size: 18px;
            flex: 0 0 auto;
          }
        }
      }
    }

    >.transit-guide-list {
      overflow: hidden;
      overflow-y: auto;
      height: calc(100% - 46px);

      >.point {
        min-height: 48px;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px 12px 8px 48px;
        box-sizing: border-box;
        font-size: 16px;
        background-color: rgb(240, 240, 240);
        position: relative;
        z-index: 0;

        >i {
          position: absolute;
          font-size: 24px;
          color: $darkPrimary;
          top: 50%;
          left: 24px;
          transform: translate(-50%, -50%);
        }
      }

      >.group-guide {
        display: flex;
        flex-direction: row;
        position: relative;
        z-index: 1;

        >.line {
          position: absolute;
          height: calc(100% + 36px);
          top: -18px;
          left: 20px;
          border: none;
          border-left: 8px solid;
        }

        >.point {
          position: absolute;
          width: 14px;
          height: 14px;
          left: 24px;
          transform: translateX(-50%);
          background-color: rgb(255, 255, 255);
          border-radius: 7px;
          border: 2px solid;
          box-sizing: border-box;
        }

        >.point.start {
          top: -25px;
        }

        >.point.end {
          bottom: -25px;
        }

        >.group-guide-step {
          padding-left: 48px;
          width: 100%;
          box-sizing: border-box;

          .guide-step {
            min-height: 36px;
            color: $grey;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 8px 8px 0px 0px;
            box-sizing: border-box;

            >.content {
              align-self: stretch;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start;
              color: $grey;

              >.trip {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                position: relative;

                >.by {
                  position: absolute;
                  top: -2px;
                  left: -36px;
                  width: 24px;
                  height: 24px;
                  background-color: white;
                  border-radius: 12px;
                  box-sizing: border-box;
                  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.4));
                  display: flex;
                  justify-content: center;
                  align-items: center;

                  >i {
                    font-size: 18px;
                  }
                }

                >.line {
                  height: 20px;
                  border-radius: 12px;
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-content: center;
                  align-items: center;
                  padding: 0px 8px;
                  flex-wrap: nowrap;
                  font-size: 14px;
                  margin-right: 9px;
                  margin-bottom: 4px;

                  >.icon {
                    width: 16px;
                    height: 16px;
                    border-radius: 8px;
                    overflow: hidden;
                    background-color: rgba(255, 255, 255, 0.75);
                    margin-right: 6px;

                    >img {
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                    }
                  }
                }

                >.line.has-icon {
                  padding-left: 3px;
                }

                >.terminate {
                  padding-top: 1px;
                  color: $grey;
                }
              }

              >.count-stop {
                color: $grey;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                cursor: pointer;

                >i {
                  color: $darkGrey;
                  margin-left: 3px;
                  transition: transform 0.3s ease 0s;

                  &.up {
                    transform: rotate(-180deg);
                  }
                }
              }

              >.thru-stop-list {
                height: max-content;
                width: 100%;
                overflow: unset;
                opacity: 1;
                transition: height 0.3s ease 0s, opacity 0.6s ease 0s;

                &.hide {
                  height: 0px;
                  overflow: hidden;
                  opacity: 0;
                }

                >.thru-stop {
                  width: 100%;
                  position: relative;

                  >.thru-point {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: -27px;
                    width: 6px;
                    height: 6px;
                    background-color: white;
                    border-radius: 3px;
                    box-sizing: border-box;
                  }

                  >.name {
                    width: 100%;
                    min-height: 36px;
                    color: $grey;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 8px 8px 8px 8px;
                    box-sizing: border-box;
                  }
                }
              }
            }

            >.content.left {
              width: calc(100% - 64px);
            }

            >.content.right {
              width: 64px;
              align-items: flex-end;

              >.time {
                color: $darkPrimary;
                text-align: right;
              }

              >.fee {
                color: rgb(255, 153, 33);
              }
            }
          }
        }
      }

      >.group-guide.walk {
        >.line {
          height: calc(100% + 9px);
          top: -4.5px;
          left: 21px;
          border-left: 6px dotted $lightGrey;
        }
      }

      >.group-guide.walk.no-data {
        min-height: 9px;
      }

      .guide-stop {
        min-height: 36px;
        width: calc(100% - 48px - 12px);
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px 0px 8px 0px;
        margin: 0px 12px 0px 48px;
        box-sizing: border-box;
        font-size: 16px;
        color: $defaultFontColor;
        z-index: 1;
      }
    }
  }
}
}

//desktop v3
@media only screen and (min-width: $mobileMaxSize) {
  .routing-panel-map3 {
     &.hide {
      display: none;
    }
    position: fixed;
    z-index: 150;
    width: 490px;
    height: fit-content;
    max-height: 90vh;
    gap: 10px;
    top: 50px;
    left: 10px;
    display: flex;
    flex-direction: column;
    background-color: transparent;
  .input-panel {
    z-index: 150;
    width: 490px;
    border-radius: 10px;
    transform: translateY(0%);
    transition: transform 0.3s ease 0s;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.2);
    padding-top: 54px;
  }

    >.input-panel.hide {
      transform: translateX(-150%);
      box-shadow: none;
    }

    .control-container {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  .vehicle-list {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    height: $routeModeList;
    width: 100%;
    background-color: $darkPrimary;
    z-index: 150;
    box-sizing: border-box;
    position: absolute;
    top: 0px;
    left: 0px;

    >button {
      height: $routeModeList;
      border: none;
      background-color: transparent;
      border-bottom: 4px solid $darkPrimary;
      box-sizing: border-box;
      transition: border-bottom-color 0.3s ease 0s;

      >i {
        color: rgba(255, 255, 255, 0.4);
        font-size: 28px;
        transition: color 0.3s ease 0s;
      }
    }

    >button.active {
      border-bottom: 4px solid $lightPrimary;

      >i {
        color: rgba(255, 255, 255, 1);
      }
    }
  }

  .point-input-list {
    width: 100%;
    max-height: 40vh;
    overflow: hidden;
    overflow-y: auto;
    background-color: rgb(255, 255, 255);
    z-index: 150;
    padding-top: 6px;
    display: block;

    >.point-input {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 6px 0px;
      position: relative;

      >.drag-element {
        cursor: grab;
        padding: 0px 8px;
        width: 30px;
        height: 42px;
        z-index: 10;
      }
      >.interval-point {
        filter: hue-rotate(215deg) brightness(1.2);
      }

      >.end-point {
        filter: hue-rotate(125deg) brightness(1.6);
      }

      >i {
        font-size: 16px;
        width: 48px;
        color: $primary;
        text-align: center;
      }

      >.dotted-line {
        width: 0px;
        height: calc(60px - 24px);
        border: none;
        border-left: 3px dotted $extraLightPrimary;
        position: absolute;
        top: calc(50% + 12px);
        left: 22px;
      }

      >input {
        outline: none;
        font-size: 18px;
        padding: 0px 12px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border: 1px solid #e6e6e6;
        border-radius: 8px;
        width: calc(100% - 48px - 48px);
        height: 48px;
        line-height: 48px;
        appearance: none;
        user-select: none;
      }

      >input.full-width {
        width: calc(100% - 48px - 12px);
      }

      >button {
        width: 48px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        outline: none;
        background-color: transparent;
        padding: 0px;
        cursor: pointer;

        >i {
          color: $grey;
        }
      }
    }

    >.point-input:last-child {
      >i {
        font-size: 24px;
      }
    }
  }

  .control-container {
    width: 100%;
    background-color: rgb(255, 255, 255);
    z-index: 150;
    transform: translateY(0%);
    transition: transform 0.2s ease 0s;
    box-sizing: border-box;
    padding: 6px 12px 6px 12px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    box-sizing: border-box;

    >button {
      width: 28px;
      height: 28px;
      padding: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $primary;
      border: none;
      border-radius: 50%;
      margin-left: 12px;
      transition: background-color 0.3s ease 0s;

      &.active {
        background-color: $darkPrimary;

        >i {
          color: rgb(253, 216, 53);
        }
      }

      >i {
        color: rgb(255, 255, 255);
        font-size: 18px;
        transition: color 0.3s ease 0s;
      }
    }

    >button:first-child {
      margin-left: 0px;
    }

    >button.reset {
      margin-left: auto;
    }

    >button.reset,
    >button.route {
      width: unset;
      padding: 0px 12px;
      border-radius: 14px;
      color: rgb(255, 255, 255);
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-family: Prompt;
      transition: background-color 0.3s ease 0s;
    }

    >button.route:disabled {
      background-color: $lightGrey;
    }
  }

  .summary-point-input {
    display: none;
  }

  .setting-panel {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px 12px 12px 12px;
    background-color: rgb(247, 247, 247);

    .choice-group {
      padding: 3px 0px 3px 6px;

      label {
        margin-left: 3px;
      }
    }

    .route-type {
      width: 50%;
      max-width: 200px;
      padding: 12px 0px 0px 0px;

      .title {
        font-family: Prompt;
        color: $primary;
      }
    }

    .route-mode {
      width: fit-content;
      max-width: 300px;
      padding: 12px 0px 0px 0px;

      .title {
        font-family: Prompt;
        color: $primary;
      }
    }

    .depart-datetime {
      padding: 12px 0px 0px 0px;

      .title {
        font-family: Prompt;
        color: $primary;
      }

      .vue-datetime-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        position: relative;

        i {
          cursor: pointer;
          padding: 6px;
          background-color: $primary;
          border-radius: 0px 8px 8px 0px;
          color: rgb(255, 255, 255);
          border: 1px solid $extraLightGrey;
          border-left: none;
          box-sizing: border-box;
          font-size: 18px;
        }

        button.reset {
          width: 30px;
          height: 30px;
          position: absolute;
          top: 0px;
          right: 32px;
          cursor: pointer;
          border: none;
          background-color: transparent;
          padding: 0px;

          i {
            cursor: pointer;
            font-size: 18px;
            padding: 7px;
            box-sizing: border-box;
            background-color: transparent;
            border: none;
            color: $darkGrey;
          }
        }
      }
    }
  }

  >.input-panel.full-height {

    // @media screen and (min-width: $mobileMaxSize) {
    // }
    @media screen and (max-width: $mobileMaxSize) {
      height: 100%;
    }

    // transform: translateY(-100%);
  }

  >.input-panel.minimize-height {
    >.point-input-list {
      display: none;
    }

    >.control-container {
      display: none;
    }

    >.setting-panel {
      display: none;
    }

    >.summary-point-input {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: nowrap;
      height: 60px;
      width: 100%;
      box-sizing: border-box;
      overflow: hidden;
      background-color: $darkPrimary;

      >div {
        border: 1px solid $extraLightGrey;
        border-radius: 8px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: nowrap;
        width: calc(100% - 12px);
        height: calc(100% - 12px);
        margin: 6px;
        overflow: hidden;
        overflow-x: auto;
        background-color: rgb(255, 255, 255);

        >span {
          font-size: 18px;
          flex-grow: 0;
          flex-shrink: 0;
          padding: 0px 12px;
          // max-width: calc(100% + 5px);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          line-height: 2;
        }

        >i {
          flex-grow: 0;
          flex-shrink: 0;
          color: $lightPrimary;
          text-align: center;
        }
      }

      >div::-webkit-scrollbar {
        display: none;
      }
    }
  }

  >.choose-location-widget {
    transform: translateX(0%);
    transition: transform 0.2s ease 0s;
  }

  >.choose-location-widget.hide {
    // transform: translateX(100%);
    display: none;
  }

  >.choose-location-widget-map3 {
    opacity: 1;
    transition: opacity 0.2s ease 0s;
  }

  >.choose-location-widget-map3.hide {
    // opacity: 0;
    // pointer-events: none;
    display: none;
  }

  .longdo-map-logo {
    position: fixed;
    bottom: 35%;
    right: 0px;
    z-index: 90;

    &.hide {
      display: none;
    }

    &.full-height {
      display: none;
    }

    &.minimize-height {
      display: none;
    }
  }
  >.guide-panel {
    width: 490px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.2);
    z-index: 90;
    transition: height 0.3s ease 0s;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: fit-content;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;

    >.loading-more-container {
      width: 100%;
      height: 30vh;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    >.guide, .guide.route-color-panel{
      height: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      >.header-color-panel.info {
        display: flex;
        justify-content: space-between;
      }
      >.color-route-content {
        height: calc(100% - 52px);
        box-sizing: border-box;
        overflow: hidden;
        overflow-y: auto;
        >.btn-color {
          display: flex;
          gap: 10px;
          padding: 25px 34px 0px 34px;
          justify-content: center;
          >.btn-route-color {
            background-color: #F9F9F9;
            padding: 10px;
            border-radius: 12px;
            .route-color-btn-red, .route-color-btn-yellow, .route-color-btn-green, .route-color-btn-black {
              @media only screen and (max-width: $mobileMaxSize) {
                width: 55px;
                height: 35px;
              }
              width: 75px;
              height: 45px;
              border:1px solid #D2D2D2;
              border-radius: 6px;
            }
            >.label-color {
              text-align: center;
              font-size: 14px;
              font-family: 'Prompt';
            }
            >.route-color-btn-red {
              background-color: #EA351E;
            }
            >.route-color-btn-yellow {
              background-color: #FFFD52;
            }
            >.route-color-btn-green {
              background-color: #377D22;
            }
            >.route-color-btn-black {
              background-color: black;
            }
          }
          .btn-route-color.selected-color-route {
            border: 3px solid #16A668;
          }
        }
        >.input-content {
          display: flex;
          justify-content: center;
          >.input-detail {
            margin-top: 15px;
            label {
              padding: 0px 5px;
            }
            input[type="number"] {
              width: 30px;
            }
            input[type="radio"] {
              accent-color: green;
            }
          }
          .input-detail.text-responseColorRoute {
            text-align: center;
          }
          >.input-detail.input-datetime {
            display: flex;
          }
        }
        >.btn-submit-clear {
          display: flex;
          justify-content: center;
          gap: 12px;
          margin-top: 24px;
          >.submitForm {
            border: none;
            border-radius: 99px;
            width: 100px;
            height: 35px;
            background-color: #16a668;
            padding: 7px 22px 7px 22px;
            cursor: pointer;
            label {
              color: white;
            }
          }
          >.clearForm {
            cursor: pointer;
            border: none;
            border-radius: 99px;
            width: 100px;
            height: 35px;
            background-color: #F4FCF9;
            padding: 7px 22px 7px 22px;
            label {
              color: #16a668;
            }
          }
        }
        .vue-datetime-container {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          position: relative;
          i {
            cursor: pointer;
            padding: 6px;
            background-color: $primary;
            border-radius: 0px 8px 8px 0px;
            color: rgb(255, 255, 255);
            border: 1px solid $extraLightGrey;
            border-left: none;
            box-sizing: border-box;
            font-size: 18px;
          }

          button.reset {
            width: 30px;
            height: 30px;
            position: absolute;
            top: 0px;
            right: 26%;
            cursor: pointer;
            border: none;
            background-color: transparent;
            padding: 0px;

            i {
              cursor: pointer;
              font-size: 18px;
              padding: 7px;
              box-sizing: border-box;
              background-color: transparent;
              border: none;
              color: $darkGrey;
            }
          }
        }
      }
      >.info {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-shrink: 0;
        align-items: center;
        padding: 13px;
        background-color: #16A668;
        width: 100%;
        >button {
          display: none;
          z-index: 1;
          position: absolute;
          top: 0px;
          left: 50%;
          transform: translate(-50%, 0%);
          padding: 0px;
          width: 36px;
          height: 18px;
          border: none;
          border-radius: 0px 0px 50% 50%;
          background-color: rgb(255, 255, 255);
          box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.05);

          >i {
            font-size: 18px;
            color: $grey;
          }
        }
        >.distance {
          font-family: Prompt;
          font-size: 18px;
          color: rgb(255, 255, 255);
          margin-left: 6px;
          margin-right: auto;
        }

        >.time {
          font-family: Prompt;
          font-size: 18px;
          color: rgb(255, 255, 255);
        }

        button.start-navigation {
          // @media only screen and (min-width: $mobileMaxSize) {
          //   display: none;
          // }
          position: static;
          width: fit-content;
          background-color: white;
          color: $primary;
          border: none;
          border-radius: 14px;
          padding: 0px 12px;
          height: 28px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          font-family: Prompt;
          font-size: 16px;
          box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.05);

          i {
            font-size: 18px;
            margin-right: 3px;
            color: $primary;
          }
        }
        >.close-color-route-btn {
          border: none;
          background-color: transparent;
          i {
            color: white
          }
        }
        >.color-input-time {
          input[type="number"] {
            padding: 5px;
          }
        }
        >.route-color-change {
          border-radius: 99px;
          display: flex;
          padding: 0px 12px;
          background-color: white;
          color: #16A668;
          cursor: pointer;
          margin-left: 5px;
          font-size: 16px;
          height: 28px;
          align-items: center;
          font-family: 'Prompt';
        }
      }

      >.step-list {
        height: calc(100% - 52px);
        box-sizing: border-box;
        overflow: hidden;
        overflow-y: auto;

        >.a-to-b {
          >.step {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 12px;

            >.icon {
              width: 16px;
              margin-left: 30px;
              box-sizing: border-box;
            }

            >.name {
              padding: 0px 0px 0px 12px;
              box-sizing: border-box;
              margin-right: auto;
            }

            >.distance {
              padding: 0px 0px 0px 6px;
              box-sizing: border-box;
              margin-left: auto;
              min-width: 54px;
              text-align: right;
              color: $grey;
            }
          }

          >.step.from,
          >.step.to {
            background-color: rgb(240, 240, 240);

            >.icon {
              width: 24px;
              margin-left: 0px;
              display: flex;
              justify-content: center;
              align-items: center;

              >i {
                font-size: 20px;
                color: $darkPrimary;
              }
            }

            >.name {
              padding: 0px 0px 0px 6px;
              width: calc(100% - 24px);
            }
          }
        }
      }
    }
  }

  >.guide-panel.hide {
    // cursor: none;
    // height: 0%;
    // padding-bottom: 0px;
    // box-shadow: none;
    display:none;
  }

  >.guide-panel.full-height {
    height: 100%;
    z-index: 160;
    padding-bottom: 0px;
  }

  >.guide-panel.minimize-height:not(.hide) {
    height: 52px;
    overflow: hidden;
    overflow-y: hidden;
    padding-bottom: 0px;
  }

  >.transit-method-panel {
    >.transit-method-choose-hint {
      padding: 12px;
      font-size: 18px;
      background-color: $darkPrimary;
      color: white;
    }
    display: flex;
    flex-direction: column;
    width: 490px;
    background-color: white;
    -webkit-box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.2);
    z-index: 90;
    -webkit-transition: height 0.3s ease 0s;
    transition: height 0.3s ease 0s;
    overflow: hidden;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    >.loading-more-container {
      width: 100%;
      height: 30vh;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    >.transit-method-list {
      display: flex;
      flex-direction: column;
      row-gap: 12px;
      padding: 12px;
      height: 100%;
      overflow-y: scroll;
    }
  }

  >.transit-method-panel.hide {
    // height: 0%;
    // padding-bottom: 0px;
    // box-shadow: none;
    display:none;
  }

  >.mini-transit-guide-panel {
      display: block;
      width: 490px;
      left: 10px;
      z-index: 90;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      opacity: 1;
      transition: opacity 0.15s ease 0s;

    &.hide {
      display: none;
    }
  }

  >.transit-guide-panel {
    width: 490px;
    background-color: white;
    -webkit-box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.2);
    z-index: 90;
    -webkit-transition: height 0.3s ease 0s;
    transition: height 0.3s ease 0s;
    overflow: hidden;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    flex-direction: column;
    height: 100%;
    &.hide {
      // height: 0%;
      // padding-bottom: 0px;
      // opacity: 0;
      display:none;
    }

    &.full {
      height: 100%;
      z-index: 160;
      border-radius: 0px 0px 0px 0px;
      padding-bottom: 00px;
    }

    >.head {
      height: 46px;
      width: 100%;
      background: linear-gradient(90.56deg, $primary 0%, $darkPrimary 100%);
      border-bottom: 4px solid $lightPrimary;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      box-sizing: border-box;

      >button.back {
        width: 36px;
        height: 36px;
        border: none;
        background-color: transparent;
        padding: 0px;
        margin: 3px 0px 3px 6px;

        >i {
          color: rgb(255, 255, 255);
          font-size: 36px;
        }
      }

      >.detail {
        height: 100%;
        width: calc(100% - 6px - 36px);
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        padding: 0px 12px 0px 12px;

        div {
          display: flex;
          align-items: center;
            >* {
            font-family: Prompt;
            color: rgb(255, 255, 255);
          }

          >.fee {
            margin-right: 6px;
          }

          >.distance {
            opacity: 0.6;
            margin-right: 6px;
          }

          >.time {
            font-size: 18px;
            flex: 0 0 auto;
          }
        }
      }
    }

    >.transit-guide-list {
      overflow: hidden;
      overflow-y: auto;
      height: calc(100% - 46px);

      >.point {
        min-height: 48px;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px 12px 8px 48px;
        box-sizing: border-box;
        font-size: 16px;
        background-color: rgb(240, 240, 240);
        position: relative;
        z-index: 0;

        >i {
          position: absolute;
          font-size: 24px;
          color: $darkPrimary;
          top: 50%;
          left: 24px;
          transform: translate(-50%, -50%);
        }
      }

      >.group-guide {
        display: flex;
        flex-direction: row;
        position: relative;
        z-index: 1;

        >.line {
          position: absolute;
          height: calc(100% + 36px);
          top: -18px;
          left: 20px;
          border: none;
          border-left: 8px solid;
        }

        >.point {
          position: absolute;
          width: 14px;
          height: 14px;
          left: 24px;
          transform: translateX(-50%);
          background-color: rgb(255, 255, 255);
          border-radius: 7px;
          border: 2px solid;
          box-sizing: border-box;
        }

        >.point.start {
          top: -25px;
        }

        >.point.end {
          bottom: -25px;
        }

        >.group-guide-step {
          padding-left: 48px;
          width: 100%;
          box-sizing: border-box;

          .guide-step {
            min-height: 36px;
            color: $grey;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 8px 8px 0px 0px;
            box-sizing: border-box;

            >.content {
              align-self: stretch;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start;
              color: $grey;

              >.trip {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                position: relative;

                >.by {
                  position: absolute;
                  top: -2px;
                  left: -36px;
                  width: 24px;
                  height: 24px;
                  background-color: white;
                  border-radius: 12px;
                  box-sizing: border-box;
                  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.4));
                  display: flex;
                  justify-content: center;
                  align-items: center;

                  >i {
                    font-size: 18px;
                  }
                }

                >.line {
                  height: 20px;
                  border-radius: 12px;
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-content: center;
                  align-items: center;
                  padding: 0px 8px;
                  flex-wrap: nowrap;
                  font-size: 14px;
                  margin-right: 9px;
                  margin-bottom: 4px;

                  >.icon {
                    width: 16px;
                    height: 16px;
                    border-radius: 8px;
                    overflow: hidden;
                    background-color: rgba(255, 255, 255, 0.75);
                    margin-right: 6px;

                    >img {
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                    }
                  }
                }

                >.line.has-icon {
                  padding-left: 3px;
                }

                >.terminate {
                  padding-top: 1px;
                  color: $grey;
                }
              }

              >.count-stop {
                color: $grey;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                cursor: pointer;

                >i {
                  color: $darkGrey;
                  margin-left: 3px;
                  transition: transform 0.3s ease 0s;

                  &.up {
                    transform: rotate(-180deg);
                  }
                }
              }

              >.thru-stop-list {
                height: max-content;
                width: 100%;
                overflow: unset;
                opacity: 1;
                transition: height 0.3s ease 0s, opacity 0.6s ease 0s;

                &.hide {
                  // height: 0px;
                  // overflow: hidden;
                  // opacity: 0;
                  display:none;
                }

                >.thru-stop {
                  width: 100%;
                  position: relative;

                  >.thru-point {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: -27px;
                    width: 6px;
                    height: 6px;
                    background-color: white;
                    border-radius: 3px;
                    box-sizing: border-box;
                  }

                  >.name {
                    width: 100%;
                    min-height: 36px;
                    color: $grey;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 8px 8px 8px 8px;
                    box-sizing: border-box;
                  }
                }
              }
            }

            >.content.left {
              width: calc(100% - 64px);
            }

            >.content.right {
              width: 64px;
              align-items: flex-end;

              >.time {
                color: $darkPrimary;
                text-align: right;
              }

              >.fee {
                color: rgb(255, 153, 33);
              }
            }
          }
        }
      }

      >.group-guide.walk {
        >.line {
          height: calc(100% + 9px);
          top: -4.5px;
          left: 21px;
          border-left: 6px dotted $lightGrey;
        }
      }

      >.group-guide.walk.no-data {
        min-height: 9px;
      }

      .guide-stop {
        min-height: 36px;
        width: calc(100% - 48px - 12px);
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px 0px 8px 0px;
        margin: 0px 12px 0px 48px;
        box-sizing: border-box;
        font-size: 16px;
        color: $defaultFontColor;
        z-index: 1;
      }
    }
  }
}
}

.routing-panel {
  >.input-panel {
    position: fixed;
    z-index: 150;
    width: 100%;
    top: 0px;
    left: 0px;
    transform: translateY(0%);
    transition: transform 0.3s ease 0s;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.2);
    padding-top: 54px;

    >.vehicle-list {
      height: $routeModeList;
      width: 100%;
      background-color: $darkPrimary;
      z-index: 150;
      box-sizing: border-box;
      position: absolute;
      top: 0px;
      left: 0px;

      >button {
        height: $routeModeList;
        border: none;
        background-color: transparent;
        border-bottom: 4px solid $darkPrimary;
        box-sizing: border-box;
        transition: border-bottom-color 0.3s ease 0s;

        >i {
          color: rgba(255, 255, 255, 0.4);
          font-size: 28px;
          transition: color 0.3s ease 0s;
        }
      }

      >button.active {
        border-bottom: 4px solid $lightPrimary;

        >i {
          color: rgba(255, 255, 255, 1);
        }
      }
    }

    >.point-input-list {
      width: 100%;
      max-height: 150px;
      overflow: hidden;
      overflow-y: auto;
      background-color: rgb(255, 255, 255);
      z-index: 150;
      padding-top: 6px;
      display: block;

      >.point-input {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 6px 0px;
        position: relative;

        >i {
          font-size: 16px;
          width: 48px;
          color: $primary;
          text-align: center;
        }

        >.dotted-line {
          width: 0px;
          height: calc(60px - 24px);
          border: none;
          border-left: 3px dotted $extraLightPrimary;
          position: absolute;
          top: calc(50% + 12px);
          left: 22px;
        }

        >input {
          outline: none;
          font-size: 18px;
          padding: 0px 12px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          border: 1px solid #e6e6e6;
          border-radius: 8px;
          width: calc(100% - 48px - 48px);
          height: 48px;
          line-height: 48px;
          appearance: none;
          user-select: none;
        }

        >.drag-element {
          cursor: grab;
          padding: 0px 8px;
          width: 30px;
          height: 42px;
          z-index: 10;
        }
        >.interval-point {
          filter: hue-rotate(215deg) brightness(1.2);
        }

        >.end-point {
          filter: hue-rotate(125deg) brightness(1.6);
        }

        >input.full-width {
          width: calc(100% - 48px - 12px);
        }

        >button {
          width: 48px;
          height: 48px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: none;
          outline: none;
          background-color: transparent;
          padding: 0px;
          cursor: pointer;

          >i {
            color: $grey;
          }
        }
      }

      >.point-input:last-child {
        >i {
          font-size: 24px;
        }
      }
    }

    >.control-container {
      width: 100%;
      background-color: rgb(255, 255, 255);
      z-index: 150;
      transform: translateY(0%);
      transition: transform 0.2s ease 0s;
      box-sizing: border-box;
      padding: 6px 12px 6px 12px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      box-sizing: border-box;

      >button {
        width: 28px;
        height: 28px;
        padding: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $primary;
        border: none;
        border-radius: 50%;
        margin-left: 12px;
        transition: background-color 0.3s ease 0s;

        &.active {
          background-color: $darkPrimary;

          >i {
            color: rgb(253, 216, 53);
          }
        }

        >i {
          color: rgb(255, 255, 255);
          font-size: 18px;
          transition: color 0.3s ease 0s;
        }
      }

      >button:first-child {
        margin-left: 0px;
      }

      >button.reset {
        margin-left: auto;
      }

      >button.reset,
      >button.route {
        width: unset;
        padding: 0px 12px;
        border-radius: 14px;
        color: rgb(255, 255, 255);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-family: Prompt;
        transition: background-color 0.3s ease 0s;
      }

      >button.route:disabled {
        background-color: $lightGrey;
      }
    }

    >.summary-point-input {
      display: none;
    }

    >.setting-panel {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0px 12px 12px 12px;
      background-color: rgb(247, 247, 247);

      .choice-group {
        padding: 3px 0px 3px 6px;

        label {
          margin-left: 3px;
        }
      }

      .route-type {
        width: 50%;
        max-width: 200px;
        padding: 12px 0px 0px 0px;

        .title {
          font-family: Prompt;
          color: $primary;
        }
      }

      .route-mode {
        width: 50%;
        max-width: 200px;
        padding: 12px 0px 0px 0px;

        .title {
          font-family: Prompt;
          color: $primary;
        }
      }

      .depart-datetime {
        padding: 12px 0px 0px 0px;

        .title {
          font-family: Prompt;
          color: $primary;
        }

        .vue-datetime-container {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          position: relative;

          i {
            cursor: pointer;
            padding: 6px;
            background-color: $primary;
            border-radius: 0px 8px 8px 0px;
            color: rgb(255, 255, 255);
            border: 1px solid $extraLightGrey;
            border-left: none;
            box-sizing: border-box;
            font-size: 18px;
          }

          button.reset {
            width: 30px;
            height: 30px;
            position: absolute;
            top: 0px;
            right: 32px;
            cursor: pointer;
            border: none;
            background-color: transparent;
            padding: 0px;

            i {
              cursor: pointer;
              font-size: 18px;
              padding: 7px;
              box-sizing: border-box;
              background-color: transparent;
              border: none;
              color: $darkGrey;
            }
          }
        }
      }
    }
  }

  >.input-panel.hide {
    transform: translateY(-100%);
    box-shadow: none;
  }

  >.input-panel.full-height {
    height: 100%;
    // transform: translateY(-100%);
  }

  >.input-panel.minimize-height {
    >.point-input-list {
      display: none;
    }

    >.control-container {
      display: none;
    }

    >.setting-panel {
      display: none;
    }

    >.summary-point-input {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: nowrap;
      height: 60px;
      width: 100%;
      box-sizing: border-box;
      overflow: hidden;
      background-color: $darkPrimary;

      >div {
        border: 1px solid $extraLightGrey;
        border-radius: 8px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: nowrap;
        width: calc(100% - 12px);
        height: calc(100% - 12px);
        margin: 6px;
        overflow: hidden;
        overflow-x: auto;
        background-color: rgb(255, 255, 255);

        >span {
          font-size: 18px;
          flex-grow: 0;
          flex-shrink: 0;
          padding: 0px 12px;
          // max-width: calc(100% + 5px);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          line-height: 2;
        }

        >i {
          flex-grow: 0;
          flex-shrink: 0;
          color: $lightPrimary;
          text-align: center;
        }
      }

      >div::-webkit-scrollbar {
        display: none;
      }
    }
  }

  >.choose-location-widget {
    transform: translateX(0%);
    transition: transform 0.2s ease 0s;
  }

  >.choose-location-widget.hide {
    transform: translateX(100%);
  }

  .longdo-map-logo {
    position: fixed;
    bottom: 35%;
    right: 0px;
    z-index: 90;

    &.hide {
      display: none;
    }

    &.full-height {
      display: none;
    }

    &.minimize-height {
      display: none;
    }
  }
  >.guide-panel {
    position: fixed;
    height: 35%;
    width: 100%;
    bottom: 0%;
    left: 0px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.2);
    z-index: 90;
    transition: height 0.3s ease 0s;
    overflow: hidden;
    padding-bottom: 57px;
    box-sizing: border-box;

    >.loading-more-container {
      position: absolute;
      top: calc((100% - 57px) / 2);
      left: 50%;
      transform: translate(-50%, -50%);
    }
    >.guide {
      height: 100%;
      overflow: hidden;
      >.info {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 12px;
        background-color: rgb(76, 175, 80);

        >button.expand-more, button.expand-less {
          z-index: 1;
          position: absolute;
          top: 0px;
          left: 50%;
          transform: translate(-50%, 0%);
          padding: 0px;
          width: 36px;
          height: 18px;
          border: none;
          border-radius: 0px 0px 50% 50%;
          background-color: rgb(255, 255, 255);
          box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.05);

          >i {
            font-size: 18px;
            color: $grey;
          }
        }

        >.distance {
          font-family: Prompt;
          font-size: 18px;
          color: rgb(255, 255, 255);
          margin-left: 6px;
          margin-right: auto;
        }

        >.time {
          font-family: Prompt;
          font-size: 18px;
          color: rgb(255, 255, 255);
        }
        button.start-navigation {
          background-color: white;
          color: $primary;
          border: none;
          border-radius: 14px;
          padding: 0px 12px;
          height: 28px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          font-family: Prompt;
          font-size: 16px;
          box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.05);

          i {
            font-size: 18px;
            margin-right: 3px;
            color: $primary;
          }
        }
      }

      >.step-list {
        height: calc(100% - 52px);
        box-sizing: border-box;
        overflow: hidden;
        overflow-y: auto;

        >.a-to-b {
          >.step {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 12px;

            >.icon {
              width: 16px;
              margin-left: 30px;
              box-sizing: border-box;
            }

            >.name {
              padding: 0px 0px 0px 12px;
              box-sizing: border-box;
              margin-right: auto;
            }

            >.distance {
              padding: 0px 0px 0px 6px;
              box-sizing: border-box;
              margin-left: auto;
              min-width: 54px;
              text-align: right;
              color: $grey;
            }
          }

          >.step.from,
          >.step.to {
            background-color: rgb(240, 240, 240);

            >.icon {
              width: 24px;
              margin-left: 0px;
              display: flex;
              justify-content: center;
              align-items: center;

              >i {
                font-size: 20px;
                color: $darkPrimary;
              }
            }

            >.name {
              padding: 0px 0px 0px 6px;
              width: calc(100% - 24px);
            }
          }
        }
      }
    }
  }

  >.guide-panel.hide {
    height: 0% !important;
    padding-bottom: 0px !important;
    box-shadow: none !important;
  }

  >.guide-panel.full-height {
    height: 100%;
    z-index: 160;
    padding-bottom: 0px;
  }

  >.guide-panel.minimize-height:not(.hide) {
    height: 52px;
    overflow: hidden;
    overflow-y: hidden;
    padding-bottom: 0px;
  }

  >.transit-method-panel {
    >.transit-method-choose-hint {
      padding: 12px;
      font-size: 18px;
      background-color: $darkPrimary;
      color: white;
    }
    display: flex;
    flex-direction: column;
    position: fixed;
    height: calc(100% - 220px);
    width: 100%;
    bottom: 0%;
    left: 0px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.2);
    z-index: 90;
    transition: height 0.3s ease 0s;
    overflow: hidden;
    padding-bottom: 57px;
    box-sizing: border-box;

    >.loading-more-container {
      position: absolute;
      top: calc((100% - 57px) / 2);
      left: 50%;
      transform: translate(-50%, -50%);
    }

    >.transit-method-list {
      width: 100%;
      height: 100%;
      overflow-y: scroll;
      background-color: rgba(175, 220, 255, 0.2);
    }
  }

  >.transit-method-panel.input-minimize-height {
    height: calc(100% - 54px - 60px);
  }

  >.transit-method-panel.hide {
    height: 0%;
    padding-bottom: 0px;
    box-shadow: none;
  }

  >.mini-transit-guide-panel {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: 90;
    opacity: 1;
    transition: opacity 0.15s ease 0s;

    &.hide {
      opacity: 0;
    }
  }

  >.transit-guide-panel {
    position: fixed;
    height: calc((100% - 54px - 60px) * 0.6);
    width: 100%;
    bottom: 0%;
    left: 0px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.2);
    z-index: 90;
    transition: height 0.3s ease 0s,
      border-radius 0.3s ease 0s,
      opacity 0.3s ease 0s;
    overflow: hidden;
    padding-bottom: 57px;
    box-sizing: border-box;
    border-radius: 12px 12px 0px 0px;
    opacity: 1;

    &.hide {
      height: 0%;
      padding-bottom: 0px;
      opacity: 0;
    }

    &.full {
      height: 100%;
      z-index: 160;
      border-radius: 0px 0px 0px 0px;
      padding-bottom: 00px;
    }

    >.head {
      height: 46px;
      width: 100%;
      background: linear-gradient(90.56deg, $primary 0%, $darkPrimary 100%);
      border-bottom: 4px solid $lightPrimary;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      box-sizing: border-box;

      >button.back {
        width: 36px;
        height: 36px;
        border: none;
        background-color: transparent;
        padding: 0px;
        margin: 3px 0px 3px 6px;

        >i {
          color: rgb(255, 255, 255);
          font-size: 36px;
        }
      }

      >.detail {
        height: 100%;
        width: calc(100% - 6px - 36px);
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px 12px 0px 12px;

        >* {
          font-family: Prompt;
          color: rgb(255, 255, 255);
        }

        >.fee {
          margin-right: 6px;
        }

        >.distance {
          opacity: 0.6;
          margin: 0px auto 0px 0px;
        }

        >.time {
          font-size: 18px;
          flex: 0 0 auto;
        }
      }
    }

    >.transit-guide-list {
      overflow: hidden;
      overflow-y: auto;
      height: calc(100% - 46px);

      >.point {
        min-height: 48px;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px 12px 8px 48px;
        box-sizing: border-box;
        font-size: 16px;
        background-color: rgb(240, 240, 240);
        position: relative;
        z-index: 0;

        >i {
          position: absolute;
          font-size: 24px;
          color: $darkPrimary;
          top: 50%;
          left: 24px;
          transform: translate(-50%, -50%);
        }
      }

      >.group-guide {
        display: flex;
        flex-direction: row;
        position: relative;
        z-index: 1;

        >.line {
          position: absolute;
          height: calc(100% + 36px);
          top: -18px;
          left: 20px;
          border: none;
          border-left: 8px solid;
        }

        >.point {
          position: absolute;
          width: 14px;
          height: 14px;
          left: 24px;
          transform: translateX(-50%);
          background-color: rgb(255, 255, 255);
          border-radius: 7px;
          border: 2px solid;
          box-sizing: border-box;
        }

        >.point.start {
          top: -25px;
        }

        >.point.end {
          bottom: -25px;
        }

        >.group-guide-step {
          padding-left: 48px;
          width: 100%;
          box-sizing: border-box;

          .guide-step {
            min-height: 36px;
            color: $grey;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 8px 8px 0px 0px;
            box-sizing: border-box;

            >.content {
              align-self: stretch;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start;
              color: $grey;

              >.trip {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                position: relative;

                >.by {
                  position: absolute;
                  top: -2px;
                  left: -36px;
                  width: 24px;
                  height: 24px;
                  background-color: white;
                  border-radius: 12px;
                  box-sizing: border-box;
                  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.4));
                  display: flex;
                  justify-content: center;
                  align-items: center;

                  >i {
                    font-size: 18px;
                  }
                }

                >.line {
                  height: 20px;
                  border-radius: 12px;
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-content: center;
                  align-items: center;
                  padding: 0px 8px;
                  flex-wrap: nowrap;
                  font-size: 14px;
                  margin-right: 9px;
                  margin-bottom: 4px;

                  >.icon {
                    width: 16px;
                    height: 16px;
                    border-radius: 8px;
                    overflow: hidden;
                    background-color: rgba(255, 255, 255, 0.75);
                    margin-right: 6px;

                    >img {
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                    }
                  }
                }

                >.line.has-icon {
                  padding-left: 3px;
                }

                >.terminate {
                  padding-top: 1px;
                  color: $grey;
                }
              }

              >.count-stop {
                color: $grey;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                cursor: pointer;

                >i {
                  color: $darkGrey;
                  margin-left: 3px;
                  transition: transform 0.3s ease 0s;

                  &.up {
                    transform: rotate(-180deg);
                  }
                }
              }

              >.thru-stop-list {
                height: max-content;
                width: 100%;
                overflow: unset;
                opacity: 1;
                transition: height 0.3s ease 0s, opacity 0.6s ease 0s;

                &.hide {
                  height: 0px;
                  overflow: hidden;
                  opacity: 0;
                }

                >.thru-stop {
                  width: 100%;
                  position: relative;

                  >.thru-point {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: -27px;
                    width: 6px;
                    height: 6px;
                    background-color: white;
                    border-radius: 3px;
                    box-sizing: border-box;
                  }

                  >.name {
                    width: 100%;
                    min-height: 36px;
                    color: $grey;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 8px 8px 8px 8px;
                    box-sizing: border-box;
                  }
                }
              }
            }

            >.content.left {
              width: calc(100% - 64px);
            }

            >.content.right {
              width: 64px;
              align-items: flex-end;

              >.time {
                color: $darkPrimary;
                text-align: right;
              }

              >.fee {
                color: rgb(255, 153, 33);
              }
            }
          }
        }
      }

      >.group-guide.walk {
        >.line {
          height: calc(100% + 9px);
          top: -4.5px;
          left: 21px;
          border-left: 6px dotted $lightGrey;
        }
      }

      >.group-guide.walk.no-data {
        min-height: 9px;
      }

      .guide-stop {
        min-height: 36px;
        width: calc(100% - 48px - 12px);
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px 0px 8px 0px;
        margin: 0px 12px 0px 48px;
        box-sizing: border-box;
        font-size: 16px;
        color: $defaultFontColor;
        z-index: 1;
      }
    }
  }
}
::v-deep .vdatetime-overlay {
  background-color: rgba(0, 0, 0, 0.05) !important;
}
::v-deep .vdatetime-popup {
  width: 250px !important;
  left: unset !important;
  top: 66vh !important;
  margin-left: 110px;
  .vdatetime-popup__header {
    padding: 15px 30px !important;
  }
  .vdatetime-calendar__month {
    min-height: auto !important;
  }
  .vdatetime-time-picker {
    height: 250px !important;
  }
}
</style>
