var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "navigation-side-bar",
        class: { active: _vm.isShowingNavigationSideBar },
        on: {
          click: function ($event) {
            _vm.isShowingNavigationSideBar = false
          },
        },
      },
      [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "logo-container" }, [
            _c("img", {
              attrs: {
                src: _vm.longdoMapLogo2X,
                srcset:
                  _vm.longdoMapLogo1X + " 1x, " + _vm.longdoMapLogo2X + " 2x",
                loading: "lazy",
                alt: "Longdo Map: Thailand & World Map",
                title: "Longdo Map: Thailand & World Map",
              },
            }),
          ]),
          _c("div", { staticClass: "user" }, [
            _c("div", { staticClass: "profile-picture-container" }, [
              _vm.isLogin
                ? _c("img", {
                    attrs: { src: _vm.profilePicture, loading: "lazy" },
                    on: { click: _vm.openUserInfo },
                  })
                : _vm._e(),
              !_vm.isLogin
                ? _c(
                    "i",
                    {
                      staticClass: "material-icons-round ripple",
                      on: { click: _vm.clickLogin },
                    },
                    [_vm._v(" account_circle ")]
                  )
                : _vm._e(),
            ]),
            _c("div", { staticClass: "profile-info" }, [
              _vm.isLogin
                ? _c(
                    "div",
                    {
                      staticClass: "display-name ripple",
                      on: { click: _vm.openUserInfo },
                    },
                    [_vm._v(" " + _vm._s(_vm.displayName) + " ")]
                  )
                : _vm._e(),
              !_vm.isLogin
                ? _c(
                    "button",
                    {
                      staticClass: "login ripple",
                      on: { click: _vm.clickLogin },
                    },
                    [
                      _c("label", [
                        _vm._v(
                          _vm._s(_vm.$t("navigationSideBar.loginAndRegister"))
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "menu-list",
              style: {
                backgroundImage:
                  "url('" +
                  _vm.baseUrl +
                  "img/bg-repeat.svg?version=" +
                  _vm.appVersion +
                  "')",
              },
            },
            [
              _vm.isMapProduct &&
              ((_vm.isOnMobileApp && _vm.isLogin) || !_vm.isOnMobileApp)
                ? _c(
                    "button",
                    {
                      staticClass: "create-place ripple",
                      on: { click: _vm.clickCreatePlace },
                    },
                    [
                      _c("i", { staticClass: "material-icons-round" }, [
                        _vm._v(" add_location_alt "),
                      ]),
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("navigationSideBar.createPlace"))),
                      ]),
                    ]
                  )
                : _vm._e(),
              _c("div", { staticClass: "divider" }),
              _vm.isMapProduct &&
              ((_vm.isOnMobileApp && _vm.isLogin) || !_vm.isOnMobileApp)
                ? _c(
                    "button",
                    {
                      staticClass: "my-place ripple",
                      on: { click: _vm.clickMyPlace },
                    },
                    [
                      _c("i", { staticClass: "material-icons-round" }, [
                        _vm._v(" star "),
                      ]),
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("navigationSideBar.myPlace"))),
                      ]),
                    ]
                  )
                : _vm._e(),
              !_vm.isLongdoMapV3
                ? _c(
                    "button",
                    {
                      staticClass: "measurement ripple",
                      on: { click: _vm.clickMeasurement },
                    },
                    [
                      _c("i", { staticClass: "material-icons-round" }, [
                        _vm._v(" straighten "),
                      ]),
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("navigationSideBar.measurement"))),
                      ]),
                    ]
                  )
                : _vm._e(),
              (_vm.isMapProduct && !_vm.isOnMobileApp) || !_vm.isLongdoMapV3
                ? _c("hr", { staticClass: "solid" })
                : _vm._e(),
              _vm.isLongdoMapV3 && !_vm.isOnMobileApp
                ? _c(
                    "a",
                    {
                      staticClass: "raster-map ripple",
                      attrs: { href: _vm.rasterModeUrl },
                    },
                    [
                      _c("i", { staticClass: "material-icons-round" }, [
                        _vm._v(" grid_on "),
                      ]),
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("navigationSideBar.rasterMap"))),
                      ]),
                    ]
                  )
                : _vm._e(),
              !_vm.isLongdoMapV3
                ? _c(
                    "a",
                    {
                      staticClass: "vector-map ripple",
                      attrs: { href: _vm.vectorModeUrl },
                    },
                    [
                      _c("i", { staticClass: "material-icons-round" }, [
                        _vm._v(" grid_on "),
                      ]),
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("navigationSideBar.vectorMap"))),
                      ]),
                    ]
                  )
                : _vm._e(),
              !_vm.isOnMobileApp
                ? _c("hr", { staticClass: "solid" })
                : _vm._e(),
              _vm.isMapProduct && !_vm.isOnMobileApp
                ? _c(
                    "a",
                    {
                      staticClass: "api ripple",
                      attrs: { href: _vm.productWebUrl, target: "_blank" },
                    },
                    [
                      _c("i", { staticClass: "material-icons-round" }, [
                        _vm._v(" widgets "),
                      ]),
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("navigationSideBar.product"))),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm.isMapProduct && !_vm.isOnMobileApp
                ? _c(
                    "a",
                    {
                      staticClass: "api ripple",
                      attrs: { href: _vm.consoleWebUrl, target: "_blank" },
                    },
                    [
                      _c("i", { staticClass: "material-icons-round" }, [
                        _vm._v(" api "),
                      ]),
                      _c("label", [
                        _vm._v(
                          _vm._s(_vm.$t("navigationSideBar.ldmapConsole"))
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm.isMapProduct && !_vm.isOnMobileApp
                ? _c(
                    "a",
                    {
                      staticClass: "api-doc ripple",
                      attrs: { href: _vm.apiDocWebUrl, target: "_blank" },
                    },
                    [
                      _c("i", { staticClass: "material-icons-round" }, [
                        _vm._v(" lightbulb "),
                      ]),
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("navigationSideBar.apiDocument"))),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm.isMapProduct && !_vm.isOnMobileApp
                ? _c(
                    "a",
                    {
                      staticClass: "extra-api ripple",
                      attrs: { href: _vm.extraApiWebUrl, target: "_blank" },
                    },
                    [
                      _c("i", { staticClass: "material-icons-round" }, [
                        _vm._v(" tips_and_updates "),
                      ]),
                      _c("label", [
                        _vm._v(
                          _vm._s(_vm.$t("navigationSideBar.ldmapExtraAPI"))
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm.isMapProduct && !_vm.isOnMobileApp
                ? _c("hr", { staticClass: "solid" })
                : _vm._e(),
              !_vm.isOnMobileApp
                ? _c(
                    "a",
                    {
                      staticClass: "download ripple",
                      attrs: { href: _vm.downloadWebUrl, target: "_blank" },
                    },
                    [
                      _c("i", { staticClass: "material-icons-round" }, [
                        _vm._v(" file_download "),
                      ]),
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("navigationSideBar.download"))),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm.isMapProduct && !_vm.isOnMobileApp
                ? _c(
                    "a",
                    {
                      staticClass: "all-locations ripple",
                      attrs: { href: _vm.allLocationsWebUrl, target: "_blank" },
                    },
                    [
                      _c("i", { staticClass: "material-icons-round" }, [
                        _vm._v(" format_list_bulleted "),
                      ]),
                      _c("label", [
                        _vm._v(
                          _vm._s(_vm.$t("navigationSideBar.allLocations"))
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm.isMapProduct && !_vm.isOnMobileApp
                ? _c(
                    "a",
                    {
                      staticClass: "misc ripple",
                      attrs: { href: _vm.miscWebUrl, target: "_blank" },
                    },
                    [
                      _c("i", { staticClass: "material-icons-round" }, [
                        _vm._v(" miscellaneous_services "),
                      ]),
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("navigationSideBar.misc"))),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm.isMapProduct && !_vm.isOnMobileApp
                ? _c(
                    "a",
                    {
                      staticClass: "help ripple",
                      attrs: { href: _vm.helpWebUrl, target: "_blank" },
                    },
                    [
                      _c("i", { staticClass: "material-icons-round" }, [
                        _vm._v(" support "),
                      ]),
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("navigationSideBar.help"))),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm.isTrafficProduct
                ? _c(
                    "a",
                    {
                      staticClass: "about ripple",
                      on: {
                        click: function ($event) {
                          return _vm.onClickUrl(_vm.aboutWebUrl)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "material-icons-round" }, [
                        _vm._v(" info "),
                      ]),
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("navigationSideBar.about"))),
                      ]),
                    ]
                  )
                : _vm._e(),
              _c(
                "button",
                {
                  staticClass: "settings ripple",
                  on: { click: _vm.clickSettings },
                },
                [
                  _c("i", { staticClass: "material-icons-round" }, [
                    _vm._v(" settings "),
                  ]),
                  _c("label", [
                    _vm._v(_vm._s(_vm.$t("navigationSideBar.settings"))),
                  ]),
                ]
              ),
              _vm.isLogin
                ? _c(
                    "button",
                    {
                      staticClass: "logout ripple",
                      on: { click: _vm.clickLogout },
                    },
                    [
                      _c("i", { staticClass: "material-icons-round" }, [
                        _vm._v(" lock_open "),
                      ]),
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("navigationSideBar.logout"))),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm.isLogin ? _c("hr", { staticClass: "solid" }) : _vm._e(),
              _vm.isLogin
                ? _c(
                    "button",
                    {
                      staticClass: "pdpa-management ripple",
                      on: { click: _vm.clickPdpaManagement },
                    },
                    [
                      _c("i", { staticClass: "material-icons-round" }, [
                        _vm._v(" security "),
                      ]),
                      _c("label", [
                        _vm._v(
                          _vm._s(_vm.$t("navigationSideBar.pdpaManagement"))
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm.isLogin && _vm.isIosNativeApp
                ? _c(
                    "button",
                    {
                      staticClass: "delete-account ripple",
                      on: { click: _vm.clickDeleteLongdoAccount },
                    },
                    [
                      _c("i", { staticClass: "material-icons-round" }, [
                        _vm._v(" no_accounts "),
                      ]),
                      _c("label", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("navigationSideBar.deleteLongdoAccount")
                          )
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _c("hr", { staticClass: "solid" }),
              _vm.isTrafficProduct
                ? _c(
                    "a",
                    {
                      staticClass: "map-web ripple",
                      on: {
                        click: function ($event) {
                          return _vm.onClickUrl(_vm.mapWebUrl)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "material-icons-round" }, [
                        _vm._v(" map "),
                      ]),
                      _c("label", [_vm._v("Longdo Map")]),
                    ]
                  )
                : _vm._e(),
              _vm.isMapProduct
                ? _c(
                    "a",
                    {
                      staticClass: "traffic-web ripple",
                      on: {
                        click: function ($event) {
                          return _vm.onClickUrl(_vm.trafficWebUrl)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "material-icons-round" }, [
                        _vm._v(" traffic "),
                      ]),
                      _c("label", [_vm._v("Longdo Traffic")]),
                    ]
                  )
                : _vm._e(),
              !_vm.isOnMobileApp
                ? _c(
                    "a",
                    {
                      staticClass: "traffic-web ripple",
                      on: {
                        click: function ($event) {
                          return _vm.onClickUrl(_vm.volunteerWebUrl)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "material-icons-round" }, [
                        _vm._v(" volunteer_activism "),
                      ]),
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("navigationSideBar.volunteer"))),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm.isTrafficProduct
                ? _c(
                    "a",
                    {
                      staticClass: "traffic-web ripple volunteer",
                      on: {
                        click: function ($event) {
                          return _vm.onClickUrl(_vm.volunteerWebUrl)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "amount" }, [
                        _c("div", [
                          _vm._v(
                            _vm._s(_vm.$t("navigationSideBar.volunteerAmount"))
                          ),
                        ]),
                        _c("div", [_vm._v(_vm._s(_vm.probeAmount))]),
                      ]),
                    ]
                  )
                : _vm._e(),
              !_vm.isOnMobileApp
                ? _c(
                    "a",
                    {
                      staticClass: "traffic-web ripple",
                      on: {
                        click: function ($event) {
                          return _vm.onClickUrl(_vm.easyMapWebUrl)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "material-icons-round" }, [
                        _vm._v(" map "),
                      ]),
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("navigationSideBar.easyMap"))),
                      ]),
                    ]
                  )
                : _vm._e(),
              _c(
                "a",
                {
                  staticClass: "blog ripple",
                  on: {
                    click: function ($event) {
                      return _vm.onClickUrl(_vm.mapBlogWebUrl)
                    },
                  },
                },
                [
                  _c("i", { staticClass: "material-icons-round" }, [
                    _vm._v(" comment "),
                  ]),
                  _c("label", [
                    _vm._v(_vm._s(_vm.$t("navigationSideBar.blog"))),
                  ]),
                ]
              ),
              _vm.isTrafficProduct
                ? _c(
                    "a",
                    {
                      staticClass: "traffic-web ripple",
                      on: {
                        click: function ($event) {
                          return _vm.onClickUrl(_vm.cameraListUrl)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "material-icons-round" }, [
                        _vm._v(" videocam "),
                      ]),
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("navigationSideBar.camera"))),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm.isTrafficProduct
                ? _c(
                    "button",
                    {
                      staticClass: "traffic-web ripple",
                      on: { click: _vm.shareWebUrl },
                    },
                    [
                      _c("i", { staticClass: "material-icons-round" }, [
                        _vm._v(" mail "),
                      ]),
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("navigationSideBar.share"))),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm.isAdmin && !_vm.isOnMobileApp
                ? _c(
                    "a",
                    {
                      staticClass: "blog ripple admin-panel",
                      attrs: { href: _vm.POIIconsUrl, target: "_blank" },
                    },
                    [
                      _c("i", { staticClass: "material-icons-round" }, [
                        _vm._v(" interests "),
                      ]),
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("navigationSideBar.POIIcons"))),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm.isAdmin && !_vm.isOnMobileApp
                ? _c(
                    "a",
                    {
                      staticClass: "blog ripple admin-panel",
                      attrs: { href: _vm.userManageUrl, target: "_blank" },
                    },
                    [
                      _c("i", { staticClass: "material-icons-round" }, [
                        _vm._v(" manage_accounts "),
                      ]),
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("navigationSideBar.userManage"))),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm.isAdmin && !_vm.isOnMobileApp
                ? _c(
                    "a",
                    {
                      staticClass: "blog ripple admin-panel",
                      attrs: { href: _vm.poiManageUrl, target: "_blank" },
                    },
                    [
                      _c("i", { staticClass: "material-icons-round" }, [
                        _vm._v(" where_to_vote "),
                      ]),
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("navigationSideBar.poiManage"))),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm.isAdmin && !_vm.isOnMobileApp
                ? _c(
                    "a",
                    {
                      staticClass: "blog ripple admin-panel",
                      attrs: { href: _vm.logEntriesUrl, target: "_blank" },
                    },
                    [
                      _c("i", { staticClass: "material-icons-round" }, [
                        _vm._v(" assignment "),
                      ]),
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("navigationSideBar.logEntries"))),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm.isAdmin && !_vm.isOnMobileApp
                ? _c(
                    "a",
                    {
                      staticClass: "blog ripple admin-panel",
                      attrs: { href: _vm.OOISettingsUrl, target: "_blank" },
                    },
                    [
                      _c("i", { staticClass: "material-icons-round" }, [
                        _vm._v(" settings "),
                      ]),
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("navigationSideBar.OOISettings"))),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]
          ),
          _c("label", { staticClass: "version" }, [
            _vm._v("Version " + _vm._s(_vm.appVersion)),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }