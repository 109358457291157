var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "measurement-control-panel",
      class: {
        hide: _vm.isHiding,
      },
    },
    [
      _c("div", { staticClass: "head" }, [
        _c("label", [
          _vm._v(" " + _vm._s(_vm.$t("measurementControlPanel.title")) + " "),
        ]),
        _c(
          "button",
          { staticClass: "close ripple", on: { click: _vm.clickClose } },
          [_c("i", { staticClass: "material-icons-round" }, [_vm._v("close")])]
        ),
      ]),
      _c("div", { staticClass: "toolbar" }, [
        _c("button", { staticClass: "ripple", on: { click: _vm.clickClear } }, [
          _c("i", { staticClass: "material-icons-round" }, [_vm._v("delete")]),
          _c("label", [
            _vm._v(_vm._s(_vm.$t("measurementControlPanel.clear"))),
          ]),
        ]),
        _c("button", { staticClass: "ripple", on: { click: _vm.clickUndo } }, [
          _c("i", { staticClass: "material-icons-round" }, [_vm._v("undo")]),
          _c("label", [_vm._v(_vm._s(_vm.$t("measurementControlPanel.undo")))]),
        ]),
        _c("button", { staticClass: "ripple", on: { click: _vm.clickDone } }, [
          _c("i", { staticClass: "material-icons-round" }, [_vm._v("done")]),
          _c("label", [_vm._v(_vm._s(_vm.$t("measurementControlPanel.done")))]),
        ]),
        _c("button", { staticClass: "ripple", on: { click: _vm.clickAdd } }, [
          _c("i", { staticClass: "material-icons-round" }, [_vm._v("add")]),
          _c("label", [_vm._v(_vm._s(_vm.$t("measurementControlPanel.add")))]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }