var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isHide
    ? _c("div", { staticClass: "dialog-card" }, [
        _vm.isAuthenticated
          ? _c("div", { staticClass: "report-panel" }, [
              _c("div", { staticClass: "content" }, [
                _c("label", [_vm._v(_vm._s(_vm.$t("reportEventForm.authen")))]),
              ]),
            ])
          : _c("div", { staticClass: "report-panel" }, [
              _c("div", { staticClass: "panel-name" }, [
                _c("label", [
                  _vm._v(" " + _vm._s(_vm.$t("reportEventForm.header")) + " "),
                ]),
                _c("div", [
                  _c(
                    "button",
                    {
                      staticClass: "close ripple",
                      on: { click: _vm.clickClose },
                    },
                    [
                      _c("i", { staticClass: "material-icons-round" }, [
                        _vm._v("close"),
                      ]),
                    ]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "divider-h" }),
              _c("div", { staticClass: "content" }, [
                _c(
                  "button",
                  {
                    staticClass: "close ripple",
                    on: { click: _vm.clickClose },
                  },
                  [
                    _c("i", { staticClass: "material-icons-round" }, [
                      _vm._v("close"),
                    ]),
                  ]
                ),
                _c("label", [
                  _vm._v(_vm._s(_vm.$t("reportEventForm.type")) + " "),
                  _c("label", { staticClass: "red-star" }, [_vm._v("* ")]),
                ]),
                _c("br"),
                _c(
                  "div",
                  { staticClass: "grid eventTypeContent" },
                  _vm._l(
                    _vm.eventTypeList,
                    function (eventType, eventTypeIndex) {
                      return _c(
                        "div",
                        {
                          key: "event-type-" + eventTypeIndex,
                          ref: "eventTypeBtn" + eventTypeIndex,
                          refInFor: true,
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "eventType-btn",
                              class: {
                                selectedEventType:
                                  _vm.idEventTypeClick === eventType.id,
                                selectedEventType:
                                  _vm.idSelectedEventType === eventType.id,
                              },
                              attrs: { id: eventType.id },
                              on: {
                                click: function ($event) {
                                  return _vm.handleEventTypeClick(
                                    eventType.id,
                                    eventType.name,
                                    eventType.name_en,
                                    eventType.severity
                                  )
                                },
                              },
                            },
                            [
                              _c("img", {
                                staticClass: "eventType-icon",
                                attrs: {
                                  alt: "image",
                                  src: eventType.icon,
                                  loading: "lazy",
                                },
                              }),
                              _c("label", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$i18n.locale === "en"
                                      ? eventType.name_en
                                      : eventType.name
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ]
                      )
                    }
                  ),
                  0
                ),
                _c(
                  "p",
                  {
                    staticClass: "alret-fill-input",
                    attrs: { id: "alert-event-type" },
                  },
                  [_vm._v(_vm._s(_vm.$t("reportEventForm.alert.eventType")))]
                ),
                _c("div", { staticClass: "grid" }, [
                  _c("label", { staticClass: "input-title" }, [
                    _vm._v(_vm._s(_vm.$t("reportEventForm.title")) + " (TH) "),
                    _c("label", { staticClass: "red-star" }, [_vm._v("* ")]),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.titleInput_TH,
                        expression: "titleInput_TH",
                      },
                    ],
                    staticClass: "input-style",
                    attrs: {
                      placeholder: _vm.$t("reportEventForm.title"),
                      isRequired: true,
                    },
                    domProps: { value: _vm.titleInput_TH },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.titleInput_TH = $event.target.value
                      },
                    },
                  }),
                  _c("div", { staticClass: "spacer" }),
                  _c(
                    "p",
                    {
                      staticClass: "alret-fill-input",
                      attrs: { id: "alert-title-th" },
                    },
                    [_vm._v(_vm._s(_vm.$t("reportEventForm.alert.titleTH")))]
                  ),
                ]),
                _c("div", { staticClass: "grid" }, [
                  _c("label", { staticClass: "input-title" }, [
                    _vm._v(_vm._s(_vm.$t("reportEventForm.title")) + " (EN) "),
                    _c("label", { staticClass: "red-star" }, [_vm._v("* ")]),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.titleInput_EN,
                        expression: "titleInput_EN",
                      },
                    ],
                    staticClass: "input-style",
                    attrs: {
                      placeholder: _vm.$t("reportEventForm.title"),
                      isRequired: true,
                    },
                    domProps: { value: _vm.titleInput_EN },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.titleInput_EN = $event.target.value
                      },
                    },
                  }),
                  _c("div", { staticClass: "spacer" }),
                  _c(
                    "p",
                    {
                      staticClass: "alret-fill-input",
                      attrs: { id: "alert-title-en" },
                    },
                    [_vm._v(_vm._s(_vm.$t("reportEventForm.alert.titleEN")))]
                  ),
                ]),
                _c("div", { staticClass: "image-btn" }, [
                  _c("label", { staticClass: "add-image ripple" }, [
                    _vm._v(" " + _vm._s(_vm.$t("reportEventForm.image")) + " "),
                  ]),
                  _c(
                    "label",
                    {
                      staticClass: "custom-file-upload",
                      on: { click: _vm.onClickAddImage },
                    },
                    [
                      _c(
                        "i",
                        { staticClass: "material-icons-round icon-camera" },
                        [_vm._v("camera_alt")]
                      ),
                      _vm._v(" Choose File "),
                    ]
                  ),
                  _c("input", {
                    ref: "add-image-input",
                    staticClass: "add-image-input",
                    attrs: { type: "file", accept: "image/png, image/jpeg" },
                    on: { change: _vm.uploadImage },
                  }),
                ]),
                _vm._m(0),
                _vm.imageList
                  ? _c(
                      "div",
                      _vm._l(_vm.imageList, function (img, index) {
                        return _c(
                          "div",
                          { key: "img-" + index, staticClass: "img-wrap" },
                          [
                            _c("div", { staticClass: "grid" }, [
                              _c("div", { staticClass: "spacer" }),
                              _c(
                                "button",
                                {
                                  staticClass: "closeImage",
                                  on: {
                                    click: function ($event) {
                                      return _vm.stackRemoveSelectedImage(
                                        img.id
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "i",
                                    { staticClass: "material-icons-round" },
                                    [_vm._v("close")]
                                  ),
                                ]
                              ),
                              _c("img", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.isImagePreviewEditMode,
                                    expression: "isImagePreviewEditMode",
                                  },
                                ],
                                attrs: {
                                  id: "image-" + img.id,
                                  src: img.src,
                                  alt: "event image",
                                  width: "200",
                                },
                              }),
                            ]),
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isImagePreview,
                        expression: "isImagePreview",
                      },
                    ],
                    staticClass: "img-wrap",
                  },
                  [
                    _c("div", { staticClass: "grid" }, [
                      _c("div", { staticClass: "spacer" }),
                      _c(
                        "button",
                        {
                          staticClass: "closeImage",
                          on: { click: _vm.clearImage },
                        },
                        [
                          _c("i", { staticClass: "material-icons-round" }, [
                            _vm._v("close"),
                          ]),
                        ]
                      ),
                      _c("img", {
                        attrs: {
                          id: "eventImage",
                          alt: "event image",
                          width: "200",
                        },
                      }),
                    ]),
                  ]
                ),
                _vm.editMode
                  ? _c("div", { staticClass: "grid" }, [
                      _c("div", { staticClass: "spacer" }),
                      _c(
                        "button",
                        {
                          staticClass: "editLocationBtn",
                          on: { click: _vm.editLocation },
                        },
                        [_vm._v(_vm._s(_vm.$t("reportEventForm.editLocation")))]
                      ),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "loading-overlay-translate" }, [
                  _c("div", { staticClass: "grid" }, [
                    _c("label", { staticClass: "title-textarea" }, [
                      _vm._v(
                        _vm._s(_vm.$t("reportEventForm.detail")) + " (TH) "
                      ),
                    ]),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.detailInput,
                          expression: "detailInput",
                        },
                      ],
                      class: { "bg-fade-loading": _vm.isTranslateLoading },
                      attrs: {
                        disabled: _vm.isTranslateLoading,
                        placeholder: _vm.$t("reportEventForm.detail"),
                      },
                      domProps: { value: _vm.detailInput },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.detailInput = $event.target.value
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "grid translate" }, [
                  _c("div", { staticClass: "spacer" }),
                  _c("div", [
                    _c(
                      "i",
                      {
                        staticClass: "material-icons-round icon-import-export",
                      },
                      [_vm._v("import_export")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "translate-btn",
                        class: {
                          "unset-cursor":
                            _vm.isTranslateLoading || _vm.isNullTextTranslate,
                        },
                        attrs: {
                          disabled:
                            _vm.isTranslateLoading || _vm.isNullTextTranslate,
                        },
                        on: { click: _vm.translate },
                      },
                      [
                        _c(
                          "i",
                          {
                            staticClass: "material-icons-round icon-translate",
                          },
                          [_vm._v("translate")]
                        ),
                        _c(
                          "label",
                          {
                            class: {
                              "unset-cursor":
                                _vm.isTranslateLoading ||
                                _vm.isNullTextTranslate,
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("reportEventForm.translate")))]
                        ),
                      ]
                    ),
                    _c("label", { staticClass: "label-translate-by" }, [
                      _vm._v(
                        _vm._s(_vm.$i18n.locale === "th" ? "(โดย " : "(by ") +
                          " "
                      ),
                      _c(
                        "a",
                        {
                          on: {
                            click: function ($event) {
                              _vm.onClickUrl(_vm.$t("reportEventForm.itpLink"))
                            },
                          },
                        },
                        [_vm._v("ITP")]
                      ),
                      _vm._v(")"),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "loading-overlay-translate" }, [
                  _c("div", { staticClass: "grid" }, [
                    _c("label", [
                      _vm._v(
                        _vm._s(_vm.$t("reportEventForm.detail")) + " (EN) "
                      ),
                    ]),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.detailInput_en,
                          expression: "detailInput_en",
                        },
                      ],
                      class: { "bg-fade-loading": _vm.isTranslateLoading },
                      attrs: {
                        disabled: _vm.isTranslateLoading,
                        placeholder: _vm.$t("reportEventForm.detail"),
                      },
                      domProps: { value: _vm.detailInput_en },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.detailInput_en = $event.target.value
                        },
                      },
                    }),
                    _vm.isTranslateLoading
                      ? _c("img", {
                          staticClass: "img-loading",
                          attrs: {
                            src: require("@/assets/img/loading.gif"),
                            alt: "loading",
                          },
                        })
                      : _vm._e(),
                  ]),
                ]),
                _c("div", { staticClass: "grid" }, [
                  _c("label", [
                    _vm._v(_vm._s(_vm.$t("reportEventForm.status")) + " "),
                    _c("label", { staticClass: "red-star" }, [_vm._v("* ")]),
                  ]),
                  _c("div", { staticClass: "inline" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.picked_status,
                          expression: "picked_status",
                        },
                      ],
                      attrs: { type: "radio", id: "processing", value: "1" },
                      domProps: { checked: _vm._q(_vm.picked_status, "1") },
                      on: {
                        change: function ($event) {
                          _vm.picked_status = "1"
                        },
                      },
                    }),
                    _c("label", { attrs: { for: "processing" } }, [
                      _vm._v(
                        _vm._s(_vm.$t("reportEventForm.statusStage.processing"))
                      ),
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.picked_status,
                          expression: "picked_status",
                        },
                      ],
                      attrs: { type: "radio", id: "done", value: "0" },
                      domProps: { checked: _vm._q(_vm.picked_status, "0") },
                      on: {
                        change: function ($event) {
                          _vm.picked_status = "0"
                        },
                      },
                    }),
                    _c("label", { attrs: { for: "done" } }, [
                      _vm._v(
                        _vm._s(_vm.$t("reportEventForm.statusStage.done"))
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "depart-datetime grid" }, [
                  _c("label", [
                    _vm._v(
                      _vm._s(_vm.$t("reportEventForm.dateEventStart")) + " "
                    ),
                    _c("label", { staticClass: "red-star" }, [_vm._v("* ")]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "vue-datetime-container datetime-start" },
                    [
                      _c("VueDatetime", {
                        attrs: {
                          type: _vm.typeCalendar,
                          "input-id": "dapart-datetime-input-start",
                          "input-class": "datetime-input",
                          title: "",
                          "min-datetime": new Date().toISOString(),
                          phrases: {
                            ok: _vm.$t("routingPanel.okDepartDatetime"),
                            cancel: _vm.$t("routingPanel.cancelDepartDatetime"),
                          },
                        },
                        model: {
                          value: _vm.departDatetimeStartEvent,
                          callback: function ($$v) {
                            _vm.departDatetimeStartEvent = $$v
                          },
                          expression: "departDatetimeStartEvent",
                        },
                      }),
                      _c(
                        "i",
                        {
                          staticClass: "ripple material-icons-round",
                          on: {
                            click: function ($event) {
                              return _vm.clickDepartDatetimeIcon("start")
                            },
                          },
                        },
                        [_vm._v("schedule")]
                      ),
                      _vm.departDatetimeStartEvent !== ""
                        ? _c(
                            "button",
                            {
                              staticClass: "reset ripple",
                              on: {
                                click: function ($event) {
                                  _vm.departDatetimeStartEvent = ""
                                },
                              },
                            },
                            [
                              _c(
                                "i",
                                {
                                  staticClass: "ripple material-icons-round",
                                  style:
                                    _vm.$i18n.locale === "en"
                                      ? "margin-left: 15px;"
                                      : "",
                                },
                                [_vm._v("close")]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _c(
                  "p",
                  {
                    staticClass: "alret-fill-input",
                    attrs: { id: "alert-start-event" },
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("reportEventForm.alert.dateStartEvent"))
                    ),
                  ]
                ),
                _c("div", { staticClass: "grid depart-datetime" }, [
                  _c("label", [
                    _vm._v(
                      _vm._s(_vm.$t("reportEventForm.dateEventEnd")) + " "
                    ),
                    _c("label", { staticClass: "red-star" }, [_vm._v("* ")]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "vue-datetime-container",
                      style:
                        _vm.$i18n.locale === "en" ? "margin-left: 23px;" : "",
                    },
                    [
                      _c("VueDatetime", {
                        attrs: {
                          type: _vm.typeCalendar,
                          "input-id": "dapart-datetime-input-end",
                          "input-class": "datetime-input",
                          title: "",
                          "min-datetime": new Date().toISOString(),
                          phrases: {
                            ok: _vm.$t("routingPanel.okDepartDatetime"),
                            cancel: _vm.$t("routingPanel.cancelDepartDatetime"),
                          },
                        },
                        model: {
                          value: _vm.departDatetimeEndEvent,
                          callback: function ($$v) {
                            _vm.departDatetimeEndEvent = $$v
                          },
                          expression: "departDatetimeEndEvent",
                        },
                      }),
                      _c(
                        "i",
                        {
                          staticClass: "ripple material-icons-round",
                          on: {
                            click: function ($event) {
                              return _vm.clickDepartDatetimeIcon("end")
                            },
                          },
                        },
                        [_vm._v("schedule")]
                      ),
                      _vm.departDatetimeEndEvent !== ""
                        ? _c(
                            "button",
                            {
                              staticClass: "reset ripple end-datetime-close",
                              on: {
                                click: function ($event) {
                                  _vm.departDatetimeEndEvent = ""
                                },
                              },
                            },
                            [
                              _c(
                                "i",
                                { staticClass: "ripple material-icons-round" },
                                [_vm._v("close")]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _c(
                  "p",
                  {
                    staticClass: "alret-fill-input",
                    attrs: { id: "alert-end-event" },
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("reportEventForm.alert.dateStartEvent"))
                    ),
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "alret-fill-input",
                    attrs: { id: "alert-start-end-event-corrected" },
                  },
                  [
                    _vm._v(
                      "* กรุณาเลือกวันที่เริ่มและสิ้นสุดเหตุการณ์ให้ถูกต้อง"
                    ),
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "alret-fill-input",
                    attrs: { id: "alert-start-event-time-corrected" },
                  },
                  [
                    _vm._v(
                      "* กรุณาเลือกเวลาเริ่มต้นและสิ้นสุดเหตุการณ์ให้ถูกต้อง"
                    ),
                  ]
                ),
                _c("div", { staticClass: "grid" }, [
                  _c("div", { staticClass: "spacer" }),
                  _c("div", { staticClass: "inline" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.allday,
                          expression: "allday",
                        },
                      ],
                      attrs: { type: "checkbox", id: "checkbox-allday" },
                      domProps: {
                        checked: Array.isArray(_vm.allday)
                          ? _vm._i(_vm.allday, null) > -1
                          : _vm.allday,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.allday,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.allday = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.allday = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.allday = $$c
                          }
                        },
                      },
                    }),
                    _c("label", { attrs: { for: "checkbox-allday" } }, [
                      _vm._v(_vm._s(_vm.$t("reportEventForm.allday"))),
                    ]),
                    _vm.isHasClearMemcachePermission
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.sticky,
                              expression: "sticky",
                            },
                          ],
                          staticClass: "sticky-display",
                          attrs: { type: "checkbox", id: "checkbox-sticky" },
                          domProps: {
                            checked: Array.isArray(_vm.sticky)
                              ? _vm._i(_vm.sticky, null) > -1
                              : _vm.sticky,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.sticky,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 && (_vm.sticky = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.sticky = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.sticky = $$c
                              }
                            },
                          },
                        })
                      : _vm._e(),
                    _vm.isHasClearMemcachePermission
                      ? _c(
                          "label",
                          {
                            class: _vm.isHasClearMemcachePermission
                              ? "sticky-display"
                              : "",
                            attrs: { for: "checkbox-sticky" },
                          },
                          [_vm._v(_vm._s(_vm.$t("reportEventForm.stricky")))]
                        )
                      : _vm._e(),
                  ]),
                ]),
                _c("div", { staticClass: "grid" }, [
                  _c("label", [
                    _vm._v(_vm._s(_vm.$t("reportEventForm.place")) + " "),
                  ]),
                  _c("select", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.placeInput,
                        expression: "placeInput",
                      },
                    ],
                    attrs: { id: "dropdownPlace" },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.placeInput = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  }),
                ]),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isHasSetSeveriyPermission,
                        expression: "isHasSetSeveriyPermission",
                      },
                    ],
                    staticClass: "grid",
                  },
                  [
                    _c("label", [
                      _vm._v(_vm._s(_vm.$t("reportEventForm.severity")) + " "),
                    ]),
                    _c("select", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.severity2,
                          expression: "severity2",
                        },
                      ],
                      attrs: {
                        disabled: !_vm.isHasSetSeveriyPermission,
                        id: "dropdownSeverity",
                      },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.severity2 = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                          _vm.handleSeverityLevel,
                        ],
                      },
                    }),
                  ]
                ),
                _c("div", { staticClass: "dropdownMore" }, [
                  _c(
                    "div",
                    {
                      staticClass: "titleMore",
                      on: { click: _vm.expandMoreLess },
                    },
                    [
                      _vm.isExpandLess
                        ? _c(
                            "i",
                            {
                              staticClass: "icon-dropdown material-icons-round",
                            },
                            [_vm._v("expand_more")]
                          )
                        : _c(
                            "i",
                            {
                              staticClass: "icon-dropdown material-icons-round",
                            },
                            [_vm._v("expand_less")]
                          ),
                      _c("label", [
                        _vm._v(
                          _vm._s(_vm.$t("reportEventForm.optional.title"))
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "dropdown-content",
                      class: { Expand: _vm.isExpandLess },
                      attrs: { id: "DropdownContent" },
                    },
                    [
                      _c("label", [
                        _vm._v(
                          _vm._s(_vm.$t("reportEventForm.optional.coordinate"))
                        ),
                      ]),
                      _c("div", { staticClass: "grid-dropdownMore" }, [
                        _c("label", [
                          _vm._v(
                            _vm._s(_vm.$t("reportEventForm.optional.lat")) + " "
                          ),
                        ]),
                        _c("label", [
                          _vm._v(
                            _vm._s(_vm.$t("reportEventForm.optional.lon")) + " "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "grid-dropdownMore" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.lat,
                              expression: "lat",
                            },
                          ],
                          staticClass: "input-style",
                          attrs: {
                            type: "numner",
                            onKeyPress:
                              "if(this.value.length==9) return false;",
                          },
                          domProps: { value: _vm.lat },
                          on: {
                            change: _vm.handleLocationChange,
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.lat = $event.target.value
                            },
                          },
                        }),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.lon,
                              expression: "lon",
                            },
                          ],
                          staticClass: "input-style",
                          attrs: {
                            type: "number",
                            onKeyPress:
                              "if(this.value.length==9) return false;",
                          },
                          domProps: { value: _vm.lon },
                          on: {
                            change: _vm.handleLocationChange,
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.lon = $event.target.value
                            },
                          },
                        }),
                      ]),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "btn-submit-clear" }, [
                  _c(
                    "button",
                    {
                      staticClass: "submitForm",
                      on: { click: _vm.checkFillInput },
                    },
                    [
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("reportEventForm.save"))),
                      ]),
                    ]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "clearForm",
                      on: { click: _vm.clearReport },
                    },
                    [
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("reportEventForm.clear"))),
                      ]),
                    ]
                  ),
                ]),
                _vm.isSavingReport
                  ? _c("div", { staticClass: "loading-overlay" }, [
                      _vm.createErrorMessage === "" &&
                      _vm.createSuccessMessage === ""
                        ? _c("img", {
                            attrs: {
                              src: require("@/assets/img/loading.gif"),
                              alt: "loading",
                            },
                          })
                        : _vm._e(),
                      _vm.createErrorMessage !== ""
                        ? _c("span", { staticClass: "error-message" }, [
                            _vm._v(_vm._s(_vm.createErrorMessage)),
                          ])
                        : _vm._e(),
                      _vm.createSuccessMessage !== ""
                        ? _c("span", { staticClass: "success-message" }, [
                            _vm._v(_vm._s(_vm.createSuccessMessage)),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ]),
            ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "grid" }, [
      _c("div", { staticClass: "spacer" }),
      _c(
        "div",
        { staticClass: "file-name", attrs: { id: "file-name-chosen" } },
        [_vm._v("No file chosen")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }