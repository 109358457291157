var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "button",
      {
        staticClass: "open-traffic-event-panel ripple",
        class: { hide: _vm.isHidingOpenButton },
        on: { click: _vm.toggleIsHidingPanel },
      },
      [
        _c("i", { staticClass: "material-icons-round arrow" }, [
          _vm._v("arrow_forward"),
        ]),
      ]
    ),
    _c(
      "div",
      {
        class: {
          hide: _vm.isHidingPanel,
          "traffic-event-panel": !_vm.isLongdoMapV3,
          "traffic-event-panel-map3": _vm.isLongdoMapV3,
        },
      },
      [
        _c("div", { staticClass: "panel-upper" }, [
          _c(
            "button",
            {
              staticClass: "close ripple",
              on: { click: _vm.toggleIsHidingPanel },
            },
            [
              _c("i", { staticClass: "material-icons-round" }, [
                _vm._v("close"),
              ]),
            ]
          ),
          _c("div", { staticClass: "panel-header" }, [
            _c("div", { staticClass: "panel-name" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.isShowingSearchResult
                      ? _vm.$t("trafficIncidentPanel.searchResult")
                      : _vm.$t("trafficIncidentPanel.recentIncident")
                  ) +
                  " "
              ),
            ]),
            _c("div", { staticClass: "panel-button" }, [
              _c("button", { staticClass: "see-all" }, [
                _c(
                  "a",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.onClickUrl(_vm.allEventUrl)
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t("trafficIncidentPanel.seeAllRecentIncident")
                      )
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
          _vm.trafficIncident !== null
            ? _c(
                "div",
                { staticClass: "panel-content" },
                [
                  _vm._l(_vm.trafficIncidentList, function (incident) {
                    return _c(
                      "div",
                      {
                        key: incident.eid,
                        staticClass: "panel-incident",
                        style: { backgroundColor: incident.backgroundColor },
                        attrs: {
                          title:
                            incident.contributor + "\n" + incident.createtime,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.onClickIncident(incident)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "front-incident-card" }, [
                          _c("img", {
                            staticClass: "icon",
                            attrs: {
                              src:
                                "" +
                                _vm.baseIconUrl.replace(
                                  "{{icon}}",
                                  incident.icon.icon
                                ),
                            },
                          }),
                          _c("div", { staticClass: "name" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$i18n.locale === "th"
                                    ? incident.title
                                    : incident.title_en
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "end-incident-card" }, [
                          incident.imagenid !== "0"
                            ? _c("img", {
                                staticClass: "picture",
                                attrs: {
                                  src: "https://traffic.longdo.com/sites/all/themes/traffic_theme/images/front/ic_image@2x.png",
                                },
                              })
                            : _vm._e(),
                          _c("div", { staticClass: "date" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  incident.isToday
                                    ? new Date(
                                        incident.start_time
                                      ).toLocaleTimeString("th-TH", {
                                        minute: "numeric",
                                        hour: "numeric",
                                      })
                                    : new Date(
                                        incident.start_time
                                      ).toLocaleDateString(
                                        "" +
                                          (_vm.$i18n.locale === "th"
                                            ? "th-TH"
                                            : "en-US"),
                                        {
                                          year: "2-digit",
                                          month: "short",
                                          day: "numeric",
                                        }
                                      )
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                      ]
                    )
                  }),
                  _vm.isShowingSearchResult &&
                  _vm.trafficIncidentList.length !== 0 &&
                  _vm.trafficIncidentList.length >= 20 &&
                  _vm.hasMoreTrafficEvent
                    ? _c(
                        "button",
                        {
                          staticClass: "has-more",
                          attrs: {
                            title: _vm.$t("trafficIncidentPanel.moreTitle"),
                            disabled:
                              _vm.isLoadingMore || !_vm.hasMoreTrafficEvent,
                          },
                          on: { click: _vm.clickHasMore },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.isLoadingMore
                                  ? ""
                                  : _vm.$t("trafficIncidentPanel.more")
                              ) +
                              " "
                          ),
                          _vm.isLoadingMore
                            ? _c("img", {
                                attrs: {
                                  src: require("@/assets/img/loading.gif"),
                                },
                              })
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                  _vm.isShowingSearchResult &&
                  (_vm.trafficIncidentList.length === 0 ||
                    !_vm.hasMoreTrafficEvent)
                    ? _c("div", { staticClass: "no-result" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.trafficIncidentList.length === 0
                                ? _vm.$t("trafficIncidentPanel.noSearchResult")
                                : _vm.$t("trafficIncidentPanel.noMore")
                            ) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
        ]),
        _c("div", { staticClass: "panel-footer" }, [
          _c(
            "a",
            {
              on: {
                click: function ($event) {
                  return _vm.onClickUrl(_vm.itsUrl)
                },
              },
            },
            [_c("img", { attrs: { src: _vm.itsLogoUrl } })]
          ),
          _c(
            "a",
            {
              on: {
                click: function ($event) {
                  return _vm.onClickUrl(_vm.iticUrl)
                },
              },
            },
            [_c("img", { attrs: { src: _vm.iticLogoUrl } })]
          ),
          _c(
            "a",
            {
              on: {
                click: function ($event) {
                  return _vm.onClickUrl(_vm.truehitUrl)
                },
              },
            },
            [_c("img", { attrs: { src: _vm.truehitLogoUrl } })]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }