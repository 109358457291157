var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c("NavigationBarDesktop", { attrs: { map: _vm.map } }),
      _c("ImagesPreview"),
      _c(
        "div",
        {
          staticClass: "longdo-map-content",
          class: {
            "only-one": _vm.isShowingMapOnly,
            "dark-mode": _vm.darkMode,
            "light-mode": _vm.lightMode,
            "zoom-more-16": _vm.zoom > 16,
            "land-parcel-filter": _vm.landParcelFilter,
            "hide-my-places-name": !_vm.showMyPlacesName,
            "terrain-filter": _vm.useTerrainFilter,
            "showing-explore-panel":
              !_vm.isHidingExplorePanel && _vm.isExpandingExplorePanel,
            "collapsing-explore-panel":
              !_vm.isHidingExplorePanel && !_vm.isExpandingExplorePanel,
            "showing-search-result-panel":
              !_vm.isHidingSearchResultPanel &&
              _vm.isExpandingSearchResultPanel,
            "collapsing-search-result-panel":
              !_vm.isHidingSearchResultPanel &&
              !_vm.isExpandingSearchResultPanel,
            "showing-my-place-panel":
              !_vm.isHidingMyPlacePanel && _vm.isExpandingMyPlacePanel,
            "collapsing-my-place-panel":
              !_vm.isHidingMyPlacePanel && !_vm.isExpandingMyPlacePanel,
            "showing-explore-nearby-panel":
              !_vm.isHidingExploreNearbyPanel &&
              _vm.isExpandingExploreNearbyPanel,
            "collapsing-explore-nearby-panel":
              !_vm.isHidingExploreNearbyPanel &&
              !_vm.isExpandingExploreNearbyPanel,
            "showing-event-panel":
              !_vm.isHidingEventPanel && _vm.isExpandingEventPanel,
            "collapsing-event-panel":
              !_vm.isHidingEventPanel && !_vm.isExpandingEventPanel,
            "showing-popup": _vm.isShowingPopup,
            routing: _vm.$route.hash === "#routing",
            "place-card-step-full": _vm.placeCardStep === "FULL",
            "place-card-step-mid": _vm.placeCardStep === "MID",
            "place-card-step-mini": _vm.placeCardStep === "MINI",
          },
          on: {
            drop: function ($event) {
              $event.preventDefault()
              return _vm.onDropHandler.apply(null, arguments)
            },
            dragenter: function ($event) {
              $event.preventDefault()
            },
            dragover: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _vm.isLongdoMapV3
            ? _c("longdo-map-vector", { on: { load: _vm.loadedLongdoMap } })
            : _c("longdo-map", { on: { load: _vm.loadedLongdoMap } }),
        ],
        1
      ),
      _vm.islongdoMapReady || _vm.islongdoMap3Ready
        ? _c("SearchPanel", {
            ref: "searchPanel",
            attrs: { map: _vm.map },
            on: {
              beforeSearch: _vm.onBeforeSearch,
              searchLocation: _vm.onSearchLocation,
              searchResult: _vm.onSearchResult,
              searchTrafficResult: _vm.onSearchTrafficResult,
              beforeMore: _vm.onBeforeMore,
              moreResult: _vm.onMoreResult,
              noMoreTrafficEvent: _vm.onNoMoreTrafficEvent,
              beforeMoreTrafficEvent: _vm.onBeforeMoreTrafficEvent,
              clearSearch: _vm.onClearSearch,
              showTag: _vm.onShowTag,
            },
          })
        : _vm._e(),
      _vm.islongdoMapReady | _vm.islongdoMap3Ready
        ? _c("MapControlPanel", {
            attrs: {
              map: _vm.map,
              isTracking: _vm.isTracking,
              isTrackingDirection: _vm.isTrackingDirection,
              isShowingPopup: _vm.isShowingPopup,
              isGeolocationMobileAppDisabled:
                _vm.isGeolocationMobileAppDisabled,
              placeCardStep: _vm.placeCardStep,
            },
            on: { getCurrentLocation: _vm.getCurrentLocation },
          })
        : _vm._e(),
      _vm.islongdoMapReady | _vm.islongdoMap3Ready
        ? _c("SpecialMapControlPanel", {
            attrs: {
              isShowingPopup: _vm.isShowingPopup,
              placeCardStep: _vm.placeCardStep,
              map: _vm.map,
            },
            on: { getCurrentLocation: _vm.getCurrentLocation },
          })
        : _vm._e(),
      _vm.islongdoMapReady || (_vm.islongdoMap3Ready && _vm.isTrafficProduct)
        ? _c("TrafficControlPanel", {
            attrs: {
              map: _vm.map,
              isShowingPopup: _vm.isShowingPopup,
              placeCardStep: _vm.placeCardStep,
            },
            on: {
              getCurrentLocation: _vm.getCurrentLocation,
              showTag: _vm.onShowTag,
              hideTag: _vm.onHideTag,
            },
          })
        : _vm._e(),
      _vm.islongdoMapReady || _vm.islongdoMap3Ready
        ? _c("SearchResultPanel", {
            attrs: {
              searchResultList: _vm.searchResultList,
              showLoading: _vm.isSearching,
              showLoadingMore: _vm.isSearchingMore,
              hasMore: _vm.hasMoreSearchResult,
              userData: _vm.user,
            },
            on: {
              clickSearchResult: _vm.onClickSearchResult,
              clickHasMore: _vm.onClickHasMore,
            },
          })
        : _vm._e(),
      _vm.islongdoMapReady
        ? _c("MeasurementControlPanel", {
            on: { clickClearMeasurement: _vm.clickClearMeasurement },
          })
        : _vm._e(),
      _vm.islongdoMapReady || _vm.islongdoMap3Ready
        ? _c("ToolBar", {
            attrs: {
              isReportPanelOpened: _vm.isShowReportEventFormPanel,
              isGeolocationMobileAppDisabled:
                _vm.isGeolocationMobileAppDisabled,
            },
            on: { clickCheckNearby: _vm.onClickCheckNearby },
          })
        : _vm._e(),
      _vm.islongdoMapReady
        ? _c("LayerPanel", {
            attrs: { map: _vm.map },
            on: {
              clickClose: _vm.clickCloseLayerPanel,
              showTrafficEvent: _vm.showMapTrafficEventHandler,
              hideTrafficEvent: _vm.hideMapTrafficEventHandler,
            },
          })
        : _vm._e(),
      _vm.islongdoMap3Ready
        ? _c("Map3LayerPanel", {
            attrs: { map: _vm.map, baseMap: _vm.baseMap },
            on: {
              clickClose: _vm.clickCloseLayerPanel,
              showTrafficEvent: _vm.showMapTrafficEventHandler,
              hideTrafficEvent: _vm.hideMapTrafficEventHandler,
            },
          })
        : _vm._e(),
      _vm.islongdoMapReady || _vm.islongdoMap3Ready
        ? _c("MapTypePanel", {
            attrs: { map: _vm.map },
            on: {
              clickMapType: function (e) {
                return _vm.isLongdoMapV3
                  ? _vm.clickMapTypeV3(e)
                  : _vm.clickMapType(e)
              },
              clickClose: _vm.clickCloseMapTypePanel,
            },
          })
        : _vm._e(),
      (_vm.islongdoMapReady || _vm.islongdoMap3Ready) &&
      !_vm.isGeolocationMobileAppDisabled
        ? _c("ExplorePanel", {
            attrs: {
              map: _vm.map,
              isGeolocationMobileAppDisabled:
                _vm.isGeolocationMobileAppDisabled,
            },
            on: {
              beforeExplore: _vm.onBeforeExplore,
              exploreResult: _vm.onExploreResult,
              beforeExploreMore: _vm.onBeforeExploreMore,
              exploreMoreResult: _vm.onExploreMoreResult,
              clickExploreResult: _vm.onClickExploreResult,
            },
          })
        : _vm._e(),
      _vm.islongdoMapReady ||
      (_vm.islongdoMap3Ready && _vm.exploreNearbyCenter !== null)
        ? _c("ExploreNearbyPanel", {
            attrs: { center: _vm.exploreNearbyCenter },
            on: {
              beforeExplore: _vm.onBeforeExplore,
              exploreResult: _vm.onExploreResult,
              beforeExploreMore: _vm.onBeforeExploreMore,
              exploreMoreResult: _vm.onExploreMoreResult,
              clickExploreResult: _vm.onClickExploreResult,
            },
          })
        : _vm._e(),
      _c("MyPlacePanel", { on: { clickMyPlace: _vm.onClickMyPlace } }),
      _c("EventPanel", {
        attrs: { map: _vm.map },
        on: {
          loadedEventList: _vm.onLoadedEventList,
          clickEventRow: _vm.onClickEventRow,
          clickEventInfo: _vm.onClickEventInfo,
          fireTrafficIncidentClick: _vm.onFireTrafficIncidentClick,
        },
      }),
      _vm.islongdoMapReady || _vm.islongdoMap3Ready
        ? _c("BadgeInformation", {
            attrs: {
              map: _vm.map,
              isGeolocationMobileAppDisabled:
                _vm.isGeolocationMobileAppDisabled,
            },
          })
        : _vm._e(),
      _c(
        "button",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isShowingMainMenuButton,
              expression: "isShowingMainMenuButton",
            },
          ],
          ref: "main-menu",
          staticClass: "main-menu ripple",
          class: {
            hide: _vm.isShowingMapOnly,
            "ldmap-toggle": _vm.isLongdoMapV3,
          },
          on: { click: _vm.clickMainMenu },
        },
        [_c("i", { staticClass: "material-icons-round" }, [_vm._v(" menu ")])]
      ),
      _c("Settings"),
      _c("PdpaManagement"),
      _vm.islongdoMapReady || _vm.islongdoMap3Ready
        ? _c("RoutingPanel", {
            attrs: {
              map: _vm.map,
              baseMap: _vm.baseMap,
              userData: _vm.user,
              isGeolocationMobileAppDisabled:
                _vm.isGeolocationMobileAppDisabled,
            },
          })
        : _vm._e(),
      (_vm.islongdoMap3Ready || _vm.islongdoMapReady) &&
      _vm.isOnMobileApp &&
      !_vm.isGeolocationMobileAppDisabled
        ? _c("MobileWarningPopUp", {
            attrs: { warningPoint: _vm.warningPoint, map: _vm.map },
          })
        : _vm._e(),
      _c("NearbyDialog", {
        attrs: { message: _vm.nearbyDataFeature.nearbyMessage },
        on: {
          close: function ($event) {
            _vm.isShowNearbyDialog = false
          },
        },
        model: {
          value: _vm.isShowNearbyDialog,
          callback: function ($$v) {
            _vm.isShowNearbyDialog = $$v
          },
          expression: "isShowNearbyDialog",
        },
      }),
      _c("SelectNearbyTagDialog", {
        on: {
          close: _vm.onCloseSelectNearbyTagDialog,
          selectNearbyTag: _vm.checkNearby,
        },
        model: {
          value: _vm.isShowSelectNearbyTagDialog,
          callback: function ($$v) {
            _vm.isShowSelectNearbyTagDialog = $$v
          },
          expression: "isShowSelectNearbyTagDialog",
        },
      }),
      _vm.isShowSharePlaceWidget
        ? _c("SharePlaceWidget", {
            attrs: {
              poiId: _vm.share.poiId,
              lat: _vm.share.lat,
              lon: _vm.share.lon,
              isShareInfoPage: false,
            },
            on: {
              close: function ($event) {
                _vm.isShowSharePlaceWidget = false
              },
            },
          })
        : _vm._e(),
      _vm.islongdoMapReady || _vm.islongdoMap3Ready
        ? _c("PlaceCard", {
            attrs: {
              poiId: _vm.$route.params.poiId,
              userData: _vm.user,
              map: _vm.map,
            },
            on: {
              changeStep: _vm.onChangePlaceCardStep,
              showMessageToast: _vm.showMessageToast,
            },
          })
        : _vm._e(),
      (_vm.islongdoMapReady || _vm.islongdoMap3Ready) && _vm.isTrafficProduct
        ? _c("TrafficIncidentPanel", {
            attrs: {
              trafficIncident: _vm.trafficIncident,
              trafficEventType: _vm.trafficEventType,
              map: _vm.map,
              isLoadingMore: _vm.isSearchingMoreTrafficEvent,
              hasMoreTrafficEvent: _vm.hasMoreTrafficEvent,
              hasOpenReportEventForm: _vm.isShowReportEventFormPanel,
            },
            on: {
              clickHasMoreTrafficEvent: _vm.onClickHasMoreTrafficEvent,
              fireTrafficIncidentClick: _vm.onFireTrafficIncidentClick,
            },
          })
        : _vm._e(),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.isShowToast
          ? _c("div", { staticClass: "copied" }, [
              _vm._v(_vm._s(_vm.toastMessage)),
            ])
          : _vm._e(),
      ]),
      (_vm.islongdoMapReady || _vm.islongdoMap3Ready) &&
      _vm.isShowReportEventFormPanel
        ? _c("ReportEventFormPanel", {
            key: _vm.showReportEventFormKey,
            attrs: {
              map: _vm.map,
              userData: _vm.user,
              trafficIncident: _vm.trafficIncident,
              location: _vm.tempLocationByContext,
              editMode: _vm.eventEditMode,
              idEventTypeClick: _vm.idEventType,
            },
            on: {
              successReportEvent: _vm.handleSuccessSaveReportEvent,
              clickClose: _vm.clickCloseReportEventPanel,
            },
          })
        : _vm._e(),
      _vm.confirmDeleteEvent ? _c("ConfirmDeleteEventDialogVue") : _vm._e(),
      _vm.isLoadingDelete
        ? _c("div", { staticClass: "loading-overlay" }, [
            !_vm.isDeletedEventComplete && !_vm.isDeleteEventError
              ? _c("img", {
                  attrs: {
                    src: require("@/assets/img/loading.gif"),
                    alt: "loading",
                  },
                })
              : _vm._e(),
            _vm.isDeleteEventError
              ? _c("span", { staticClass: "error-message" }, [
                  _vm._v(_vm._s(_vm.$t("reportEventForm.deletedError"))),
                ])
              : _vm._e(),
            _vm.isDeletedEventComplete
              ? _c("span", { staticClass: "success-message" }, [
                  _vm._v(_vm._s(_vm.$t("reportEventForm.deleted"))),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }