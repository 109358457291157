var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          ref: "place-card",
          class: {
            "place-card": !_vm.isLongdoMapV3,
            "place-card-map3": _vm.isLongdoMapV3,
            animate: _vm.isAnimate,
            full: _vm.step === "FULL",
            mid: _vm.step === "MID",
            mini: _vm.step === "MINI",
            hide: _vm.isHide,
            empty: _vm.isEmptyPlaceDetail,
            draging: _vm.canDrag,
          },
          style: { top: _vm.top },
          on: {
            mousedown: _vm.onMouseDown,
            mousemove: _vm.onMouseMove,
            mouseup: _vm.onMouseUp,
            touchstart: _vm.onTouchStart,
            touchmove: _vm.onTouchMove,
            touchend: _vm.onTouchEnd,
          },
        },
        [
          _c(
            "div",
            {
              ref: "head",
              staticClass: "head",
              class: { traffic: _vm.product === "traffic" },
              on: { click: _vm.onClickHead },
            },
            [
              _c(
                "button",
                {
                  staticClass: "close ripple",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.onClickClose.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("i", { staticClass: "material-icons-round" }, [
                    _vm._v(" arrow_back "),
                  ]),
                ]
              ),
              _c("div", { staticClass: "type" }, [
                _c("img", {
                  attrs: {
                    src: _vm.placeCategoryIconSrc,
                    title: _vm.placeCategoryLabel,
                    alt: _vm.placeCategoryLabel,
                  },
                }),
              ]),
              _c(
                "div",
                {
                  staticClass: "name",
                  attrs: { title: _vm.placeName },
                  on: { click: _vm.copyPlaceName },
                },
                [_vm._v(_vm._s(_vm.placeName))]
              ),
              _c(
                "div",
                {
                  staticClass: "sub-name",
                  attrs: { title: _vm.subPlaceName },
                  on: { click: _vm.copySubPlaceName },
                },
                [_vm._v(_vm._s(_vm.subPlaceName))]
              ),
            ]
          ),
          !_vm.isLoading
            ? _c("div", { ref: "body", staticClass: "body" }, [
                _vm.thumbnailSrc
                  ? _c("div", {
                      staticClass: "thumbnail",
                      style: {
                        height: _vm.thumbnailHeight,
                        backgroundImage:
                          "url('" + (_vm.thumbnailSrc || "") + "')",
                      },
                    })
                  : _vm._e(),
                !_vm.isEmptyPlaceDetail
                  ? _c("div", { staticClass: "action-list" }, [
                      !_vm.isMarkFav && _vm.isLongdoPoi
                        ? _c(
                            "button",
                            {
                              staticClass: "ripple",
                              attrs: { disabled: _vm.isUpdatingFav },
                              on: { click: _vm.onClickFav },
                            },
                            [
                              _c("i", { staticClass: "material-icons-round" }, [
                                _vm._v("star_border"),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm.isAuthenticated && _vm.isMarkFav && _vm.isLongdoPoi
                        ? _c(
                            "button",
                            {
                              staticClass: "ripple",
                              attrs: { disabled: _vm.isUpdatingFav },
                              on: { click: _vm.onClickUnfav },
                            },
                            [
                              _c("i", { staticClass: "material-icons-round" }, [
                                _vm._v("star"),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "button",
                        {
                          staticClass: "ripple",
                          on: { click: _vm.onClickDirection },
                        },
                        [
                          _c("i", { staticClass: "material-icons-round" }, [
                            _vm._v("navigation"),
                          ]),
                        ]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "ripple",
                          on: { click: _vm.onClickShare },
                        },
                        [
                          _c("i", { staticClass: "material-icons-round" }, [
                            _vm._v("share"),
                          ]),
                        ]
                      ),
                      _vm.canEdit && !_vm.isTrafficProduct && _vm.isLongdoPoi
                        ? _c(
                            "button",
                            {
                              staticClass: "ripple",
                              on: { click: _vm.onClickEdit },
                            },
                            [
                              _c("i", { staticClass: "material-icons-round" }, [
                                _vm._v("edit"),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm.canDelete && !_vm.isTrafficProduct && _vm.isLongdoPoi
                        ? _c(
                            "button",
                            {
                              staticClass: "ripple",
                              on: { click: _vm.onClickDelete },
                            },
                            [
                              _c("i", { staticClass: "material-icons-round" }, [
                                _vm._v("delete"),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      (!_vm.canEdit || (_vm.canEdit && !_vm.canDelete)) &&
                      !_vm.isTrafficProduct
                        ? _c(
                            "button",
                            {
                              staticClass: "ripple",
                              attrs: { disabled: !_vm.isLongdoPoi },
                              on: { click: _vm.onClickReport },
                            },
                            [
                              _c("i", { staticClass: "material-icons-round" }, [
                                _vm._v("report"),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm.isNewStatus
                  ? _c("div", { staticClass: "new-status notice" }, [
                      _vm._v("! " + _vm._s(_vm.$t("placeInfo.newStatus"))),
                    ])
                  : _vm._e(),
                _vm.isUnverifyStatus
                  ? _c("div", { staticClass: "new-status notice" }, [
                      _vm._v("! " + _vm._s(_vm.$t("placeInfo.newStatus"))),
                    ])
                  : _vm._e(),
                _vm.isObsoletedStatus
                  ? _c("div", { staticClass: "obsoleted-status notice" }, [
                      _vm._v(
                        "! " + _vm._s(_vm.$t("placeInfo.obsoletedStatus"))
                      ),
                    ])
                  : _vm._e(),
                _vm.isDeletedStatus || _vm.isEmptyPlaceDetail
                  ? _c("div", { staticClass: "deleted-status notice" }, [
                      _vm._v("! " + _vm._s(_vm.$t("placeInfo.deletedStatus"))),
                    ])
                  : _vm._e(),
                _vm.isRejectedStatus
                  ? _c("div", { staticClass: "rejected-status notice" }, [
                      _vm._v("! " + _vm._s(_vm.$t("placeInfo.rejectedStatus"))),
                    ])
                  : _vm._e(),
                !_vm.isEmptyPlaceDetail && _vm.isPromptPai
                  ? _c("div", { staticClass: "field" }, [
                      _vm.isPromptPai
                        ? _c("iframe", {
                            staticStyle: {
                              border: "none",
                              overflow: "hidden",
                              margin: "0 0 0 0",
                            },
                            attrs: {
                              src: _vm.promptPaiIframe,
                              width: "119",
                              height: "26",
                              allowTransparency: "true",
                              allow: "encrypted-media",
                            },
                          })
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm.dataSource !== null && !_vm.isLongdoPoi
                  ? _c("div", { staticClass: "field" }, [
                      _c("div", { staticClass: "field-icon" }, [
                        _c("img", {
                          attrs: {
                            src: _vm.baseUrl + "img/datasource_icon.svg",
                          },
                        }),
                      ]),
                      _c("div", { staticClass: "content" }, [
                        _c(
                          "a",
                          {
                            staticStyle: { cursor: "pointer" },
                            on: {
                              click: function ($event) {
                                return _vm.onClickUrl(_vm.dataSource.url)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.dataSource.name) + " ")]
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.address
                  ? _c("div", { staticClass: "field" }, [
                      _vm._m(0),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(" " + _vm._s(_vm.address) + " "),
                      ]),
                    ])
                  : _vm._e(),
                _vm.connectorList.length > 0
                  ? _c("div", { staticClass: "field connector-list" }, [
                      _vm._m(1),
                      _c("div", { staticClass: "content" }, [
                        _c(
                          "ul",
                          _vm._l(
                            _vm.connectorList,
                            function (connector, index) {
                              return _c("div", { key: index }, [
                                _c("span", { staticClass: "total-available" }, [
                                  _vm._v(_vm._s(connector.total_available)),
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(connector.charger_type_name) +
                                    " " +
                                    _vm._s(connector.connector_type_name) +
                                    " " +
                                    _vm._s(connector.discharge_electricity) +
                                    " "
                                ),
                              ])
                            }
                          ),
                          0
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.busList.length > 0
                  ? _c("div", { staticClass: "field" }, [
                      _vm._m(2),
                      _c("div", { staticClass: "content" }, [
                        _c(
                          "div",
                          { staticClass: "bus-list" },
                          _vm._l(_vm.busList, function (bus, i) {
                            return _c(
                              "span",
                              {
                                key: "bus-" + i,
                                staticClass: "bus ripple",
                                on: {
                                  click: function ($event) {
                                    return _vm.onClickBus(bus)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(bus.display))]
                            )
                          }),
                          0
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.parentPlaceName
                  ? _c("div", { staticClass: "field" }, [
                      _c(
                        "div",
                        {
                          staticClass: "field-icon",
                          on: {
                            touchstart: function ($event) {
                              return _vm.onTouchstartIcon($event)
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "material-icons-round" }, [
                            _vm._v("home_work"),
                          ]),
                        ]
                      ),
                      _c("div", { staticClass: "content" }, [
                        _c(
                          "a",
                          {
                            staticStyle: { cursor: "pointer" },
                            on: { click: _vm.gotoParentPlaceInfo },
                          },
                          [_vm._v(_vm._s(_vm.parentPlaceName))]
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.workingHours
                  ? _c("div", { staticClass: "field" }, [
                      _vm._m(3),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(" " + _vm._s(_vm.workingHours) + " "),
                      ]),
                    ])
                  : _vm._e(),
                !_vm.isLongdoPoi && _vm.otherSourceTelNum
                  ? _c("div", { staticClass: "field" }, [
                      _vm._m(4),
                      _c("div", { staticClass: "content" }, [
                        _c(
                          "a",
                          { attrs: { href: "tel:" + _vm.otherSourceTelNum } },
                          [_vm._v(" " + _vm._s(_vm.otherSourceTelNum) + " ")]
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.mobileList.length > 0
                  ? _c("div", { staticClass: "field" }, [
                      _vm._m(5),
                      _c("div", { staticClass: "content" }, [
                        _c(
                          "div",
                          { staticClass: "col" },
                          _vm._l(_vm.mobileList, function (mobile, i) {
                            return _c("div", { key: "mobile-" + i }, [
                              _c("div", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.formatPhoneNumber(mobile)
                                  ),
                                },
                              }),
                              mobile.remark
                                ? _c("div", { staticClass: "contact-remark" }, [
                                    _vm._v(_vm._s(mobile.remark)),
                                  ])
                                : _vm._e(),
                            ])
                          }),
                          0
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.telList.length > 0
                  ? _c("div", { staticClass: "field" }, [
                      _vm._m(6),
                      _c("div", { staticClass: "content" }, [
                        _c(
                          "div",
                          { staticClass: "col" },
                          _vm._l(_vm.telList, function (tel, i) {
                            return _c(
                              "div",
                              { key: "tel-" + i, staticClass: "tel-content" },
                              [
                                _c("div", { staticClass: "tel" }, [
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.formatPhoneNumber(tel)
                                      ),
                                    },
                                  }),
                                ]),
                                tel.remark
                                  ? _c(
                                      "div",
                                      { staticClass: "contact-remark" },
                                      [_vm._v(_vm._s(tel.remark))]
                                    )
                                  : _vm._e(),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.faxList.length > 0
                  ? _c("div", { staticClass: "field" }, [
                      _vm._m(7),
                      _c("div", { staticClass: "content" }, [
                        _c(
                          "div",
                          { staticClass: "col" },
                          _vm._l(_vm.faxList, function (fax, i) {
                            return _c("div", { key: "fax-" + i }, [
                              _c("div", {
                                domProps: {
                                  innerHTML: _vm._s(_vm.formatPhoneNumber(fax)),
                                },
                              }),
                              fax.remark
                                ? _c("div", { staticClass: "contact-remark" }, [
                                    _vm._v(_vm._s(fax.remark)),
                                  ])
                                : _vm._e(),
                            ])
                          }),
                          0
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.specialTelList.length > 0
                  ? _c("div", { staticClass: "field" }, [
                      _vm._m(8),
                      _c("div", { staticClass: "content" }, [
                        _c(
                          "div",
                          { staticClass: "col" },
                          _vm._l(_vm.specialTelList, function (tel, i) {
                            return _c("div", { key: "tel-" + i }, [
                              _c("div", {
                                domProps: {
                                  innerHTML: _vm._s(_vm.formatPhoneNumber(tel)),
                                },
                              }),
                              tel.remark
                                ? _c("div", { staticClass: "contact-remark" }, [
                                    _vm._v(_vm._s(tel.remark)),
                                  ])
                                : _vm._e(),
                            ])
                          }),
                          0
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.lineList.length > 0
                  ? _c("div", { staticClass: "field" }, [
                      _c("div", { staticClass: "field-icon" }, [
                        _c("div", {
                          staticClass: "image",
                          style: {
                            backgroundImage: "url('" + _vm.lineLogoUrl + "')",
                          },
                        }),
                      ]),
                      _c("div", { staticClass: "content" }, [
                        _c(
                          "div",
                          { staticClass: "col" },
                          _vm._l(_vm.lineList, function (line, i) {
                            return _c("div", { key: "line-" + i }, [
                              line.id
                                ? _c("span", [_vm._v(_vm._s(line.id))])
                                : _c(
                                    "a",
                                    {
                                      staticStyle: { cursor: "pointer" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onClickUrl(
                                            "https://line.me/ti/p/" + line.link
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(line.link))]
                                  ),
                              line.remark
                                ? _c("div", { staticClass: "contact-remark" }, [
                                    _vm._v(_vm._s(line.remark)),
                                  ])
                                : _vm._e(),
                            ])
                          }),
                          0
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.website
                  ? _c("div", { staticClass: "field" }, [
                      _vm._m(9),
                      _c("div", { staticClass: "content" }, [
                        _c(
                          "a",
                          {
                            staticStyle: { cursor: "pointer" },
                            on: {
                              click: function ($event) {
                                return _vm.onClickUrl(_vm.website)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.website))]
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.facebook
                  ? _c("div", { staticClass: "field" }, [
                      _vm._m(10),
                      _c("div", { staticClass: "content" }, [
                        _c(
                          "a",
                          {
                            staticStyle: { cursor: "pointer" },
                            on: {
                              click: function ($event) {
                                return _vm.onClickUrl(_vm.facebook)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.facebook))]
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.bookingcomUrl
                  ? _c("div", { staticClass: "field" }, [
                      _vm._m(11),
                      _c("div", { staticClass: "content" }, [
                        _c(
                          "a",
                          {
                            staticStyle: { cursor: "pointer" },
                            on: {
                              click: function ($event) {
                                return _vm.onClickUrl(_vm.bookingcomUrl)
                              },
                            },
                          },
                          [_vm._v("Booking.com")]
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.placeTagList.length > 0
                  ? _c("div", { staticClass: "field" }, [
                      _vm._m(12),
                      _c("div", { staticClass: "content" }, [
                        _c(
                          "div",
                          { staticClass: "tag-list" },
                          _vm._l(_vm.placeTagList, function (tag, i) {
                            return _c(
                              "span",
                              {
                                key: "tag-" + i,
                                staticClass: "tag ripple",
                                on: {
                                  click: function ($event) {
                                    return _vm.onClickTag(tag)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(tag))]
                            )
                          }),
                          0
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.address_detail
                  ? _c("div", { staticClass: "field" }, [
                      _vm._m(13),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(" " + _vm._s(_vm.address_detail) + " "),
                      ]),
                    ])
                  : _vm._e(),
                _vm.showContributor() !== null && _vm.isLongdoPoi
                  ? _c("div", { staticClass: "field" }, [
                      _vm._m(14),
                      _c("div", { staticClass: "content" }, [
                        _vm.showContributor().url
                          ? _c(
                              "a",
                              {
                                staticStyle: { cursor: "pointer" },
                                on: {
                                  click: function ($event) {
                                    _vm.onClickUrl(_vm.showContributor().url)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.showContributor().name) + " "
                                ),
                              ]
                            )
                          : _c("span", [
                              _vm._v(
                                " " + _vm._s(_vm.showContributor().name) + " "
                              ),
                            ]),
                      ]),
                    ])
                  : _vm._e(),
                _vm.descriptionHtml
                  ? _c("div", { staticClass: "field full-description" }, [
                      _vm._m(15),
                      _c("div", {
                        staticClass: "content",
                        domProps: { innerHTML: _vm._s(_vm.descriptionHtml) },
                      }),
                    ])
                  : _vm._e(),
                _vm.imageList.length > 0
                  ? _c("div", { staticClass: "field" }, [
                      _vm._m(16),
                      _c("div", { staticClass: "content" }, [
                        _c("div", { staticClass: "col" }, [
                          _c(
                            "div",
                            { staticClass: "image-list row" },
                            _vm._l(_vm.imageList, function (image, i) {
                              return _c("div", {
                                key: i,
                                staticClass: "image",
                                style: {
                                  borderRadius: "10px",
                                  width: _vm.imageWidth,
                                  height: _vm.imageHeight,
                                  backgroundSize: "cover",
                                  backgroundImage:
                                    "url('" + image.preview + "')",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.onPreviewImage(i)
                                  },
                                },
                              })
                            }),
                            0
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _c("div", {
                  staticClass: "date",
                  domProps: { innerHTML: _vm._s(_vm.date) },
                }),
              ])
            : _c("div", [_vm._m(17)]),
          _c("div", { staticClass: "place-footer" }),
        ]
      ),
      _vm.requestDelete ? _c("ConfirmDeletePlaceDialogVue") : _vm._e(),
      _vm.isShowSharePlaceWidget
        ? _c("SharePlaceWidget", {
            staticStyle: { position: "fixed" },
            attrs: {
              poiId: _vm.poiId,
              lat: _vm.lat,
              lon: _vm.lon,
              isShareInfoPage: false,
            },
            on: {
              close: function ($event) {
                _vm.isShowSharePlaceWidget = false
              },
            },
          })
        : _vm._e(),
      _vm.isDeleting
        ? _c("div", { staticClass: "loading-overlay" }, [
            _c("div", { staticClass: "background" }),
            _c("img", {
              attrs: {
                src: require("@/assets/img/loading.gif"),
                alt: "loading",
              },
            }),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-icon" }, [
      _c("i", { staticClass: "material-icons-round" }, [_vm._v("place")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-icon" }, [
      _c("i", { staticClass: "material-icons-round" }, [
        _vm._v("electrical_services"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-icon" }, [
      _c("i", { staticClass: "material-icons-round" }, [
        _vm._v("directions_bus"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-icon" }, [
      _c("i", { staticClass: "material-icons-round" }, [_vm._v("schedule")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-icon" }, [
      _c("i", { staticClass: "material-icons-round" }, [_vm._v("phone")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-icon" }, [
      _c("i", { staticClass: "material-icons-round" }, [
        _vm._v("phone_iphone"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-icon" }, [
      _c("i", { staticClass: "material-icons-round" }, [_vm._v("phone")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-icon" }, [
      _c("i", { staticClass: "material-icons-round" }, [_vm._v("print")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-icon" }, [
      _c("i", { staticClass: "material-icons-round" }, [
        _vm._v("support_agent"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-icon" }, [
      _c("i", { staticClass: "material-icons-round" }, [_vm._v("public")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-icon" }, [
      _c("i", { staticClass: "material-icons-round" }, [_vm._v("facebook")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-icon" }, [
      _c("i", { staticClass: "material-icons-round" }, [_vm._v("hotel")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-icon" }, [
      _c("i", { staticClass: "material-icons-round" }, [_vm._v("local_offer")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-icon" }, [
      _c("i", { staticClass: "material-icons-round" }, [_vm._v("info")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-icon" }, [
      _c("i", { staticClass: "material-icons-round" }, [_vm._v("person")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-icon" }, [
      _c("i", { staticClass: "material-icons-round" }, [_vm._v("description")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-icon" }, [
      _c("i", { staticClass: "material-icons-round" }, [_vm._v("image")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { display: "flex", "justify-content": "center" } },
      [
        _c("div", { staticClass: "ldmap-loading-ripple" }, [
          _c("div"),
          _c("div"),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }